import React from 'react'
import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import memoize from 'memoize-one'

import InputDateDialog from '../Components/InputDateDialog'
import FileUploadComponent from '../FileUtils/FileUploadComponent'
import FileDownloadLinkComponent from '../FileUtils/FileDownloadLinkComponent'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 2
  },
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  }
})

const employeeRecordKeys = {
  name: 'nombre',
  lastName: 'apellido',
  birthDate: 'fechaNacimiento',
  phone: 'telefono',
  email: 'email',
  address: 'direccion',
  city: 'comuna',
  healthInsurance: 'salud',
  healthStatus: 'Declaracion de salud',
  pension: 'afp',
  files: 'archivoCarnetIdentidad'
}

const employeeContractKeys = {
  beginDate: 'fechaInicio',
  expirationDate: 'fechaTermino',
  bonuses: 'bonos',
  endDate: 'fechaFiniquito',
  farm: 'predio',
  labours: 'labores',
  reportsTo: 'supervisor',
  schedule: 'horario',
  name: 'tipo',
  paymentMethodType: 'medioDePago',
  files: 'archivoContratoEscaneado'
}

const bankKeys = {
  bankName: 'Banco',
  bankAccountNumber: 'Numero de cuenta',
  bankAccountType: 'Tipo de cuenta',
  bankAccountHolderName: 'Titular',
  bankAccountHolderId: 'Rut titular'
}

class EmployeesView extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openDialog: false, viewContractDialog: false, viewContractData: '' }
  }

  edit = (type) => {
    if (type === 'employeeData') {
      this.props.history.push(`/app/employees/edit/employee`, { employeeId: this.props.employeeId })
    }
    if (type === 'contractData') {
      this.props.history.push(`/app/employees/edit/contract`, { employeeId: this.props.employeeId })
    }
  }

  employeeContract = memoize((employeeContract, farms, employeeAccounts) => reduce(employeeContract, (result, value, key) => {
    if (key === 'farm' && value) value = farms[`farm/${value}`] ? farms[`farm/${value}`].displayName : 'n/d'
    if (key === 'paymentMethod' && value) {
      const account = filter(employeeAccounts, (acc) => acc.id === value.id)[0] || { bankName: 'n/d', bankAccountNumber: 'n/d' }
      result.paymentMethodType = `${startCase(account.bankName)} / ${account.bankAccountNumber}`
    }

    result[key] = value
    result.name = startCase((this.props.employeeContractType && this.props.employeeContractType.name) || 'omitido')
    return result
  }, {}))

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog })
  }

  toggleViewContractDialog = () => {
    this.setState({ viewContractDialog: !this.state.viewContractDialog })
  }

  onCloseTerminateContractDialog = (data) => {
    const ok = window.confirm(`Seguro finiquitar contrato con fecha ${data} ?`)
    if (ok) {
      this.props.terminateContract({ employeeId: this.props.employeeId, endDate: data }, () => {
        this.props.history.goBack()
      })
    }
  }

  searchPreviousContracts = () => {
    this.props.getContracts(this.props.employeeId, (err, allEmployeeContracts) => {
      if (err) {
        window.alert(err)
      } else {
        this.props.history.push(`/app/employees/contracts`, { allEmployeeContracts, employeeId: this.props.employeeId })
      }
    })
  }

  deletePaymentMethod = (payload) => {
    const ok = window.confirm(`Seguro que desea eliminar la cuenta ${payload.bankAccount.bankAccountNumber}`)
    if (ok) {
      this.props.deletePaymentMethod(payload, (err, res) => {
        if (err) window.alert(err)
      })
    }
  }

  viewContract = () => {
    this.props.buildContractHtml(this.props.employeeContract, this.props.employeeRecord, this.props.employeeContractType, this.props.farmRecords, this.props.userAccess, (err, html) => {
      if (!err) {
        this.setState({ viewContractData: html })
        this.toggleViewContractDialog()
      }
    })
  }

  onFileUploadContract = (err, res) => {
    if (err) {
      window.alert(err)
    } else {
      const contractFiles = map(res.data, (d) => d.fileRecordName)
      this.props.uploadContractFiles({ contractFiles, employeeId: this.props.employeeId })
    }
  }

  onFileUploadEmployee = (err, res) => {
    if (err) {
      window.alert(err)
    } else {
      const employeeFiles = map(res.data, (d) => d.fileRecordName)
      this.props.editPath({ path: 'files', data: employeeFiles, employeeId: this.props.employeeId })
    }
  }

  render () {
    const { classes } = this.props
    const employeeContract = this.employeeContract(this.props.employeeContract, this.props.farmRecords, this.props.employeeAccounts)

    return (
      <div>
        <Card>
          <CardHeader
            title='Datos trabajador'
          />
          <CardContent>
            <Typography variant='body1' gutterBottom>
              RUT: {this.props.employeeId}
            </Typography>
            {map(this.props.employeeRecord, (value, key) => {
              if (value && includes(keys(employeeRecordKeys), key)) {
                if (key === 'files') {
                  return (
                    <div key={key}>
                      <Typography variant='body1' gutterBottom>
                        {startCase(employeeRecordKeys[key])}:
                      </Typography>
                      {
                        map(value, (file, idx) => (<div key={idx}><FileDownloadLinkComponent
                          fileRecordName={file}
                          name={`Archivo ${idx + 1}`}
                        /> &nbsp;</div>
                        ))
                      }
                      <br />
                    </div>
                  )
                }

                return <Typography variant='body1' gutterBottom key={key}>
                  {startCase(employeeRecordKeys[key])}: {value}
                </Typography>
              }
            })}
          </CardContent>
          {
            this.props.userAccess < 4
              ? <CardActions>
                <Button size='small' color='primary' className={classes.button} onClick={() => this.edit('employeeData')}>
                Editar
                </Button>
                <FileUploadComponent
                  buttonText={isEmpty(this.props.employeeRecord.files) ? 'Adjuntar carnet identidad' : 'Actualizar archivo carnet identidad'}
                  onFileUpload={this.onFileUploadEmployee}
                />

              </CardActions>
              : <></>
          }
        </Card>

        <br />

        <Card>
          <CardHeader
            title={employeeContract.endDate ? 'Datos contrato: finiquitado' : 'Datos contrato'}
          />
          <CardContent>
            {map(employeeContract, (value, key) => {
              if (key === 'undeclared' && value) {
                return <Typography variant='h6' gutterBottom key={key}>
                  Contrato no declarado
                </Typography>
              }
              if (!isEmpty(value) && includes(keys(employeeContractKeys), key)) {
                if (key === 'files') {
                  return (
                    <div key={key}>
                      <Typography variant='body1' gutterBottom>
                        {startCase(employeeContractKeys[key])}:
                      </Typography>
                      {
                        map(value, (file, idx) => (<div key={idx}><FileDownloadLinkComponent
                          fileRecordName={file}
                          name={`Archivo ${idx + 1}`}
                        /> &nbsp;</div>
                        ))
                      }
                      <br />
                    </div>
                  )
                }

                return <Typography variant='body1' gutterBottom key={key}>
                  {startCase(employeeContractKeys[key])}: {value}
                </Typography>
              }
            })}
          </CardContent>
          <CardActions>
            <Button size='small' color='primary' className={classes.button} onClick={() => this.viewContract()}>
              Ver Contrato
            </Button>
            {
              this.props.userAccess < 4
                ? <>
                  <Button size='small' disabled={employeeContract.endDate} color='primary' className={classes.button} onClick={() => this.edit('contractData')}>
                    Editar Contrato
                  </Button>
                  <Button size='small' color='primary' className={classes.button} onClick={() => this.toggleDialog()}>
                    Finiquitar Contrato
                  </Button>
                  <Button size='small' color='primary' className={classes.button} onClick={() => this.searchPreviousContracts()}>
                    Contratos previos
                  </Button>
                  <FileUploadComponent
                    buttonText={isEmpty(this.props.employeeContract.files) ? 'Adjuntar contrato escaneado' : 'Actualizar contrato escaneado'}
                    onFileUpload={this.onFileUploadContract}
                  />
                </>
                : <></>
            }
          </CardActions>
        </Card>

        <br />

        <Card>
          <CardHeader
            title='Medios de pago trabajador'
          />
          <CardContent>
            {map(this.props.employeeAccounts, (value, idx) => {
              return (
                <div key={idx}>
                  {
                    map(value, (item, key) => {
                      if (includes(keys(bankKeys), key)) {
                        return (
                          <Typography variant='body1' gutterBottom key={key}>
                            {bankKeys[key]}: {startCase(item)}
                          </Typography>)
                      }
                    })
                  }
                  {
                    this.props.userAccess < 4
                      ? <Tooltip title='Eliminar'>
                        <IconButton onClick={() => this.deletePaymentMethod({ bankAccount: value, employeeContract, employeeId: this.props.employeeId })}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      : <></>
                  }

                  <Divider />
                  <br />
                </div>)
            })}
          </CardContent>
          {
            this.props.userAccess < 4
              ? <CardActions>
                <Button size='small' color='primary' className={classes.button} onClick={() => this.props.history.push(`/app/employees/register/payment-method`, { employeeId: this.props.employeeId, goBack: true })}>
                Agregar
                </Button>
              </CardActions>
              : <></>
          }
        </Card>
        <InputDateDialog
          open={this.state.openDialog}
          closeInputTextDialog={this.toggleDialog}
          callback={this.onCloseTerminateContractDialog}
          title='Fecha finiquito'
        />
        {/* view contract dialog */}
        <Dialog onClose={this.toggleViewContractDialog} open={this.state.viewContractDialog} scroll={'paper'} fullWidth>
          <DialogTitle>Contrato</DialogTitle>
          <DialogContent ref={el => (this.componentRef = el)}>
            <div dangerouslySetInnerHTML={{ __html: this.state.viewContractData }} />
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => <Button color='primary' >Imprimir</Button>}
              content={() => this.componentRef}
            />
            <Button onClick={() => this.toggleViewContractDialog()}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

EmployeesView.propTypes = {
  classes: PropTypes.object.isRequired,
  employeeId: PropTypes.string.isRequired,
  employeeContract: PropTypes.object.isRequired,
  employeeAccounts: PropTypes.array.isRequired,
  employeeContractType: PropTypes.object,
  employeeRecord: PropTypes.object.isRequired,
  farmRecords: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  terminateContract: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
  buildContractHtml: PropTypes.func.isRequired,
  uploadContractFiles: PropTypes.func.isRequired,
  editPath: PropTypes.func.isRequired,
  userAccess: PropTypes.number.isRequired
}

export default withStyles(styles)(EmployeesView)
