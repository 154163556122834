import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'

import now from 'lodash/now'

export default class InputDateDialog extends React.Component {
  state = {
    date: moment(now()).tz('America/Santiago').format('YYYY-MM-DD')
  }

  handleClose = () => {
    this.props.closeInputTextDialog()
  };

  handleChange = (date) => {
    this.setState({ date })
  }

  handleSave = (noDate = false) => {
    if (noDate) {
      this.props.callback(null)
    } else {
      this.props.callback(this.state.date)
    }

    this.handleClose()
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{this.props.title}</DialogTitle>
          <DialogContent>
            <FormControl >
              <TextField
                id='day'
                name='day'
                label='Fecha'
                InputLabelProps={{
                  shrink: true
                }}
                type={this.props.type || 'date'}
                value={this.state.date}
                onChange={(event) => this.handleChange(event.target.value)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {this.props.showNullDate
              ? <Button onClick={() => this.handleSave(true)} color='default'>
                  Anular fecha
              </Button>
              : <></>}
            <Button onClick={() => this.handleClose()} color='primary'>
              Cerrar
            </Button>
            <Button onClick={() => this.handleSave()} color='primary'>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

InputDateDialog.propTypes = {
  closeInputTextDialog: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showNullDate: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string
}
