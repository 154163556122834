import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DropzoneDialog } from 'material-ui-dropzone'
import clone from 'lodash/clone'
import noop from 'lodash/noop'
import async from 'async'
import { getFileUploadUrl, uploadedFiles } from './redux/fileUtilsActions'

const initialState = {
  open: false,
  loading: false
}

class FileUpLoad extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  uploadFile = (file, data, cb = noop) => {
    fetch(
      data.putUrl,
      {
        method: 'PUT',
        body: file,
        mode: 'cors',
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': file.type, 'Content-Disposition': `attachment; filename=${file.name}` }
      }
    )
      .then((response) => cb(null, { fileName: file.name, fileKey: data.fileKey }))
      .catch((error) => cb(error))
  }

  onSave = (files) => {
    this.setState({ loading: true, open: false })
    const success = []
    async.each(files, (file, cb) => {
      this.props.getFileUploadUrl(file.type, (err, res) => {
        if (err) {
          console.error(err)
          return
        }
        // upload file to Bucket
        this.uploadFile(file, res.data, (err, result) => {
          if (err) console.error(err)
          else success.push(result)
          cb()
        })
      })
    }, () => {
      this.props.uploadedFiles(success, (err, response) => {
        this.setState({ loading: false })
        // TOASTS
        if (err) {
          console.error('TOAST ERROR', err)
        }
        if (response.status === 200) {
          console.log('TOAST OK')
        }
        if (response.status === 206) {
          console.warn('TOAST PARTIAL')
        }

        // pass the file upload data to parent component
        this.props.onFileUpload(err, response)
      })
    })
  }

  render () {
    return (
      <div>
        {this.state.loading ? <CircularProgress />
          : <Button variant='outlined' color='primary' onClick={() => this.setState({ open: true })}>
            {this.props.buttonText || 'Agregar Archivo'}
          </Button>
        }
        {/* NOTE: I modified the component code on the node_modules folder to make it work...I think. So in fresh installs it might not work */}
        <DropzoneDialog
          acceptedFiles={['image/*', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.txt', '.ods', '.odt']}
          cancelButtonText={'cancelar'}
          submitButtonText={'guardar'}
          maxFileSize={5000000}
          dialogTitle={'Agregar archivos'}
          dropzoneText={'Haz click para seleccionar archivos o arrastralos aqui'}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          onSave={(files) => this.onSave(files)}
        />
      </div>
    )
  }
}

FileUpLoad.propTypes = {
  getFileUploadUrl: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  buttonText: PropTypes.string
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getFileUploadUrl,
  uploadedFiles
}, dispatch)

export default connect(null, mapDispatchToProps)(FileUpLoad)
