import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import memoize from 'memoize-one'

import FileDownloadLinkComponent from '../FileUtils/FileDownloadLinkComponent'

const employeeRecordKeys = {
  name: 'nombre',
  lastName: 'apellido'
}

const employeeContractKeys = {
  beginDate: 'fechaInicio',
  bonuses: 'bonos',
  expirationDate: 'fechaTermino',
  endDate: 'fechaFiniquito',
  farm: 'predio',
  labours: 'labores',
  reportsTo: 'supervisor',
  schedule: 'horario',
  name: 'tipo',
  paymentMethodType: 'medioDePago',
  files: 'archivoContratoEscaneado'
}

class EmployeesViewAllContracts extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openDialog: false }
  }

  employeeContract = memoize((employeeContract, farms, employeeAccounts) => reduce(employeeContract, (result, value, key) => {
    if (key === 'farm' && value) value = farms[`farm/${value}`] ? farms[`farm/${value}`].displayName : 'n/d'
    if (key === 'paymentMethod' && value) {
      const account = filter(employeeAccounts, (acc) => acc.id === value.id)[0] || { bankName: 'n/d', bankAccountNumber: 'n/d' }
      result.paymentMethodType = `${startCase(account.bankName)} / ${account.bankAccountNumber}`
    }
    result[key] = value
    result.name = startCase((this.props.employeeContractTypes[`contract/${employeeContract.type}`] && this.props.employeeContractTypes[`contract/${employeeContract.type}`].name) || 'omitido')
    return result
  }, {}))

  allContracts = memoize((allContracts, currentContract) => (filter(allContracts, (contract) => contract.contractId !== currentContract.contractId && contract.endDate)))

  render () {
    const employeeContract = (contract) => this.employeeContract(contract, this.props.farmRecords, this.props.employeeAccounts)

    const allContracts = this.allContracts(this.props.allEmployeeContracts, this.props.employeeCurrentContract)

    return (
      <div>
        <Card>
          <CardHeader
            title='Datos trabajador'
          />
          <CardContent>
            <Typography variant='body1' gutterBottom>
              RUT: {this.props.employeeId}
            </Typography>
            {map(this.props.employeeRecord, (value, key) => {
              if (value && includes(keys(employeeRecordKeys), key)) {
                return <Typography variant='body1' gutterBottom key={key}>
                  {startCase(employeeRecordKeys[key])}: {value}
                </Typography>
              }
            })}
          </CardContent>
        </Card>

        <br />

        {map(allContracts, (value, key) =>
          <div key={key}>
            <Card>
              <CardHeader
                title='Datos contratos ya finiquitados'
              />
              <CardContent>
                {map(employeeContract(value), (value, key) => {
                  if (!isEmpty(value) && includes(keys(employeeContractKeys), key)) {
                    if (key === 'files') {
                      return (
                        <div key={key}>
                          <Typography variant='body1' gutterBottom>
                            {startCase(employeeContractKeys[key])}:
                          </Typography>
                          {
                            map(value, (file, idx) => (<div key={idx}><FileDownloadLinkComponent
                              fileRecordName={file}
                              name={`Archivo ${idx + 1}`}
                            /> &nbsp;</div>
                            ))
                          }
                          <br />
                        </div>
                      )
                    }

                    return <Typography variant='body1' gutterBottom key={key}>
                      {startCase(employeeContractKeys[key])}: {value}
                    </Typography>
                  }
                })}
              </CardContent>
            </Card>
            <br />
          </div>
        )}
      </div>
    )
  }
}

EmployeesViewAllContracts.propTypes = {
  employeeId: PropTypes.string.isRequired,
  allEmployeeContracts: PropTypes.array.isRequired,
  employeeAccounts: PropTypes.array.isRequired,
  employeeContractTypes: PropTypes.object,
  employeeRecord: PropTypes.object.isRequired,
  employeeCurrentContract: PropTypes.object.isRequired,
  farmRecords: PropTypes.object.isRequired
}

export default EmployeesViewAllContracts
