import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import HarvestPayments from './HarvestPayments.js'
import { createHarvestPaymentOrder, updateHarvestRecordPaymentData } from './redux/harvestActions'

const mapStateToProps = (state, ownProps) => {
  if (ownProps.location.state) {
    const { result, sites, farms, employees } = ownProps.location.state
    const { harvestPayments } = state.harvest
    let lastHarvestPayment = null
    if (result && result.harvestPayment) lastHarvestPayment = harvestPayments[result.harvestPayment]

    return { lastHarvestPayment, harvestPayments, sites, farms, employees, payments: state.payments }
  }
  return {}
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createHarvestPaymentOrder,
  updateHarvestRecordPaymentData
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HarvestPayments)
