import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import clone from 'lodash/clone'

const initialState = {
  text: ''
}

export default class InputTextDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  handleClose = () => {
    this.props.closeInputTextDialog()
    this.setState(initialState)
  };

  handleChange = (event) => {
    this.setState({ text: event.target.value })
  }

  handleSave = () => {
    this.props.callback(this.state.text)
    this.handleClose()
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{this.props.title}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id='text'
              fullWidth
              type='string'
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancelar
            </Button>
            <Button onClick={this.handleSave} color='primary'>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

InputTextDialog.propTypes = {
  closeInputTextDialog: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string
}
