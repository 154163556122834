import React from 'react'
import PropTypes from 'prop-types'

const UserAccessComponent = (props) => {
  if (props.userAccess < props.access) return props.children
  return (<></>)
}

UserAccessComponent.propTypes = {
  userAccess: PropTypes.number.isRequired,
  access: PropTypes.number.isRequired,
  children: PropTypes.any
}

export default UserAccessComponent
