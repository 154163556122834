import noop from 'lodash/noop'
import { makeRpc } from '../../Main/redux/mainActionsDeepstream'
import { appConfig } from '../../../config'
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import forEach from 'lodash/forEach'

export const getFileDownloadUrl = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  let fileKey = null
  let fileRecordName = null
  if (payload.href) {
    fileKey = payload.href.split(appConfig.downloadUrlPrefix)[1]
  }
  if (payload.fileRecordName) {
    fileRecordName = payload.fileRecordName
  }

  dispatch(makeRpc('files/download/getSignedUrl', { fileKey, fileRecordName, companyId }, cb))
}

export const getFileUploadUrl = (fileContentType = null, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(makeRpc('files/upload/getSignedUrl', { fileContentType, companyId }, cb))
}

export const uploadedFiles = (files, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const userId = state.auth.clientData.userId
  dispatch(makeRpc('files/upload', { files, userId, companyId }, cb))
}

export const deleteFile = (fileRecordName, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const userId = state.auth.clientData.userId
  dispatch(makeRpc('files/delete', { fileRecordName, userId, companyId }, cb))
}

export const makeXlsxFile = (fileName, jsonData, header) => () => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const wb = utils.book_new()

  forEach(jsonData, (value) => {
    const ws = utils.json_to_sheet(value.data, { header: value.header || header })
    utils.book_append_sheet(wb, ws, value.name)
  })
  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  saveAs(data, fileName + fileExtension)
}
