import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import clone from 'lodash/clone'

import memoize from 'memoize-one'

import DayFarmSelect from './DayFarmSelect'
import InputDateDialog from '../Components/InputDateDialog'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  body1: {
    margin: theme.spacing.unit
  }
})

const CustomToolbarSelect = (props) => {
  if (props.userAccess >= 3) {
    return <></>
  }
  if (!props.isDayIntervalValidated) {
    return (
      <Typography variant='body1' gutterBottom className={props.classes.body1}>
        Validar pendientes
      </Typography>
    )
  }
  return (
    <>
      {props.accountingDate
        ? <Button className={props.classes.button} color='primary' onClick={
          () => props.generatePaymentOrder(map(props.displayData, (value) => ({ idx: value.dataIndex, data: value.data })), map(props.selectedRows.data, (row) => row.dataIndex))} >
          Generar Pago
        </Button> : <></>
      }
      <Button className={props.classes.button} color='primary' onClick={() => props.setAccountingDate()} >
        {`Fecha contable de pago ${props.accountingDate}`}
      </Button>
    </>
  )
}

const initialState = {
  openInputDateDialog: false,
  accountingDate: ''
}

class HarvestSummaryTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    // subscribe to validated days
    this.props.subscribeValidatedDays()
  }

  componentWillUnmount () {
    // unsubscribe to validated Days record
    this.props.unsubscribeValidatedDays()
  }

  onCellClick = (data, meta) => {
    const value = this.props.data[meta.dataIndex]
    this.props.history.push(`/app/harvest/summary/view`, { value, formats: this.props.formats })
  }

  generatePaymentOrder = (data, selectedIndex) => {
    // data is an array of table values. At index 0 we have rut
    let employeeIds = map(filter(data, (d) => includes(selectedIndex, d.idx)), (d) => d.data[0])
    const registeredEmployeeIds = keys(this.props.employees)
    employeeIds = filter(registeredEmployeeIds, (id) => includes(employeeIds, id))

    if (!employeeIds.length) {
      window.alert('No se generan pagos a cosecheros no registrados')
    } else {
      let ok = window.confirm(`Seguro que desea generar pago para ${employeeIds.length} cosecheros para el mes ${this.state.accountingDate}?`)
      if (ok && this.state.accountingDate) {
        this.props.createHarvestPayment({ employeeIds, data: this.props.data, accountingDate: this.state.accountingDate }, (err, result) => {
          if (err) {
            window.alert(err)
          } else {
            this.props.history.push(`/app/harvest/payments`, { result, sites: this.props.sites, farms: this.props.farms, employees: this.props.employees })
          }
        })
      }
    }
  }

  isRowSelectable = (dataIdx) => {
    // we can only select rows that have nonPayed records
    return !isEmpty(this.props.data[dataIdx] && this.props.data[dataIdx].harvestRecords && this.props.data[dataIdx].harvestRecords.nonPayment)
  }

  _relevantDays = memoize((days, selectedDay) => {
    return filter(days, (day) => day >= selectedDay.from && day <= selectedDay.to)
  })

  isDayIntervalValidated = memoize((days, selectedDay, selectedFarm, harvestValidatedDays) => {
    const relevantDays = this._relevantDays(days, selectedDay)

    const allValidated = reduce(relevantDays, (result, day) => {
      if (selectedFarm && selectedFarm.id && selectedFarm.id === 'todos') {
        forEach(harvestValidatedDays, (value) => {
          if (!includes(value, day)) {
            result = false
          }
        })
      } else {
        if (!includes(harvestValidatedDays[this.props.selectedFarm.id], day)) result = false
      }
      return result
    }, true)

    return allValidated
  })

  componentDidUpdate (prevProps) {
    if (this.props.selectedDay.to && this.props.selectedDay.from && (!isEqual(prevProps.selectedDay, this.props.selectedDay) || !isEqual(prevProps.selectedFarm, this.props.selectedFarm))) {
      this.props.getHarvestData(() => {
        console.log('done getting harvest data')
      })
    }
  }

  render () {
    const isDayIntervalValidated = this.isDayIntervalValidated(this.props.days, this.props.selectedDay, this.props.selectedFarm, this.props.harvestValidatedDays)

    return (
      <>
        <DayFarmSelect
          days={this.props.days}
          farms={this.props.farms}
          selectedDay={this.props.selectedDay}
          selectedFarm={this.props.selectedFarm || { name: '', id: '' }}
          setSelectedDayAndFarmForTable={this.props.setSelectedDayAndFarmForTable}
          nextLoading={this.props.nextLoading}
        />
        <InputDateDialog title={'Fecha Contable'}
          closeInputTextDialog={() => this.setState({ openInputDateDialog: false })}
          open={this.state.openInputDateDialog}
          callback={(date) => this.setState({ accountingDate: date })}
          type={'month'}
        />

        {this.props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <>
            {
              this.props.selectedDay.from && this.props.selectedDay.to && this.props.selectedFarm.id
                ? <MUIDataTable
                  title={'Resumen'}
                  data={this.props.data}
                  columns={this.props.columns}
                  options={{ ...this.props.options,
                    onCellClick: this.onCellClick,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                      <CustomToolbarSelect
                        classes={this.props.classes}
                        selectedRows={selectedRows}
                        displayData={displayData}
                        generatePaymentOrder={this.generatePaymentOrder}
                        userAccess={this.props.userAccess}
                        isDayIntervalValidated={isDayIntervalValidated}
                        setAccountingDate={() => this.setState({ openInputDateDialog: true })}
                        accountingDate={this.state.accountingDate}
                      />),
                    onDownload: (buildHead, buildBody, columns, values) => {
                      const json = reduce(values, (result, val) => {
                        const temp = {}
                        forEach(val.data, (v, idx) => {
                          // do not include these values
                          if (!includes(['id', 'farm', 'pending', 'payment'], columns[idx].name)) {
                            temp[columns[idx].name] = v
                          }
                        })
                        result.push(temp)
                        return result
                      }, [])

                      const fileName = `resumenCosecheros-${this.props.selectedDay.from}-al-${this.props.selectedDay.to}`
                      this.props.makeXlsxFile(fileName, [{ data: json, name: 'data' }], ['rut', 'nombre', 'apellido'])
                      // cancel default  CSV download from table
                      return false
                    },
                    isRowSelectable: this.isRowSelectable }
                  } /> : null }
          </>
        }
      </>
    )
  }
}

HarvestSummaryTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  formats: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  employees: PropTypes.object.isRequired,
  createHarvestPayment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userAccess: PropTypes.number.isRequired,
  selectedDay: PropTypes.object,
  selectedFarm: PropTypes.object,
  harvestValidatedDays: PropTypes.object,
  days: PropTypes.array,
  setSelectedDayAndFarmForTable: PropTypes.func,
  getHarvestData: PropTypes.func,
  subscribeValidatedDays: PropTypes.func,
  unsubscribeValidatedDays: PropTypes.func,
  nextLoading: PropTypes.func,
  makeXlsxFile: PropTypes.func
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  generatePaymentOrder: PropTypes.func.isRequired,
  setAccountingDate: PropTypes.func.isRequired,
  accountingDate: PropTypes.string.isRequired,
  userAccess: PropTypes.number.isRequired,
  isDayIntervalValidated: PropTypes.bool
}

export default withRouter(withStyles(styles)(HarvestSummaryTable))
