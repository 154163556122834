import React from 'react'
import { Prompt } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import map from 'lodash/map'
import deburr from 'lodash/deburr'
import clone from 'lodash/clone'
import filter from 'lodash/filter'
import trim from 'lodash/trim'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'
import includes from 'lodash/includes'
import now from 'lodash/now'
import split from 'lodash/split'
import startCase from 'lodash/startCase'
import find from 'lodash/find'
import reduce from 'lodash/reduce'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import isFinite from 'lodash/isFinite'
import toNumber from 'lodash/toNumber'
import isString from 'lodash/isString'

import TableSearchCreateDialog from './../../Components/TableSearchCreateDialog'
import LabourRegister from '../../Contract/LabourRegister'
// NOTE: hide for now
// import MachineRegister from '../../Farms/MachineRegister'
import SiteRegister from '../../Farms/SiteRegister'
import AnnotationComponent from '../../Components/AnnotationComponent'
import InputTextDialog from '../../Components/InputTextDialog'
import HourMinuteInputDialog from '../../Components/HourMinuteInputDialog'
import UserAccessComponent from '../../Components/UserAccessComponent'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  chip: {
    margin: theme.spacing.unit
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    minWidth: 120
  }
})

const durationOptions = [
  { value: 1, label: 'Jornada completa' },
  { value: 0.75, label: 'Tres cuartos jornada' },
  { value: 0.5, label: 'Media jornada' },
  { value: 0.25, label: 'Cuarto jornada' }
]

const initialState = {
  siteLogRecord: {
    membershipId: '',
    labourId: '',
    machineIds: [],
    duration: 0,
    horaExtra: 0,
    horaExtraAdicional: 0,
    horaExtraNocturna: 0,
    horaExtraControlHelada: 0,
    desgasteHerramientas: 0,
    reembolsoGasto: 0,
    usoVehiculoParticular: 0,
    annotations: []
  },
  overtimeDialog: false,
  selectedSite: { id: '', name: '', farm: '' },
  showCreateComponent: false,
  editId: '',
  openLabourDialog: false,
  selectedLabour: { id: '', name: '' },
  openMachineDialog: false,
  openSiteDialog: false,
  openAnnotationTopicDialog: false,
  openAnnotationIndex: null,
  selectedMachines: [{ id: '', name: '' }],
  modified: false,
  updatedAt: null,
  otrosHaberesDialog: false,
  expanded: false
}

class SiteLogCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentWillUnmount () {
    this.setState(clone(initialState))
  }

  setLocalData = () => {
    // TODO: this could be done subscribing directly to records from the list
    this.props.getSiteLogRecord(this.props.recordName, (err, siteLogRecord) => {
      if (err) {
        console.error(err)
        return
      }
      // set labour
      const selectedLabour = filter(this.props.laboursData, (l) => l.id === siteLogRecord.labourId)[0]
      // set machines
      let selectedMachines = [{ id: '', name: '' }]
      if (siteLogRecord.machineIds) selectedMachines = concat(selectedMachines, filter(this.props.machinesData, (m) => includes(siteLogRecord.machineIds, m.id)))
      // set site
      const siteId = split(this.props.recordName, '/')[1]
      const selectedSite = filter(this.props.sitesData, (s) => s.id === siteId)[0]
      // update state
      this.setState({ siteLogRecord,
        selectedLabour,
        selectedMachines,
        selectedSite,
        updatedAt: siteLogRecord.updatedAt,
        expanded: (siteLogRecord.desgasteHerramientas || siteLogRecord.usoVehiculoParticular || siteLogRecord.reembolsoGasto) })
    })
  }

  componentDidMount () {
    // we are receiving a record
    if (this.props.recordName) {
      this.setLocalData()
    }
    // subscribe to annotation topics
    this.props.subscribeToAnnotationTopics()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.recordName !== this.props.recordName) {
      // eslint-disable-next-line
      this.setState(clone(initialState), () => {
        this.setLocalData()
      })
    }
  }

  save = () => {
    const state = clone(this.state)
    const payload = pickBy(state.siteLogRecord, identity)

    payload.membershipId = this.props.membershipId
    payload.siteId = this.state.selectedSite.id
    payload.day = this.props.day
    // sanitize anotation
    payload.annotations = reduce(payload.annotations, (res, note) => {
      if (!note.topicId) note.topicId = 'none'
      res.push({ ...note, text: trim(deburr(note.text)) })
      return res
    }, [])

    if (payload.annotations.length === 0) {
      delete payload.annotations
    }

    // editing a record
    if (this.props.recordName) {
      // check that we are still on the same site
      const previousSiteId = split(this.props.recordName, '/')[1]
      if (previousSiteId !== payload.siteId) {
        payload.recordToDelete = this.props.recordName
      } else {
        payload.recordName = this.props.recordName
      }
    }

    this.props.saveSiteLogRecord(payload, (err) => {
      if (err) window.alert(err.message || err)
      else this.setState({ modified: false, updatedAt: now() })
    })
  }

  delete = () => {
    const ok = window.confirm('Seguro que desea eliminar el registro')
    if (ok) {
      this.props.deleteSiteLogRecord({ recordName: this.props.recordName, membershipId: this.props.membershipId, day: this.props.day })
    }
  }

  disableSave = () => {
    if (this.props.readOnly) return true
    if ((this.state.selectedSite && this.state.selectedSite.id) && (this.state.selectedLabour && this.state.selectedLabour.id) && this.state.siteLogRecord.duration) return false
    return true
  }

  handleClose = () => {
    this.setState({ openLabourDialog: false, openMachineDialog: false, openSiteDialog: false, openAnnotationTopicDialog: false, openAnnotationIndex: null }, () => {
      // Just to avoid a blink
      setTimeout(() => {
        this.setState({ showCreateComponent: false, editId: '' })
      }, 10)
    })
  }

  onSelectedDuration = (duration) => {
    this.props.selectedDuration(duration, (err) => {
      if (err) {
        window.alert('Duracion incorrecta: mas de 1 jornada')
      } else {
        this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, duration } })
      }
    })
  }

  onSelectedSite = (siteId, retry = 0) => {
    const selectedSite = find(this.props.sitesData, (l) => l.id === siteId)
    if (!selectedSite && retry <= 3) {
      // retry 3 times untill data updates
      setTimeout(() => {
        this.onSelectedSite(siteId, retry + 1)
      }, 500)
      return
    }

    this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord }, selectedSite })
    this.handleClose()
  }

  onSelectedLabour = (labourId, retry = 0) => {
    const selectedLabour = find(this.props.laboursData, (l) => l.id === labourId)
    if (!selectedLabour && retry <= 3) {
      // retry 3 times untill data updates
      setTimeout(() => {
        this.onSelectedLabour(labourId, retry + 1)
      }, 500)
      return
    }
    this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, labourId }, selectedLabour: { id: labourId, name: selectedLabour.name } })
    this.handleClose()
  }

  onSelectedMachine = (machineId, retry = 0) => {
    const selectedMachine = find(this.props.machinesData, (m) => m.id === machineId)
    if (!selectedMachine && retry <= 3) {
      // retry 3 times untill data updates
      setTimeout(() => {
        this.onSelectedMachine(machineId, retry + 1)
      }, 500)
      return
    }

    this.setState({
      modified: true,
      selectedMachines: uniq(concat(this.state.selectedMachines, [{ id: machineId, name: selectedMachine.name }])),
      siteLogRecord: { ...this.state.siteLogRecord, machineIds: uniq(concat(this.state.siteLogRecord.machineIds, [machineId])) }
    })
    this.handleClose()
  }

  onCreatedMachine = (machine) => {
    const payload = {
      type: trim(machine.type),
      manufacturer: trim(machine.manufacturer),
      model: trim(machine.model),
      description: trim(machine.description),
      farmId: machine.farm.id
    }

    if (machine.id) {
      this.props.editMachine({ ...payload, id: machine.id }, (err, res) => {
        if (!err) {
          this.onSelectedMachine(machine.id)
        }
      })
    } else {
      this.props.createMachine(payload, (err, res) => {
        if (!err) {
          this.onSelectedMachine(res.data)
        }
      })
    }
  }

  onDeletedMachine = (machineId) => {
    if (machineId) {
      this.setState({
        modified: true,
        selectedMachines: filter(this.state.selectedMachines, (m) => m.id !== machineId),
        siteLogRecord: { ...this.state.siteLogRecord, machineIds: filter(this.state.siteLogRecord.machineIds, (m) => m !== machineId) }
      })
    }
  }

  onCreatedLabour = (labour) => {
    const payload = {
      name: trim(labour.name),
      description: trim(labour.description),
      reference: trim(labour.reference)
    }

    if (labour.validFrom && labour.amountValue) {
      payload.value = {
        validFrom: labour.validFrom,
        amount: {
          currency: labour.amountCurrency || 'CLP',
          value: labour.amountValue
        }
      }
    }

    if (labour.id) {
      this.props.editLabour({ ...payload, id: labour.id }, (err, res) => {
        if (!err) {
          this.onSelectedLabour(labour.id)
        }
      })
    } else {
      this.props.createLabour(payload, (err, res) => {
        if (!err) {
          this.onSelectedLabour(res.data)
        }
      })
    }
  }

  // this hanldes edit/create logic
  onCreatedSite = (site) => {
    const payload = {
      displayName: trim(site.displayName),
      type: site.type,
      farm: site.farm.id
    }

    if (site.id) {
      this.props.editSite({ ...payload, id: site.id }, (err, res) => {
        if (!err) {
          this.onSelectedSite(site.id)
        }
      })
    } else {
      this.props.createSite(payload, (err, res) => {
        if (!err) {
          this.onSelectedSite(res.data)
        }
      })
    }
  }

  onCreatedAnnotationTopic = (topic) => {
    const payload = {
      topic: camelCase(trim(deburr(topic)))
    }

    if (this.state.editId) {
      payload.editId = this.state.editId
      this.setState({ editId: '' })
    }

    this.props.createAnnotationTopic(payload, (err, topicId) => {
      if (!err) this.onSelectedAnnotationTopic(topicId)
    })
  }

  onAnnotationChange = (payload) => {
    // handle anotation text
    if (payload.text || payload.text === '') {
      const annotations = clone(this.state.siteLogRecord.annotations)
      const annotation = annotations[payload.idx]
      annotations[payload.idx] = { ...annotation, text: payload.text }
      this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, annotations } })
    }

    // handle anotation amount
    if (payload.amount || payload.amount === '') {
      if (isFinite((toNumber(payload.amount)))) {
        const annotations = clone(this.state.siteLogRecord.annotations)
        const annotation = annotations[payload.idx]
        annotations[payload.idx] = { ...annotation, amount: payload.amount === '' ? '' : toNumber(payload.amount) }
        this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, annotations } })
      }
    }

    // Annotation topic
    if (payload.openTopicDialog) {
      this.setState({ openAnnotationTopicDialog: true, openAnnotationIndex: payload.idx })
    }

    // Annotation files

    // file uploaded to bucket, keep reference in annotation
    if (payload.uploadedFile) {
      const annotations = clone(this.state.siteLogRecord.annotations)
      const annotation = annotations[payload.idx]
      let annotationFiles = isEqual(annotation.files, [{}]) ? [] : annotation.files
      const { data } = payload.uploadedFile
      forEach(data, (d) => annotationFiles.push({ name: d.fileName, recordName: d.fileRecordName }))
      annotations[payload.idx] = { ...annotation, files: annotationFiles }
      this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, annotations } })
    }

    // open file in new tab
    if (payload.viewFile) {
      const { recordName } = payload.viewFile
      if (!recordName) return

      this.props.getFileDownloadUrl({ fileRecordName: recordName }, (err, res) => {
        if (err) {
          window.alert('Error al descargar', err.message)
        }
        if (res.data && res.data.getUrl) {
          window.open(res.data.getUrl)
        }
      })
    }

    // delete file
    // this will only set it's property to deleted and remove it from company files
    if (payload.deleteFile) {
      const ok = window.confirm(`Seguro desea borrar archivo ${payload.deleteFile.name} ?`)
      if (ok) {
        const annotations = clone(this.state.siteLogRecord.annotations)
        const annotation = annotations[payload.idx]
        const { recordName } = payload.deleteFile
        const annotationFiles = filter(annotation.files, (f) => f.recordName !== recordName)
        annotations[payload.idx] = { ...annotation, files: annotationFiles }
        this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, annotations } })
        this.props.deleteFile(recordName)
      }
    }

    // delete annotation
    if (payload.delete || payload.delete === 0) {
      const ok = window.confirm(`Seguro desea borrar anotacion ?`)
      if (ok) {
        const annotation = find(this.state.siteLogRecord.annotations, (a, idx) => idx === payload.delete)
        // delete files
        forEach(annotation.files, (file) => this.props.deleteFile(file.recordName))
        // remove annotation
        const annotations = filter(this.state.siteLogRecord.annotations, (a, idx) => idx !== payload.delete)
        this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, annotations } })
      }
    }
  }

  onSelectedAnnotationTopic = (topicId) => {
    const annotations = clone(this.state.siteLogRecord.annotations)
    const annotation = annotations[this.state.openAnnotationIndex]
    annotations[this.state.openAnnotationIndex] = { ...annotation, topicId }
    this.setState({ modified: true, siteLogRecord: { ...this.state.siteLogRecord, annotations } })
    this.handleClose()
  }

  addAnnotation = () => {
    const base = [{ topicId: '', text: '', files: [{}] }]
    if (isEmpty(this.state.siteLogRecord.annotations)) this.setState({ siteLogRecord: { ...this.state.siteLogRecord, annotations: base } })
    else this.setState({ siteLogRecord: { ...this.state.siteLogRecord, annotations: concat(this.state.siteLogRecord.annotations, base) } })
  }

  setOvertime = (opts) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.horaExtra === 0) {
      this.setState({ overtimeDialog: 'horaExtra', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, horaExtra: 0 }, modified: true })
    }
  }

  setOvertimeExtra = (opts) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.horaExtraAdicional === 0) {
      this.setState({ overtimeDialog: 'horaExtraAdicional', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, horaExtraAdicional: 0 }, modified: true })
    }
  }

  setOvertimeNocturno = (opts) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.horaExtraNocturna === 0) {
      this.setState({ overtimeDialog: 'horaExtraNocturna', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, horaExtraNocturna: 0 }, modified: true })
    }
  }

  setOvertimeHeladas = (opts) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.horaExtraControlHelada === 0) {
      this.setState({ overtimeDialog: 'horaExtraControlHelada', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, horaExtraControlHelada: 0 }, modified: true })
    }
  }

  setDesgasteHerramientas = (value) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.desgasteHerramientas === 0) {
      this.setState({ otrosHaberesDialog: 'desgasteHerramientas', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, desgasteHerramientas: 0 }, modified: true })
    }
  }

  setReembolsoGasto = (value) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.reembolsoGasto === 0) {
      this.setState({ otrosHaberesDialog: 'reembolsoGasto', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, reembolsoGasto: 0 }, modified: true })
    }
  }

  setUsoVehiculoParticular = (value) => {
    if (this.props.readOnly) return
    if (this.state.siteLogRecord.usoVehiculoParticular === 0) {
      this.setState({ otrosHaberesDialog: 'usoVehiculoParticular', modified: true })
    } else {
      this.setState({ siteLogRecord: { ...this.state.siteLogRecord, usoVehiculoParticular: 0 }, modified: true })
    }
  }

  readableHours = (hoursDecimal) => {
    const hour = Math.floor(hoursDecimal)
    const minutes = Math.round((hoursDecimal - hour) * 60)
    return `${hour} horas y ${minutes} minutos`
  }

  toCurrency = (value) => {
    return value ? value.toLocaleString('es-CL', { currency: 'CLP', style: 'currency' }) + ' pesos' : ''
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <Prompt
          when={this.state.modified && !this.props.readOnly}
          message={'Tienes cambios sin guardar. Seguro que deseas continuar?'}
        />

        <Card>
          <CardHeader
            title={`Actividad ${this.props.date.dd} de ${this.props.date.mm} ${this.props.date.yy} : ${startCase(this.props.employeeName)}`}
          />
          <CardContent>
            <div className={classes.root}>
              <Grid container direction='row' spacing={24}>
                <Grid item xs={3}>
                  <FormControl required error={!(this.state.selectedSite && this.state.selectedSite.id)} className={classes.formControl}>
                    <FormLabel component='legend'>Lugar</FormLabel>
                    <FormGroup row>
                      <Chip
                        className={classes.chip}
                        label={(this.state.selectedSite && this.state.selectedSite.id) ? `${this.state.selectedSite.name}-${this.state.selectedSite.type}-${this.state.selectedSite.farm}` : 'Seleccionar'}
                        variant='outlined'
                        onClick={() => this.setState({ openSiteDialog: true })}
                      />
                    </FormGroup>
                  </FormControl>
                  <br />
                  <FormControl required error={!(this.state.selectedLabour && this.state.selectedLabour.id)} className={classes.formControl}>
                    <FormLabel component='legend'>Actividad</FormLabel>
                    <FormGroup row>
                      <Chip
                        className={classes.chip}
                        label={(this.state.selectedLabour && this.state.selectedLabour.name) || 'Seleccionar'}
                        variant='outlined'
                        onClick={() => this.setState({ openLabourDialog: true })}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl required error={!(this.state.siteLogRecord.duration)}>
                    <FormLabel component='legend'>Duracion</FormLabel>
                    <FormGroup column>
                      {map(durationOptions, (option, key) =>
                        <FormControlLabel
                          key={key}
                          control={
                            <Checkbox
                              checked={this.state.siteLogRecord.duration === option.value}
                              onChange={() => this.onSelectedDuration(option.value)}
                            />
                          }
                          label={option.label}
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Grid>

                {/* HORAS EXTRA */}

                <Grid item xs={3}>
                  <FormControl>
                    <FormLabel component='legend'>Horas Extra</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!this.state.siteLogRecord.horaExtra}
                            onChange={this.setOvertime}
                          />
                        }
                        label={this.state.siteLogRecord.horaExtra ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                  <Typography variant='body1' gutterBottom>
                    {this.state.siteLogRecord.horaExtra ? `${this.readableHours(this.state.siteLogRecord.horaExtra)}` : ''}
                  </Typography>
                  <br />
                  <FormControl>
                    <FormLabel component='legend'>Horas Extra Adicionales</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!this.state.siteLogRecord.horaExtraAdicional}
                            onChange={this.setOvertimeExtra}
                          />
                        }
                        label={this.state.siteLogRecord.horaExtraAdicional ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                  <Typography variant='body1' gutterBottom>
                    {this.state.siteLogRecord.horaExtraAdicional ? `${this.readableHours(this.state.siteLogRecord.horaExtraAdicional)}` : ''}
                  </Typography>

                </Grid>
                <Grid item xs={3}>
                  <FormControl>
                    <FormLabel component='legend'>Horas Extra Nocturna</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!this.state.siteLogRecord.horaExtraNocturna}
                            onChange={this.setOvertimeNocturno}
                          />
                        }
                        label={this.state.siteLogRecord.horaExtraNocturna ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                  <Typography variant='body1' gutterBottom>
                    {this.state.siteLogRecord.horaExtraNocturna ? `${this.readableHours(this.state.siteLogRecord.horaExtraNocturna)}` : ''}
                  </Typography>
                  <br />
                  <FormControl>
                    <FormLabel component='legend'>Horas Extra Control Heladas</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!this.state.siteLogRecord.horaExtraControlHelada}
                            onChange={this.setOvertimeHeladas}
                          />
                        }
                        label={this.state.siteLogRecord.horaExtraControlHelada ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                  <Typography variant='body1' gutterBottom>
                    {this.state.siteLogRecord.horaExtraControlHelada ? `${this.readableHours(this.state.siteLogRecord.horaExtraControlHelada)}` : ''}
                  </Typography>
                </Grid>
              </Grid>

              {/* OTROS INGRESOS */}
              <ExpansionPanel expanded={this.state.expanded} onChange={() => this.setState({ expanded: !this.state.expanded })}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='body1' gutterBottom>
                    Otros haberes
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container direction='row' spacing={24}>
                    <Grid item xs={3}>
                      <FormControl>
                        <FormLabel component='legend'>Desgaste Heramientas</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!this.state.siteLogRecord.desgasteHerramientas}
                                onChange={this.setDesgasteHerramientas}
                              />
                            }
                            label={this.state.siteLogRecord.desgasteHerramientas ? 'si' : 'no'}
                          />
                        </FormGroup>
                      </FormControl>
                      <Typography variant='body1' gutterBottom>
                        {this.state.siteLogRecord.desgasteHerramientas ? `${this.toCurrency(this.state.siteLogRecord.desgasteHerramientas)}` : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl>
                        <FormLabel component='legend'>Reembolso gastos</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!this.state.siteLogRecord.reembolsoGasto}
                                onChange={this.setReembolsoGasto}
                              />
                            }
                            label={this.state.siteLogRecord.reembolsoGasto ? 'si' : 'no'}
                          />
                        </FormGroup>
                      </FormControl>
                      <Typography variant='body1' gutterBottom>
                        {this.state.siteLogRecord.reembolsoGasto ? `${this.toCurrency(this.state.siteLogRecord.reembolsoGasto)}` : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl>
                        <FormLabel component='legend'>Uso vehiculo particular</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!this.state.siteLogRecord.usoVehiculoParticular}
                                onChange={this.setUsoVehiculoParticular}
                              />
                            }
                            label={this.state.siteLogRecord.usoVehiculoParticular ? 'si' : 'no'}
                          />
                        </FormGroup>
                      </FormControl>
                      <Typography variant='body1' gutterBottom>
                        {this.state.siteLogRecord.usoVehiculoParticular ? `${this.toCurrency(this.state.siteLogRecord.usoVehiculoParticular)}` : ''}
                      </Typography>
                    </Grid>

                  </Grid>

                </ExpansionPanelDetails>
              </ExpansionPanel>

              {/* NOTE: hide this section for now */}
              {/* <Grid item xs={3}>
                  <FormControl>
                    <FormLabel component='legend'>Implementos </FormLabel>
                    <FormGroup row>
                      {map(this.state.selectedMachines, (machine, key) =>
                        <Chip
                          key={key}
                          onDelete={() => this.onDeletedMachine(machine.id)}
                          className={classes.chip}
                          label={(this.state.selectedSite && this.state.selectedSite.id) ? (machine.name ? machine.name : 'Agregar') : 'Selecciona Lugar'}
                          variant='outlined'
                          onClick={() => this.state.selectedSite.id && this.setState({ openMachineDialog: true })}
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Grid> */}
              <br />
              <Grid container direction='row' spacing={24}>
                <Grid item xs={3}>
                  <Tooltip title='Cualquier información relacionada a la labor que se deba registrar: falla maquinaria, plagas encontradas, desgaste herramientas, horas extra especiales, etc'>
                    <span>
                      <Button size='small' color='primary' variant='outlined' onClick={() => this.addAnnotation()} disabled={this.props.readOnly}>
                    Agregar Anotacion
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
                {map(this.state.siteLogRecord.annotations, (note, idx) =>
                  (<AnnotationComponent key={idx} idx={idx} topicData={this.props.siteLogAnnotationTopics} topicId={note.topicId} text={note.text} files={note.files} onChange={this.onAnnotationChange} readOnly={this.props.readOnly} />)
                )}
              </Grid>
            </div>
          </CardContent>
          <CardActions>
            <Button size='small' color={this.state.modified ? 'secondary' : 'primary'} onClick={this.save} disabled={this.disableSave()}>
              Guardar
            </Button>
            {
              this.state.updatedAt ? <Typography variant='body1' gutterBottom>
              Actualizado el {moment(this.state.updatedAt).tz('America/Santiago').format('YYYY-MM-DD, hh:mm:ss')}
              </Typography> : <></>
            }

            <div className={classes.root} />
            {this.state.updatedAt
              ? <Button size='small' color={'primary'} disabled={this.props.readOnly} onClick={this.delete}>
                  Eliminar registro de labor
              </Button>
              : <></>
            }

          </CardActions>
        </Card>
        {/* DIALOGS */}
        <HourMinuteInputDialog
          handleClose={() => this.setState({ overtimeDialog: false })}
          callback={(horaExtra) => {
            if (this.state.overtimeDialog === 'horaExtra') {
              if (horaExtra > 2) {
                window.alert('2 horas es el maximo')
                return
              }
            }

            this.setState({ siteLogRecord: { ...this.state.siteLogRecord, [this.state.overtimeDialog]: horaExtra } })
          }
          }
          open={this.state.overtimeDialog}
          title={startCase(this.state.overtimeDialog)}
        />
        {/* INPUT AMOUNTS OTROS HABERES */}
        <InputTextDialog
          closeInputTextDialog={() => this.setState({ otrosHaberesDialog: false })}
          callback={(value) => {
            if (isFinite(Number(value))) {
              this.setState({ siteLogRecord: { ...this.state.siteLogRecord, [this.state.otrosHaberesDialog]: Number(value) } })
            } else {
              window.alert('Ingresar un monto valido')
            }
          }}
          open={this.state.otrosHaberesDialog}
          title={`Ingresar valor ${startCase(this.state.otrosHaberesDialog)}`}
        />

        {/* Select/create site */}
        <TableSearchCreateDialog
          handleSelect={this.onSelectedSite}
          handleClose={this.handleClose}
          handleCreate={this.onCreatedSite}
          open={this.state.openSiteDialog}
          data={this.props.sitesData}
          columns={this.props.sitesColumns}
          title={'Lugares'}
          text={'Lugar'}
          showCreateComponent={this.state.showCreateComponent}
          showCreateEdit={(id = '') => this.setState({ editId: id, showCreateComponent: true })}
          createEditComponent={() => (<SiteRegister editData={find(this.props.sitesData, (d) => d.id === this.state.editId)} handleCreate={this.onCreatedSite} handleClose={this.handleClose} farms={this.props.farmsData} />)}
        />
        {/* Select/create Labour */}
        <TableSearchCreateDialog
          handleSelect={this.onSelectedLabour}
          handleClose={this.handleClose}
          open={this.state.openLabourDialog}
          data={this.props.laboursData}
          columns={this.props.laboursColumns}
          title={'Labores'}
          text={'Labor'}
          showCreateComponent={this.state.showCreateComponent}
          showCreateEdit={(id = '') => this.setState({ editId: id, showCreateComponent: true })}
          createEditComponent={() => (<LabourRegister editData={find(this.props.laboursData, (d) => d.id === this.state.editId)} handleCreate={this.onCreatedLabour} handleClose={this.handleClose} />)}
        />
        {/* NOTE: hide for now */}
        {/* Select/create machine */}
        {/* <TableSearchCreateDialog
          handleSelect={this.onSelectedMachine}
          handleClose={this.handleClose}
          open={this.state.openMachineDialog}
          data={this.props.machinesData}
          columns={this.props.machinesColumns}
          title={'Implementos'}
          text={'Implemento'}
          showCreateComponent={this.state.showCreateComponent}
          showCreateEdit={(id = '') => this.setState({ editId: id, showCreateComponent: true })}
          createEditComponent={() => (<MachineRegister editData={find(this.props.machinesData, (d) => d.id === this.state.editId)} handleCreate={this.onCreatedMachine} handleClose={this.handleClose} farms={this.props.farmsData} />)}
        /> */}
        {/* Select/create annotation */}
        <TableSearchCreateDialog
          handleSelect={this.onSelectedAnnotationTopic}
          handleClose={this.handleClose}
          open={this.state.openAnnotationTopicDialog}
          data={this.props.siteLogAnnotationTopics}
          columns={this.props.annotationTopicColumns}
          title={'Temas'}
          text={'Tema'}
          showCreateComponent={this.state.showCreateComponent}
          showCreateEdit={(id = '') => this.setState({ editId: isString(id) ? id : '', showCreateComponent: true })}
          disableEdit={this.props.userAccess > 2}
          createEditComponent={() => (
            <UserAccessComponent access={2} {...this.props}>
              <InputTextDialog
                closeInputTextDialog={() => this.setState({ showCreateComponent: false })}
                callback={this.onCreatedAnnotationTopic}
                open={this.state.showCreateComponent}
                editData={find(this.props.laboursData, (d) => d.id === this.state.editId)}
                title={'Crear tema anotacion'}
              />
            </UserAccessComponent>
          )}
        />
      </>
    )
  }
}

SiteLogCard.propTypes = {
  day: PropTypes.string.isRequired,
  membershipId: PropTypes.string.isRequired,
  employeeName: PropTypes.string,
  recordName: PropTypes.string,
  selectedDuration: PropTypes.func.isRequired,
  saveSiteLogRecord: PropTypes.func.isRequired,
  getSiteLogRecord: PropTypes.func.isRequired,
  deleteSiteLogRecord: PropTypes.func.isRequired,
  createLabour: PropTypes.func.isRequired,
  editLabour: PropTypes.func.isRequired,
  createMachine: PropTypes.func.isRequired,
  editMachine: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  editSite: PropTypes.func.isRequired,
  subscribeToAnnotationTopics: PropTypes.func.isRequired,
  createAnnotationTopic: PropTypes.func.isRequired,
  getFileDownloadUrl: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  classes: PropTypes.object,
  date: PropTypes.object,
  machinesData: PropTypes.array.isRequired,
  // NOTE: hide for now
  // machinesColumns: PropTypes.array.isRequired,
  laboursData: PropTypes.array.isRequired,
  laboursColumns: PropTypes.array.isRequired,
  sitesData: PropTypes.array.isRequired,
  sitesColumns: PropTypes.array.isRequired,
  farmsData: PropTypes.array.isRequired,
  siteLogAnnotationTopics: PropTypes.array.isRequired,
  annotationTopicColumns: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  userAccess: PropTypes.number.isRequired
}

export default withStyles(styles)(SiteLogCard)
