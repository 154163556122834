import { createReducer } from '@reduxjs/toolkit'
import { companyFarmsList, farmRecord, companyFarmsSiteList, siteCropRecord, cropRecord, userFarms, userSites,
  farmStart, farmReset, companyMachineRecords, companyMachinesList } from './farmsActions'

const initialState = {
  farmRecord: {},
  companyFarmsList: {},
  companyFarmsSiteList: {},
  siteRecord: {},
  siteCropRecord: {},
  cropRecord: {},
  userFarms: [],
  userSites: [],
  start: null,
  companyMachinesList: [],
  companyMachineRecord: {}
}

const farmsReducer = createReducer(initialState, {
  [companyFarmsList]: (state, action) => { state.companyFarmsList[action.payload.listName] = action.payload.data },
  [farmRecord]: (state, action) => {
    state.farmRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.farmRecord[action.payload.recordName]
    }
  },
  [companyMachinesList]: (state, action) => {
    state.companyMachinesList = action.payload
  },
  [companyMachineRecords]: (state, action) => {
    state.companyMachineRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.companyMachineRecord[action.payload.recordName]
    }
  },
  [companyFarmsSiteList]: (state, action) => { state.companyFarmsSiteList[action.payload.listName] = action.payload.data },
  [siteCropRecord]: (state, action) => { state.siteCropRecord[action.payload.recordName] = action.payload.data },
  [cropRecord]: (state, action) => { state.cropRecord[action.payload.recordName] = action.payload.data },
  [userFarms]: (state, action) => { state.userFarms = action.payload },
  [userSites]: (state, action) => { state.userSites = action.payload },
  [farmStart]: (state, action) => { state.start = action.payload },
  [farmReset]: (state, action) => {
    state.farmRecord = {}
    state.userFarms = []
    state.userSites = []
    state.start = null
    state.companyFarmsList = {}
    state.companyFarmsSiteList = {}
    state.siteCropRecord = {}
    state.siteRecord = {}
    state.companyMachinesList = []
    state.companyMachineRecord = {}
  }
})

function reducer (state = initialState, action) {
  // compute state by harvest reducer
  const state1 = farmsReducer(state, action)

  // site record actions come from different  farm sites lists, therefore the list subscribe method was
  // comparing entries on state to entries on each list thus causing a state reset,
  // In order to have multiple lists that update the state path, we have a function that creates multiple actions that share the same path, but differ at the end,
  // therefore the listSubscribe method believes it is handling a different slice of state.
  // That is why we have this auxiliary reducer to handle such action types and keep state synced.
  //
  // This also breaks the remove logic from state, thats why we use the deleted prop on the site record to remove from state

  if (action.type.split('__:')[0] === 'farms/siteRecord') {
    let state2 = {
      ...state1,
      siteRecord: {
        ...state.siteRecord,
        [action.payload.recordName]: action.payload.data
      }
    }
    if (action.payload.remove || action.payload.data.deleted) {
      delete state2.siteRecord[action.payload.recordName]
    }
    return state2
  }
  return state1
}

export default reducer
