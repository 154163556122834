import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MUIDataTable from 'mui-datatables'
import EditIcon from '@material-ui/icons/CreateOutlined'
import concat from 'lodash/concat'

const TableIcon = () => {
  return (<EditIcon />)
}

const TableSearchCreateDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='form-dialog-title'
      >
        {props.showCreateComponent
          ? props.createEditComponent()
          : <MUIDataTable
            title={props.title}
            data={props.data}
            columns={props.disableEdit ? props.columns
              : concat(props.columns, [{ name: 'editar', label: 'editar', options: { filter: false, customBodyRender: TableIcon } }])}
            options={{
              selectableRows: 'none',
              download: false,
              print: false,
              rowsPerPage: 100,
              viewColumns: false,
              onCellClick: (colData, cellMeta) => {
                const { id } = props.data[cellMeta.dataIndex]

                if (!props.disableEdit && cellMeta.colIndex === props.columns.length) {
                  props.showCreateEdit(id)
                } else {
                  props.handleSelect(id)
                }
              },
              textLabels: {
                body: {
                  noMatch: `${props.text} no encontrado. Haz click en "CREAR" para registrar uno nuevo`
                }
              }
            }}
          />
        }
        <DialogActions>
          {props.showCreateComponent ? <></>
            : <Button onClick={props.showCreateEdit} color='primary'>
              crear
            </Button>
          }
          <Button onClick={props.handleClose} color='primary'>
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

TableSearchCreateDialog.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  createEditComponent: PropTypes.func.isRequired,
  showCreateEdit: PropTypes.func.isRequired,
  showCreateComponent: PropTypes.bool.isRequired,
  disableEdit: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string
}

export default TableSearchCreateDialog
