import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import ReactToPrint from 'react-to-print'

import map from 'lodash/map'
import startCase from 'lodash/startCase'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'
import clone from 'lodash/clone'
import noop from 'lodash/noop'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'

import Attendance from './Attendance/AttendanceContainer'
import SiteLog from './SiteLog/SiteLogContainer'
import UserAccessComponent from '../Components/UserAccessComponent'
import EmployeeLiquidacionComponent from '../Components/EmployeeLiquidacionComponent'
import EmployeeSummaryComponent from '../Components/EmployeeSummaryComponent'
import InputTextDialog from '../Components/InputTextDialog'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const initialState = {
  day: '',
  showLiquidacion: false,
  showSummary: false,
  openDialog: false,
  path: '',
  dialogTitle: '',
  hideForPrint: false
}

class ActivityDayView extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    if (this.props.day) {
      this.props.subscribeAttendanceDayRecordList(this.props.membershipId, this.props.day)
    }
    if (this.props.showSummary) {
      this.props.subscribeSummaryRecord(`attendance/${this.props.membershipId}/summary/${this.props.yearMonth}`)
    }
  }

  componentWillUnmount () {
    this.props.unSubscribeAttendanceDayRecordList(this.props.membershipId, this.props.day)
    this.props.unSubscribeSummaryRecord(`attendance/${this.props.membershipId}/summary/${this.props.yearMonth}`)
    this.props.unSubscribeEmployeeLiquidacion(this.props.employeeLiquidacionRecordName)
    this.props.siteLogRecords({ reset: true })
    this.props.selectActivitySummaryDay('')
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.currentAttendanceRecord, this.props.currentAttendanceRecord) || !isEqual(prevProps.siteLogRecord, this.props.siteLogRecord) || !isEqual(prevProps.siteLogRecordDayList, this.props.siteLogRecordDayList)) {
      const duration = reduce(this.props.siteLogRecord, (result, value) => {
        if (value.duration) result += value.duration
        return result
      }, 0)

      // automatically add/remove labour input cards when duration mandates
      if (!isEmpty(this.props.currentAttendanceRecord)) {
        if (duration < 1 - this.props.currentAttendanceRecord.data.absent && !includes(this.props.siteLogRecordDayList, '')) this.props.addSiteLogRecord()
        if (duration === 1 - this.props.currentAttendanceRecord.data.absent && includes(this.props.siteLogRecordDayList, '')) this.props.removeSiteLogRecord()
      }
    }
    if (this.props.showSummary && (this.props.day !== prevProps.day)) {
      this.props.subscribeAttendanceDayRecordList(this.props.membershipId, this.props.day)
    }
  }

  viewDay = (day) => {
    const newDay = `${this.props.yearMonth}-${day}`
    if (newDay !== this.props.day) {
      this.setState({ day, showLiquidacion: false, showSummary: false })
      this.props.selectActivitySummaryDay(newDay)
      this.props.unSubscribeAttendanceDayRecordList(this.props.membershipId, this.props.day)
    }
  }

  // return secondary color if day has annotations
  getChipColor = (day) => {
    if (this.props.summary.annotations.indexOf(day) > -1) {
      return 'secondary'
    }
    return 'primary'
  }

  // if the day has been valdiated set to outlined
  getChipVariant = (day) => {
    if (includes(this.props.attendanceSummaryRecord.validatedDays || [], day)) return 'outlined'
    return 'default'
  }

  setDuration = (payload, cb = noop) => {
    // other records duration
    const otherDuration = reduce(filter(this.props.siteLogRecord, (record, recordName) => recordName !== payload.recordName), (result, value) => {
      if (value.duration) result += value.duration
      return result
    }, 0)

    if (this.props.currentAttendanceRecord.data.overtimeOnly) {
      return cb(null)
    }
    if (otherDuration + payload.duration > 1 - this.props.currentAttendanceRecord.data.absent) {
      return cb(true)
    }
    cb(null)
  }

  computeLiquidacion = () => {
    // compute liquidacion
    this.props.computeEmployeeLiquidacion({ yearMonth: this.props.yearMonth, membershipId: this.props.membershipId }, (err) => {
      if (err) window.alert(JSON.stringify(err))
    })
  }

  viewLiquidacion = () => {
    this.computeLiquidacion()
    this.setState({ showLiquidacion: true, showSummary: false })
  }

  openDialog = (path) => {
    const dialogTitle = startCase(path.split('.')[2] || path.split('.')[1])
    this.setState({ openDialog: true, path, dialogTitle })
  }

  dialogCallback = (amountOrText) => {
    this.props.updateEmployeeLiquidacion({ recordName: this.props.employeeLiquidacionRecordName, path: this.state.path, amountOrText }, (err) => {
      if (err) window.alert(err)
      else this.computeLiquidacion()
    })
  }

  render () {
    return (
      <>
        {
          this.props.showSummary
            ? <Card>
              <CardHeader
                title={`Dias asistencia en ${this.props.monthText} de ${this.props.yearMonth.split('-')[0]} de ${startCase(this.props.employeeName.name)} ${startCase(this.props.employeeName.lastName)}`}
              />
              <CardContent>
                {map(sortBy(this.props.attendedDays), (day, idx) =>
                  <Chip key={idx} clickable className={this.props.classes.chip} color={this.getChipColor(day)} variant={this.getChipVariant(day)} label={day} onClick={() => this.viewDay(day)} />
                )}
              </CardContent>
              <CardActions>
                <UserAccessComponent access={4} {...this.props}>
                  <Button color='primary' onClick={() => this.setState({ showLiquidacion: false, showSummary: true })}>Ver Resumen</Button>
                  <ReactToPrint
                    trigger={() => <Button color='primary'>Imprimir Resumen</Button>}
                    content={() => this.componentRef2}
                  />

                  <UserAccessComponent access={3} {...this.props}>
                    <Button color='primary' onClick={this.viewLiquidacion}>Ver Liquidacion</Button>
                    <ReactToPrint
                      onBeforeGetContent={() => this.setState({ hideForPrint: true })}
                      onAfterPrint={() => this.setState({ hideForPrint: false })}
                      trigger={() => <Button color='primary'>Imprimir Liquidacion</Button>}
                      content={() => this.componentRef}
                    />

                  </UserAccessComponent>
                </UserAccessComponent>
              </CardActions>

            </Card>
            : <></>
        }
        <br />
        <br />
        {
          this.props.day && !this.state.showLiquidacion && !this.state.showSummary
            ? <Grid container spacing={24}>
              <Grid item xs={12}>
                <Attendance
                  day={this.props.day}
                  membershipId={this.props.membershipId}
                  employeeName={this.props.employeeName.name + ' ' + this.props.employeeName.lastName}
                  readOnly={this.props.showSummary}
                  contractType={this.props.contractType}
                />
              </Grid>
              {map(this.props.showSummary ? compact(this.props.siteLogRecordDayList) : this.props.siteLogRecordDayList, (recordName) =>
                <Grid item xs={12} key={recordName}>
                  <SiteLog
                    day={this.props.day}
                    membershipId={this.props.membershipId}
                    recordName={recordName}
                    employeeName={this.props.employeeName.name + ' ' + this.props.employeeName.lastName}
                    selectedDuration={(duration, cb) => this.setDuration({ recordName, duration }, cb)}
                    readOnly={this.props.showSummary}
                  />
                </Grid>
              )}
            </Grid> : <></>
        }
        <Grid container spacing={24}>
          {
            this.state.showLiquidacion
              ? <Grid item xs={12}>
                <EmployeeLiquidacionComponent {...this.props} hideForPrint={this.state.hideForPrint} action1={this.computeLiquidacion} ref={el => (this.componentRef = el)} openDialog={this.openDialog} />
              </Grid>
              : <></>
          }
          {
            this.state.showSummary
              ? <Grid item xs={12}>
                <EmployeeSummaryComponent {...this.props} ref={el => (this.componentRef2 = el)} />
              </Grid>
              : <></>
          }

          <Grid container item xs={12} direction='row' justify='flex-end'>
            <Button color='primary' onClick={this.props.history.goBack}>Continuar</Button>
          </Grid>

        </Grid>
        <InputTextDialog
          closeInputTextDialog={() => { this.setState({ openDialog: false }) }}
          callback={this.dialogCallback}
          open={this.state.openDialog}
          title={this.state.dialogTitle} />
      </>
    )
  }
}

ActivityDayView.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  membershipId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  day: PropTypes.string,
  employeeName: PropTypes.object,
  siteLogRecordDayList: PropTypes.array,
  subscribeAttendanceDayRecordList: PropTypes.func.isRequired,
  unSubscribeAttendanceDayRecordList: PropTypes.func.isRequired,
  addSiteLogRecord: PropTypes.func.isRequired,
  removeSiteLogRecord: PropTypes.func.isRequired,
  siteLogRecord: PropTypes.object.isRequired,
  siteLogRecords: PropTypes.func.isRequired,
  showSummary: PropTypes.bool.isRequired,
  summary: PropTypes.object,
  employeeContracts: PropTypes.object,
  attendanceSummaryRecord: PropTypes.object.isRequired,
  currentAttendanceRecord: PropTypes.object.isRequired,
  selectActivitySummaryDay: PropTypes.func.isRequired,
  unSubscribeSummaryRecord: PropTypes.func.isRequired,
  subscribeSummaryRecord: PropTypes.func.isRequired,
  computeEmployeeLiquidacion: PropTypes.func.isRequired,
  updateEmployeeLiquidacion: PropTypes.func.isRequired,
  employeeLiquidacion: PropTypes.object,
  yearMonth: PropTypes.string,
  monthText: PropTypes.string,
  attendedDays: PropTypes.array,
  annotationTopicsToGroupBy: PropTypes.array,
  employeeLiquidacionRecordName: PropTypes.string,
  contractType: PropTypes.string,
  unSubscribeEmployeeLiquidacion: PropTypes.func
}

export default withStyles(styles)(ActivityDayView)
