import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import EmployeesContractRegister from './EmployeesContractRegister'

const EmployeeRegisterContract = (props) => {
  const edit = props.location.pathname.indexOf('edit') > 1
  const { employeeId } = props.location.state
  const employeeAccounts = props.employeesBankAccount[`employee/${employeeId}`] || []

  return (
    <div>
      {
        props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <EmployeesContractRegister {...props}
            employeeId={employeeId}
            edit={edit}
            loading={props.loading}
            employeeAccounts={employeeAccounts}
          />
      }
    </div>
  )
}

EmployeeRegisterContract.propTypes = {
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  employeesBankAccount: PropTypes.object.isRequired
}

export default EmployeeRegisterContract
