import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import remove from 'lodash/remove'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'
import words from 'lodash/words'
import find from 'lodash/find'
import concat from 'lodash/concat'

import memoize from 'memoize-one'

import ActivityTableSummary from './ActivityTableSummary.js'
import { selectActivitySummaryMonth, setLoading, getSummaryPunchCard, unSubscribePunchCardRecord, subscribePunchCardRecord, getEmployeeLiquidacionParams, computeEmployeeLiquidacionForAll } from './Attendance/redux/attendanceActions'
import { subscribeToAnnotationTopics } from './SiteLog/redux/siteLogActions.js'
import { recordNameToId, columnsFilter, getTipoContrato } from '../../utils'
import { makeXlsxFile } from '../FileUtils/redux/fileUtilsActions.js'
import { createOutgoingPayment, outgoingPaymentRequest, createPaymentOrder, discardOutgoingPaymentRequestRecord } from '../Payments/redux/paymentsActions.js'

const columns = [
  { name: 'employeeId', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'membershipId', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'employeeName', options: { viewColumns: false, display: 'excluded', filter: false } }
]

const defaultColumns = ['rut', 'nombre', 'predio', 'tipoContrato', 'fechaInicio', 'declarado', 'finiquitado', 'diasAsistencia', 'jornadasAusencias', 'diasVacaciones', 'diasLicencia']

const _employeesList = memoize((employeesMemberships) => map(employeesMemberships, (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')))
const _membershipIds = memoize((employeesMemberships) => map(employeesMemberships, (m) => recordNameToId(m)))

const _data = memoize((employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, summaryPunchCard) => reduce(employeesList, (result, employeeRecordName) => {
  // return if no data
  if (!summaryPunchCard.data) return result
  if (summaryPunchCard.data.length === 0) return result

  let employee = employeeRecord[employeeRecordName]
  let farmId = null
  let farmName = null
  let employeeContract = null
  if (!isEmpty(employeeContracts)) {
    farmId = employeeContracts[employeeRecordName] ? employeeContracts[employeeRecordName].farm : null
    employeeContract = employeeContracts[employeeRecordName]
  }

  if (farmId) farmName = farmRecords[`farm/${farmId}`] ? farmRecords[`farm/${farmId}`].displayName : 'n/d'

  const employeeContractType = employeeContract ? `contract/${employeeContract.type}` : 'contract/type/'
  const contractTypeRecord = contractTypeRecords[employeeContractType]
  const employeeId = employeeRecordName.split('/')[1]
  const membershipId = recordNameToId(find(employeesMemberships, (m) => m.indexOf(employeeId) > 0))
  const summary = find(summaryPunchCard.data, (d) => d.membershipId === membershipId)
  // return if no data
  if (!summary) return result

  const diasAsistencia = reduce(summary, (result, value, key) => {
    if (Number(key) > 0) {
      result += value
    }
    return result
  }, 0)

  let relevant = {
    rut: employeeId,
    nombre: employee ? `${(words(employee.name)[0])} ${words(employee.lastName)[0]}` : 'n/d',
    jornadasAusencias: summary.absent,
    diasVacaciones: summary.vacation.length,
    diasLicencia: summary.license.length,
    diasAsistencia,
    predio: farmName || 'n/d',
    tipoContrato: getTipoContrato(contractTypeRecord),
    fechaInicio: summary.contractBeginDate,
    declarado: summary.contractUndeclared ? 'No' : 'Si',
    finiquitado: summary.contractEndDate,
    employeeId,
    membershipId,
    employeeName: { name: employee.name, lastName: employee.lastName }
  }

  for (var key in relevant) {
    if (!includes(map(columns, (c) => c.name), key)) {
      columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
    }
  }

  result.push(relevant)
  return result
}, []))

const _remoteData = memoize((employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, summaryPunchCard) => reduce(summaryPunchCard.remoteMembershipData, (result, remoteData) => {
  // return if no data
  if (isEmpty(remoteData)) return result

  let employeeData = remoteData.employeeData
  let farmId = remoteData.farmId
  let farmName = null

  if (farmId) farmName = farmRecords[`farm/${farmId}`] ? farmRecords[`farm/${farmId}`].displayName : 'n/d'

  const employeeContractType = `contract/${remoteData.contractTypeId}`
  const contractTypeRecord = contractTypeRecords[employeeContractType]
  const employeeId = remoteData.employeeId
  const membershipId = remoteData.membershipId
  const summary = find(summaryPunchCard.data, (d) => d.membershipId === membershipId)
  // return if no data
  if (!summary) return result

  const diasAsistencia = reduce(summary, (result, value, key) => {
    if (Number(key) > 0) {
      result += value
    }
    return result
  }, 0)

  let relevant = {
    rut: employeeId,
    nombre: `${(words(employeeData.name)[0])} ${words(employeeData.lastName)[0]}`,
    jornadasAusencias: summary.absent,
    diasVacaciones: summary.vacation.length,
    diasLicencia: summary.license.length,
    diasAsistencia,
    predio: farmName || 'n/d',
    tipoContrato: getTipoContrato(contractTypeRecord),
    fechaInicio: remoteData.contractBeginDate,
    finiquitado: remoteData.contractEndDate,
    declarado: remoteData.contractUndeclared ? 'No' : 'Si',
    employeeId,
    membershipId,
    employeeName: { name: employeeData.name, lastName: employeeData.lastName }
  }

  for (var key in relevant) {
    if (!includes(map(columns, (c) => c.name), key)) {
      columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
    }
  }

  result.push(relevant)
  return result
}, []))

const _getPredio = memoize((userFarms, farmRecords) => {
  if (farmRecords[userFarms[0]]) return farmRecords[userFarms[0]].displayName
  return null
})

const mapStateToProps = (state, ownProps) => {
  const companyId = state.auth.clientData.activeCompany
  const { access } = state.main.userData
  const userId = state.auth.clientData.userId
  const farmRecords = state.farms.farmRecord
  const predio = _getPredio(state.farms.userFarms, farmRecords)
  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]
  const membershipIds = _membershipIds(employeesMemberships)
  const employeeContracts = state.employees.employeeContract
  const employeesList = _employeesList(employeesMemberships)
  const contractTypeRecords = state.employees.contractTypeRecord
  const { selectedActivitySummaryMonth, loading, summaryPunchCard } = state.attendance
  const data = _data(employeesList, state.employees.employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, summaryPunchCard)
  const remoteData = _remoteData(employeesList, state.employees.employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, summaryPunchCard)
  const annotationTopicsToGroupBy = state.siteLog.siteLogAnnotationTopics.topics
  const { outgoingPaymentRequest } = state.payments

  return {
    data: concat(data, remoteData),
    columns: columnsFilter(columns, defaultColumns, ownProps.location, summaryPunchCard.data ? summaryPunchCard.data.length : 0),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'multiple',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    selectedActivitySummaryMonth,
    membershipIds,
    loading,
    predio,
    summaryPunchCard,
    annotationTopicsToGroupBy,
    userAccess: access,
    outgoingPaymentRequests: outgoingPaymentRequest,
    employeeContracts,
    companyId,
    userId
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  selectActivitySummaryMonth,
  setLoading,
  getSummaryPunchCard,
  makeXlsxFile,
  unSubscribePunchCardRecord,
  subscribePunchCardRecord,
  subscribeToAnnotationTopics,
  createOutgoingPayment,
  outgoingPaymentRequest,
  createPaymentOrder,
  discardOutgoingPaymentRequestRecord,
  getEmployeeLiquidacionParams,
  computeEmployeeLiquidacionForAll
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTableSummary)
