import { createReducer } from '@reduxjs/toolkit'
import { laboursList, labourRecord } from './contractActions'

const initialState = {
  laboursList: {},
  labourRecord: {},
  start: null
}

const contractReducer = createReducer(initialState, {
  [laboursList]: (state, action) => {
    state.laboursList[action.payload.listName] = action.payload.data
  },
  [labourRecord]: (state, action) => {
    state.labourRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.labourRecord[action.payload.recordName]
    }
  }
})

export default contractReducer
