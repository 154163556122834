import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import find from 'lodash/find'

import moment from 'moment-timezone'
import 'moment/locale/es'

import ActivityLiquidacionSummary from './ActivityLiquidacionSummary'
import { computeEmployeeLiquidacion, unSubscribeEmployeeLiquidacion } from './Attendance/redux/attendanceActions'
import { makeXlsxFile } from '../FileUtils/redux/fileUtilsActions.js'

const mapDispatchToProps = dispatch => bindActionCreators({
  computeEmployeeLiquidacion,
  unSubscribeEmployeeLiquidacion,
  makeXlsxFile
}, dispatch)

const mapStateToProps = (state, ownProps) => {
  let { membershipIds, yearMonth, summaryPunchCard, search } = ownProps.location.state
  // month to text
  moment.locale('es')
  const monthText = startCase(moment(yearMonth, 'YYYY-MM').format('MMMM'))
  // query params
  const query = new URLSearchParams(search)
  const { access } = state.main.userData
  const employeesLiquidacion = state.payments.employeesLiquidacion
  const companyId = state.auth.clientData.activeCompany
  const annotationTopicsToGroupBy = state.siteLog.siteLogAnnotationTopics.topics
  const employeesRecords = state.employees.employeeRecord
  const contractTypeRecords = state.employees.contractTypeRecord

  const liquidacionData = map(membershipIds, (membershipId) => {
    const employeeId = membershipId.split('/')[1]
    const employeeRecordName = `employee/${employeeId}`
    let employeeRecord = employeesRecords[employeeRecordName]
    let employeeName = { name: '', lastName: '' }
    if (employeeRecord) {
      employeeName.name = employeeRecord.name
      employeeName.lastName = employeeRecord.lastName
    } else {
      // check remote data
      if (summaryPunchCard.remoteData) {
        const remoteData = find(summaryPunchCard.remoteData, (remote) => remote.membershipId === membershipId)
        if (remoteData) {
          employeeName = remoteData.employeeName
        }
      }
    }
    const employeeLiquidacion = employeesLiquidacion[`payment/${companyId}/${membershipId}/liquidacion/${yearMonth}`]

    const summary = find(summaryPunchCard.data, (d) => d.membershipId === membershipId)

    const contractTypeRecord = contractTypeRecords[`contract/${summary.contractTypeId}`]

    return { summary, membershipId, employeeName, employeeLiquidacion, contractTypeRecord }
  })

  return { liquidacionData, companyId, userAccess: access, membershipIds, yearMonth, monthText, annotationTopicsToGroupBy, query }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLiquidacionSummary)
