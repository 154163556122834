import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import startCase from 'lodash/startCase'
import map from 'lodash/map'

const EmployeeSummaryComponent = React.forwardRef((props, ref) => {
  const { summary } = props
  const diasAsistencia = reduce(summary, (result, value, key) => {
    if (Number(key) > 0) {
      result += value
    }
    return result
  }, 0)

  return (
    <Card ref={ref}>
      <CardHeader
        title={`Resumen asistencia en ${props.monthText} de ${props.yearMonth.split('-')[0]} de ${startCase(props.employeeName.name)} ${startCase(props.employeeName.lastName)}`}
      />
      <CardContent>
        <p>
          {`Dias Asistencia: ${diasAsistencia}`}
        </p>
        <p>
          {`Ausencias: ${summary.absent}`}
        </p>
        <p>
          {`Horas Extra: ${summary.horaExtra + summary.horaExtraAdicional}`}
        </p>
        <p>
          {`Horas Extra Nocturna: ${summary.horaExtraNocturna}`}
        </p>
        <p>
          {`Horas Extra Control Heladas: ${summary.horaExtraControlHelada}`}
        </p>
        <p>
          {`Dias vacaciones: ${summary.vacation.length}`}
        </p>
        <p>Anotaciones:</p>
        {map(summary.annotationTopicsToGroupBy, (data, key) => {
          return (
            <p key={key}>Dia {data.day}: {startCase(find(props.annotationTopicsToGroupBy, (topic) => topic.id === data.annotation.topicId).topic)}: {data.annotation.text} : {data.annotation.amount}</p>
          )
        })}

      </CardContent>
    </Card>)
})

EmployeeSummaryComponent.propTypes = {
  employeeName: PropTypes.object,
  yearMonth: PropTypes.string,
  monthText: PropTypes.string,
  summary: PropTypes.object.isRequired,
  annotationTopicsToGroupBy: PropTypes.array.isRequired
}

export default EmployeeSummaryComponent
