import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import moment from 'moment-timezone'
import { appConfig } from '../../config'
import FileDownloadLinkComponent from '../FileUtils/FileDownloadLinkComponent'

import map from 'lodash/map'
import join from 'lodash/join'
import compact from 'lodash/compact'
import split from 'lodash/split'
import deburr from 'lodash/deburr'
import debounce from 'lodash/debounce'
import clone from 'lodash/clone'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 2
  },
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  grow: {
    flexGrow: 1
  }
})
let orderRecord = null

const initialState = { topic: null, comment: null, viewPaymentDialog: false }

class PaymentOrderRecordView extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    this.props.getPaymentOrderRecord(this.props.outgoingPaymentOrderRecordName, (err, record) => {
      if (err) {
        console.error(err)
      } else {
        orderRecord = record
      }
    })
  }

  componentWillUnmount () {
    orderRecord = null
  }

  getUrls = (outgoingOrderRecord) => {
    if (outgoingOrderRecord.data && outgoingOrderRecord.data.document) {
      return map(outgoingOrderRecord.data.document, (url) => `${appConfig.downloadUrlPrefix}${url}`)
    }
    return ['']
  }

  setPaymentStatus = (payload) => {
    const orderId = this.props.outgoingPaymentOrderRecordName.substring(8)
    this.props.setPaymentStatus({ ...payload, orderId })
  }

  doWrite = (name, value) => {
    if (name === 'comment') {
      orderRecord.set(name, value)
    }
    if (name === 'topic') {
      orderRecord.set(name, compact(split(value, ' ')))
    }
  }

  // delay handleChange invocation by 1 s
  debouncedWrite = debounce(this.doWrite, 1000, { trailing: true })

  handleChange = name => event => {
    const formated = deburr(event.target.value)
    if (name === 'comment') this.setState({ [name]: formated })
    if (name === 'topic') this.setState({ [name]: formated.split(' ') })
    if (orderRecord) {
      this.debouncedWrite(name, formated)
    }
  }

  toggleViewPaymentDialog = () => {
    this.setState({ viewPaymentDialog: !this.state.viewPaymentDialog })
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <Card>
          <CardHeader title='Orden de Pago' />
          <CardContent>
            <Typography variant='body1' gutterBottom>
                Total: {this.props.outgoingPaymentOrderRecord.data.amount.CLP.toLocaleString('es-CL', { currency: 'CLP', style: 'currency' })}
            </Typography>
            <Typography variant='body1' gutterBottom>
                Fecha creacion: {moment(this.props.outgoingPaymentOrderRecord.createdAt).tz('America/Santiago').format('DD-MMM hh:mm a')}
            </Typography>
            <Typography variant='body1' gutterBottom>
                Status: {this.props.outgoingPaymentOrderRecord.status}
            </Typography>
            <TextField
              id='topic'
              label='Topicos'
              value={join(this.state.topic || this.props.outgoingPaymentOrderRecord.topic || [''], ' ')}
              onChange={this.handleChange('topic')}
              margin='normal'
            />
            <br />
            <TextField
              id='comment'
              label='Comentario'
              value={this.state.comment || this.props.outgoingPaymentOrderRecord.comment}
              onChange={this.handleChange('comment')}
              margin='normal'
            />

            <List
              subheader={<ListSubheader>Pagos en la orden</ListSubheader>}>
              {
                map(this.props.outgoingPaymentOrderRecord.data.paymentIds, (paymentId, key) =>
                  <ListItem key={key}>
                    <ListItemText
                      primary={paymentId}
                      secondary={this.props.paymentRecords[`payment/${paymentId}`] ? this.props.paymentRecords[`payment/${paymentId}`].status : ''}
                    />
                    <Button size='small' color='secondary' className={classes.button} onClick={() => this.setPaymentStatus({ status: 400, paymentId })}>
                      Error
                    </Button>
                    <Button size='small' color='primary' className={classes.button} onClick={() => this.setPaymentStatus({ status: 200, paymentId })}>
                      OK
                    </Button>
                    <Button size='small' color='primary' className={classes.button} onClick={() => { this.toggleViewPaymentDialog() }}>
                      Detalle
                    </Button>

                  </ListItem>
                )
              }
            </List>

            <List
              subheader={<ListSubheader>Documentos</ListSubheader>}>
              {
                map(this.getUrls(this.props.outgoingPaymentOrderRecord), (url, key) =>
                  <ListItem key={key}>
                    <ListItemText
                      primary={<FileDownloadLinkComponent href={url} />}
                    />
                  </ListItem>
                )
              }
            </List>

          </CardContent>
        </Card>
        {/* view payent dialog */}
        <Dialog onClose={this.toggleViewPaymentDialog} open={this.state.viewPaymentDialog} scroll={'paper'} fullWidth>
          <DialogTitle>Pago saliente</DialogTitle>
          <DialogContent>
            <pre>{JSON.stringify(this.props.paymentRecords, null, 2)}</pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.toggleViewPaymentDialog()}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </>

    )
  }
}

PaymentOrderRecordView.propTypes = {
  classes: PropTypes.object.isRequired,
  outgoingPaymentOrderRecordName: PropTypes.string.isRequired,
  setPaymentStatus: PropTypes.func,
  outgoingPaymentOrderRecord: PropTypes.object,
  paymentRecords: PropTypes.object,
  getPaymentOrderRecord: PropTypes.func
}

export default withStyles(styles)(PaymentOrderRecordView)
