import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from './redux/authActions.js'
import LoginComponent from './Login.js'

const mapDispatchToProps = dispatch => bindActionCreators({
  login
}, dispatch)

const mapStateToProps = state => ({ isAuthenticated: state.auth.isAuthenticated })

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
