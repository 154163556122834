import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import remove from 'lodash/remove'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import join from 'lodash/join'

import memoize from 'memoize-one'

import EmployeesAbsentTable from './EmployeesAbsentTable.js'
import { getAbsentEmployees } from './redux/employeesActions'
import { getTipoContrato } from '../../utils.js'

const columns = [{ name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } }]
const defaultColumns = ['rut', 'nombre', 'apellido', 'predio', 'tipoContrato', 'diasAusenciaEnUltimos15', 'declarado']

const _employeesList = memoize((employeesMemberships) => map(employeesMemberships, (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')))

const _data = memoize((employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesPerDayAndFarm) => reduce(employeesList, (result, employeeRecordId) => {
  let employee = employeeRecord[employeeRecordId]
  let farm = null
  let employeeContract = null
  if (!isEmpty(employeeContracts)) {
    farm = employeeContracts[employeeRecordId] ? employeeContracts[employeeRecordId].farm : null
    employeeContract = employeeContracts[employeeRecordId]
  }

  if (farm) farm = farmRecords[`farm/${employeeContracts[employeeRecordId].farm}`] ? farmRecords[`farm/${employeeContracts[employeeRecordId].farm}`].displayName : 'n/d'

  const employeeContractType = employeeContract ? `contract/${employeeContract.type}` : 'contract/type/'
  const contractTypeRecord = contractTypeRecords[employeeContractType]

  // This will tell us if an employee was not present in his farm a day where there was harvest there.
  // If there wasn't harvest at his farm it won''t know if he went to another farm because he is not required to
  // If employees change farms for some time, it should be changed on the contract data
  const absentDays = reduce(employeesPerDayAndFarm, (result, data, day) => {
    // no harvest at company that day
    if (data.length === 0) return result
    // if contract began after day return
    if (employeeContract.beginDate > day) {
      return result
    }

    // harvested at farm?
    const employeesAtFarm = find(data, ['farmId', employeeContracts[employeeRecordId].farm])
    if (employeesAtFarm) {
      // there was harvest, is he present?
      const present = employeesAtFarm.employees.indexOf(employeeRecordId.split('/')[1]) > -1
      if (!present) result.push(day)
    }
    return result
  }, [])

  let relevant = {
    rut: employeeRecordId.split('/')[1],
    nombre: employee ? employee.name : 'n/d',
    apellido: employee ? employee.lastName : 'n/d',
    predio: farm || 'n/d',
    tipoContrato: getTipoContrato(contractTypeRecord),
    diasAusenciaEnUltimos15: join(absentDays, '; '),
    declarado: (employeeContract && employeeContract.undeclared) ? 'No' : 'Si',
    id: `__employeeRecordId__:${employeeRecordId}`
  }
  for (var key in relevant) {
    if (key !== 'id') {
      if (!includes(map(columns, (c) => c.name), key)) {
        columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
      }
    }
  }
  result.push(relevant)
  return result
}, []))

const _columns = memoize((columns) => orderBy(columns, (o) => defaultColumns.indexOf(o.name)))

const mapStateToProps = state => {
  const companyId = state.auth.clientData.activeCompany
  const farmRecords = state.farms.farmRecord
  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]
  const employeeContracts = state.employees.employeeContract
  const employeesList = _employeesList(employeesMemberships)
  const contractTypeRecords = state.employees.contractTypeRecord
  const employeesPerDayAndFarm = state.employees.employeesPerDayAndFarm

  return {
    data: _data(employeesList, state.employees.employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesPerDayAndFarm),
    columns: _columns(columns),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'none',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    loading: state.employees.loading || false
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getAbsentEmployees
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesAbsentTable)
