import { createReducer } from '@reduxjs/toolkit'
import remove from 'lodash/remove'

import {
  connectionState, connectionError, userData,
  userMembershipList, userMemberships, companySelectorData,
  mainStart, userAccess, loading, userRequests } from './mainActions'

import {
  addPendingRequest, resetPendingRequests, sendingPendingRequests, saveFailedRequests, alterPendingRequests } from './mainActionsPendingRequests'

const initialState = {
  connectionState: '',
  connectionError: {},
  pendingRequests: [],
  sendingPendingRequestsActive: false,
  failedRequests: [],
  userData: {},
  userMembershipList: [],
  userMemberships: {},
  companySelectorData: {},
  start: true,
  loading: true,
  userRequests: {}
}

const mainReducer = createReducer(initialState, {
  [connectionState]: (state, action) => { state.connectionState = action.payload },
  [connectionError]: (state, action) => { state.connectionError = action.payload },
  [addPendingRequest]: (state, action) => { state.pendingRequests.push(action.payload) },
  [resetPendingRequests]: (state, action) => { state.pendingRequests = action.payload || [] },
  [sendingPendingRequests]: (state, action) => { state.sendingPendingRequestsActive = action.payload },
  [saveFailedRequests]: (state, action) => { state.failedRequests.push(action.payload) },
  [userData]: (state, action) => { state.userData = { ...state.userData, ...action.payload } },
  [userAccess]: (state, action) => { state.userData.access = action.payload },
  [mainStart]: (state, action) => { state.start = action.payload },
  [loading]: (state, action) => { state.loading = action.payload },
  [userRequests]: (state, action) => { state.userRequests[action.payload.recordName] = action.payload.data },
  [userMembershipList]: (state, action) => { state.userMembershipList = action.payload.data },
  [userMemberships]: (state, action) => {
    state.userMemberships[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.userMemberships[action.payload.recordName]
    }
    if (action.payload.removeAll) {
      state.userMemberships = {}
    }
  },
  [companySelectorData]: (state, action) => {
    state.companySelectorData[action.payload.recordName] = { ...action.payload.data, company: action.payload.recordName.split('/')[1] }
    if (action.payload.remove) {
      delete state.companySelectorData[action.payload.recordName]
    }
  },
  [alterPendingRequests]: (state, action) => {
    state.pendingRequests[action.payload.idx].data = action.payload.data
    if (action.payload.remove) {
      remove(state.pendingRequests, (val, idx) => idx === action.payload.idx)
    }
  }
})

export default mainReducer
