import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import memoize from 'memoize-one'
import reduce from 'lodash/reduce'
import map from 'lodash/map'

import { createEmployee, createEmployeeResult, createContract, editEmployee, editContract, editContractPaymentMethod, getNonRegisteredEmployeeData, editPath, createPaymentMethod, getEmployeeBankAccount } from './redux/employeesActions'
import _EmployeesRegister from './EmployeesRegister.js'
import _EmployeesRegisterContract from './EmployeesRegisterContract.js'
import _EmployeesRegisterPaymentMethod from './EmployeesRegisterPaymentMethod.js'
import { recordNameToId } from '../../utils'
// disabled
// import _EmployeesRegisterExtras from './EmployeesRegisterExtras.js'

const mapDispatchToProps = dispatch => bindActionCreators({
  createEmployee,
  createEmployeeResult,
  createContract,
  editEmployee,
  editContract,
  getNonRegisteredEmployeeData,
  editPath,
  createPaymentMethod,
  getEmployeeBankAccount,
  editContractPaymentMethod
}, dispatch)

const _contractTypes = memoize((companyContractTypesList, contractTypeRecord, activeCompany, access) => reduce(companyContractTypesList[`company/${activeCompany}/list/contract/types`], (res, value) => {
  const contractData = contractTypeRecord[value]
  if (!contractData.active) return res
  if (contractData.personal && access >= 3) return res
  let relevant = {
    id: recordNameToId(value),
    name: contractData.name,
    type: contractData.type
  }
  res.push(relevant)
  return res
}, []))

const _farms = memoize((userFarms, farmRecord) => reduce(userFarms, (res, value, key) => {
  let relevant = {
    name: farmRecord[value].displayName,
    id: value.split('/')[1]
  }
  res.push(relevant)
  return res
}, []))

const _companyEmployeesList = memoize((companyEmployeesList) => map(companyEmployeesList, (membershipRecordName) => membershipRecordName.split('/')[2]))

const mapStateToProps = state => {
  const { activeCompany } = state.auth.clientData
  const { access } = state.main.userData
  const result = {
    contractTypes: _contractTypes(state.employees.companyContractTypesList, state.employees.contractTypeRecord, activeCompany, access),
    farms: _farms(state.farms.userFarms, state.farms.farmRecord),
    createEmployeeSuccess: state.employees.createEmployeeSuccess,
    employees: state.employees.employeeRecord,
    employeesBankAccount: state.employees.employeesBankAccount,
    contracts: state.employees.employeeContract,
    companyEmployeesList: _companyEmployeesList(state.employees.companyEmployeesList[`company/${activeCompany}/list/employees`]),
    loading: state.employees.loading
  }
  return result
}

const EmployeesRegister = connect(mapStateToProps, mapDispatchToProps)(_EmployeesRegister)
const EmployeesRegisterContract = connect(mapStateToProps, mapDispatchToProps)(_EmployeesRegisterContract)
const EmployeesRegisterPaymentMethod = connect(mapStateToProps, mapDispatchToProps)(_EmployeesRegisterPaymentMethod)
// disabled
// const EmployeesRegisterExtras = connect(mapStateToProps, mapDispatchToProps)(_EmployeesRegisterExtras)

export default { EmployeesRegister, EmployeesRegisterContract, EmployeesRegisterPaymentMethod }
