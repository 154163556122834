import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import map from 'lodash/map'
import pullAt from 'lodash/pullAt'
import clone from 'lodash/clone'
import forEach from 'lodash/forEach'

import InputSelectDialog from '../Components/InputSelectDialog'
import { paymentClass } from '../../config'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
})

const CustomToolbarSelect = (props) => {
  const updateOrder = (status, classification) => {
    const indexes = map(props.selectedRows.data, (d) => d.dataIndex)
    // we make a clone since pullAt mutates array
    const dataClone = clone(props.data)
    const orderIds = map(pullAt(dataClone, indexes), (value) => value.id)
    props.updatePaymentOrder({ status, orderIds, classification })
  }

  return (
    <>
      <Button className={props.classes.button} color='primary' onClick={
        () => props.toggle()} >
        ejecutado
      </Button>
      <Button className={props.classes.button} color='secondary' onClick={
        () => updateOrder(400, null)} >
        Error
      </Button>
      <InputSelectDialog
        closeInputTextDialog={() => props.toggle()}
        callback={(value) => updateOrder(200, value)}
        open={props.open}
        title={'Clasificacion pago'}
        options={paymentClass}
      />
    </>

  )
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array,
  updatePaymentOrder: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

const CustomToolbarSelect2 = (props) => {
  const createOutgoingPaymentOrder = () => {
    const indexes = map(props.selectedRows.data, (d) => d.dataIndex)
    // we make a clone since pullAt mutates array
    const dataClone = clone(props.data)
    const requests = map(pullAt(dataClone, indexes), (value) => value.recordName)
    props.createPaymentOrder(requests[0])
  }

  const removeOutgoingPaymentRequest = () => {
    const indexes = map(props.selectedRows.data, (d) => d.dataIndex)
    // we make a clone since pullAt mutates array
    const dataClone = clone(props.data)
    const requests = map(pullAt(dataClone, indexes), (value) => value.recordName)
    props.removePaymentRequest(requests[0])
  }

  return (
    <>
      <Button className={props.classes.button} color='primary' onClick={() => createOutgoingPaymentOrder()} >
        Crear Orden
      </Button>
      <Button className={props.classes.button} color='primary' onClick={() => removeOutgoingPaymentRequest()} >
        Eliminar Solicitud
      </Button>
    </>

  )
}

CustomToolbarSelect2.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedRows: PropTypes.object.isRequired,
  data: PropTypes.array,
  createPaymentOrder: PropTypes.func.isRequired,
  removePaymentRequest: PropTypes.func.isRequired
}

class PaymentsTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = { openDialog: false }
  }

  componentDidMount () {
    this.props.subscribeToOutgoingPaymentOrderList()
    this.props.subscribeToOutgoingPaymentRequestList()
  }

  componentWillUnmount () {
    this.props.unSubscribeToOutgoingPaymentOrderList()
    this.props.unSubscribeToOutgoingPaymentRequestList()
  }

  onCellClick = (data, meta) => {
    const id = this.props.outgoingPaymentOrder[meta.dataIndex].id
    this.props.history.push(`/app/payments/view`, { outgoingPaymentOrderRecordName: id })
  }

  createPaymentOrder = (recordName) => {
    const request = this.props.outgoingPaymentRequests[recordName]
    if (request.status !== 200) return
    forEach(request.data, (value) => {
      const { payment } = value
      this.props.createPaymentOrder({ paymentIds: [value.paymentId], topic: payment.topic, comment: payment.comment }, (err, res) => {
        if (err) {
          window.alert(err)
        } else {
          console.log('success', res)
        }
      })
    })
  }

  removePaymentRequest = (recordName) => {
    this.props.removePaymentRequest(recordName, (err, res) => {
      console.log('removePaymentRequest:result', err, res)
    })
  }

  render () {
    return (
      <>
        <MUIDataTable
          title={'Ordenes de pago emitidas'}
          data={this.props.outgoingPaymentOrder}
          columns={this.props.columns}
          options={{
            responsive: 'scrollMaxHeight',
            expandableRows: false,
            selectableRows: 'multiple',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            onCellClick: this.onCellClick,
            customToolbarSelect: (selectedRows) => (
              <CustomToolbarSelect
                classes={this.props.classes}
                selectedRows={selectedRows}
                data={this.props.outgoingPaymentOrder}
                updatePaymentOrder={this.props.updatePaymentOrder}
                open={this.state.openDialog}
                toggle={() => this.setState({ openDialog: !this.state.openDialog })}
              />)
          }}
        />
        <br />
        <MUIDataTable
          title={'Solicitudes de pago salientes'}
          data={this.props.outgoingPaymentRequest}
          columns={this.props.columns2}
          options={{
            responsive: 'scrollMaxHeight',
            expandableRows: false,
            selectableRows: 'multiple',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            onCellClick: (data, meta) => console.log('REQUEST', this.props.outgoingPaymentRequests[this.props.outgoingPaymentRequest[meta.dataIndex].recordName]),
            customToolbarSelect: (selectedRows) => (
              <CustomToolbarSelect2
                classes={this.props.classes}
                selectedRows={selectedRows}
                data={this.props.outgoingPaymentRequest}
                createPaymentOrder={this.createPaymentOrder}
                removePaymentRequest={this.removePaymentRequest}
              />)
          }}
        />

      </>

    )
  }
}
PaymentsTable.propTypes = {
  outgoingPaymentOrder: PropTypes.array.isRequired,
  outgoingPaymentRequest: PropTypes.array.isRequired,
  columns: PropTypes.array,
  columns2: PropTypes.array,
  updatePaymentOrder: PropTypes.func,
  unSubscribeToOutgoingPaymentOrderList: PropTypes.func.isRequired,
  subscribeToOutgoingPaymentOrderList: PropTypes.func.isRequired,
  subscribeToOutgoingPaymentRequestList: PropTypes.func.isRequired,
  unSubscribeToOutgoingPaymentRequestList: PropTypes.func.isRequired,
  createPaymentOrder: PropTypes.func.isRequired,
  removePaymentRequest: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  outgoingPaymentRequests: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(PaymentsTable))
