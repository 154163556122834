import { createAction } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import clone from 'lodash/clone'
import now from 'lodash/now'
import findIndex from 'lodash/findIndex'

import { deepstreamConfig } from '../../../config'

import { getPendingRecords } from '../../Harvest/redux/harvestActions.js'

/*
  Pending requests logic
 */

/*
  # Request format for records:
  topic string false Must have value "record".
  action string false Must have value "write".
  recordName string false The name of the record e.g. "cities/hamburg".
  path string true The record path.
  version integer true The version to be written. Defaults to -1 (force write).
  data JSON false The data to be set. If no path is set, this must be an object.
  {
    "topic": "record",
    "action": "write",
    "recordName": "users/123",
    "path": "firstname",
    "version": 6,
    "data": "Bob"
  }

  # Request format for RPC
  topic string false Must have value "rpc".
  action string false Must have value "make".
  rpcName string false The name of the RPC to call.
  data JSON true The RPC data argument.
  {
    "topic": "rpc",
    "action": "make",
    "rpcName": "add-two",
    "data": {
      "numA": 2,
      "numB": 5
      }
  }

*/
export const addPendingRequest = createAction('main/addPendingRequest')
export const resetPendingRequests = createAction('main/resetPendingRequests')
export const sendingPendingRequests = createAction('main/sendingPendingRequests')
export const saveFailedRequests = createAction('main/saveFailedRequests')

export const sendPendingRequests = () => (dispatch, getState, dsClient) => {
  const state = getState()
  const { sendingPendingRequestsActive, pendingRequests } = state.main

  if (sendingPendingRequestsActive || isEmpty(pendingRequests)) {
    console.log('sendingPendingRequestsActive or no pendingRequests')
    return
  }

  dispatch(sendingPendingRequests(true))

  // use http endpoint
  fetch(deepstreamConfig.httpUrl, {
    method: 'POST',
    body: JSON.stringify({
      body: pendingRequests,
      authData: {
        username: state.auth.credentials.username,
        password: state.auth.credentials.password
      }
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      // if ok deepstrem processed request
      if (response.ok) return response.json()
      // else deepstrem encountered request error
      // TODO: handle bad request by statusCode? inform other endpoint? wait and retry?
      console.error(response)
    })
    .then((value) => {
      if (!value) return
      if (value.result === 'SUCCESS') {
        dispatch(resetPendingRequestsAndState())
        return
      }
      // reduce body and keep those wich success is false
      let failedRequests = reduce(value.body, (result, value, idx) => {
        if (!value.success) {
          // save original request
          let f = { originalRequest: clone(pendingRequests[idx]) }
          // save error
          f.errorMessage = value
          f.errorTimestamp = now()
          result.push(f)
        }
        return result
      }, [])

      // TODO: handle current state modifications
      dispatch(saveFailedRequests(failedRequests))
      dispatch(resetPendingRequests())
    })
    .catch(error => {
      console.error(error)
    })
    .then(() => {
      dispatch(sendingPendingRequests(false))
    })
}

/**
 * action to alert all involved states that pending requests have changed
 */
const resetPendingRequestsAndState = () => (dispatch, getState) => {
  dispatch(resetPendingRequests())
  dispatch(getPendingRecords())
}

export const alterPendingRequests = createAction('main/alterPendingRequests')

export const modifyHarvestRecordPendingRequest = (data, cb = null) => (dispatch, getState) => {
  const { pendingRequests } = getState().main
  const idx = findIndex(pendingRequests, (req) => req.rpcName === 'harvest/record/create' && req.data && req.data.recordUid === data.recordUid && req.data.newRecord.date === data.newRecord.date)
  if (idx > -1) {
    dispatch(alterPendingRequests({ idx, data }))
    if (cb) return cb(null, 200)
  } else {
    if (cb) return cb(new Error(404))
  }
}

export const deleteHarvestRecordPendingRequest = (recordUid, cb = null) => (dispatch, getState) => {
  const { pendingRequests } = getState().main
  const idx = findIndex(pendingRequests, (req) => req.rpcName === 'harvest/record/create' && req.data && req.data.recordUid === recordUid)

  if (idx > -1) {
    dispatch(alterPendingRequests({ idx, remove: true }))
    if (cb) return cb(null, 200)
  } else {
    if (cb) return cb(new Error(404))
  }
}
