import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import memoize from 'memoize-one'
import moment from 'moment-timezone'

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import compact from 'lodash/compact'

import PaymentsTable from './PaymentsTable.js'
import { updatePaymentOrder, getPaymentOrderRecord, subscribeToOutgoingPaymentOrderList, unSubscribeToOutgoingPaymentOrderList, subscribeToOutgoingPaymentRequestList, unSubscribeToOutgoingPaymentRequestList, createPaymentOrder, removePaymentRequest } from './redux/paymentsActions'

const _out = memoize((outgoingPaymentOrder) => map(outgoingPaymentOrder, (value, id) => {
  const getAmount = (value) => {
    if (value.data.amount && value.data.amount['CLP']) {
      return value.data.amount['CLP'].toLocaleString('es-CL', { currency: 'CLP', style: 'currency' })
    }
    return (0).toLocaleString('es-CL', { currency: 'CLP', style: 'currency' })
  }
  return {
    status: value.status,
    topic: value.topic,
    createdAt: moment(value.createdAt).tz('America/Santiago').format('YYYY-MM-DD'),
    amount: getAmount(value),
    comment: value.comment,
    id
  }
}))

const _outRequest = memoize((outgoingPaymentRequest) => compact(map(outgoingPaymentRequest, (value, recordName) => {
  const getAmount = (data) => {
    const amount = reduce(data, (result, value) => {
      result += value.payment.total['CLP']
      return result
    }, 0)

    return amount.toLocaleString('es-CL', { currency: 'CLP', style: 'currency' })
  }
  if (!value || value.status !== 200) return
  return {
    status: value.status,
    createdAt: moment(value.createdAt).tz('America/Santiago').format('YYYY-MM-DD'),
    amount: getAmount(value.data),
    accountingDate: value.data[0].payment.accountingDate,
    topic: value.data[0].payment.topic || '',
    comment: value.data[0].payment.comment || '',
    recordName
  }
})))

const _in = memoize((incomingPaymentOrder) => map(incomingPaymentOrder, (data, id) => ({ ...data, id })))

const mapStateToProps = (state, ownProps) => {
  return {
    outgoingPaymentOrder: _out(state.payments.outgoingPaymentOrder),
    incomingPaymentOrder: _in(state.payments.incomingPaymentOrder),
    outgoingPaymentRequest: _outRequest(state.payments.outgoingPaymentRequest),
    columns: [
      { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
      { name: 'status' },
      { name: 'createdAt', label: 'creado' },
      { name: 'amount', label: 'monto' },
      { name: 'topic', label: 'topico' },
      { name: 'comment', label: 'comentario', options: { filter: false } }
    ],
    columns2: [
      { name: 'recordName', options: { viewColumns: false, display: 'excluded', filter: false } },
      { name: 'status' },
      { name: 'createdAt', label: 'creado' },
      { name: 'amount', label: 'monto' },
      { name: 'accountingDate', label: 'fechaContable' },
      { name: 'topic', label: 'topico' },
      { name: 'comment', label: 'comentario', options: { filter: false } }
    ],
    outgoingPaymentRequests: state.payments.outgoingPaymentRequest
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePaymentOrder,
  getPaymentOrderRecord,
  subscribeToOutgoingPaymentOrderList,
  unSubscribeToOutgoingPaymentOrderList,
  subscribeToOutgoingPaymentRequestList,
  unSubscribeToOutgoingPaymentRequestList,
  createPaymentOrder,
  removePaymentRequest
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTable)
