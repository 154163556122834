import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import isFunction from 'lodash/isFunction'

const ITEM_HEIGHT = 48

function MainMenu (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose (action) {
    setAnchorEl(null)
    if (isFunction(action)) action()
  }

  return (
    <div>
      <IconButton
        aria-label='More'
        aria-owns={open ? 'long-menu' : undefined}
        aria-haspopup='true'
        color={props.color}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {props.options.map((option, idx) => (
          <MenuItem key={idx} onClick={() => { handleClose(option.action) }}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

MainMenu.propTypes = {
  options: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired
}

export default MainMenu
