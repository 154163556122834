import main from './app/Main/redux/mainReducer.js'
import auth from './app/Auth/redux/authReducer.js'
import expenses from './app/Expenses/redux/expensesReducer.js'
import employees from './app/Employees/redux/employeesReducer.js'
import harvest from './app/Harvest/redux/harvestReducer.js'
import farms from './app/Farms/redux/farmsReducer.js'
import payments from './app/Payments/redux/paymentsReducer.js'
import attendance from './app/ActivityLog/Attendance/redux/attendanceReducer'
import contract from './app/Contract/redux/contractReducer'
import siteLog from './app/ActivityLog/SiteLog/redux/siteLogReducer'

const reducers = {
  main,
  auth,
  expenses,
  employees,
  harvest,
  farms,
  payments,
  attendance,
  contract,
  siteLog
}

export default reducers
