import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import memoize from 'memoize-one'
// import moment from 'moment-timezone'

import map from 'lodash/map'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
// import orderBy from 'lodash/orderBy'
// import last from 'lodash/last'
import debounce from 'lodash/debounce'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    margin: theme.spacing.unit / 2
  },
  button: {
    margin: theme.spacing.unit
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
})

const _farms = memoize((farms) => {
  if (farms.length > 1) {
    return concat(farms, [{ name: 'todos', id: 'todos' }])
  }
  return farms
})

// NOTE: disabled
// show 40 days ahead of selectedDay.from only
// const _daysTo = memoize((days, from) => {
//   const future = moment(from).add(40, 'd').format('YYYY-MM-DD')
//   return orderBy(filter(days, (day) => day >= from && day <= future), null, ['desc'])
// })

class DayFarmSelect extends React.Component {
  state = {
    mount: true,
    selectedFarm: { id: '', name: '' },
    selectedDay: { from: '', to: '' }
  }

  componentDidMount () {
    const farms = _farms(this.props.farms)
    // if only one farm set it
    if (farms.length === 1) {
      this.setState({ selectedFarm: farms[0] })
    }
    // if we are coming back from the harvest/view page, restore values in props as initial state, but dont call an update
    if (this.props.selectedFarm.id) {
      this.setState({ selectedFarm: this.props.selectedFarm, selectedDay: this.props.selectedDay })
    }
  }

  changeSelectedDay = (key, day) => {
    // reset date to when changing date from
    if (key === 'from') {
      this.setState({ selectedDay: { from: day, to: this.props.oneDayOnly ? day : '' }, mount: false })
    } else {
      debounce(() => this.setState({ selectedDay: { to: day, from: this.state.selectedDay.from }, mount: false }), 1000)()
    }
  }

  changeSelectedFarm = (farm) => {
    this.setState({ selectedFarm: farm, selectedDay: { to: '', from: '' }, mount: false })
  }

  componentDidUpdate (prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      // dont update if it is first mount
      if (this.state.selectedFarm.id && this.state.selectedDay.to && this.state.selectedDay.from && !this.state.mount) {
        this.props.nextLoading(true).then(() => {
          setTimeout(() => {
            this.props.setSelectedDayAndFarmForTable({ selectedFarm: this.state.selectedFarm, selectedDay: this.state.selectedDay })
          }, 10)
        })
      }
    }
    if (!isEqual(prevProps.selectedDay, this.props.selectedDay)) {
      // eslint-disable-next-line
      this.setState({ selectedDay: this.props.selectedDay })
    }

    if (!isEqual(prevProps.selectedFarm, this.props.selectedFarm)) {
      // eslint-disable-next-line
      this.setState({ selectedFarm: this.props.selectedFarm })
    }
  }

  render () {
    const { classes } = this.props
    const farms = _farms(this.props.farms)
    // const daysTo = _daysTo(this.props.days, this.state.selectedDay.from)

    return (
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl}>
          <InputLabel shrink >
            <Typography
              component='h6'
              variant='button'
              noWrap
            >
              Predio
            </Typography>
          </InputLabel>
          <Select
            value={filter(farms, (farm) => farm.id === this.state.selectedFarm.id)[0] || ''}
            onChange={(event) => this.changeSelectedFarm(event.target.value)}
            name='farm'
            className={classes.selectEmpty}
          >
            {map(farms, (farm) => <MenuItem key={farm.id} value={farm}>{farm.name}</MenuItem>)}
          </Select>
        </FormControl>

        {!this.state.selectedFarm.id ? <></>
          : <div>
            <FormControl className={classes.formControl}>
              <TextField
                id='dayFrom'
                name='dayFrom'
                label={this.props.oneDayOnly ? 'Dia' : 'Desde'}
                InputLabelProps={{
                  shrink: true
                }}
                type='date'
                value={this.state.selectedDay.from}
                // inputProps={{ min: last(this.props.days) }}
                onChange={(event) => this.changeSelectedDay('from', event.target.value)}
                className={[classes.textField, classes.selectEmpty].join(' ')}
              />
            </FormControl>

            {this.props.oneDayOnly ? <></>
              : <FormControl className={classes.formControl}>
                <TextField
                  id='dayTo'
                  name='dayTo'
                  label='Hasta'
                  InputLabelProps={{
                    shrink: true
                  }}
                  type='date'
                  value={this.state.selectedDay.to}
                  // inputProps={{ min: this.state.selectedDay.from, max: daysTo[0] }}
                  onChange={(event) => this.changeSelectedDay('to', event.target.value)}
                  className={[classes.textField, classes.selectEmpty].join(' ')}
                />
              </FormControl>
            }
          </div>
        }
      </form>
    )
  }
}

DayFarmSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  // days: PropTypes.array.isRequired,
  selectedDay: PropTypes.object.isRequired,
  setSelectedDayAndFarmForTable: PropTypes.func.isRequired,
  nextLoading: PropTypes.func.isRequired,
  farms: PropTypes.array.isRequired,
  selectedFarm: PropTypes.object,
  oneDayOnly: PropTypes.bool
}

export default withStyles(styles)(DayFarmSelect)
