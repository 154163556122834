import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import EmployeesViewComponent from './EmployeesView.js'
import { terminateContract, deletePaymentMethod, getContracts, uploadContractFiles, editPath } from './redux/employeesActions'
import { getFileDownloadUrl } from '../FileUtils/redux/fileUtilsActions'
import { buildContractHtml } from '../Contract/redux/contractActions'

const mapStateToProps = (state, ownProps) => {
  const { employeeId } = ownProps.location.state
  const employeeRecord = state.employees.employeeRecord[`employee/${employeeId}`] || {}
  const employeeAccounts = state.employees.employeesBankAccount[`employee/${employeeId}`] || []
  const employeeContract = state.employees.employeeContract[`employee/${employeeId}`] || {}
  const employeeContractType = (employeeContract.type && state.employees.contractTypeRecord[`contract/${employeeContract.type}`])
  const farmRecords = state.farms.farmRecord
  const userAccess = state.main.userData.access

  return { employeeId, employeeRecord, employeeContract, employeeContractType, farmRecords, userAccess, employeeAccounts }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  terminateContract,
  deletePaymentMethod,
  getContracts,
  getFileDownloadUrl,
  buildContractHtml,
  uploadContractFiles,
  editPath
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesViewComponent)
