import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import memoize from 'memoize-one'
import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import find from 'lodash/find'
import keys from 'lodash/keys'
import compact from 'lodash/compact'

/**
 * handleEmpty
 * @param  {Array} arr array to search
 * @param  {string} id  element id
 * @return {string}  element name or empty
 */
const handleEmpty = (arr, id) => {
  const data = find(arr, ['id', id])
  if (!data) {
    console.error('notFound', arr, id)
    return ''
  }
  return data.name
}

const _seasonStats = memoize((farms, sites, formats, qualities, harvestSeasonStats) =>
  compact(flatMap(harvestSeasonStats, (value, farmId) => {
    // do not consider updatedAt key
    if (farmId === 'updatedAt') return null
    const farmName = handleEmpty(farms, farmId)
    // return if there is no farm: ie no permission
    if (!farmName) return

    // value has {$siteId: {$cropId: {$quality: $data, employees: [$employeeIds]}}}
    return flatMap(value, (siteData, siteId) => {
      let site = find(sites, ['id', siteId])
      let siteName = (site && site.name) || ''
      // get data for given crop
      return compact(flatMap(siteData, (cropData, cropId) => {
        // employees is the key for the current employees in site, is the only key other than a cropId
        if (cropId === 'employees') return null
        return flatMap(cropData, (d, qualityId) => ({
          quality: handleEmpty(qualities, qualityId),
          format: handleEmpty(formats, `${keys(d.format)[0]}`),
          weight: map(d.weight, (v, u) => `${v} ${u}`),
          cropName: handleEmpty((site && site.crops) || [], cropId),
          siteName,
          farmName,
          employees: siteData.employees
        }))
      }))
    })
  })))

const HarvestFarmStatsCard = (props) => {
  const seasonStats = _seasonStats(props.farms, props.sites, props.formats, props.qualities, props.harvestSeasonStats)

  return (
    <MUIDataTable
      title={<Tooltip title='Hasta dia anterior de cosecha'><Button>Total temporada por cuartel</Button></Tooltip>}
      data={seasonStats}
      columns={[
        { name: 'farmName', label: 'predio' },
        { name: 'siteName', label: 'cuartel' },
        { name: 'cropName', label: 'variedad' },
        { name: 'quality', label: 'calidad' },
        { name: 'format', label: 'formato', options: { viewColumns: false, display: 'excluded', filter: false } },
        { name: 'weight', label: 'cantidad' },
        { name: 'employees', label: 'cosecheros' }
      ]}
      options={{
        responsive: 'scrollMaxHeight',
        expandableRows: false,
        selectableRows: 'none'
      }}
    />
  )
}

HarvestFarmStatsCard.propTypes = {
  sites: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  harvestSeasonStats: PropTypes.object.isRequired
}

export default HarvestFarmStatsCard
