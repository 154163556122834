import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import map from 'lodash/map'
import startCase from 'lodash/startCase'
import clone from 'lodash/clone'
import trim from 'lodash/trim'
import deburr from 'lodash/deburr'

import { translation, paymentMethods, chileBanks } from '../../config'
import { isChileRut } from '../../utils'

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit / 2
  },
  root: {
    display: 'flex'
    // flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    minWidth: 120
  }
})

const initialState = {
  paymentMethod: '',
  bankName: '',
  bankAccountNumber: '',
  bankAccountType: '',
  bankAccountHolderName: '',
  bankAccountHolderId: '',
  employeeAccounts: []
}

class EmployeePaymentRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    const { employeeId, employeeName, employeeLastName } = this.props
    this.props.getEmployeeBankAccount(employeeId, () => {
      this.setState({
        employeeAccounts: this.props.employeesBankAccount[`employee/${employeeId}`] || [],
        bankAccountHolderName: `${employeeName} ${employeeLastName}`,
        bankAccountHolderId: employeeId
      })
    })
  }

  onChange = (event) => {
    this.setState({ [event.target.name || event.target.id]: event.target.value })
  }

  paymentMethodPrepare = () => {
    const bankAccount = {}
    if (this.state.paymentMethod === 'cuentaRut') {
      bankAccount.bankName = 'bancoEstado'
      bankAccount.bankAccountNumber = this.props.employeeId.split('-')[0]
      bankAccount.bankAccountType = 'cuentaVista'
      bankAccount.bankAccountHolderName = trim(deburr(`${this.props.employeeName} ${this.props.employeeLastName}`))
      bankAccount.bankAccountHolderId = this.props.employeeId
    } else {
      bankAccount.bankName = this.state.bankName
      bankAccount.bankAccountNumber = this.state.bankAccountNumber
      bankAccount.bankAccountType = this.state.paymentMethod
      bankAccount.bankAccountHolderName = trim(deburr(this.state.bankAccountHolderName))
      bankAccount.bankAccountHolderId = this.state.bankAccountHolderId
    }

    return bankAccount
  }

  paymentMethodRegister = () => {
    const bankAccount = this.paymentMethodPrepare()

    this.props.createPaymentMethod({ employeeId: this.props.employeeId, bankAccount }, (err) => {
      if (err) {
        console.error(err)
        window.alert('Error al registrar cuenta')
        this.props.history.push(`/app/employees`)
        return
      }
      // if adding from view go back there
      if (this.props.goBack) {
        this.props.history.goBack()
        return
      }
      // NOTE: here we could register extras
      // this.props.history.push(`/app/employees/register/extras`, { employeeId: this.props.employeeId })

      // register contract
      this.props.history.push(`/app/employees/register/contract`, { employeeId: this.props.employeeId })
    })
  }

  paymentMethodEdit = () => {
    // NOTE: currently we can not edit, just delete accounts that are no longer set as default for the current contract. It works ok.
  }

  buttonDisabled = () => {
    if (this.state.paymentMethod) return false
    return true
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {this.props.loading
          ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <div>
            <Card>
              <CardHeader
                title={this.props.edit ? 'Editar datos medio de pago' : 'Registro medio de pago'}
              />
              <CardContent>
                <form className={classes.root} autoComplete='off'>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>
                        Seleccionar
                    </InputLabel>

                    <Select
                      value={this.state.paymentMethod || ''}
                      onChange={this.onChange}
                      name='paymentMethod'
                      autoWidth
                      error={!this.state.paymentMethod}
                    >
                      {map(paymentMethods, (d, idx) => <MenuItem key={idx} value={d}>{startCase(translation.es[d] || d)}</MenuItem>)}
                    </Select>
                  </FormControl>
                </form>
                {(this.state.paymentMethod && this.state.paymentMethod !== 'cuentaRut')
                  ? <BankAccountForm data={this.state} onChange={this.onChange} classes={classes} />
                  : <>
                    {this.state.paymentMethod && this.state.paymentMethod === 'cuentaRut'
                      ? <Typography style={{ marginLeft: 16 }} variant='body1'>Cuenta Rut propia</Typography> : null
                    }
                  </>
                }

              </CardContent>
              <CardActions>
                {this.props.edit
                  ? <Button size='small' color='primary' onClick={() => this.paymentMethodEdit()} disabled={this.buttonDisabled()}>
                      Guardar
                  </Button>
                  : <Button size='small' color='primary' onClick={() => this.paymentMethodRegister()} disabled={this.buttonDisabled()}>
                        Registrar
                  </Button>
                }
              </CardActions>
            </Card>

          </div>
        }
      </div>
    )
  }
}

const BankAccountForm = (props) => {
  return (
    <form className={props.classes.root} autoComplete='off'>
      <FormControl className={props.classes.formControl}>
        <InputLabel shrink>
            Banco
        </InputLabel>

        <Select
          id='bankName'
          name='bankName'
          label='Banco'
          value={props.data.bankName}
          onChange={props.onChange}
          autoWidth
          required
        >
          {map(chileBanks, (d, idx) => <MenuItem key={idx} value={d}>{startCase(d)}</MenuItem>)}
        </Select>
        <br />
        <br />

        <TextField
          id='bankAccountNumber'
          placeholder=''
          label='Numero de Cuenta'
          fullWidth
          type='string'
          onChange={props.onChange}
          className={props.classes.textField}
          value={props.data.bankAccountNumber}
          required
        />
        <br />
        <br />

        <TextField
          id='bankAccountHolderName'
          placeholder='Titular'
          label='Titular'
          fullWidth
          type='string'
          onChange={props.onChange}
          className={props.classes.textField}
          value={props.data.bankAccountHolderName}
          required
        />
        <TextField
          id='bankAccountHolderId'
          label='RUT'
          fullWidth
          type='string'
          onChange={props.onChange}
          className={props.classes.textField}
          value={props.data.bankAccountHolderId}
          required
          error={!isChileRut(props.data.bankAccountHolderId)}
        />

      </FormControl>
    </form>
  )
}

BankAccountForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

EmployeePaymentRegister.propTypes = {
  classes: PropTypes.object.isRequired,
  createPaymentMethod: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  employeeId: PropTypes.string,
  employeeName: PropTypes.string,
  employeeLastName: PropTypes.string,
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  goBack: PropTypes.bool.isRequired,
  employeesBankAccount: PropTypes.object.isRequired,
  getEmployeeBankAccount: PropTypes.func.isRequired
}

export default withStyles(styles)(EmployeePaymentRegister)
