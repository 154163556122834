import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import map from 'lodash/map'
import find from 'lodash/find'

import FileUploadComponent from '../FileUtils/FileUploadComponent'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    minWidth: 120
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const AnnotationComponent = (props) => {
  const topicLabel = (topicId) => {
    const selectedTopic = find(props.topicData, (d) => d.id === topicId)
    if (selectedTopic) return selectedTopic.topic
    return 'Seleccionar Tema'
  }

  const { classes } = props
  return (
    <Grid item xs={12}>
      <Card variant='outlined'>
        <CardHeader
          title='Anotacion'
        />
        <CardContent>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend'>Tema</FormLabel>
            <FormGroup>
              <Chip
                className={classes.chip}
                label={topicLabel(props.topicId)}
                variant='outlined'
                onClick={() => props.onChange({ openTopicDialog: true, idx: props.idx })}
              />
            </FormGroup>
          </FormControl>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend'>Archivos adjuntos</FormLabel>
            <FormGroup>
              {map(props.files, (file, idx) => {
                if (file.name) {
                  return <Chip
                    key={idx}
                    className={classes.chip}
                    label={file.name}
                    variant='outlined'
                    onClick={() => props.onChange({ viewFile: file, idx: props.idx })}
                    onDelete={() => props.onChange({ deleteFile: file, idx: props.idx })}
                  />
                }
              }
              )}
            </FormGroup>
          </FormControl>
          <FormControl component='fieldset' className={classes.formControl}>
            <FileUploadComponent onFileUpload={(err, data) => {
              if (!err) {
                if (!props.readOnly) props.onChange({ uploadedFile: data, idx: props.idx })
              }
            }} />
          </FormControl>
          <TextField
            id='commentText'
            label='Comentario'
            InputLabelProps={{
              shrink: true
            }}
            multiline
            value={props.text}
            onChange={(event) => { props.onChange({ text: event.target.value, idx: props.idx }) }}
            margin='normal'
            variant='outlined'
            fullWidth
            disabled={props.readOnly}
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Tooltip title='Eliminar esta anotacion'>
            <span>
              <IconButton onClick={() => props.onChange({ delete: props.idx })} disabled={props.readOnly}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>

      </Card>
    </Grid>
  )
}

AnnotationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  files: PropTypes.array,
  text: PropTypes.string,
  topicId: PropTypes.string,
  topicData: PropTypes.array.isRequired,
  idx: PropTypes.number,
  readOnly: PropTypes.bool
}

export default withStyles(styles)(AnnotationComponent)
