import memoize from 'memoize-one'
import slice from 'lodash/slice'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import uniq from 'lodash/uniq'
import flatMap from 'lodash/flatMap'
import orderBy from 'lodash/orderBy'
import trim from 'lodash/trim'
import replace from 'lodash/replace'
import compact from 'lodash/compact'
import split from 'lodash/split'
import drop from 'lodash/drop'
import join from 'lodash/join'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import startCase from 'lodash/startCase'

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// validate chilean rut
export const isChileRut = (string) => {
  // eslint-disable-next-line
  var rexp = new RegExp(/^([0-9])+\-([kK0-9])+$/)
  if (rexp.test(string)) {
    var RUT = string.split('-')
    var elRut = RUT[0]
    var factor = 2
    var suma = 0
    var dv
    for (var i = (elRut.length - 1); i >= 0; i--) {
      factor = factor > 7 ? 2 : factor
      suma += parseInt(elRut[i], 10) * parseInt(factor++, 10)
    }
    dv = 11 - (suma % 11)
    if (dv === 11) {
      dv = 0
    } else if (dv === 10) {
      dv = 'k'
    } if (dv.toString() === RUT[1].toLowerCase() && (elRut !== '11111111')) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

// MUIDataTable helpers
export const visibleRecords = memoize((state) => {
  const rowStart = state.page ? state.page * state.rowsPerPage : 0
  const rowEnd = ((state.page + 1) * state.rowsPerPage)
  return map(slice(state.displayData, rowStart, rowEnd), (value) => value.data[0].split('__:')[1])
})

// day selector formatter
export const daysSelector = memoize((harvestSitesDays, farms, selectedFarm) => {
  let sites = filter(farms, (f) => f.id === selectedFarm.id)
  if (sites[0]) sites = sites[0].sites
  else sites = []
  if (selectedFarm.id === 'todos') {
    sites = compact(flatMap(farms, (farm) => farm.sites))
  }

  sites = map(sites, (site) => site.split('/')[1])
  const days = map(filter(harvestSitesDays, (siteDay) => includes(sites, siteDay.split('/')[0])), (val) => val.split('/')[1])
  return orderBy(uniq(days), null, ['desc'])
})

export const isEmail = (email) => {
  return emailRegex.test(email)
}

export const formatChileRut = (value) => {
  let result = trim(replace(value, '.', ''))
  if (result.length > 1) {
    result = replace(result, '-', '')
    result = result.split('')
    result.splice(result.length - 1, 0, '-')
    result = result.join('')
    result = replace(result, '*', 'k')
  }
  return result
}

export const recordNameToId = (recordName) => join(drop(split(recordName, '/')), '/')

// TABLE COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
export const columnsFilter = memoize((columns, defaultColumns, location, dataLength) => {
  const orderedColumns = orderBy(columns, (o) => defaultColumns.indexOf(o.name))
  if (dataLength === 0) return orderedColumns

  // we filter based on query
  const query = getQuery(location)
  return reduce(orderedColumns, (result, column, idx) => {
    const value = query.get(column.name)
    column.options = { ...column.options, filterList: value ? [value] : [] }
    result.push(column)
    return result
  }, [])
})

const getQuery = (location) => {
  return new URLSearchParams(location.search)
}

export const getTipoContrato = (contractTypeRecord) => {
  let result = contractTypeRecord ? find(['plazoFijo', 'faena', 'indefinido'], (v) => v.indexOf(contractTypeRecord.type) === 0) : ''
  if (result === 'faena') result = contractTypeRecord.name
  return startCase(result)
}
