import React from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router'
import classNames from 'classnames'
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CachedIcon from '@material-ui/icons/Cached'
// import NotificationsIcon from '@material-ui/icons/Notifications'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import red from '@material-ui/core/colors/red'

import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import filter from 'lodash/filter'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

import memoize from 'memoize-one'

import styles from './Main.styles'
import routes from '../../routes'
import MainMenu from './MainMenu'
import CompanySelect from './CompanySelect'

import { version } from './../../../package.json'
import { env } from './../../config'

let theme = createMuiTheme()

if (env === 'staging') {
  theme = createMuiTheme({
    palette: {
      primary: red
    }
  })
}

class Main extends React.Component {
  state = {
    open: false
  };

  componentDidMount () {
    this.props.connectionHandler()
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  logout = () => {
    // check pending requests and alert if there are present
    const pending = this._pendingCreateRequests(this.props.mainState.pendingRequests)
    if (pending) {
      const ok = window.confirm('Tienes registros pendientes por sincronizar. Deseas cerrar sesión de todas formas? Se podría perder información.')
      if (ok) {
        this.props.logout()
        this.props.history.push('/app')
      }
    } else {
      this.props.logout()
      this.props.history.push('/app')
    }
  }

  checkUpload = () => {
    const { connectionState } = this.props.mainState
    if (connectionState === 'OPEN' && navigator.onLine) {
      this.props.sendPendingRequests()
    }
  }

  _pendingCreateRequests = memoize((pending) => filter(pending, (req) => req.rpcName.indexOf('create') > -1).length)

  // Here we filter routes by user access
  // if userAccess < route access, route will be reachable
  _routes = memoize((routes, userAccess) => reduce(routes, (result, route) => {
    if (route.access && userAccess < route.access) {
      let subRoutes = []
      if (route.subRoutes) {
        subRoutes = filter(route.subRoutes, (subRoute) => subRoute.access && userAccess < subRoute.access)
      }
      result.push({ ...route, subRoutes })
    }
    return result
  }, []))

  // map path only from routes in order to send user back when an invalid path is reached
  _paths = memoize((routes) => flatMap(routes, (route) => {
    if (route.subRoutes) {
      const sub = map(route.subRoutes, (r) => r.path)
      sub.push(route.path)
      return sub
    }
    return route.path
  }))

  render () {
    const { classes } = this.props
    const pendingBadgeNumber = this._pendingCreateRequests(this.props.mainState.pendingRequests)
    const _routes = this._routes(routes, this.props.mainState.userData.access)
    const paths = this._paths(_routes)
    // Block access to forbidden paths
    if (!isEmpty(paths) && this.props.mainState && this.props.mainState.userData && (this.props.mainState.userData.access || this.props.mainState.userData.access === 0) && !includes(paths, this.props.location.pathname)) {
      window.alert('No tienes acceso a esta pagina')
      this.props.history.goBack()
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <AppBar
            position='absolute'
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <CompanySelect activeCompany={this.props.activeCompany} companySelectorData={this.props.mainState.companySelectorData} changeActiveCompany={this.props.changeActiveCompany} />
              <div className={classes.grow} />
              {
                pendingBadgeNumber && !this.props.mainState.sendingPendingRequestsActive
                  ? <IconButton color='inherit' onClick={this.checkUpload}>
                    <Badge badgeContent={pendingBadgeNumber} color='secondary'>
                      <CachedIcon />
                    </Badge>
                  </IconButton> : <></>
              }
              {
                pendingBadgeNumber && this.props.mainState.sendingPendingRequestsActive
                  ? <CircularProgress className={classes.progress} color='secondary' /> : <></>
              }
              {/* {
                TODO
                this.props.mainState.failedRequests.length
                  ? <IconButton color='inherit'>
                    <Badge badgeContent={this.props.mainState.failedRequests.length} color='secondary'>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> : <></>
              } */}
              <MainMenu color='inherit' options={[{ name: 'Salir', action: this.logout }]} />
            </Toolbar>
          </AppBar>

        </MuiThemeProvider>
        <Drawer
          variant={isWidthUp('sm', this.props.width) ? 'permanent' : 'temporary'}
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose)
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />

          <List>{
            _routes.map((route, idx) =>
              <Link to={route.path} key={idx} onClick={this.handleDrawerClose}>
                <ListItem button>
                  <ListItemIcon>
                    <Tooltip title={route.name}>
                      <route.icon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItem>
              </Link>
            )
          }</List>

          {/* Here we could add a secondary list
            <Divider />
            <List>{secondaryListItems}</List> */}
          <Typography variant='body1' gutterBottom className={classes.userName}>
            {this.props.mainState.userData.name}
            <br />
            {version}
          </Typography>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          {/* here routes are instantiated */
            this.props.mainState.loading ? <CircularProgress className={classes.loader} color='primary' size={80} thickness={4} />
              : flatMap(_routes, (route, idx) => {
                let result = [<Route key={idx} exact path={route.path} component={route.component} />]
                if (route.subRoutes) {
                  let sub = route.subRoutes.map((subRoute, subIdx) => <Route key={idx + '-' + subIdx} exact path={subRoute.path} component={subRoute.component} />)
                  result = result.concat(sub)
                }
                return result
              })
          }
        </main>
      </div>
    )
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  connectionHandler: PropTypes.func.isRequired,
  sendPendingRequests: PropTypes.func.isRequired,
  changeActiveCompany: PropTypes.func.isRequired,
  mainState: PropTypes.object.isRequired,
  activeCompany: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(Main)))
