import React from 'react'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'

const TableIcons = (value) => {
  const done = (value && value === 'ok')
  if (done) return (<DoneIcon color='primary' />)
  return (<CloseIcon color='secondary' />)
}

export default TableIcons
