import each from 'async/each'
import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import startsWith from 'lodash/startsWith'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import { batch } from 'react-redux'

import { appConfig } from '../../../config'

// keep track of subscribed records and list
const records = new Map()
const lists = new Map()

// keep track of subscription functions
const subscriptionFunctions = new Map()

/**
 * dispatchInterval
 * function to be called at intervals in order to make batch dispatches that will result in a single render update
 */

let dispatchBatchArray = []
let dispatchIntervalTimer = null

export const dispatchBatchInterval = () => (dispatch) => {
  dispatchIntervalTimer = setInterval(() => {
    if (isEmpty(dispatchBatchArray)) return
    // apply bath update
    batch(() => {
      map(dispatchBatchArray, (action) => dispatch(action))
      dispatchBatchArray = []
    })
  }, appConfig.dispatchBatchInterval)
}

/*
  Record and list subscribe utilities
  action is the helper funtion from redux-starter-kit to be called, we pass it as param in order to call any state reducer.
  recordAction is an actionCreator, if set, all records from list will be set in the actionCreator object as {$recordname: $recordData}
  nameAsStateKey tells if we use the record name as key on the object state
 */

/**
 * [recordSubscribe description]
 * @param  {[type]}  recordName       [description]
 * @param  {[type]}  action           [description]
 * @param  {Boolean} [nameAsStateKey=false] [description]
 * @param  {[type]}  [path=null]      [description]
 * @param  {[type]}  [cb=null]        [description]
 * @return {[type]}                   [description]
 */
export const recordSubscribe = (recordName, action, nameAsStateKey = false, path, cb = noop) => async (dispatch, getState, dsClient) => {
  // nameAsStateKey path and callback are optional
  // we can pass callback in position 3
  if (isFunction(nameAsStateKey)) {
    cb = nameAsStateKey
    nameAsStateKey = false
    path = undefined
  }
  // we can pass callback in position 4
  if (isFunction(path)) {
    cb = path
    path = undefined
  }

  // do we already have the record?
  let record = records.get(recordName)

  if (!record) {
    // check we are connected
    if (!navigator.onLine) {
      return cb(202)
    }

    try {
      const recordExists = await dsClient().record.has(recordName)
      if (!recordExists) return cb(404)
      // record exists
      record = dsClient().record.getRecord(recordName)
      // keep record for future usage
      records.set(recordName, record)
    } catch (error) {
      return cb(error)
    }
  }

  // avoid re-subscription
  if (subscriptionFunctions.has(`${recordName}-${path}-${getActionName(action)}`)) {
    console.log('already subscribed to ', `${recordName}-${path}-${getActionName(action)}`)
    return cb(null, record)
  }

  // subscription function
  subscriptionFunctions.set(`${recordName}-${path}-${getActionName(action)}`, (data) => {
    // dispatch
    if (nameAsStateKey) {
      dispatchBatchArray.push(action({ recordName, data }))
    } else {
      dispatchBatchArray.push(action(data))
    }
    // called only the first time to acknowledge that the subscription was made
    // subsequent calls will only modify state
    if (cb) cb(null, record)
    cb = null
  })

  // subscribe
  record.subscribe(path, subscriptionFunctions.get(`${recordName}-${path}-${getActionName(action)}`), true)
}

/**
 * [discardRecord description]
 * @param  {[type]}  recordName                  [description]
 * @param  {Boolean} [discardAllInstances=false] [description]
 * @return {[type]}                              [description]
 */
export const discardRecord = (recordName) => (dispatch, getState, dsClient) => {
  const record = records.get(recordName)
  if (record) {
    record.discard()
    // delete record from reference
    records.delete(recordName)
    // remove functions
    subscriptionFunctions.forEach((value, key) => {
      if (startsWith(key, recordName)) {
        subscriptionFunctions.delete(key)
      }
    })
  }
}

/**
 * [unsubscribe]
 * @param  {string}  recordName
 * @param  {string}  path
 * @param  {function} actionCreator
 * @return {void}
 */
export const unsubscribeRecord = (recordName, path, action = '') => () => {
  const record = records.get(recordName)
  if (record) {
    if (action) {
      record.unsubscribe(path, `${recordName}-${path}-${getActionName(action)}`)
      // remove function reference
      subscriptionFunctions.delete(`${recordName}-${path}-${getActionName(action)}`)
    } else {
      record.unsubscribe(path)
      // remove functions reference
      subscriptionFunctions.forEach((value, key) => {
        if (startsWith(key, `${recordName}-${path}`)) {
          subscriptionFunctions.delete(key)
        }
      })
    }
  }
}

/**
 * [listSubscribe: if recordAction is given it automatically removes the record from state if removed from list after adding list name inside function section]
 * @param  {[type]}  listName             [description]
 * @param  {[type]}  action               [description]
 * @param  {Boolean} [recordAction=false] [description]
 * @return {[type]}                       [description]
 */
export const listSubscribe = (listName, action, recordAction = false, cb = noop, actionCallback = noop) => async (dispatch, getState, dsClient) => {
  // do we already have the list?
  let list = lists.get(listName)

  if (!list) {
    // check we are connected
    if (!navigator.onLine) {
      return cb(202)
    }
    try {
      // list exists?
      const listExists = await dsClient().record.has(listName)
      if (!listExists) {
        if (cb) cb(404)
        if (actionCallback) actionCallback(404)
        return
      }

      // list exists
      list = dsClient().record.getList(listName)
      // keep list for future usage
      lists.set(listName, list)
    } catch (error) {
      if (cb) cb(error)
      if (actionCallback) actionCallback(error)
      return
    }
  }

  // are we subscribed?
  if (subscriptionFunctions.has(`${listName}-${getActionName(action)}`)) {
    console.log('already subscribed to ', `${listName}-${getActionName(action)}`)
    cb()
    actionCallback()
    return
  }

  // subscription function
  subscriptionFunctions.set(`${listName}-${getActionName(action)}`, (entries) => {
    // dispatch list action
    dispatch(action({ listName, data: entries }, () => {
    // call actionCallback only once
      if (actionCallback) actionCallback()
      actionCallback = null
    }))
  })
  // subscribe to list
  list.subscribe(subscriptionFunctions.get(`${listName}-${getActionName(action)}`), true)

  // get list current data
  // TODO: this promise might never resolve and thus the code gets stuck here
  // implement a timeout to get a hold of these issues
  await list.whenReady()
  const entries = list.getEntries()

  // here we subscribe to all valid entries
  if (recordAction) {
    each(entries, (recordName, callback) => {
      // subscribe to entry
      dispatch(recordSubscribe(recordName, recordAction, true, () => {
        callback()
      }))
    }, () => {
      if (cb) cb()
      cb = null
    })
  } else {
    // no record action needed
    if (cb) cb()
    cb = null
  }

  // set up listeners
  list.on('entry-added', (entry, index) => {
    if (recordAction) dispatch(recordSubscribe(entry, recordAction, true))
  })

  list.on('entry-removed', (entry, index) => {
    if (recordAction) {
      dispatch(discardRecord(entry))
      dispatchBatchArray.push(recordAction({ entry, remove: true }))
    }
  })
}

/**
 * [discardList description]
 * @param  {[type]}  recordName
 * @param  {function} actionCreator
 * @return {[type]}
 */
export const discardList = (listName, withRecords) => () => {
  const list = lists.get(listName)
  if (list) {
    // Discard all records from list
    if (withRecords) {
      const recordsToDiscard = list.getEntries()
      forEach(recordsToDiscard, (recordName) => dispatchBatchArray.push(discardRecord(recordName)))
    }

    list.discard()
    // delete list from reference
    lists.delete(listName)
    // remove functions
    subscriptionFunctions.forEach((value, key) => {
      if (startsWith(key, listName)) {
        subscriptionFunctions.delete(key)
      }
    })
  }
}

/**
 * [unsubscribeList]
 * @param  {string}  recordName
 * @param  {string}  path
 * @param  {function} actionCreator
 * @return {void}
 */
export const unsubscribeList = (listName, action = '', withRecords) => () => {
  const list = lists.get(listName)
  if (list) {
    // unsubscribe all records from list
    // NOTE: this will unsubscribe all paths and functions from records
    if (withRecords) {
      const recordsToUnsubscribe = list.getEntries()
      forEach(recordsToUnsubscribe, (recordName) => dispatchBatchArray.push(unsubscribeRecord(recordName)))
    }

    if (action) {
      list.unsubscribe(subscriptionFunctions.get(`${listName}-${getActionName(action)}`))
      // remove function reference
      subscriptionFunctions.delete(`${listName}-${getActionName(action)}`)
    } else {
      list.unsubscribe()
      // remove functions reference
      subscriptionFunctions.forEach((value, key) => {
        if (startsWith(key, listName)) {
          subscriptionFunctions.delete(key)
        }
      })
    }
  }
}

export const recordSnapshot = (recordName, action, cb = noop) => (dispatch, getState, dsClient) => {
  if (!navigator.onLine) {
    return cb(202)
  }
  dsClient().record.snapshot(recordName, (err, data) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    if (action) {
      dispatch(action({ recordName, data }))
    }
    cb(null, data)
  })
}

export const recordVersion = (recordName, cb = noop) => (dispatch, getState, dsClient) => {
  if (!navigator.onLine) {
    return cb(202)
  }
  dsClient().record.head(recordName, (err, data) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    cb(null, data)
  })
}

// reset local memory when logging out
export const resetLocalVariables = () => () => {
  records.clear()
  lists.clear()
  subscriptionFunctions.clear()
  dispatchBatchArray = []
  if (dispatchIntervalTimer) clearInterval(dispatchIntervalTimer)
}

const getActionName = (action) => {
  let toString = action.toString()
  if (toString.indexOf('function') === 0) {
    toString = toString.split(' ')[1]
  }
  return toString
}
