import React from 'react'
import PropTypes from 'prop-types'
import QrReader from 'react-qr-reader'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'

import moment from 'moment-timezone'

import toNumber from 'lodash/toNumber'
import now from 'lodash/now'
import filter from 'lodash/filter'
import startCase from 'lodash/startCase'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import clone from 'lodash/clone'

import HarvestInputForm from './HarvestInputForm'
import HarvestDefaults from './HarvestDefaults'

import { isChileRut } from '../../utils'

const audio = new Audio('/coin.mp3')

const initialState = {
  harvestEmployeeId: '',
  errorMessage: '',
  format: null,
  quantity: null,
  quality: null,
  site: null,
  crop: null,
  editMode: false,
  harvestRecordName: null,
  pending: null,
  date: null
}

const totalDialogInitialState = {
  open: false,
  total: [],
  employee: null
}

const harvestRecordCreateDate = {}

class HarvestInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = initialState
    this.state.totalDialog = totalDialogInitialState
    this.state.hasCamera = false
  }

  componentDidMount () {
    const locationState = this.props.location.state
    this.setState({ totalDialog: clone(totalDialogInitialState) })
    // check if browser has camera
    this.detectWebcam((ok) => {
      this.setState({ hasCamera: ok })
    })
    if (this.props.location.pathname.indexOf('edit') > 1) {
      this.setState({
        editMode: true,
        harvestEmployeeId: locationState.harvestRecord.employee,
        harvestRecordName: locationState.harvestRecordName,
        crop: locationState.harvestRecord.crop,
        format: locationState.harvestRecord.format.id,
        quantity: locationState.harvestRecord.format.quantity,
        quality: locationState.harvestRecord.quality,
        site: locationState.harvestRecord.site,
        pending: locationState.harvestRecord.pending || false,
        date: locationState.harvestRecord.date
      })
    }
  }

  detectWebcam = (callback) => {
    let md = navigator.mediaDevices
    if (!md || !md.enumerateDevices) return callback(false)
    md.enumerateDevices().then(devices => {
      callback(devices.some(device => device.kind === 'videoinput'))
    })
  }

  handleScan = data => {
    if (data) {
      if (!isChileRut(data)) {
        // is it another rut format?
        if (data.indexOf('https://portal.sidiv.registrocivil.cl') === 0) {
          let rut = data.split('?')[1].split('&')[0].split('=')[1]
          if (isChileRut(rut)) {
            this.setState({ harvestEmployeeId: rut, errorMessage: 'ok' })
            this.props.harvestInputQrOpen(false)
          } else {
            this.setState({ errorMessage: 'Codigo incorrecto' })
          }
        }
      } else {
        this.setState({ harvestEmployeeId: data, errorMessage: '' })
        this.props.harvestInputQrOpen(false)
      }
    }
  }

  handleClose = () => {
    this.props.harvestInputQrOpen(false)
    this.setState({ errorMessage: '' })
  }

  handleError = err => {
    this.setState({ errorMessage: err.message || err })
  }

  closeTotalDialog = () => {
    this.setState({ ...initialState, totalDialog: clone(totalDialogInitialState) }, () => {
      this.props.harvestInputQrOpen(false)
    })
  }

  createHarvestRecord = (newRecord, cb) => {
    const { state, defaults } = newRecord

    const harvestRecord = {
      site: (state.site && state.site.id) || defaults.defaultSite.id,
      crop: (state.crop && state.crop.id) || defaults.defaultSiteCrop.id,
      employee: state.employee || defaults.defaultEmployee,
      row: toNumber(state.row) || null,
      destination: state.destination || null,
      quality: (state.quality && state.quality.id) || defaults.defaultQuality.id,
      format: {
        id: (state.format && state.format.id) || defaults.defaultFormat.id,
        quantity: toNumber(state.quantity) || toNumber(defaults.defaultQuantity)
      },
      weight: {
        unit: (state.format && state.format.capacity.unit) || defaults.defaultFormat.capacity.unit,
        value: toNumber(state.format && state.format.capacity.value) || toNumber(defaults.defaultFormat.capacity.value)
      },
      date: state.date || this.state.date || now()
    }

    if (this.state.editMode) {
      // pending recrods are directly updated on state
      if (this.state.pending) {
        // modifiy pending requests first
        let recordUid = this.state.harvestRecordName.split('/')

        this.props.modifyPendingHarvestRecord(`${recordUid[3]}/${recordUid[4]}`, harvestRecord, () => {
          this.props.getPendingRecords(() => {
            this.props.history.goBack()
          })
        })
      } else {
        this.props.modifyHarvestRecord(this.state.harvestRecordName, harvestRecord, (err) => {
          if (err) {
            // do not edit non pending records without connection
            window.alert(startCase(err))
            this.props.history.goBack()
          } else {
            this.props.history.goBack()
          }
        })
      }
    } else {
      // do not create 2 records in less than 1 minute
      if (harvestRecordCreateDate[harvestRecord.employee] && ((now() - harvestRecordCreateDate[harvestRecord.employee]) < 1 * 60 * 1000)) {
        window.alert('Debe pasar 1 minuto entre registros de un mismo trabajador')
        return cb()
      }

      harvestRecordCreateDate[harvestRecord.employee] = now()

      this.props.createHarvestRecord(harvestRecord, (err, recordName) => {
        if (err) {
          window.alert('error')
          return cb()
        }
        // play sound
        audio.play()
        this.setState(initialState, () => {
          this.props.getPendingRecords(() => {
            // modal with day totoal for worker
            const recordDate = moment(harvestRecord.date).tz('America/Santiago').format('YYYY-MM-DD')
            const employeeHarvestRecordsForDay = filter(this.props.harvestRecords, (value) => value.employee === harvestRecord.employee && moment(value.date).tz('America/Santiago').format('YYYY-MM-DD') === recordDate)

            let formatTotal = reduce(employeeHarvestRecordsForDay, (total, record) => {
              if (total[record.format.id]) total[record.format.id] += record.format.quantity
              else total[record.format.id] = record.format.quantity
              return total
            }, {})

            // add current record data if we are not connected to internet
            if (this.props.connectionState !== 'OPEN' || !navigator.onLine) {
              if (formatTotal[harvestRecord.format.id]) formatTotal[harvestRecord.format.id] += harvestRecord.format.quantity
              else formatTotal[harvestRecord.format.id] = harvestRecord.format.quantity
            }
            // format with name
            formatTotal = map(formatTotal, (value, format) => ({ name: filter(this.props.formats, (f) => f.id === format)[0].name, value }))
            this.setState({ totalDialog: { open: true, total: formatTotal, employee: harvestRecord.employee } }, () => {
              return cb()
            })
          })
        })
      })
    }
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.props.open && this.state.hasCamera}
          fullScreen
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Escanear codigo</DialogTitle>
          <DialogContent>
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
            {this.state.errorMessage
              ? <Typography variant='h5' gutterBottom component='h2'>
                {this.state.errorMessage}
              </Typography> : <></>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
            Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.totalDialog.open}
          fullScreen
          onClose={() => this.closeTotalDialog()}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Totales del día</DialogTitle>
          <DialogContent>
            <Typography variant='h4' gutterBottom component='h2'>
              RUT: {this.state.totalDialog.employee}
            </Typography>
            {map(this.state.totalDialog.total, (total, key) =>
              <Typography variant='h5' gutterBottom component='h2' key={key}>
                {startCase(total.name)} : {total.value}
              </Typography>
            )}
            <br />
            <br />
            <br />
            <Button variant='contained' size='large' onClick={() => this.closeTotalDialog()} color='primary'>
            Cerrar
            </Button>
          </DialogContent>
        </Dialog>
        {
          this.state.editMode ? <></> : <HarvestDefaults formats={this.props.formats} qualities={this.props.qualities} sites={this.props.sites} harvestDefaults={this.props.harvestDefaults} setHarvestDefaults={this.props.setHarvestDefaults} />
        }

        <br />
        <br />

        <HarvestInputForm
          employeeId={this.state.harvestEmployeeId}
          formats={this.props.formats}
          qualities={this.props.qualities}
          sites={this.props.sites}
          defaultQuantity={this.state.quantity || this.props.defaultQuantity}
          defaultFormat={this.state.format || this.props.defaultFormat}
          defaultQuality={this.state.quality || this.props.defaultQuality}
          defaultSite={this.state.site || this.props.defaultSite}
          defaultSiteCrop={this.state.crop || this.props.defaultSiteCrop}
          createHarvestRecord={this.createHarvestRecord}
          editMode={this.state.editMode}
          openCamera={() => this.props.harvestInputQrOpen(true)}
          employeeIds={this.props.employeeIds}
          userAccess={this.props.userAccess}
        />

      </div>

    )
  }
}

HarvestInput.propTypes = {
  open: PropTypes.bool.isRequired,
  harvestInputQrOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  createHarvestRecord: PropTypes.func.isRequired,
  setHarvestDefaults: PropTypes.func.isRequired,
  modifyPendingHarvestRecord: PropTypes.func.isRequired,
  modifyHarvestRecord: PropTypes.func.isRequired,
  getPendingRecords: PropTypes.func.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  employeeIds: PropTypes.array.isRequired,
  defaultFormat: PropTypes.string,
  defaultSite: PropTypes.string,
  defaultSiteCrop: PropTypes.string,
  defaultQuantity: PropTypes.number,
  defaultQuality: PropTypes.string,
  harvestRecords: PropTypes.object.isRequired,
  userAccess: PropTypes.number.isRequired,
  connectionState: PropTypes.string.isRequired,
  harvestDefaults: PropTypes.object.isRequired
}

export default HarvestInput
