import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'typeface-roboto'
import { PersistGate } from 'redux-persist/integration/react'
import isEmpty from 'lodash/isEmpty'

import App from './app/App'
import * as serviceWorker from './serviceWorker'
import { store, persistor } from './store'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Route path='/' component={App} />
      </Router>
    </PersistGate>
  </Provider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

const serviceWorkerOnUpdate = () => {
  const state = store.getState()
  // we clear store after user accepts and only if there are no pending requests
  if (isEmpty(state.main.pendingRequests)) {
    const ok = window.confirm('Hay una nueva version disponible. Favor cierra esta ventana y abre nuevamente para actualizar')
    if (ok) {
      persistor.purge()
    }
  }
}

serviceWorker.register({ onUpdate: serviceWorkerOnUpdate })
