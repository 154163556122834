import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import memoize from 'memoize-one'
import moment from 'moment-timezone'

import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import startCase from 'lodash/startCase'
import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import forEach from 'lodash/forEach'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'
import deburr from 'lodash/deburr'

import HarvestTable from './HarvestSummaryTable.js'
import { makeXlsxFile } from '../FileUtils/redux/fileUtilsActions.js'
import { createHarvestPayment, setSelectedDayAndFarmForTable, getHarvestData, nextLoading, subscribeValidatedDays, unsubscribeValidatedDays } from './redux/harvestActions'

import { daysSelector } from '../../utils'

let columns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'farm', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'pending', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'payment', options: { viewColumns: false, display: 'excluded', filter: false } }
]
const defaultColumns = ['rut', 'nombre', 'apellido', 'contrato', 'declarado']

for (let key of defaultColumns) {
  if (!includes(map(columns, (c) => c.name), key)) {
    columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
  }
}

const _data = memoize((harvestRecords, companyId, sites, formats, farms, qualities, selectedDay, selectedFarm) => reduce(harvestRecords, (result, harvestRecord, id) => {
  if (harvestRecord.deleted || id.indexOf(companyId) < 0) {
    return result
  }

  // get data based in ids for printing in table
  let site = filter(sites, site => site.id === harvestRecord.site)
  site = isEmpty(site) ? 'n/d' : site[0].name
  let format = filter(formats, (format) => format.id === harvestRecord.format.id)
  format = isEmpty(format) ? 'n/d' : format[0].name
  let quality = filter(qualities, (q) => q.id === harvestRecord.quality)
  quality = isEmpty(quality) ? 'n/d' : quality[0].name
  let farm = filter(farms, (farm) => includes(farm.sites, `site/${harvestRecord.site}`))[0]

  // include if selected day is in range
  const recordDay = moment(harvestRecord.date).tz('America/Santiago').format('YYYY-MM-DD')
  if (!(recordDay >= selectedDay.from && recordDay <= selectedDay.to)) {
    return result
  }

  // include if selected farm is todos or the record farm
  if (selectedFarm && selectedFarm.id && selectedFarm.id !== 'todos' && farm && selectedFarm.id !== farm.id) {
    return result
  }

  const employeeData = result[harvestRecord.employee] || {}

  const harvestRecords = employeeData['harvestRecords'] || { pending: [], inPayment: [], nonPayment: [], payed: [] }

  const relevantHarvestRecord = {
    fecha: moment(harvestRecord.date).tz('America/Santiago').format('DD-MMM h:mm:ss a'),
    cuartel: site,
    predio: farm ? farm.name : 'n/d',
    formato: startCase(format),
    calidad: startCase(quality),
    cantidad: harvestRecord.format.quantity,
    farm: farm ? farm.id : '',
    payment: harvestRecord.payment || false,
    pending: harvestRecord.pending || false,
    id
  }

  const summary = employeeData['summary'] || {}
  const inPaymentSummary = employeeData['inPaymentSummary'] || {}
  const payedSummary = employeeData['payedSummary'] || {}

  // we include data in summary only if it is not a pending record
  if (harvestRecord.pending) {
    harvestRecords.pending.push(relevantHarvestRecord)
    result[harvestRecord.employee] = {
      harvestRecords,
      summary,
      inPaymentSummary,
      payedSummary
    }
    return result
  }
  if (harvestRecord.payment && harvestRecord.payment.status !== 400) {
    if (harvestRecord.payment.status === 100) {
      harvestRecords.inPayment.push(relevantHarvestRecord)
      // handle in payment records
      if (isEmpty(inPaymentSummary)) {
        inPaymentSummary[`${harvestRecord.format.id}:${harvestRecord.quality}`] = harvestRecord.format.quantity
      } else {
        const value = inPaymentSummary[`${harvestRecord.format.id}:${harvestRecord.quality}`] || 0
        inPaymentSummary[`${harvestRecord.format.id}:${harvestRecord.quality}`] = value + harvestRecord.format.quantity
      }

      result[harvestRecord.employee] = {
        harvestRecords,
        summary,
        inPaymentSummary,
        payedSummary
      }
      return result
    }
    if (harvestRecord.payment.status === 200) {
      harvestRecords.payed.push(relevantHarvestRecord)
      // handle in payment records
      if (isEmpty(payedSummary)) {
        payedSummary[`${harvestRecord.format.id}:${harvestRecord.quality}`] = harvestRecord.format.quantity
      } else {
        const value = payedSummary[`${harvestRecord.format.id}:${harvestRecord.quality}`] || 0
        payedSummary[`${harvestRecord.format.id}:${harvestRecord.quality}`] = value + harvestRecord.format.quantity
      }

      result[harvestRecord.employee] = {
        harvestRecords,
        summary,
        inPaymentSummary,
        payedSummary
      }
      return result
    }
    // TODO: handle other payment status ?
    return result
  } else {
    // records in database and not in payment process
    harvestRecords.nonPayment.push(relevantHarvestRecord)

    if (isEmpty(summary)) {
      summary[`${harvestRecord.format.id}:${harvestRecord.quality}`] = harvestRecord.format.quantity
      // TODO: calculate total to be payed
      // summary.total = 0
    } else {
      const value = summary[`${harvestRecord.format.id}:${harvestRecord.quality}`] || 0
      summary[`${harvestRecord.format.id}:${harvestRecord.quality}`] = value + harvestRecord.format.quantity
      // TODO: calculate total to be payed
      // get labour value for harvesting, multiply and sum
    }

    result[harvestRecord.employee] = {
      harvestRecords,
      summary,
      inPaymentSummary,
      payedSummary
    }
    return result
  }
}, {}))

const _columns = memoize((columns) => orderBy(columns, (o) => {
  let idx = defaultColumns.indexOf(o.name)
  if (idx < 0) {
    idx = columns.length
  }
  return idx
}))

const _employeesList = memoize((employeesMemberships) => map(employeesMemberships, (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')))

const _employeesData = memoize((employeesList, employeeRecord, employeeContracts, contractTypes) => reduce(employeesList, (result, employeeRecordName) => {
  let employee = employeeRecord[employeeRecordName]
  const employeeContract = employeeContracts[employeeRecordName]
  const employeeContractType = contractTypes[`contract/${employeeContract.type}`]

  let relevant = {
    nombre: employee ? deburr(employee.name) : '',
    apellido: employee ? deburr(employee.lastName) : '',
    contrato: employeeContractType ? employeeContractType.name : 'n/a',
    declarado: (employeeContract && employeeContract.undeclared) ? 'No' : 'Si'
  }

  result[employeeRecordName.split('/')[1]] = relevant
  return result
}, {}))

const _mergeData = memoize((data, employees, formats, qualities) => reduce(data, (result, value, key) => {
  // here we set the values we want to see on the table
  let employee = employees[key] || {}
  value.nombre = employee.nombre || 'n/a'
  value.apellido = employee.apellido || 'n/a'
  value.contrato = employee.contrato
  value.declarado = employee.declarado
  value.rut = key
  // set each summary value
  forEach(value.summary, (amount, key) => {
    const format = filter(formats, (format) => format.id === key.split(':')[0])[0].name
    const quality = filter(qualities, (q) => q.id === key.split(':')[1])[0].name
    value[`${format}:${quality}`] = amount

    if (!includes(map(result.columns, (c) => c.name), `${format}:${quality}`)) {
      result.columns.push({ name: `${format}:${quality}`, label: lowerCase(`${format}:${quality}`), options: { display: 'true', viewColumns: true } })
    }
  })

  // set each inPaymentSummary value
  forEach(value.inPaymentSummary, (amount, key) => {
    const format = filter(formats, (format) => format.id === key.split(':')[0])[0].name
    const quality = filter(qualities, (q) => q.id === key.split(':')[1])[0].name
    if (isEmpty(value.inPayment)) value.inPayment = {}
    value.inPayment[`${format}:${quality}`] = amount
  })

  // set each payed value
  forEach(value.payedSummary, (amount, key) => {
    const format = filter(formats, (format) => format.id === key.split(':')[0])[0].name
    const quality = filter(qualities, (q) => q.id === key.split(':')[1])[0].name
    if (isEmpty(value.payed)) value.payed = {}
    value.payed[`${format}:${quality}`] = amount
  })

  result.values.push(value)
  return result
}, { values: [], columns: [] }))

const mapStateToProps = (state, ownProps) => {
  const locationState = ownProps.location.state
  const sites = locationState.sites
  const formats = locationState.formats
  const farms = locationState.farms
  const qualities = locationState.qualities
  const companyId = state.auth.clientData.activeCompany
  const userAccess = state.main.userData.access
  const { selectedDay, selectedFarm, loading, harvestValidatedDays } = state.harvest

  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]
  const employeesList = _employeesList(employeesMemberships)
  const employees = _employeesData(employeesList, state.employees.employeeRecord, state.employees.employeeContract, state.employees.contractTypeRecord)
  const { harvestSitesDays } = state.harvest

  const days = daysSelector(harvestSitesDays, farms, selectedFarm)
  const data = _data(state.harvest.harvestRecord, companyId, sites, formats, farms, qualities, selectedDay, selectedFarm)
  const merged = _mergeData(data, employees, formats, qualities)

  return {
    data: merged.values,
    columns: _columns(uniq(concat(columns, merged.columns))),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'multiple',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    sites,
    formats,
    farms,
    employees,
    loading,
    userAccess,
    days,
    selectedDay,
    selectedFarm,
    harvestValidatedDays
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createHarvestPayment,
  setSelectedDayAndFarmForTable,
  getHarvestData,
  nextLoading,
  subscribeValidatedDays,
  unsubscribeValidatedDays,
  makeXlsxFile
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HarvestTable)
