import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'

class EmployeesTable extends React.Component {
  componentDidMount () {
    this.props.getAbsentEmployees()
  }

  render () {
    return (
      <div>
        {
          this.props.loading
            ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
            : <MUIDataTable
              title={'Inasistencias a Cosecha en Huerto del trabajador'}
              data={this.props.data}
              columns={this.props.columns}
              options={{ ...this.props.options }}
            />
        }
      </div>
    )
  }
}

EmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  getAbsentEmployees: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default withRouter(EmployeesTable)
