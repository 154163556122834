import { createReducer } from '@reduxjs/toolkit'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import filter from 'lodash/filter'

import { siteLogRecordDayList, addSiteLogRecordNameToList, siteLogRecords, removeSiteLogRecordNameToList, siteLogAnnotationTopics } from './siteLogActions'

const initialState = {
  siteLogRecordDayList: [''],
  siteLogRecord: {},
  siteLogAnnotationTopics: { topics: [] }
}

const siteLogReducer = createReducer(initialState, {
  [siteLogRecordDayList]: (state, action) => {
    state.siteLogRecordDayList = action.payload
  },
  [addSiteLogRecordNameToList]: (state, action) => {
    state.siteLogRecordDayList = concat(compact(state.siteLogRecordDayList), [''])
  },
  [removeSiteLogRecordNameToList]: (state, action) => {
    state.siteLogRecordDayList = filter(state.siteLogRecordDayList, (recordName) => recordName !== action.payload)
  },
  [siteLogRecords]: (state, action) => {
    state.siteLogRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.siteLogRecord[action.payload.recordName]
    }
    if (action.payload.reset) {
      state.siteLogRecord = {}
    }
  },
  [siteLogAnnotationTopics]: (state, action) => {
    state.siteLogAnnotationTopics = action.payload
  }
})

export default siteLogReducer
