import React from 'react'
import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import CardHeader from '@material-ui/core/CardHeader'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import moment from 'moment-timezone'
import 'moment/locale/es'

import clone from 'lodash/clone'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import filter from 'lodash/filter'
import replace from 'lodash/replace'
import reduce from 'lodash/reduce'
import isEqual from 'lodash/isEqual'
import toLower from 'lodash/toLower'
import deburr from 'lodash/deburr'
import trim from 'lodash/trim'

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
})

// TODO: puth this options on independent record

const contractVariables = {
  signDate: { text: '__FechaFirma__', required: true },
  beginDate: { text: '__FechaInicio__', required: true },
  expirationDate: { test: '__FechaTermino__', required: false },
  schedule: { text: '__Jornada__', required: true },
  bonuses: { text: '__Bonos__', required: false },
  nationality: { text: '__Nacionalidad__', required: false },
  farm: { text: '__Lugar__', required: true },
  employeeFullName: { text: '__NombreCompleto__', required: true },
  employeeBirthDay: { text: '__FechaNacimiento__', required: true },
  employeeId: { text: '__RUT__', required: true },
  address: { text: '__Direccion__', required: true },
  civilState: { text: '__EstadoCivil__', required: true },
  pension: { text: '__AFP__', required: true },
  healthSystem: { text: '__Salud__', required: true },
  salaryType: { text: '__TipoSalario__', required: true },
  salaryValue: { text: '__ValorSalario__', required: true },
  email: { text: '__Email__', required: true },
  phone: { text: '__Telefono__', required: true }
}

const salaryTypes = [
  { key: 'day', value: 'diario' },
  { key: 'month', value: 'mensual' }
]

const contractTypes = [
  { key: 'i', value: 'indefinido' },
  { key: 'p', value: 'plazoFijo' },
  { key: 'f', value: 'faena' }
]

const bonusTypes = ['imponible', 'noImponible']

const initialState = {
  newContract: {
    contentText: '',
    name: '',
    description: '',
    validFrom: '',
    scheduleHours: '',
    salaryType: '',
    salaryValue: { currency: 'CLP', value: '' },
    personal: false,
    type: ''
  },
  contentTextErrors: [],
  contentTextSuggestions: [],
  salaryType: { key: '', value: '' },
  bonus: [],
  contractType: { key: '', value: '' },
  viewContractData: '',
  viewContractDialog: false,
  currentContractData: {}
}

class ContractTypeRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    if (this.props.edit) {
      const contractData = this.props.contractTypeRecords[`contract/${this.props.contractId}`]
      let contractType = find(contractTypes, (cT) => cT.key === contractData.type)
      // NOTE: for backwards compatibility only, erase in 2024
      if (!contractType) {
        contractType = { key: 'i', value: 'indefinido' }
        if (contractData.name.indexOf('faena') > -1) contractType = { key: 'f', value: 'faena' }
        if (contractData.name.indexOf('plazo') > -1) contractType = { key: 'p', value: 'plazoFijo' }
      }

      this.setState({
        newContract: { ...contractData },
        bonus: contractData.bonus || [],
        salaryType: find(salaryTypes, (sT) => sT.key === contractData.salaryType),
        contractType,
        currentContractData: contractData
      })
    }
  }

  onChange = (event) => {
    let value = event.target.value
    this.setState({ [event.target.id || event.target.name]: value })
  }

  onChangeContentText = (contentText) => {
    const errors = []
    const suggestions = []
    forEach(contractVariables, (value) => {
      if (value.required) {
        if (!contentText.includes(value.text)) errors.push(value.text)
      } else {
        if (!contentText.includes(value.text)) suggestions.push(value.text)
      }
    })
    this.setState({ contentTextErrors: errors, contentTextSuggestions: suggestions, newContract: { ...this.state.newContract, contentText } })
  }

  contractPrepare = () => {
    const newContract = clone(this.state.newContract)
    newContract.salaryType = this.state.salaryType.key
    newContract.type = this.state.contractType.key
    newContract.bonus = filter(this.state.bonus, (b) => b.value)
    return newContract
  }

  contractRegister = () => {
    const newContract = this.contractPrepare()

    this.props.createContractType(newContract, (err, res) => {
      if (err) window.alert(err)
      else this.props.history.push('/app/contract/type/table')
    })
  }

  contractUpdate = () => {
    const ok = window.confirm('Seguro que deseas actualizar los datos del contrato?')
    if (ok) {
      const newContract = this.contractPrepare()

      // compute day before this new contract start
      const validUntil = moment(newContract.validFrom).subtract(1, 'days').format('YYYY-MM-DD')

      this.props.updateContractType({ newContract, oldContract: { validUntil, id: this.props.contractId } }, (err, res) => {
        if (err) window.alert(err)
        else this.props.history.goBack()
      })
    }
  }

  contractTypeDeactivate = () => {
    const ok = window.confirm('Seguro que deseas desactivar este tipo de contrato? Esto es irreversible')
    if (ok) {
      this.props.deactivateContractType({ contractId: this.props.contractId }, (err, res) => {
        if (err) window.alert(err)
        else this.props.history.goBack()
      })
    }
  }

  contractDisabled = () => {
    if (this.state.newContract.name && this.state.newContract.validFrom && this.state.newContract.scheduleHours && this.state.salaryType && this.state.newContract.salaryValue.value && this.state.newContract.contentText && this.state.contentTextErrors.length === 0) return false
    return true
  }

  contractUpdateDisabled = () => {
    // do not update inactive contracts
    if (!this.state.currentContractData.active) return true
    // check if something changed
    if (this.state.newContract.name === this.state.currentContractData.name &&
      this.state.newContract.description === this.state.currentContractData.description &&
       this.state.newContract.validFrom === this.state.currentContractData.validFrom &&
       this.state.newContract.scheduleHours === this.state.currentContractData.scheduleHours &&
       this.state.newContract.salaryType === this.state.currentContractData.salaryType &&
       this.state.newContract.salaryValue.value === this.state.currentContractData.salaryValue.value &&
       this.state.newContract.contentText === this.state.currentContractData.contentText &&
       isEqual(this.state.bonus, this.state.currentContractData.bonus) &&
       this.state.newContract.personal === this.state.currentContractData.personal &&
       this.state.contentTextErrors.length === 0) return true
    return false
  }

  toggleViewContractDialog = () => {
    this.setState({ viewContractDialog: !this.state.viewContractDialog })
  }

  viewContract = () => {
    const dateToText = (date) => {
    // month to text
      moment.locale('es')
      const split = date.split('-')
      return `${split[2]} de ${startCase(moment().month(Number(split[1]) - 1).format('MMMM'))} de ${split[0]}`
    }

    const bold = (text) => `<span style="font-weight:bold">${text}</span>`

    const bonus = (bono) => reduce(bono, (result, b, idx) => {
      result += ` ${b.name} de ${b.value.toLocaleString('es-CL', { currency: b.currency, style: 'currency' })} pesos`
      if (idx === bono.length - 1) result += '.'
      else result += ','
      return result
    }, '')

    //  format html
    const html = (contentText) => ('<p>' + contentText.replace(/\r?\n([ \t]*\r?\n)+/g, '</p><p>').replace(/\r?\n/g, '<br />') + '</p>')

    let viewContractData = replace(html(this.state.newContract.contentText), '__FechaInicio__', bold(dateToText('2021-08-26')))
    viewContractData = replace(viewContractData, '__FechaTermino__', bold(dateToText('2021-08-26')))
    viewContractData = replace(viewContractData, '__Jornada__', bold(this.state.newContract.scheduleHours))
    viewContractData = replace(viewContractData, '__Bonos__', bold(bonus(this.state.bonus)))
    viewContractData = replace(viewContractData, '__Lugar__', bold(`Los Maitenes, Cato, Coihueco`))
    viewContractData = replace(viewContractData, '__NombreCompleto__', bold('Ejemplo Ejemplo'))
    viewContractData = replace(viewContractData, '__FechaNacimiento__', bold(dateToText('2021-08-26')))
    viewContractData = replace(viewContractData, '__RUT__', bold('111111111-1'))
    viewContractData = replace(viewContractData, '__Direccion__', bold('Camino a Cato km 28, Coihueco'))
    viewContractData = replace(viewContractData, '__EstadoCivil__', bold('Soltero'))
    viewContractData = replace(viewContractData, '__Nacionalidad__', bold('Chileno'))
    viewContractData = replace(viewContractData, '__AFP__', bold('Cuprum'))
    viewContractData = replace(viewContractData, '__Salud__', bold('Fonasa'))
    viewContractData = replace(viewContractData, '__TipoSalario__', bold(this.state.salaryType.value))
    viewContractData = replace(viewContractData, '__ValorSalario__', bold(this.state.newContract.salaryValue.value.toLocaleString('es-CL', { currency: 'CLP', style: 'currency' }) + ' pesos'))
    viewContractData = replace(viewContractData, '__FechaFirma__', bold(dateToText('2021-08-26')))

    viewContractData += '<br /><br />' +
    `<div style="text-align:end">
    <img src="" />
    <br />
    <p style="display: flex;justify-content: space-between;">
    <span>Trabajador</span>
    <span>Empleador</span>
    </p>
    <p style="text-align:end">pp.Empresa</p>
    </div>`

    this.setState({ viewContractData })
    this.toggleViewContractDialog()
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <Card>
          <CardHeader
            title={this.props.edit ? 'Ver Contrato Tipo' : 'Crear Contrato Tipo'}
          />

          <CardContent>
            <TextField
              id='name'
              label='Nombre Tipo Contrato'
              required
              disabled={!!this.state.currentContractData.name}
              fullWidth
              margin='normal'
              onChange={(e) => this.setState({ newContract: { ...this.state.newContract, [e.target.id]: e.target.value } })}
              value={this.state.newContract.name}
            />

            <TextField
              id='description'
              label='Descripcion'
              fullWidth
              margin='normal'
              onChange={(e) => this.setState({ newContract: { ...this.state.newContract, [e.target.id]: e.target.value } })}
              value={this.state.newContract.description || ''}
            />
            <br />
            <br />

            <FormControl className={classes.formControl}>
              <FormGroup row>
                <InputLabel shrink>
                      Tipo Contrato
                </InputLabel>
                <Select
                  value={find(contractTypes, (s) => s.key === this.state.contractType.key) || ''}
                  onChange={this.onChange}
                  name='contractType'
                  className={classes.formControl}
                >
                  {map(contractTypes, (type, idx) => {
                    return <MenuItem key={idx} value={type}>{startCase(type.value)}</MenuItem>
                  })}
                </Select>
              </FormGroup>
            </FormControl>
            <br />
            <br />

            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.newContract.personal}
                    onChange={(e) => this.setState({ newContract: { ...this.state.newContract, personal: !this.state.newContract.personal } })}
                  />
                }
                label='Personal'
              /></FormGroup>
            <br />
            <br />

            <TextField
              id='validFrom'
              name='validFrom'
              required
              label='Valido desde'
              InputLabelProps={{
                shrink: true
              }}
              type='date'
              value={this.state.newContract.validFrom}
              onChange={(e) => this.setState({ newContract: { ...this.state.newContract, [e.target.id]: e.target.value } })}
            />
            <br />
            <br />
            <TextField
              id='scheduleHours'
              label='Horas Jornada'
              required
              type='number'
              margin='normal'
              onChange={(e) => this.setState({ newContract: { ...this.state.newContract, [e.target.id]: e.target.value ? Number(e.target.value) : '' } })}
              value={this.state.newContract.scheduleHours}
            />
            <br />
            <br />
            <Grid container direction='row' spacing={24}>
              <Grid item xs>
                <FormControl className={classes.formControl}>
                  <FormGroup row>
                    <InputLabel shrink>
                      Tipo Sueldo
                    </InputLabel>
                    <Select
                      value={find(salaryTypes, (s) => s.key === this.state.salaryType.key) || ''}
                      onChange={this.onChange}
                      name='salaryType'
                      className={classes.formControl}
                    >
                      {map(salaryTypes, (salary, idx) => {
                        return <MenuItem key={idx} value={salary}>{startCase(salary.value)}</MenuItem>
                      })}
                    </Select>

                    <TextField
                      id='salaryValue'
                      label='Valor Sueldo Base'
                      required
                      className={classes.formControl}
                      type='number'
                      onChange={(e) => this.setState({ newContract: { ...this.state.newContract, salaryValue: { currency: 'CLP', value: e.target.value ? Number(e.target.value) : '' } } })}
                      value={this.state.newContract.salaryValue.value}
                    />
                    <Button color='primary' onClick={() => {
                      const bonus = clone(this.state.bonus)
                      bonus.push({
                        name: '',
                        type: '',
                        currency: 'CLP',
                        value: 0
                      })
                      this.setState({ bonus })
                    }}>Agregar Bono</Button>

                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={24}>
              {map(this.state.bonus, (bono, idx) =>
                <Grid item xs key={idx}>
                  <FormControl className={classes.formControl}>
                    <FormGroup row>
                      <InputLabel shrink htmlFor='age-label-placeholder'>
                        Tipo Bono
                      </InputLabel>
                      <Select
                        value={find(bonusTypes, (b) => b === bono.type) || ''}
                        onChange={(e) => {
                          const bonus = clone(this.state.bonus)
                          bonus[idx].type = e.target.value
                          this.setState({ bonus })
                        }}
                        name='bonusType'
                        className={classes.formControl}
                      >
                        {map(bonusTypes, (bT, idx) => {
                          return <MenuItem key={idx} value={bT}>{startCase(bT)}</MenuItem>
                        })}
                      </Select>
                      <TextField
                        id='bonusName'
                        label='nombre'
                        className={classes.formControl}
                        type='text'
                        onChange={(e) => {
                          const bonus = clone(this.state.bonus)
                          bonus[idx].name = toLower(trim(deburr(e.target.value)))
                          this.setState({ bonus })
                        }}
                        value={bono.name}
                      />

                      <TextField
                        id='bonusValue'
                        label='valor'
                        className={classes.formControl}
                        type='number'
                        onChange={(e) => {
                          const bonus = clone(this.state.bonus)
                          bonus[idx].value = e.target.value ? Number(e.target.value) : ''
                          this.setState({ bonus })
                        }}
                        value={bono.value}
                      />
                      <Button onClick={() => {
                        this.setState({ bonus: filter(this.state.bonus, (v, i) => i !== idx) })
                      }}>Eliminar</Button>
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <br />

            <br />
            <TextField
              id='contentText'
              label='Texto Contrato'
              multiline
              required
              variant='outlined'
              fullWidth
              margin='normal'
              onChange={(e) => this.onChangeContentText(e.target.value)}
              value={this.state.newContract.contentText}
              error={!!this.state.contentTextErrors.length}
            />
            {this.state.contentTextErrors.length
              ? <FormHelperText error={!!this.state.contentTextErrors.length}>
          Texto debe incluir: {this.state.contentTextErrors.join(' , ')}
              </FormHelperText> : <></>}
            {this.state.contentTextSuggestions.length
              ? <FormHelperText>
                Texto puede incluir: {this.state.contentTextSuggestions.join(' , ')}
              </FormHelperText> : <></>}

            <CardActions>
              {this.props.userAccess < 3 ? <>
                {this.props.edit
                  ? <Button size='small' color='primary' onClick={() => this.contractUpdate()} disabled={this.contractUpdateDisabled()}>
                      Actualizar
                  </Button>
                  : <Button size='small' color='primary' onClick={() => this.contractRegister()} disabled={this.contractDisabled()}>
                     Registrar contrato
                  </Button>
                }
              </> : <></>}
              <Button size='small' color='primary' onClick={() => this.viewContract()} disabled={this.contractDisabled()}>
                 Ver contrato
              </Button>
              <Button size='small' color='primary' onClick={() => this.contractTypeDeactivate()}>
                     Desactivar contrato
              </Button>

            </CardActions>
          </CardContent>
        </Card>
        {/* view contract dialog */}
        <Dialog onClose={this.toggleViewContractDialog} open={this.state.viewContractDialog} scroll={'paper'} fullWidth>
          <DialogTitle>Contrato</DialogTitle>
          <DialogContent ref={el => (this.componentRef = el)}>
            <div dangerouslySetInnerHTML={{ __html: this.state.viewContractData }} />
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => <Button color='primary' >Imprimir</Button>}
              content={() => this.componentRef}
            />
            <Button onClick={() => this.toggleViewContractDialog()}>Cerrar</Button>
          </DialogActions>
        </Dialog>

      </>
    )
  }
}

ContractTypeRegister.propTypes = {
  classes: PropTypes.object,
  createContractType: PropTypes.func,
  updateContractType: PropTypes.func,
  deactivateContractType: PropTypes.func,
  edit: PropTypes.bool,
  userAccess: PropTypes.number,
  contractTypeRecords: PropTypes.object,
  contractId: PropTypes.string,
  history: PropTypes.object
}

export default withStyles(styles)(ContractTypeRegister)
