import { createReducer } from '@reduxjs/toolkit'
import { companyMonthlyExpenses, companyExpenses, expenseMetadata, selectMonth, loading, addDetailDialog, companyExpensesAccounts } from './expensesActions'

const initialState = {
  companyMonthlyExpenses: {},
  companyExpenses: {},
  companyExpensesAccounts: {},
  expenseMetadata: {},
  selectedMonth: '',
  loading: true,
  addDetailDialogOpen: false
}

const expensesReducer = createReducer(initialState, {
  [companyMonthlyExpenses]: (state, action) => { state.companyMonthlyExpenses[action.payload.listName] = action.payload.data },
  [companyExpenses]: (state, action) => { state.companyExpenses[action.payload.recordName] = action.payload.data },
  [companyExpensesAccounts]: (state, action) => {
    state.companyExpensesAccounts[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.companyExpensesAccounts[action.payload.recordName]
    }
  },
  [expenseMetadata]: (state, action) => { state.expenseMetadata[action.payload.recordName] = action.payload.data },
  [selectMonth]: (state, action) => { state.selectedMonth = action.payload },
  [loading]: (state, action) => { state.loading = action.payload },
  [addDetailDialog]: (state, action) => { state.addDetailDialogOpen = action.payload }
})

export default expensesReducer
