import { createAction } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import 'moment/locale/es'

import reduce from 'lodash/reduce'
import startCase from 'lodash/startCase'
import replace from 'lodash/replace'
import find from 'lodash/find'
import noop from 'lodash/noop'
import clone from 'lodash/clone'

import { makeRpc, eventSubscribe } from '../../Main/redux/mainActionsDeepstream'

import { listSubscribe } from '../../Main/redux/mainActionsRecords'
import { employeesContractsBulk, getEmployeeContract } from '../../Employees/redux/employeesActions'
import { getFileDownloadUrl } from '../../FileUtils/redux/fileUtilsActions'

export const laboursList = createAction('contract/laboursList')
export const labourRecord = createAction('contract/labour')

let companyId = null
let userId = null

// START
export const startContractData = (cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  companyId = state.auth.clientData.activeCompany
  userId = state.auth.clientData.userId
  dispatch(listSubscribe(`company/${companyId}/list/labours`, laboursList, labourRecord, cb))
  // start event listeners
  dispatch(contractEvent())
}

export const createLabour = (payload, cb = noop) => (dispatch, getState) => {
  dispatch(makeRpc('contract/labour/create', { newLabour: payload, companyId }, cb))
}

export const editLabour = (payload, cb = noop) => (dispatch, getState) => {
  dispatch(makeRpc('contract/labour/update', { labour: payload, companyId }, cb))
}

export const createContractType = (payload, cb = noop) => (dispatch, getState) => {
  dispatch(makeRpc('contract/type/create', { newContract: payload, companyId }, cb))
}

export const updateContractType = (payload, cb = noop) => (dispatch, getState) => {
  dispatch(makeRpc('contract/type/update', { ...payload, companyId }, cb))
}

export const deactivateContractType = (payload, cb = noop) => (dispatch, getState) => {
  dispatch(makeRpc('contract/type/deactivate', { ...payload, companyId }, cb))
}

export const setSignDateForContracts = (payload, cb = noop) => (dispatch, getState) => {
  const { contractIds, signDate } = payload
  dispatch(makeRpc('contract/set/signDate', { contractIds, signDate, companyId }, cb))
}

export const signContracts = (payload, cb = noop) => (dispatch, getState) => {
  const { contractIds } = payload
  dispatch(makeRpc('contract/set/signature', { contractIds, userId, companyId }, cb))
}

export const buildContractHtml = (employeeContract, employeeRecord, contractTypeRecord, farmRecords, access, cb = noop) => (dispatch, getState) => {
  const dateToText = (date) => {
    if (!date) return ''
    // month to text
    moment.locale('es')
    const split = date.split('-')
    return `${split[2]} de ${startCase(moment().month(Number(split[1]) - 1).format('MMMM'))} de ${split[0]}`
  }

  const bold = (text) => `<span style="font-weight:bold">${text}</span>`

  const bonus = (bono) => reduce(bono, (result, b, idx) => {
    result += ` ${b.name} de ${b.value.toLocaleString('es-CL', { currency: b.currency, style: 'currency' })} pesos`
    if (idx === bono.length - 1) result += '.'
    else result += ','
    return result
  }, '')

  //  format html
  const html = (contentText) => ('<p>' + contentText.replace(/\r?\n([ \t]*\r?\n)+/g, '</p><p>').replace(/\r?\n/g, '<br />') + '</p>')

  const farm = find(farmRecords, (f, id) => employeeContract.farm === id.split('/')[1])
  let viewContractData = replace(html(contractTypeRecord.contentText), '__FechaInicio__', bold(dateToText(employeeContract.beginDate)))
  viewContractData = replace(viewContractData, '__FechaTermino__', bold(dateToText(employeeContract.expirationDate)))
  viewContractData = replace(viewContractData, '__Jornada__', bold(`${contractTypeRecord.scheduleHours}`))
  viewContractData = replace(viewContractData, '__Lugar__', bold(`${farm.displayName}, ${farm.contact.address}, ${farm.contact.city}`))
  viewContractData = replace(viewContractData, '__NombreCompleto__', bold(`${startCase(employeeRecord.name)} ${startCase(employeeRecord.lastName)}`))
  viewContractData = replace(viewContractData, '__FechaNacimiento__', bold(dateToText(employeeRecord.birthDate)))
  viewContractData = replace(viewContractData, '__RUT__', bold(`${employeeContract.employee}`))
  viewContractData = replace(viewContractData, '__Email__', bold(`${employeeRecord.email || 'No informa'}`))
  viewContractData = replace(viewContractData, '__Telefono__', bold(`${employeeRecord.phone || 'No informa'}`))
  viewContractData = replace(viewContractData, '__Direccion__', bold(`${startCase(employeeRecord.address)}, ${startCase(employeeRecord.city) || ' '}`))
  viewContractData = replace(viewContractData, '__EstadoCivil__', bold(`${startCase(employeeRecord.civilState) || 'No informa'}`))
  viewContractData = replace(viewContractData, '__Nacionalidad__', bold(`${startCase(employeeRecord.nationality) || 'Chilena'}`))
  viewContractData = replace(viewContractData, '__AFP__', bold(`${startCase(employeeRecord.pension)}`))
  viewContractData = replace(viewContractData, '__Salud__', bold(`${startCase(employeeRecord.healthInsurance)}`))
  viewContractData = replace(viewContractData, '__TipoSalario__', bold(`${contractTypeRecord.salaryType === 'day' ? 'diario' : 'mensual'}`))
  viewContractData = replace(viewContractData, '__FechaFirma__', bold(dateToText(employeeContract.signDate)))

  // personal info
  if (contractTypeRecord.personal) {
    if (access < 3) {
      // ok to view
      viewContractData = replace(viewContractData, '__ValorSalario__', bold(`${contractTypeRecord.salaryValue ? contractTypeRecord.salaryValue.value.toLocaleString('es-CL', { currency: contractTypeRecord.salaryValue.currency, style: 'currency' }) : ' '} pesos`))
      viewContractData = replace(viewContractData, '__Bonos__', bold(bonus(contractTypeRecord.bonos)))
    } else {
      // censor personal data
      viewContractData = replace(viewContractData, '__ValorSalario__', bold('xxx pesos'))
      viewContractData = replace(viewContractData, '__Bonos__', bold('xxx pesos'))
    }
  } else {
    // not personal
    viewContractData = replace(viewContractData, '__ValorSalario__', bold(`${contractTypeRecord.salaryValue ? contractTypeRecord.salaryValue.value.toLocaleString('es-CL', { currency: contractTypeRecord.salaryValue.currency, style: 'currency' }) : ' '} pesos`))
    viewContractData = replace(viewContractData, '__Bonos__', bold(bonus(contractTypeRecord.bonos)))
  }

  // signature
  let signatureImgUrl = employeeContract.signature ? employeeContract.signature.signatureImgUrl : ''
  dispatch(getFileDownloadUrl({ href: signatureImgUrl }, (err, response) => {
    if (!err && response.data.getUrl) signatureImgUrl = response.data.getUrl

    viewContractData += '<br /><br />' +
        `<div style="text-align:end">
        <img src="${signatureImgUrl}" />
        <br />
        <p style="display: flex;justify-content: space-between;">
        <span>Trabajador</span>
        <span>Empleador</span>
        </p>
        <p style="display: flex;justify-content: space-between;">
        <span>RUT: ${employeeContract.employee}</span>
        <span>${employeeContract.signature ? employeeContract.signature.signatureText : ''}</span>
        </p>
        <p style="text-align:end">${employeeContract.signature ? `Código verificador firma electrónica: ${employeeContract.contractId.split('/')[2]}` : ''}</p>
        </div>`

    cb(null, viewContractData)
  }))
}

/**
 * contractEvent: this will hanlde when a contract related event is emitted
 * @return {void}
 * BEST PRACTICE FOR MULTIPLE BATCH UPDATES
 */
const contractEvent = () => (dispatch, getState) => {
  /*
      MEMBERSHIP UPDATED EVENT
      Also emmited when contract is updated
   */
  // define event callback
  const onMembershipUpdate = (payload) => {
    const state = getState()
    dispatch(getEmployeeContract(`membership/${payload.membershipId}`, state, true))
  }
  // subscribe
  dispatch(eventSubscribe(`contract/${companyId}/membership/update`, { name: 'onMembershipUpdate', function: onMembershipUpdate }))

  /*
  SET SIGN DATE EVENT
   */
  // define event callback
  const onSetSignDate = (payload) => {
    const state = getState()
    const employeeContracts = state.employees.employeeContract
    // update signDate locally
    const employeeContractsToUpdate = reduce(payload.contractIds, (result, contractId) => {
      const employeeContract = clone(find(employeeContracts, (c) => c.contractId === `${contractId}`))
      if (!employeeContract) return result
      employeeContract.signDate = payload.signDate
      result[`employee/${employeeContract.employee}`] = employeeContract
      return result
    }, {})

    dispatch(employeesContractsBulk({ data: employeeContractsToUpdate }))
  }
  // subscribe
  dispatch(eventSubscribe(`contract/${companyId}/set/signDate`, { name: 'onSetSignDate', function: onSetSignDate }))

  /*
    SET SIGNATURE EVENT
   */

  // define event callback
  const onSetSignature = (payload) => {
    const state = getState()
    const employeeContracts = state.employees.employeeContract
    // update signature locally
    const employeeContractsToUpdate = reduce(payload.contractIds, (result, contractId) => {
      const employeeContract = clone(find(employeeContracts, (c) => c.contractId === contractId))
      if (!employeeContract) return result
      employeeContract.signature = payload.signature
      result[`employee/${employeeContract.employee}`] = employeeContract
      return result
    }, {})

    dispatch(employeesContractsBulk({ data: employeeContractsToUpdate }))
  }
  // subscribe
  dispatch(eventSubscribe(`contract/${companyId}/set/signature`, { name: 'onSetSignature', function: onSetSignature }))
}
