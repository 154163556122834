import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import memoize from 'memoize-one'

import map from 'lodash/map'
import keys from 'lodash/keys'
import isEqual from 'lodash/isEqual'
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'

import InputTextDialog from '../Components/InputTextDialog'
import { appConfig } from '../../config'
import FileDownloadLinkComponent from '../FileUtils/FileDownloadLinkComponent'

const initialState = {
  loading: false,
  createPaymentOrderResult: {},
  openInputTextDialog: false,
  payment: {}
}

class HarvestPayments extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.lastHarvestPayment, this.props.lastHarvestPayment) && (this.props.lastHarvestPayment.status > 100 || this.props.lastHarvestPayment.status < 400)) {
      this.props.updateHarvestRecordPaymentData(this.props.lastHarvestPayment.data)
    }
  }

  createPaymentOrder = (comment = '') => {
    this.setState({ loading: true })
    const paymentIds = map(this.state.payment.data, (value) => value.paymentId)

    this.props.createHarvestPaymentOrder({ paymentIds, comment }, (err, result) => {
      this.setState({ loading: false, payment: {}, createPaymentOrderResult: { err, result } })
    })
  }

  openDialog = (payment = {}) => {
    this.setState({ payment, openInputTextDialog: true })
  }

  getAmount = (outgoingOrderRecord) => {
    if (outgoingOrderRecord.data && outgoingOrderRecord.data.amount) {
      return `${outgoingOrderRecord.data.amount['CLP']} pesos`
    }
    return null
  }

  getUrls = (outgoingOrderRecord) => {
    if (outgoingOrderRecord.data && outgoingOrderRecord.data.document) {
      return map(outgoingOrderRecord.data.document, (url) => `${appConfig.downloadUrlPrefix}${url}`)
    }
    return ['']
  }

  render () {
    if (this.state.loading) {
      return (<LinearProgress />)
    }

    if (!isEmpty(this.state.createPaymentOrderResult)) {
      if (this.state.createPaymentOrderResult.err) {
        return (
          <Card>
            <CardHeader title='Error' />
            <CardContent>
              {JSON.stringify(this.state.createPaymentOrderResult.err)}
            </CardContent>
          </Card>
        )
      }

      const outgoingOrderRecord = this.props.payments.outgoingPaymentOrder[this.state.createPaymentOrderResult.result.orderRecordName]

      if (!outgoingOrderRecord || !outgoingOrderRecord.data) {
        return (<LinearProgress />)
      }

      if (outgoingOrderRecord.status > 300) {
        return (
          <Card>
            <CardHeader title='Error' />
            <CardContent>
              {JSON.stringify(outgoingOrderRecord)}
            </CardContent>
          </Card>
        )
      }

      return (
        <Card>
          <CardHeader title='Archivos de Pago' />
          <CardContent>
            <Typography variant='body1' gutterBottom>
                  Total a pago: {this.getAmount(outgoingOrderRecord)}
            </Typography>
            <Typography variant='body1' gutterBottom>
                  Archivos a descargar
            </Typography>
            {map(this.getUrls(outgoingOrderRecord), (value, key) => {
              return (
                <div key={key}>
                  <FileDownloadLinkComponent href={value} />
                  <br />
                </div>
              )
            })}
          </CardContent>
        </Card>
      )
    }

    if (this.props.lastHarvestPayment) {
      if (this.props.lastHarvestPayment.status === 100) {
        return (
          <>
            <Typography variant='h5' gutterBottom>
            Procesando
            </Typography>
            <LinearProgress />
          </>
        )
      }
      if (this.props.lastHarvestPayment.status === 400) {
        return (
          <>
            {error(this.props.lastHarvestPayment.error)}
          </>
        )
      }
      if (this.props.lastHarvestPayment.status === 200) {
        return (
          <>
            {result({ ...this.props,
              createPaymentOrder: this.createPaymentOrder,
              openDialog: this.openDialog,
              closeInputTextDialog: () => this.setState({ openInputTextDialog: false }),
              openInputTextDialog: this.state.openInputTextDialog })}
          </>)
      }

      if (this.props.lastHarvestPayment.status === 206) {
        return (
          <>
            {result({ ...this.props, createPaymentOrder: this.createPaymentOrder })}
            <br />
            {error(this.props.lastHarvestPayment.error)}
          </>)
      }
    }

    return null
  }
}

HarvestPayments.propTypes = {
  lastHarvestPayment: PropTypes.object,
  payments: PropTypes.object,
  updateHarvestRecordPaymentData: PropTypes.func,
  createHarvestPaymentOrder: PropTypes.func
}

const _employeeId = memoize((payment) => payment.ressourceRecordName.split('/')[1])

const HeaderTitle = (props) => {
  const { status } = props
  if (status === 100) {
    return <>
      <Typography variant='h5' gutterBottom>
        Procesando
      </Typography>
      <LinearProgress />
    </>
  }

  if (status === 206) {
    return <>
      <Typography variant='h5' gutterBottom>
        Procesado parcialmente
      </Typography>
    </>
  }

  return <Typography variant='h5' gutterBottom>
    Procesado
  </Typography>
}

HeaderTitle.propTypes = {
  status: PropTypes.number
}

const result = (props) => {
  return (
    <div>
      <Card>
        <CardHeader
          title={<HeaderTitle status={props.lastHarvestPayment.status} />}
        />
        <CardContent>
          <List>
            {map(props.lastHarvestPayment.data, (value, key) => {
              const employeeId = _employeeId(value.payment)
              return <React.Fragment key={key}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant='body1' gutterBottom>
                        {`${props.employees[employeeId].nombre} ${props.employees[employeeId].apellido} / RUT: ${employeeId}`}
                        <br />
                            Total a pago: {`${value.payment.total[keys(value.payment.total)[0]]} pesos`}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            }
            )}
          </List>
        </CardContent>
        <CardActions>
          <Button variant='outlined' color='primary' onClick={() =>
            props.openDialog(props.lastHarvestPayment)}>
            Generar archivo de pago
          </Button>
        </CardActions>
      </Card>
      <br />
      <InputTextDialog title={'Comentario'}
        closeInputTextDialog={props.closeInputTextDialog}
        open={props.openInputTextDialog}
        callback={props.createPaymentOrder} />
    </div>
  )
}

result.propTypes = {
  employees: PropTypes.object,
  lastHarvestPayment: PropTypes.object,
  closeInputTextDialog: PropTypes.func,
  createPaymentOrder: PropTypes.func,
  openDialog: PropTypes.func,
  openInputTextDialog: PropTypes.bool
}

const error = (props) => {
  return (
    <div>
      <Card>
        <CardHeader
          title='Pagos fallidos'
        />
        <CardContent>
          {map(props, (value, key) => {
            return map(value.payment, (payment, idx) => {
              return <div key={idx}>
                <Typography variant='body1' gutterBottom>
                RUT: {payment.employeeId}
                  <br />
                Numero de registros afectados: {payment.items.length}
                </Typography>
                <Divider />
              </div>
            })
          })}
        </CardContent>
      </Card>
      <br />
    </div>
  )
}

export default HarvestPayments
