import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'

class ContractTypeTable extends React.Component {
  onCellClick = (data, meta) => {
    let id = this.props.data[meta.dataIndex].id
    // force data fetch
    this.props.history.push(`/app/contract/type`, { contractId: id, edit: true })
  }

  render () {
    return (
      <MUIDataTable
        title={'Contratos Tipo'}
        data={this.props.data}
        columns={this.props.columns}
        options={{ ...this.props.options,
          onCellClick: this.onCellClick
        }}
      />
    )
  }
}

ContractTypeTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(ContractTypeTable)
