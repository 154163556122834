import { createReducer } from '@reduxjs/toolkit'

import { outgoingPaymentOrder, incomingPaymentOrder, paymentRecords, outgoingPaymentOrderList, outgoingPaymentRequest, paymentLiquidacionParams, employeesLiquidacion, outgoingPaymentRequestList } from './paymentsActions'

const initialState = {
  outgoingPaymentOrder: {},
  incomingPaymentOrder: {},
  paymentRecords: {},
  outgoingPaymentOrderList: {},
  outgoingPaymentRequest: {},
  paymentLiquidacionParams: {},
  employeesLiquidacion: {},
  outgoingPaymentRequestList: {}
}

const paymentsReducer = createReducer(initialState, {
  [outgoingPaymentOrder]: (state, action) => { state.outgoingPaymentOrder[action.payload.recordName] = action.payload.data },
  [outgoingPaymentOrderList]: (state, action) => { state.outgoingPaymentOrderList[action.payload.listName] = action.payload.data },
  [outgoingPaymentRequestList]: (state, action) => { state.outgoingPaymentRequestList[action.payload.listName] = action.payload.data },
  [incomingPaymentOrder]: (state, action) => { state.incomingPaymentOrder[action.payload.recordName] = action.payload.data },
  [outgoingPaymentRequest]: (state, action) => {
    state.outgoingPaymentRequest[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.outgoingPaymentRequest[action.payload.entry]
    }
  },
  [paymentRecords]: (state, action) => {
    if (action.payload.batch) {
      state.paymentRecords = action.payload.data
    } else {
      state.paymentRecords[action.payload.recordName] = action.payload.data
    }
  },
  [paymentLiquidacionParams]: (state, action) => { state.paymentLiquidacionParams = action.payload.data },
  [employeesLiquidacion]: (state, action) => {
    if (action.payload.batch) {
      state.employeesLiquidacion = action.payload.data
    } else {
      state.employeesLiquidacion[action.payload.recordName] = action.payload.data
    }
  }

})

export default paymentsReducer
