import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

import moment from 'moment-timezone'
import { appConfig } from '../../config'

import map from 'lodash/map'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import now from 'lodash/now'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 2
  },
  button: {
    margin: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  grow: {
    flexGrow: 1
  }
})

const employeeRecordKeys = {
  name: 'nombre',
  lastName: 'apellido'
}

const harvestRecordKeys = {
  cropName: 'variedad',
  farmName: 'predio',
  siteName: 'cuartel',
  formatName: 'formato',
  qualityName: 'calidad',
  quantity: 'cantidad',
  date: 'fecha'
}

class HarvestRecordView extends React.Component {
  constructor (props) {
    super(props)
    this.state = { anchorEl: null, loading: false, now: now() }
  }

  componentWillUnmount () {
    this.setState({ loading: false })
  }
  edit = () => {
    if (this.props.harvestRecord.payment) {
      window.alert(`No se pueden modificar registros en proceso de pago`)
      return
    }
    if (this.props.userAccess > 2 && (this.state.now - this.props.harvestRecord.date) > appConfig.maxHarvestRecordDeleteTime) {
      window.alert(`No se pueden modificar registros luego de ${appConfig.maxHarvestRecordDeleteTime / (3600 * 1000)} hrs`)
    } else {
      this.props.history.push(`/app/harvest/edit`, { harvestRecordName: this.props.harvestRecordName, harvestRecord: this.props.harvestRecord, sites: this.props.sites, formats: this.props.formats, qualities: this.props.qualities })
    }
  }

  delete = () => {
    if (this.props.harvestRecord.payment) {
      window.alert(`No se pueden eliminar registros en proceso de pago`)
      return
    }

    const ok = window.confirm('Eliminar registro definitivamente?')
    if (ok) {
      this.setState({ loading: true })
      if (this.props.harvestRecord.pending) {
        // modifiy pending requests first
        const recordUid = this.props.harvestRecordName.split('/')

        this.props.deletePendingHarvestRecord(`${recordUid[3]}/${recordUid[4]}`, () => {
          this.props.getPendingRecords(() => {
            this.props.history.goBack()
          })
        })
      } else {
        this.props.deleteHarvestRecord(this.props.harvestRecordName, this.props.harvestRecord.site, (err, ok) => {
          if (err) {
            // do not delete non pending records without connection
            window.alert(startCase(err))
            this.setState({ loading: false })
          } else {
            this.props.history.goBack()
          }
        })
      }
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: (event && event.currentTarget) || null })
  };

  render () {
    const { classes } = this.props
    const open = !!this.state.anchorEl
    return (
      <div>
        {this.state.loading
          ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <Card>
            <CardHeader
              title='Registro cosecha'
              action={
                <div>
                  {
                    // NOTE: allow record deletion in hte first 24 hours since creation
                    // TODO: user provided timezone
                    (this.props.userAccess < 3 || (this.state.now - this.props.harvestRecord.date) < appConfig.maxHarvestRecordDeleteTime)
                      ? <>
                        <IconButton onClick={this.handleClick}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={this.state.anchorEl}
                          open={open}
                          onClose={() => this.handleClick(null)}
                        >
                          <MenuItem onClick={() => this.delete()}>
                        Eliminar
                          </MenuItem>
                        </Menu>
                      </>
                      : <></>
                  }
                </div>
              }
            />
            <CardContent>
              <Typography variant='body1' gutterBottom>
                RUT: {this.props.harvestRecord.employee}
              </Typography>
              {map(this.props.employeeRecord, (value, key) => {
                if (value && includes(keys(employeeRecordKeys), key)) {
                  return <Typography variant='body1' gutterBottom key={key}>
                    {startCase(employeeRecordKeys[key])}: {value}
                  </Typography>
                }
              })}

              {map(this.props.harvestRecord, (value, key) => {
                if (value && includes(keys(harvestRecordKeys), key)) {
                  return <Typography variant='body1' gutterBottom key={key}>
                    {startCase(harvestRecordKeys[key])}: {key === 'date'
                      ? moment(value).tz('America/Santiago').format('DD-MMM hh:mm a')
                      : value}
                  </Typography>
                }
              })}

            </CardContent>
            <CardActions>
              <Button size='small' color='primary' className={classes.button} onClick={() => this.edit()}>
                Editar
              </Button>
            </CardActions>
          </Card>
        }
      </div>
    )
  }
}

HarvestRecordView.propTypes = {
  classes: PropTypes.object.isRequired,
  harvestRecordName: PropTypes.string.isRequired,
  harvestRecord: PropTypes.object,
  employeeRecord: PropTypes.object,
  history: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  deletePendingHarvestRecord: PropTypes.func.isRequired,
  getPendingRecords: PropTypes.func.isRequired,
  deleteHarvestRecord: PropTypes.func.isRequired,
  userAccess: PropTypes.number.isRequired
}

export default withStyles(styles)(HarvestRecordView)
