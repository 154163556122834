import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import remove from 'lodash/remove'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'

import memoize from 'memoize-one'

import ContractSignTable from './ContractSignTable.js'
import { setSignDateForContracts, signContracts, buildContractHtml } from './redux/contractActions'
import { recordNameToId, columnsFilter, getTipoContrato } from '../../utils.js'

const columns = [
  { name: 'contractId', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'employeeId', options: { viewColumns: false, display: 'excluded', filter: false } }
]
const defaultColumns = ['rut', 'nombre', 'apellido', 'predio', 'tipoContrato', 'fechaFirma', 'firmaEmpleador']

const _employeesList = memoize((employeesMemberships) => map(employeesMemberships, (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')))

const _data = memoize((employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords) => reduce(employeesList, (result, employeeRecordName) => {
  let employee = employeeRecord[employeeRecordName]
  let farm = null
  let employeeContract = null
  if (!isEmpty(employeeContracts)) {
    employeeContract = employeeContracts[employeeRecordName]
    farm = employeeContract ? employeeContract.farm : null
  }

  if (!employeeContract) return result

  if (employeeContract.employerSignature) return result

  if (farm) farm = farmRecords[`farm/${employeeContracts[employeeRecordName].farm}`] ? farmRecords[`farm/${employeeContracts[employeeRecordName].farm}`].displayName : 'n/d'

  const employeeContractType = employeeContract ? `contract/${employeeContract.type}` : 'contract/type/'
  const contractTypeRecord = contractTypeRecords[employeeContractType]
  const employeeId = recordNameToId(employeeRecordName)
  let relevant = {
    rut: employeeId,
    nombre: employee ? employee.name : 'n/d',
    apellido: employee ? employee.lastName : 'n/d',
    predio: farm || 'n/d',
    tipoContrato: getTipoContrato(contractTypeRecord),
    fechaFirma: employeeContract.signDate || '',
    contractId: employeeContract.contractId,
    firmaEmpleador: employeeContract.signature ? 'si' : 'no',
    employeeId
  }

  for (var key in relevant) {
    if (key !== 'id') {
      if (!includes(map(columns, (c) => c.name), key)) {
        columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
      }
    }
  }
  result.push(relevant)
  return result
}, []))

const mapStateToProps = (state, ownProps) => {
  const companyId = state.auth.clientData.activeCompany
  const userAccess = state.main.userData.access
  const farmRecords = state.farms.farmRecord
  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]
  const employeeContracts = state.employees.employeeContract
  const employeesList = _employeesList(employeesMemberships)
  const contractTypeRecords = state.employees.contractTypeRecord
  const employeeRecords = state.employees.employeeRecord

  return {
    data: _data(employeesList, employeeRecords, employeeContracts, farmRecords, contractTypeRecords),
    columns: columnsFilter(columns, defaultColumns, ownProps.location, employeesList.length),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'multiple',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    employeeRecords,
    employeeContracts,
    contractTypeRecords,
    farmRecords,
    userAccess
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setSignDateForContracts,
  signContracts,
  buildContractHtml
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ContractSignTable)
