import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { DeepstreamClient } from '@deepstream/client'
import reducers from './reducers'
import { deepstreamConfig } from './config'

const appPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['main', 'auth', 'expenses', 'harvest', 'employees', 'farms']
}

const mainPersistConfig = {
  key: 'main',
  storage,
  blacklist: ['connectionError', 'connectionState', 'sendingPendingRequestsActive', 'start', 'loading']
}

const authPersistConfig = {
  key: 'auth',
  storage
}

const expensesPersistConfig = {
  key: 'expenses',
  storage,
  blacklist: ['loading', 'addDetailDialogOpen', 'classifyForm']
}

const employeesPersistConfig = {
  key: 'employees',
  storage,
  blacklist: ['loading', 'createEmployeeSuccess', 'start', 'employeesBankAccount']
}

const harvestPersistConfig = {
  key: 'harvest',
  storage,
  blacklist: ['harvestInputQrOpen', 'harvestDefaults', 'start', 'selectedDay', 'loading', 'harvestRecord', 'harvestStats', 'harvestPayments']
}

const farmsPersistConfig = {
  key: 'farms',
  storage,
  blacklist: ['start']
}

const paymentsPersistConfig = {
  key: 'payments',
  storage,
  blacklist: ['outgoingPaymentOrder', 'incomingPaymentOrder', 'paymentRecords']
}

const attendancePersistConfig = {
  key: 'attendance',
  storage,
  whitelist: ['attendanceAnnotationTopics']
}

const contractPersistConfig = {
  key: 'contract',
  storage
}

const siteLogPersistConfig = {
  key: 'siteLog',
  storage,
  whitelist: ['siteLogAnnotationTopics']
}

// Deepstream client using "singleton" pattern
let client = new DeepstreamClient(deepstreamConfig.wsUrl, deepstreamConfig.options)

// singleton pattern
// could be used with keys also to have multiple clients at the same time
const dsClient = () => {
  const clientState = client.getConnectionState()
  if (client && (clientState === 'CLOSED' || clientState === 'CLOSING')) {
    client = new DeepstreamClient(deepstreamConfig.wsUrl, deepstreamConfig.options)
    return client
  }
  return client
}

// @reduxjs/toolkit comes with handy default middlewere in dev mode, in production is thunk only
const middleware = getDefaultMiddleware({
  thunk: {
    extraArgument: dsClient
  },
  immutableCheck: false,
  serializableCheck: false
})

const store = configureStore({
  reducer: persistReducer(appPersistConfig, combineReducers({
    main: persistReducer(mainPersistConfig, reducers.main),
    auth: persistReducer(authPersistConfig, reducers.auth),
    expenses: persistReducer(expensesPersistConfig, reducers.expenses),
    employees: persistReducer(employeesPersistConfig, reducers.employees),
    harvest: persistReducer(harvestPersistConfig, reducers.harvest),
    farms: persistReducer(farmsPersistConfig, reducers.farms),
    payments: persistReducer(paymentsPersistConfig, reducers.payments),
    attendance: persistReducer(attendancePersistConfig, reducers.attendance),
    contract: persistReducer(contractPersistConfig, reducers.contract),
    siteLog: persistReducer(siteLogPersistConfig, reducers.siteLog)
  })),
  middleware
})

const persistor = persistStore(store)

export { store, persistor }
