import { createReducer } from '@reduxjs/toolkit'
import { companyEmployeesList, companyEmployeesListAltered, employeesRecords, employeesContracts,
  loading, companyContractTypesList, contractTypeRecord,
  createEmployeeResult, employeesStart, employeesRecordsBulk, employeesContractsBulk, employeesBankAccount, employeesPerDayAndFarm } from './employeesActions'

const initialState = {
  companyEmployeesList: {},
  companyEmployeesListAltered: {},
  employeeRecord: {},
  employeeContract: {},
  loading: false,
  companyContractTypesList: {},
  contractTypeRecord: {},
  createEmployeeSuccess: false,
  start: null,
  employeesBankAccount: {},
  employeesPerDayAndFarm: {}
}

const employeesReducer = createReducer(initialState, {
  [companyEmployeesList]: (state, action) => { state.companyEmployeesList[action.payload.listName] = action.payload.data },
  [companyEmployeesListAltered]: (state, action) => {
    if (action.payload.push) {
      state.companyEmployeesList[action.payload.listName] = [...state.companyEmployeesList[action.payload.listName], action.payload.data]
      if (state.companyEmployeesListAltered[action.payload.listName]) state.companyEmployeesListAltered[action.payload.listName] = [...state.companyEmployeesListAltered[action.payload.listName], action.payload.data]
      else state.companyEmployeesListAltered[action.payload.listName] = [action.payload.data]
    }
    if (action.payload.clear) {
      if (state.companyEmployeesListAltered[action.payload.listName]) {
        state.companyEmployeesList[action.payload.listName] = state.companyEmployeesList[action.payload.listName].filter((m) => state.companyEmployeesListAltered[action.payload.listName].indexOf(m) < 0)
        state.companyEmployeesListAltered[action.payload.listName] = []
      }
    }
  },
  [employeesRecords]: (state, action) => {
    state.employeeRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.employeeRecord[action.payload.recordName]
    }
  },
  [employeesBankAccount]: (state, action) => { state.employeesBankAccount[action.payload.recordName] = action.payload.data },
  [employeesRecordsBulk]: (state, action) => {
    state.employeeRecord = {
      ...state.employeeRecord,
      ...action.payload
    }
  },
  [employeesContracts]: (state, action) => {
    state.employeeContract[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.employeeContract[action.payload.recordName]
    }
  },
  [employeesContractsBulk]: (state, action) => {
    state.employeeContract = {
      ...state.employeeContract,
      ...action.payload.data
    }
  },
  [loading]: (state, action) => { state.loading = action.payload },
  [employeesStart]: (state, action) => { state.start = action.payload },
  [companyContractTypesList]: (state, action) => { state.companyContractTypesList[action.payload.listName] = action.payload.data },
  [contractTypeRecord]: (state, action) => {
    state.contractTypeRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.contractTypeRecord[action.payload.recordName]
    }
  },
  [createEmployeeResult]: (state, action) => { state.createEmployeeSuccess = action.payload },
  [employeesPerDayAndFarm]: (state, action) => { state.employeesPerDayAndFarm = action.payload }
})

export default employeesReducer
