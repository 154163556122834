import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import CardHeader from '@material-ui/core/CardHeader'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import clone from 'lodash/clone'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

const types = ['potrero', 'cuartel', 'oficina', 'bodega', 'instalacionesPersonal', 'packing', 'casetaRiego']

const initialState = {
  displayName: '',
  farm: { id: '', name: '' },
  type: '',
  id: ''
}

class SiteRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    if (!isEmpty(this.props.editData)) {
      this.setState({
        displayName: this.props.editData.name,
        farm: find(this.props.farms, (f) => f.id === this.props.editData.farmId),
        type: this.props.editData.type,
        id: this.props.editData.id })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.id || event.target.name]: event.target.value })
  }

  save = () => {
    this.props.handleCreate(this.state)
    // is this a modal ?
    if (this.props.handleClose) this.props.handleClose()
  }

  render () {
    return (
      <Card>
        <CardHeader
          title={`${this.state.id ? 'Editar Lugar' : 'Registro Lugar'}`}
        />
        <CardContent>
          <div>
            <InputLabel shrink htmlFor='age-label-placeholder'>
                Predio
            </InputLabel>
            <Select
              value={this.state.farm}
              onChange={this.onChange}
              name='farm'
              fullWidth
              disabled={!!(this.props.editData && this.props.editData.id)}
            >
              {map(this.props.farms, (value, idx) => {
                return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
              })}
            </Select>
          </div>
          <br />
          <br />
          <div>
            <InputLabel shrink htmlFor='age-label-placeholder'>
                Tipo
            </InputLabel>
            <Select
              value={this.state.type}
              onChange={this.onChange}
              name='type'
              fullWidth
            >
              {map(types, (value, idx) => {
                return <MenuItem key={idx} value={value}>{startCase(value)}</MenuItem>
              })}
            </Select>
          </div>
          <br />
          <br />

          <TextField
            id='displayName'
            label='Nombre'
            required
            fullWidth
            placeholder=''
            type='string'
            onChange={this.onChange}
            value={this.state.displayName}
          />
        </CardContent>
        <CardActions>
          <Button size='small' color='primary' onClick={this.save} disabled={!(this.state.farm.id && this.state.displayName && this.state.type)}>
              Guardar
          </Button>
        </CardActions>
      </Card>
    )
  }
}

SiteRegister.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  farms: PropTypes.array.isRequired,
  handleClose: PropTypes.func,
  editData: PropTypes.object
}

export default SiteRegister
