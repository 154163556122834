import React from 'react'
import PropTypes from 'prop-types'

import MUIDataTable from 'mui-datatables'
import memoize from 'memoize-one'

import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import find from 'lodash/find'
import keys from 'lodash/keys'
import compact from 'lodash/compact'

import DayStatsSelect from './DayStatsSelect'

const handleEmpty = (arr, id) => {
  const data = find(arr, ['id', id])
  if (!data) {
    console.error('notFound', arr, id)
    return ''
  }
  return data.name
}

const _dayStats = memoize((farms, sites, formats, qualities, harvestStats) =>
  compact(flatMap(harvestStats, (value, key) => {
    const splitKey = key.split('/')
    const farmName = handleEmpty(farms, splitKey[3])
    // return if there is no farm: ie no permission
    if (!farmName) return

    const day = splitKey[4]
    // value has {$siteId: {$cropId: {$quality: $data, employees: [$employeeIds]}}}
    return flatMap(value, (siteData, siteId) => {
      let site = find(sites, ['id', siteId]) || {}
      let siteName = site.name || ''
      // get data for given crop
      return compact(flatMap(siteData, (cropData, cropId) => {
        // employees is the key for the current employees in site, is the only key other than a cropId
        if (cropId === 'employees') return null

        return flatMap(cropData, (d, qualityId) => ({
          quality: handleEmpty(qualities, qualityId),
          format: handleEmpty(formats, `${keys(d.format)[0]}`),
          weight: map(d.weight, (v, u) => `${v} ${u}`),
          cropName: handleEmpty(site.crops, cropId),
          siteName,
          farmName,
          employees: siteData.employees.length,
          day
        }))
      }))
    })
  })))

const HarvestDayStatsCard = (props) => {
  const dayStats = _dayStats(props.farms, props.sites, props.formats, props.qualities, props.harvestStats)

  return (
    <MUIDataTable
      title={<DayStatsSelect
        harvestStatsSubscribe={props.harvestStatsSubscribe}
        harvestStatsUnsubscribe={props.harvestStatsUnsubscribe}
        harvestStatsDay={(dayStats[0] && dayStats[0].day) || ''} />}
      data={dayStats}
      columns={[
        { name: 'farmName', label: 'predio' },
        { name: 'siteName', label: 'cuartel' },
        { name: 'cropName', label: 'variedad' },
        { name: 'quality', label: 'calidad' },
        { name: 'format', label: 'formato', options: { viewColumns: false, display: 'excluded', filter: false } },
        { name: 'weight', label: 'cantidad' }
      ]}
      options={{
        responsive: 'scrollMaxHeight',
        expandableRows: false,
        selectableRows: 'none'
      }}
    />
  )
}

HarvestDayStatsCard.propTypes = {
  harvestStatsSubscribe: PropTypes.func.isRequired,
  harvestStatsUnsubscribe: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  harvestStats: PropTypes.object.isRequired
}

export default HarvestDayStatsCard
