import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import memoize from 'memoize-one'

import reduce from 'lodash/reduce'
import map from 'lodash/map'
import isArray from 'lodash/isArray'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import includes from 'lodash/includes'

import { harvestInputQrOpen, startHarvestData, setHarvestDefaults, harvestStatsSubscribe, harvestStatsUnsubscribe, computeFarmDayStats, computeCompanyStats } from './redux/harvestActions'

import HarvestComponent from './Harvest.js'

const mapDispatchToProps = dispatch => bindActionCreators({
  harvestInputQrOpen,
  startHarvestData,
  setHarvestDefaults,
  harvestStatsSubscribe,
  harvestStatsUnsubscribe,
  computeFarmDayStats,
  computeCompanyStats
}, dispatch)

const _siteCrops = memoize((siteCropRecord) => reduce(siteCropRecord, (result, value, key) => {
  key = `site/${key.split('/')[1]}`
  if (result[key] && isArray(result[key])) result[key].push(value.crop)
  else result[key] = [value.crop]
  return result
}, {}))

const _sites = memoize((userSites, siteRecord, siteCrops, cropRecord) => reduce(userSites, (result, siteId) => {
  const siteData = siteRecord[siteId]
  // show only relevant sites:
  if (siteData.type && !includes(['cuartel', 'potrero'], siteData.type)) {
    return result
  }
  const relevantSite = {
    name: siteData ? siteData.displayName : 'n/d',
    type: siteData ? siteData.type : '',
    id: siteId.split('/')[1],
    farmName: siteData ? siteData.farm : 'n/d',
    crops: reduce(siteCrops[siteId], (res, cropId) => {
      const cropData = cropRecord[`crop/${cropId}`]
      const relevantCrop = {
        name: cropData.displayName,
        id: cropId
      }
      res.push(relevantCrop)
      return res
    }, [])
  }
  result.push(relevantSite)
  return result
}, []))

const _formats = memoize((companyHarvestFormatsList, harvestFormatRecord, activeCompany) => reduce(companyHarvestFormatsList[`company/${activeCompany}/list/harvest/formats`], (result, formatId) => {
  const formatData = harvestFormatRecord[formatId]
  const relevant = {
    name: formatData ? formatData.name : '',
    capacity: formatData ? formatData.capacity : '',
    id: formatId.substring(8)
  }
  result.push(relevant)
  return result
}, []))

const _qualities = memoize((companyHarvestQualitiesList, harvestQualityRecord, activeCompany) => reduce(companyHarvestQualitiesList[`company/${activeCompany}/list/harvest/qualities`], (result, qualityId) => {
  const qualityData = harvestQualityRecord[qualityId]
  const relevant = {
    name: qualityData ? qualityData.name : '',
    id: qualityId.substring(8)
  }
  result.push(relevant)
  return result
}, []))

const _farms = memoize((userFarms, farmRecord, farmSitesList) => reduce(userFarms, (result, farm) => {
  const farmData = farmRecord[farm]
  const sites = farmSitesList[`${farm}/list/sites`]
  const relevant = {
    name: farmData ? farmData.displayName : 'n/d',
    id: farm.substring(5),
    sites
  }
  result.push(relevant)
  return result
}, []))

const _siteFarm = memoize((sites, farms) => reduce(sites, (result, site) => {
  const farmName = filter(farms, (farm) => farm.id === site.farmName)
  site.farmName = isEmpty(farmName) ? site.farmName : farmName[0].name
  result.push(site)
  return result
}, []))

const mapStateToProps = state => {
  const { activeCompany } = state.auth.clientData

  const { access } = state.main.userData

  const { harvestDefaults, harvestStats, harvestSitesDays, harvestSeasonStats, statsCompute } = state.harvest

  const days = uniq(map(harvestSitesDays, (v) => v.split('/')[1]))

  const siteCrops = _siteCrops(state.farms.siteCropRecord)

  let sites = _sites(state.farms.userSites, state.farms.siteRecord, siteCrops, state.farms.cropRecord)

  const formats = _formats(state.harvest.companyHarvestFormatsList, state.harvest.harvestFormatRecord, activeCompany)

  const qualities = _qualities(state.harvest.companyHarvestQualitiesList, state.harvest.harvestQualityRecord, activeCompany)

  const farms = _farms(state.farms.userFarms, state.farms.farmRecord, state.farms.companyFarmsSiteList)

  sites = _siteFarm(sites, farms)

  return {
    formats,
    sites,
    harvestDefaults,
    farms,
    qualities,
    access,
    harvestStats,
    days,
    harvestSeasonStats,
    statsCompute
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HarvestComponent)
