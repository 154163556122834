import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import memoize from 'memoize-one'
import moment from 'moment-timezone'

import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import lowerCase from 'lodash/lowerCase'
import filter from 'lodash/filter'
import startCase from 'lodash/startCase'
import now from 'lodash/now'
import deburr from 'lodash/deburr'
import words from 'lodash/words'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'

import HarvestTable from './HarvestTable.js'
import { daysSelector } from '../../utils'
import { makeXlsxFile } from '../FileUtils/redux/fileUtilsActions.js'

import { getHarvestData, subscribeHarvestRecord, setSelectedDayAndFarmForTable, nextLoading, subscribeToDayRecordList } from './redux/harvestActions'

const columns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'payment', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'formato', label: 'formato', options: { viewColumns: false, display: 'excluded', filter: true } },
  { name: 'calidad', label: 'calidad', options: { viewColumns: false, display: 'excluded', filter: true } }
]
const defaultColumns = ['dia', 'hora', 'rut', 'nombre', 'predio', 'cuartel', 'formato', 'calidad', 'cantidad']

// if user has access level over 3, show only his records and from today
const today = moment(now()).tz('America/Santiago').format('YYYY-MM-DD')

const _data = memoize((harvestRecords, companyId, sites, formats, farms, qualities, user, selectedDay, selectedFarm, employees) => reduce(harvestRecords, (result, harvestRecord, id) => {
  // show only records that are not deleted and from company
  // records in payment process can be shown but not modified
  if (harvestRecord.deleted || id.indexOf(companyId) < 0) {
    return result
  }

  const recordDate = moment(harvestRecord.date).tz('America/Santiago')

  if (user.access > 3) {
    if (harvestRecord.createdBy && harvestRecord.createdBy !== user.id) {
      return result
    }

    if (recordDate.format('YYYY-MM-DD') !== today) {
      return result
    }
  }

  // filter by selected day from - to
  let recordDay = id.split('/')[3]

  if (!(recordDay >= selectedDay.from && recordDay <= selectedDay.to)) {
    return result
  }

  const site = filter(sites, site => site.id === harvestRecord.site)[0]
  const format = filter(formats, (format) => format.id === harvestRecord.format.id)[0]
  const quality = filter(qualities, (q) => q.id === harvestRecord.quality)
  const farm = filter(farms, (farm) => includes(farm.sites, `site/${harvestRecord.site}`))[0]
  const employee = employees[`employee/${harvestRecord.employee}`] || { name: 'n/a', lastName: 'n/a' }

  // filter by selected farm
  if (selectedFarm.id !== 'todos' && (farm && farm.id !== selectedFarm.id)) {
    return result
  }

  let relevant = {
    dia: recordDate.format('DD-MMM'),
    hora: recordDate.format('h:mm:ss a'),
    rut: harvestRecord.employee,
    nombre: `${deburr(words(employee.name)[0])} ${deburr(employee.lastName)}`,
    cuartel: site ? site.name : '',
    formato: startCase(format ? format.name : ''),
    calidad: startCase(quality[0] ? quality[0].name : ''),
    cantidad: harvestRecord.format.quantity,
    payment: !!harvestRecord.payment,
    id: `__harvestRecordId__:${id}`
  }
  for (var key in relevant) {
    if (!includes(map(columns, (c) => c.name), key)) {
      if (!includes(map(result.columns, (c) => c.name), key)) {
        result.columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
      }
    }
  }

  result.values.push(relevant)
  return result
}, { values: [], columns: [] }))

const _columns = memoize((columns) => orderBy(columns, (o) => defaultColumns.indexOf(o.name)))

const mapStateToProps = (state, ownProps) => {
  const { selectedDay, selectedFarm, loading, todayRecordsList } = state.harvest

  const locationState = ownProps.location.state
  const sites = locationState.sites
  const formats = locationState.formats
  const qualities = locationState.qualities
  const farms = locationState.farms
  const companyId = state.auth.clientData.activeCompany
  const { access } = state.main.userData
  const { userId } = state.auth.clientData
  const { harvestSitesDays } = state.harvest
  const { employeeRecord } = state.employees

  const days = daysSelector(harvestSitesDays, farms, selectedFarm)
  if (loading) {
    return {
      sites,
      formats,
      farms,
      qualities,
      loading,
      days,
      selectedDay,
      selectedFarm,
      todayRecordsList
    }
  }

  const computedValues = _data(state.harvest.harvestRecord, companyId, sites, formats, farms, qualities, { access, id: userId }, selectedDay, selectedFarm, employeeRecord)

  return {
    data: computedValues.values,
    columns: _columns(uniq(concat(columns, computedValues.columns))),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'none',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    sites,
    formats,
    farms,
    qualities,
    loading,
    days,
    selectedDay,
    selectedFarm
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  subscribeHarvestRecord,
  getHarvestData,
  setSelectedDayAndFarmForTable,
  nextLoading,
  subscribeToDayRecordList,
  makeXlsxFile
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HarvestTable)
