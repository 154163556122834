import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import ReactToPrint from 'react-to-print'

import map from 'lodash/map'
import noop from 'lodash/noop'
import forEach from 'lodash/forEach'
import trim from 'lodash/trim'
import join from 'lodash/join'
import clone from 'lodash/clone'
import orderBy from 'lodash/orderBy'

import UserAccessComponent from '../Components/UserAccessComponent'
import EmployeeLiquidacionComponent from '../Components/EmployeeLiquidacionComponent'
import EmployeeSummaryComponent from '../Components/EmployeeSummaryComponent'
import { getTipoContrato } from '../../utils'

const styles = theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

class ActivityLiquidacionSummary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hideForPrint: false
    }
  }

  componentWillUnmount () {
    forEach(this.props.membershipIds, (membershipId) => {
      this.props.unSubscribeEmployeeLiquidacion(`payment/${this.props.companyId}/${membershipId}/liquidacion/${this.props.yearMonth}`)
    })
  }

  getPrintStyle = () => {
    return `
      @media all {
        .page-break {
          display: none;
        }
      }

      @media print {
        .page-break {
          margin-top: 1rem;
          display: block;
          page-break-after: always;
        }
      }

      @page {
        size: auto;
        margin: 0;
      }
`
  }

  computeLiquidacion = (membershipId) => {
    // compute liquidacion
    this.props.computeEmployeeLiquidacion({ yearMonth: this.props.yearMonth, membershipId }, (err) => {
      if (err) window.alert(JSON.stringify(err))
    })
  }

  downloadPlanillaLiquidaciones = (cb = noop) => {
    let fileName = `liquidaciones-${this.props.yearMonth}`
    if (this.props.query) {
      const filters = [...this.props.query.values()]
      fileName += `-${join(filters, '-')}`
    }

    const data = orderBy(map(this.props.liquidacionData, (data) => {
      const split = (data.employeeLiquidacion.contract && data.employeeLiquidacion.contract.afp) ? data.employeeLiquidacion.contract.afp.split(':') : ['sinInformacion', 'sinInformacion']
      const afp = trim(split[0])
      const tasaAfp = trim(split[1])
      const tipoContrato = getTipoContrato(data.contractTypeRecord)
      const employeeLiquidacion = clone(data.employeeLiquidacion.data)
      const otrosDescuentos = data.employeeLiquidacion.data.otrosDescuentos
      delete employeeLiquidacion.otrosDescuentos
      const { horaExtra, horaExtraAdicional, horaExtraNocturna, horaExtraControlHelada } = data.summary

      return { tipoContrato, nombre: data.employeeName.name, apellido: data.employeeName.lastName, rut: data.membershipId.split('/')[1], afp, tasaAfp, ...otrosDescuentos, ...employeeLiquidacion, horaExtra, horaExtraAdicional, horaExtraNocturna, horaExtraControlHelada }
    }), ['apellido'], 'asc')

    this.props.makeXlsxFile(fileName, [{ name: 'liquidacion', data }], ['tipoContrato', 'nombre', 'apellido', 'rut', 'diasAsistencia', 'sueldoBase', 'horaExtra', 'valorHoraExtra', 'semanaCorrida', 'gratificacion', 'totalBonosImponibles', 'totalImponible',
      'movilizacion', 'colacion', 'desgasteHerramientas', 'horaExtraAdicional', 'valorHoraExtraAdicional', 'horaExtraNocturna', 'valorHoraExtraNocturna', 'horaExtraControlHelada', 'valorHoraExtraControlHelada', 'reembolsoGasto', 'usoVehiculoParticular', 'cargasFamiliares', 'cargasFamiliaresRetroactivas', 'totalNoImponible', 'afp',
      'tasaAfp', 'descuentoAFP', 'descuentoSalud', 'descuentoSeguroCesantia', 'prestamo', 'prestamoSolidario', 'anticipo'])
  }

  render () {
    return (
      <>
        <UserAccessComponent access={3} {...this.props}>
          <Grid container spacing={24} >
            <Grid item xs={3}>
              <ReactToPrint
                onBeforeGetContent={() => { return new Promise((resolve) => this.setState({ hideForPrint: true }, () => resolve())) }}
                onAfterPrint={() => this.setState({ hideForPrint: false })}
                trigger={() => <Button variant='contained' color='primary'>Imprimir Liquidaciones</Button>}
                content={() => this.componentRef}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant='contained' color='primary' onClick={this.downloadPlanillaLiquidaciones}>Descargar planilla</Button>
            </Grid>

            <Grid item xs={3}>
              <Button variant='contained' color='primary'>Descargar planilla DT</Button>
            </Grid>

          </Grid>
        </UserAccessComponent>

        <br />
        <br />
        <div ref={el => (this.componentRef = el)}>
          <style>{this.getPrintStyle()}</style>
          {
            map(this.props.liquidacionData, (data, idx) => (
              <div key={idx}>
                <Grid container spacing={24} >
                  <Grid item xs={12}>
                    <EmployeeLiquidacionComponent {...data} hideForPrint={this.state.hideForPrint} yearMonth={this.props.yearMonth} monthText={this.props.monthText} action1={() => this.computeLiquidacion(data.membershipId)} />
                  </Grid>
                  <div className='page-break' />
                  <Grid item xs={12}>
                    <EmployeeSummaryComponent {...data} annotationTopicsToGroupBy={this.props.annotationTopicsToGroupBy} yearMonth={this.props.yearMonth} monthText={this.props.monthText} />
                  </Grid>
                </Grid>
                <div className='page-break' />
                <div style={this.state.hideForPrint ? { display: 'none' } : {}}>
                  <br />
                  <Divider />
                  <br />
                </div>
              </div>
            ))
          }
        </div>
      </>
    )
  }
}

ActivityLiquidacionSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  membershipIds: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  liquidacionData: PropTypes.array.isRequired,
  computeEmployeeLiquidacion: PropTypes.func.isRequired,
  yearMonth: PropTypes.string.isRequired,
  monthText: PropTypes.string.isRequired,
  annotationTopicsToGroupBy: PropTypes.array.isRequired,
  unSubscribeEmployeeLiquidacion: PropTypes.func.isRequired,
  makeXlsxFile: PropTypes.func.isRequired,
  query: PropTypes.object
}

export default withStyles(styles)(ActivityLiquidacionSummary)
