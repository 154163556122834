import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'

import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import take from 'lodash/take'
import filter from 'lodash/filter'
import toString from 'lodash/toString'
import toLower from 'lodash/toLower'
import deburr from 'lodash/deburr'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import difference from 'lodash/difference'

import DayFarmSelect from './DayFarmSelect'
import { appConfig } from '../../config'

const TableTitle = (props) => {
  if (props.filter.length) {
    return map(props.filter, (filter, idx) => <Chip key={idx} onDelete={props.onDelete(idx)} label={filter} />)
  }
  return <Typography variant='h6' gutterBottom >
  Listado de Cosecha
  </Typography>
}

TableTitle.propTypes = {
  filter: PropTypes.array,
  onDelete: PropTypes.func
}

class HarvestTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = { totalQ: null, totalE: null, tableData: [], tableFilter: [], tableSearchText: '' }
  }

  componentDidMount () {
    // We subscribe to current day records list and records
    this.props.subscribeToDayRecordList()
    this.computeTotals(this.props.data)
    this.setTableData()
  }

  componentDidUpdate (prevProps) {
    if (this.props.selectedDay.to && this.props.selectedDay.from && (!isEqual(prevProps.selectedDay, this.props.selectedDay) || !isEqual(prevProps.selectedFarm, this.props.selectedFarm))) {
      this.props.getHarvestData(() => {
        // Call subscribe again in case a list wasn't previously defined
        this.props.subscribeToDayRecordList()
        this.setTableData()
        this.computeTotals(this.props.data)
        this.setState({ tableFilter: [], tableSearchText: '' })
      })
    }

    if (!isEqual(prevProps.data, this.props.data)) {
      this.applyFilter(this.state.tableFilter)
    }
  }

  componentWillUnmount () {
    this.props.nextLoading(false)
  }

  setTableData = () => {
    this.setState({ tableData: take(this.props.data, appConfig.maxTableRows) })
    this.computeTotals(this.props.data)
  }

  onCellClick = (data, meta) => {
    let id = this.state.tableData[meta.dataIndex].id.split(':')[1]
    this.props.nextLoading(true).then(() => {
      // subscribe to harvest record first
      this.props.subscribeHarvestRecord(id, () => {
        this.props.history.push(`/app/harvest/view`, { id, sites: this.props.sites, formats: this.props.formats, farms: this.props.farms, qualities: this.props.qualities })
      })
    })
  }

  applyFilter = (filtros) => {
    if (filtros.length === 0) {
      this.setTableData()
      this.setState({ tableFilter: [] })
      return
    }
    const data = filter(this.props.data, (value) => {
      const valueMap = map(value, (v) => v)
      return difference(filtros, valueMap).length === 0
    })

    this.setState({ tableData: data, tableFilter: filtros }, () => {
      this.computeTotals(data)
    })
  }

  onTableChange = (action, state) => {
    if (action === 'filterChange') {
      const filtros = concat(compact(map(state.filterList, (f) => f[0])), this.state.tableFilter)
      this.applyFilter(filtros)
    }
  }

  onFilterChange = (a, b, type, d, e) => {
    // TODO: handle when All is selected from dropdown
    if (type === 'reset') {
      this.applyFilter([])
    }
  }

  onFilterDelete = idx => () => {
    const filtros = filter(this.state.tableFilter, (val, i) => i !== idx)
    this.applyFilter(filtros)
  }

  computeTotals = (data = []) => {
    if (data.length === 0) return

    const total = reduce(data, (result, value) => {
      result.totalQ += value['cantidad']
      if (!includes(result.totalE, value['rut'])) {
        result.totalE.push(value['rut'])
      }
      return result
    }, { totalQ: 0, totalE: [] })

    this.setState({ totalQ: total.totalQ, totalE: total.totalE.length })
  }

  doSearch = (searchText) => {
    const filtered = filter(this.props.data, (data) => {
      return reduce(data, (res, val) => {
        const test = deburr(toLower(toString(val))).indexOf(deburr(toLower(searchText))) > -1
        if (test) res = true
        return res
      }, false)
    })
    this.setState({ tableData: filtered, tableSearchText: searchText }, () => {
      this.computeTotals(filtered)
    })
  }

  // delay search invocation by 500 ms
  debouncedSearch = debounce(this.doSearch, 500, { trailing: true })

  onSearchChange = (searchText) => {
    if (!searchText) {
      this.setTableData()
      this.setState({ tableSearchText: searchText })
      return
    }
    if (searchText.length > 3) {
      this.debouncedSearch(searchText)
    } else {
      this.debouncedSearch.cancel()
      this.setState({ tableSearchText: searchText })
    }
  }

  render () {
    return (
      <div>
        <DayFarmSelect
          days={this.props.days}
          farms={this.props.farms}
          selectedDay={this.props.selectedDay}
          selectedFarm={this.props.selectedFarm || { name: '', id: '' }}
          setSelectedDayAndFarmForTable={this.props.setSelectedDayAndFarmForTable}
          nextLoading={this.props.nextLoading}
          loading={this.props.loading}
        />

        {this.props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <div>
            {
              this.props.selectedDay.from && this.props.selectedDay.to && this.props.selectedFarm.id
                ? <div>
                  <MUIDataTable
                    title={<TableTitle filter={this.state.tableFilter} onDelete={this.onFilterDelete} />}
                    data={this.state.tableData}
                    columns={this.props.columns}
                    options={{
                      ...this.props.options,
                      searchText: this.state.tableSearchText,
                      onCellClick: this.onCellClick,
                      onTableChange: this.onTableChange,
                      onTableInit: this.onTableChange,
                      onFilterChange: this.onFilterChange,
                      onSearchChange: this.onSearchChange,
                      onDownload: (buildHead, buildBody, columns, values) => {
                        const json = reduce(values, (result, val) => {
                          const temp = {}
                          forEach(val.data, (v, idx) => {
                            // do not include these values
                            if (!includes(['id'], columns[idx].name)) {
                              temp[columns[idx].name] = v
                            }
                          })
                          result.push(temp)
                          return result
                        }, [])

                        const fileName = `registros-${this.props.selectedFarm.name}-${this.props.selectedDay.from}-al-${this.props.selectedDay.to}`
                        this.props.makeXlsxFile(fileName, [{ data: json, name: 'data' }], ['rut', 'nombre', 'apellido'])
                        // cancel default  CSV download from table
                        return false
                      }

                    }}
                  />
                  <br />

                  <Card style={{ maxWidth: '50%' }}>
                    <CardHeader
                      title='Totales'
                    />
                    <CardContent>
                      <Typography component='h3'>
                        Cajas cosechadas {this.state.totalQ}
                      </Typography>
                      <Typography component='h3'>
                        Cosecheros {this.state.totalE}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                : <></>
            }
          </div>
        }
      </div>
    )
  }
}

HarvestTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array,
  sites: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  options: PropTypes.object,
  history: PropTypes.object.isRequired,
  subscribeHarvestRecord: PropTypes.func.isRequired,
  selectedDay: PropTypes.object,
  selectedFarm: PropTypes.object,
  days: PropTypes.array,
  setSelectedDayAndFarmForTable: PropTypes.func,
  nextLoading: PropTypes.func,
  getHarvestData: PropTypes.func,
  subscribeToDayRecordList: PropTypes.func,
  makeXlsxFile: PropTypes.func
}

export default withRouter(HarvestTable)
