import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import HarvestIcon from '@material-ui/icons/Grain'

import Dashboard from './app/Dashboard/Dashboard'

// expenses
// import Expenses from './app/Expenses/ExpensesContainer'
// import ExpensesTable from './app/Expenses/ExpensesTableContainer'
// import ExpensesView from './app/Expenses/ExpensesViewContainer'
// import ExpensesAccounts from './app/Expenses/ExpensesAccountsContainer'

// employees
import EmployeesDashboard from './app/Employees/EmployeesContainer'
import EmployeesTable from './app/Employees/EmployeesTableContainer'
import EmployeesAbsentTable from './app/Employees/EmployeesAbsentTableContainer'
import EmployeesRegisterContainer from './app/Employees/EmployeesRegisterContainer'
import EmployeesView from './app/Employees/EmployeesViewContainer'
import EmployeesViewContractContainer from './app/Employees/EmployeesViewContractContainer'
import EmployeesQrCodes from './app/Employees/EmployeesQrCodesContainer'

// contract, included in employee menu
import ContractTypeContainer from './app/Contract/ContractTypeContainer'
import ContractSignTableContainer from './app/Contract/ContractSignTableContainer'

// activity, included in employees menu
import ActivityTable from './app/ActivityLog/ActivityTableContainer'
import ActivityTableSummary from './app/ActivityLog/ActivityTableSummaryContainer'
import ActivityDayView from './app/ActivityLog/ActivityDayViewContainer'
import ActivityLiquidacionSummary from './app/ActivityLog/ActivityLiquidacionSummaryContainer'

// harvest
import Harvest from './app/Harvest/HarvestContainer'
import HarvestInput from './app/Harvest/HarvestInputContainer'
import HarvestTable from './app/Harvest/HarvestTableContainer'
import HarvestView from './app/Harvest/HarvestViewContainer'
import HarvestSummary from './app/Harvest/HarvestSummaryContainer'
import HarvestSummaryView from './app/Harvest/HarvestSummaryViewContainer'
import HarvestValidateDay from './app/Harvest/HarvestValidateDayContainer'
import HarvestPayments from './app/Harvest/HarvestPaymentsContainer'

// payments
import Payments from './app/Payments/PaymentsContainer'
import PaymentView from './app/Payments/PaymentViewContainer'

// ROUTES
const routes = [
  {
    path: '/app',
    name: 'Inicio',
    icon: DashboardIcon,
    component: Dashboard,
    access: 99
  },
  // {
  //   path: '/app/expenses',
  //   name: 'Compras',
  //   icon: CreditCardIcon,
  //   component: Expenses,
  //   access: '',
  //   subRoutes: [
  //     {
  //       path: '/app/expenses/table',
  //       name: 'Table',
  //       component: ExpensesTable,
  //       access: ''
  //     },
  //     {
  //       path: '/app/expenses/accounts',
  //       name: 'Accounts',
  //       component: ExpensesAccounts,
  //       access: ''
  //     },
  //     {
  //       path: '/app/expenses/view',
  //       name: 'expensesView',
  //       component: ExpensesView,
  //       access: ''
  //     }
  //   ]
  // },
  {
    path: '/app/payments',
    name: 'Pagos',
    icon: CreditCardIcon,
    component: Payments,
    access: 3,
    subRoutes: [
      {
        path: '/app/payments/view',
        name: 'PaymentsView',
        component: PaymentView,
        access: 2
      }
    ]
  },
  {
    path: '/app/employees',
    name: 'Trabajadores',
    icon: PeopleIcon,
    component: EmployeesDashboard,
    access: 99,
    subRoutes: [
      {
        path: '/app/employees/register',
        name: 'Register',
        component: EmployeesRegisterContainer.EmployeesRegister,
        access: 4
      },
      {
        path: '/app/employees/register/payment-method',
        name: 'RegisterPaymentMethod',
        component: EmployeesRegisterContainer.EmployeesRegisterPaymentMethod,
        access: 4
      },
      // DISABLED
      // {
      //   path: '/app/employees/register/extras',
      //   name: 'RegisterExtras',
      //   component: EmployeesRegisterContainer.EmployeesRegisterExtras,
      //   access: ''
      // },
      {
        path: '/app/employees/register/contract',
        name: 'RegisterContract',
        component: EmployeesRegisterContainer.EmployeesRegisterContract,
        access: 4
      },
      {
        path: '/app/employees/table',
        name: 'table',
        component: EmployeesTable,
        access: 99
      },
      {
        path: '/app/employees/absent',
        name: 'absentTable',
        component: EmployeesAbsentTable,
        access: 99
      },
      {
        path: '/app/employees/view',
        name: 'employeesView',
        component: EmployeesView,
        access: 99
      },
      {
        path: '/app/employees/contracts',
        name: 'employeesViewContract',
        component: EmployeesViewContractContainer.EmployeesViewAllContracts,
        access: 4
      },
      {
        path: '/app/employees/edit/employee',
        name: 'employeesEdit',
        component: EmployeesRegisterContainer.EmployeesRegister,
        access: 4
      },
      {
        path: '/app/employees/edit/extras',
        name: 'Register',
        component: EmployeesRegisterContainer.EmployeesRegisterExtras,
        access: 4
      },
      {
        path: '/app/employees/edit/contract',
        name: 'employeesEdit',
        component: EmployeesRegisterContainer.EmployeesRegisterContract,
        access: 4
      },
      {
        path: '/app/employees/qrcodes',
        name: 'employeesQrCodes',
        component: EmployeesQrCodes,
        access: 99
      },
      // Contract routes
      {
        path: '/app/contract/type',
        name: 'contractTypeRegister',
        component: ContractTypeContainer.ContractTypeRegister,
        access: 3
      },
      {
        path: '/app/contract/type/table',
        name: 'contractTypeTable',
        component: ContractTypeContainer.ContractTypeTable,
        access: 4
      },
      {
        path: '/app/contract/sign/table',
        name: 'contractSignTable',
        component: ContractSignTableContainer,
        access: 4
      },

      // Activity routes
      {
        path: '/app/activity/table',
        name: 'activityTable',
        component: ActivityTable,
        access: 4
      },
      {
        path: '/app/activity/day',
        name: 'activityDayView',
        component: ActivityDayView,
        access: 4
      },
      {
        path: '/app/activity/summary',
        name: 'activityTableSummary',
        component: ActivityTableSummary,
        access: 4
      },
      {
        path: '/app/activity/summary/view',
        name: 'activitySummaryView',
        component: ActivityDayView,
        access: 4
      },
      {
        path: '/app/activity/summary/liquidacion',
        name: 'activityLiquidacionSummary',
        component: ActivityLiquidacionSummary,
        access: 3
      }
    ]
  },
  {
    path: '/app/harvest',
    name: 'Cosecha',
    icon: HarvestIcon,
    component: Harvest,
    access: 5,
    subRoutes: [
      {
        path: '/app/harvest/input',
        name: 'harvestInput',
        component: HarvestInput,
        access: 5
      },
      {
        path: '/app/harvest/table',
        name: 'harvestTable',
        component: HarvestTable,
        access: 5
      },
      {
        path: '/app/harvest/view',
        name: 'harvestView',
        component: HarvestView,
        access: 5
      },
      {
        path: '/app/harvest/edit',
        name: 'harvestEdit',
        component: HarvestInput,
        access: 4
      },
      {
        path: '/app/harvest/summary',
        name: 'harvestSummary',
        component: HarvestSummary,
        access: 5
      },
      {
        path: '/app/harvest/summary/view',
        name: 'harvestSummaryView',
        component: HarvestSummaryView,
        access: 5
      },
      {
        path: '/app/harvest/validate-day',
        name: 'HarvestValidateDay',
        component: HarvestValidateDay,
        access: 4
      },
      {
        path: '/app/harvest/payments',
        name: 'harvestPayments',
        component: HarvestPayments,
        access: 4
      }
    ]
  }
]

export default routes
