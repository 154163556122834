import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import memoize from 'memoize-one'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { harvestInputQrOpen, createHarvestRecord, modifyPendingHarvestRecord, getPendingRecords, modifyHarvestRecord, setHarvestDefaults } from './redux/harvestActions'

import HarvestInput from './HarvestInput.js'

const mapDispatchToProps = dispatch => bindActionCreators({
  harvestInputQrOpen,
  createHarvestRecord,
  modifyPendingHarvestRecord,
  getPendingRecords,
  modifyHarvestRecord,
  setHarvestDefaults
}, dispatch)

const _employees = memoize((data) => map(data, (v, id) => id.split('/')[1]))

const _harvestRecords = memoize((companyId, harvestRecords) => reduce(harvestRecords, (res, rec, id) => {
  if (id.indexOf(companyId) > -1) {
    res[id] = rec
  }
  return res
}, {}))

const mapStateToProps = (state, ownProps) => {
  const locationState = ownProps.location.state

  const sites = locationState.sites
  const formats = locationState.formats
  const qualities = locationState.qualities
  const companyId = state.auth.clientData.activeCompany
  const userAccess = state.main.userData.access
  const employeeIds = _employees(state.employees.employeeContract)
  const harvestRecords = _harvestRecords(companyId, state.harvest.harvestRecord)
  const { harvestDefaults } = state.harvest
  const { connectionState } = state.main

  return {
    open: state.harvest.harvestInputQrOpen,
    defaultQuantity: state.harvest.harvestDefaults.defaultQuantity || 4,
    formats,
    defaultFormat: state.harvest.harvestDefaults.defaultFormat,
    sites,
    defaultSite: state.harvest.harvestDefaults.defaultSite,
    defaultSiteCrop: state.harvest.harvestDefaults.defaultSiteCrop,
    defaultQuality: state.harvest.harvestDefaults.defaultQuality,
    qualities,
    employeeIds,
    harvestRecords,
    userAccess,
    connectionState,
    harvestDefaults
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HarvestInput)
