const wsUrl = 'wss://staging.campocontrol.com:6020/deepstream'
const httpUrl = 'https://staging.campocontrol.com:6020/api'

module.exports = {
  env: 'staging',
  deepstreamConfig: {
    wsUrl,
    httpUrl,
    options: {
      maxReconnectAttempts: 1000,
      subscriptionTimeout: 3000
    }
  },
  appConfig: {
    maxHarvestRecordDeleteTime: 72 * 24 * 3600 * 1000,
    maxTableRows: 200,
    downloadUrlPrefix: 'https://campocontrol-company-files-dev.nyc3.digitaloceanspaces.com/',
    dispatchBatchInterval: 100
  },
  apkUrl: 'https://public-campocontrol.nyc3.digitaloceanspaces.com/campocontrol-staging-signed.apk',
  paymentMethods: ['cuentaRut', 'cuentaVista', 'cuentaCorriente'],
  pensionSystems: ['uno', 'capital', 'cuprum', 'habitat', 'modelo', 'planVital', 'provida', 'inp', 'noCotiza'],
  healthInsurances: ['fonasa', 'banmedica', 'colmena', 'consalud', 'cruzBlanca', 'masVida', 'vidaTres'],
  chileBanks: ['bancoSecurity', 'bancoItauChile', 'bancoParis', 'bancoSantander', 'bbva', 'scotiabank', 'bancoEstado', 'bancoDeChile', 'bancoBice', 'bci', 'bancoRipley', 'bancoFalabella'],
  paymentClass: [
    { type: 'liquido', name: 'sueldo' },
    { type: 'bonoImponible', name: 'bonoProductividad' },
    { type: 'bonoNoImponible', name: 'colacion' },
    { type: 'bonoNoImponible', name: 'movilizacion' },
    { type: 'bonoNoImponible', name: 'desgasteHerramientas' },
    { type: 'bonoNoImponible', name: 'finiquito' },
    { type: 'bonoNoImponible', name: 'agnosServicio' },
    { type: 'bonoNoImponible', name: 'vacacionesProporcionales' }
  ],
  translation: {
    es: {
      beginMonth: 'mes',
      beginYear: 'año',
      month: 'mes',
      year: 'año',
      day: 'dia',
      beginDay: 'dia',
      cash: 'efectivo',
      bankAccount: 'cuentaBancaria',
      othersBankAccount: 'cuentaBancariaDeTerceros'
    }
  }
}
