import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import map from 'lodash/map'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import orderBy from 'lodash/orderBy'

const employeeRecordKeys = ['rut', 'nombre', 'apellido']
const knownKeys = concat(['summary', 'payed', 'payedSummary', 'inPayment', 'inPaymentSummary', 'harvestRecords', 'id'], employeeRecordKeys)

const HarvestSummaryView = (props) => {
  return (
    <div>
      <Card>
        <CardHeader
          title='Resumen cosecha'
        />
        <CardContent>
          <Typography variant='h6' gutterBottom>
            Datos cosechero
          </Typography>
          {map(props.employeeHarvestSummary, (value, key) => {
            if (value && includes(employeeRecordKeys, key)) {
              return <Typography variant='body1' gutterBottom key={key}>
                {startCase(key)}: {value}
              </Typography>
            }
          })}

          <Typography variant='h6' gutterBottom>
            Totales cosechados por pagar
          </Typography>
          {map(props.employeeHarvestSummary, (value, key) => {
            if (value && !includes(knownKeys, key)) {
              return <Typography variant='body1' gutterBottom key={key}>
                {startCase(key)}: {value}
              </Typography>
            }
          })}

          <Typography variant='h6' gutterBottom>
            Totales cosechados en proceso de pago
          </Typography>
          {map(props.employeeHarvestSummary.inPayment, (value, key) => {
            if (value && !includes(knownKeys, key)) {
              return <Typography variant='body1' gutterBottom key={key}>
                {startCase(key)}: {value}
              </Typography>
            }
          })}

          <Typography variant='h6' gutterBottom>
            Totales cosechados pagados
          </Typography>
          {map(props.employeeHarvestSummary.payed, (value, key) => {
            if (value && !includes(knownKeys, key)) {
              return <Typography variant='body1' gutterBottom key={key}>
                {startCase(key)}: {value}
              </Typography>
            }
          })}
        </CardContent>
      </Card>
      <br />

      <Card>
        <CardHeader
          title='Entregas por pagar'
        />
        <CardContent>
          <List>
            {
              map(orderBy(props.employeeHarvestSummary.harvestRecords.nonPayment, (val) => val.fecha, ['desc']), (record, key) =>
                <React.Fragment key={key}>
                  <ListItem>
                    <ListItemText
                      primary={`${record.fecha} / ${record.formato} - ${record.calidad} : ${record.cantidad}`}
                      secondary={`${record.predio} / ${record.cuartel}`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            }
          </List>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardHeader
          title='Entregas en proceso de pago'
        />
        <CardContent>
          <List>
            {
              map(orderBy(props.employeeHarvestSummary.harvestRecords.inPayment, (val) => val.fecha, ['desc']), (record, key) =>
                <React.Fragment key={key}>
                  <ListItem>
                    <ListItemText
                      primary={`${record.fecha} / ${record.formato} - ${record.calidad} : ${record.cantidad}`}
                      secondary={`${record.predio} / ${record.cuartel}`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            }
          </List>
        </CardContent>
      </Card>

      <br />
      <Card>
        <CardHeader
          title='Entregas pagadas'
        />
        <CardContent>
          <List>
            {
              map(orderBy(props.employeeHarvestSummary.harvestRecords.payed, (val) => val.fecha, ['desc']), (record, key) =>
                <React.Fragment key={key}>
                  <ListItem>
                    <ListItemText
                      primary={`${record.fecha} / ${record.formato} - ${record.calidad} : ${record.cantidad}`}
                      secondary={`${record.predio} / ${record.cuartel}`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            }
          </List>
        </CardContent>
      </Card>

      <br />
      <Card>
        <CardHeader
          title='Entregas pendientes de registro'
        />
        <CardContent>
          <List>
            {
              map(orderBy(props.employeeHarvestSummary.harvestRecords.pending, (val) => val.fecha, ['desc']), (record, key) =>
                <React.Fragment key={key}>
                  <ListItem>
                    <ListItemText
                      primary={`${record.fecha} / ${record.formato} - ${record.calidad} : ${record.cantidad}`}
                      secondary={`${record.predio} / ${record.cuartel}`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            }
          </List>
        </CardContent>
      </Card>

    </div>
  )
}

HarvestSummaryView.propTypes = {
  employeeHarvestSummary: PropTypes.object.isRequired
}

export default HarvestSummaryView
