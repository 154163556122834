import { connect } from 'react-redux'

import reduce from 'lodash/reduce'
import memoize from 'memoize-one'

import _EmployeesViewAllContracts from './EmployeesViewAllContracts.js'

const _userAccess = memoize((userMemberships, companyId) => reduce(userMemberships, (result, value) => {
  if (value.company === companyId) result = value.access
  return result
}, 99))

const mapStateToProps = (state, ownProps) => {
  const { activeCompany } = state.auth.clientData
  const { employeeId, allEmployeeContracts } = ownProps.location.state
  const employeeRecord = state.employees.employeeRecord[`employee/${employeeId}`] || {}
  const employeeCurrentContract = state.employees.employeeContract[`employee/${employeeId}`] || {}
  const employeeAccounts = state.employees.employeesBankAccount[`employee/${employeeId}`] || []
  const employeeContractTypes = state.employees.contractTypeRecord
  const farmRecords = state.farms.farmRecord
  const userAccess = _userAccess(state.main.userMemberships, activeCompany)

  return { employeeId, employeeRecord, employeeCurrentContract, allEmployeeContracts, employeeContractTypes, farmRecords, userAccess, employeeAccounts }
}
const EmployeesViewAllContracts = connect(mapStateToProps, null)(_EmployeesViewAllContracts)

export default { EmployeesViewAllContracts }
