import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import ReactToPrint from 'react-to-print'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

import map from 'lodash/map'
import includes from 'lodash/includes'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  gridList: {
    width: 800
  },
  gridListTile: {
    marginTop: '20px',
    textAlign: 'center'
  },
  gridListTile2: {
    marginTop: '110px',
    textAlign: 'center'
  }
})

class EmployeesQrCodes extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button color='primary'>Imprimir</Button>}
          content={() => this.componentRef}
        />
        <Printable classes={classes} employees={this.props.employees} employeeIds={this.props.employeeIds} ref={el => (this.componentRef = el)} />
      </div>
    )
  }
}

class Printable extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <GridList cellHeight={300} cols={4} className={classes.gridList}>
          {
            map(this.props.employeeIds, (employeeId, key) =>
              <GridListTile className={(key > 5 && includes([1, 2, 3, 4], (key + 1) % 12)) ? classes.gridListTile2 : classes.gridListTile} key={key}>
                <Typography variant='h5' gutterBottom component='h5'>
                  {employeeId}
                </Typography>
                <Typography variant='h6' gutterBottom component='h6' style={{ height: '80px' }}>
                  {
                    this.props.employees[`employee/${employeeId}`]
                      ? `${this.props.employees[`employee/${employeeId}`].name} ${this.props.employees[`employee/${employeeId}`].lastName}` : <></>
                  }
                </Typography>
                <QRCode value={employeeId} renderAs={'svg'} />

                <Divider />
              </GridListTile>)
          }
        </GridList>
      </div>
    )
  }
}

EmployeesQrCodes.propTypes = {
  employeeIds: PropTypes.array.isRequired,
  employees: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

Printable.propTypes = {
  employeeIds: PropTypes.array.isRequired,
  employees: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EmployeesQrCodes)
