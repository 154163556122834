import { createAction } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import clone from 'lodash/clone'
import noop from 'lodash/noop'
import { mainStart, userMembershipList, userMemberships, userAccess } from './../../Main/redux/mainActions'
import { resetLocalVariables } from './../../Main/redux/mainActionsRecords'
import { farmReset } from './../../Farms/redux/farmsActions'
import { harvestStart } from './../../Harvest/redux/harvestActions'
import { employeesStart } from './../../Employees/redux/employeesActions'

/*
  Login
 */
export const loginRequest = createAction('auth/loginRequest')
export const loginResponse = createAction('auth/loginResponse')

export const login = (credentials, cb = noop) => (dispatch, getState, dsClient) => {
  let authCredentials = credentials
  if (!isEmpty(authCredentials)) {
    dispatch(loginRequest(authCredentials))
  } else {
    // get credentials from state
    authCredentials = clone(getState().auth.credentials) || {}
  }
  dsClient().login(authCredentials, (success, clientData) => {
    if (!success) {
      window.alert('usuario o contraseña incorrecto')
      cb()
    } else {
      dispatch(loginResponse({ isAuthenticated: success, clientData }))
    }
  })
  // catch too many auth attempts error
  dsClient().once('error', (err) => {
    // TODO: reload page or client?
    console.error(err)
  })
}

/*
  Logout
 */

export const logoutRequest = createAction('auth/logoutRequest')

export const logout = () => (dispatch, getState, dsClient) => {
  dsClient().close()
  // clear all state indicators
  dispatch(resetLocalVariables())
  dispatch(mainStart(true))
  dispatch(userAccess(99))
  dispatch(farmReset())
  dispatch(userMembershipList({ data: [] }))
  dispatch(userMemberships({ removeAll: true }))
  dispatch(harvestStart({ company: null, date: null }))
  dispatch(employeesStart(null))
  dispatch(logoutRequest())
}

/*
  Change active Company
 */

export const changeActiveCompanyRequest = createAction('auth/changeActiveCompanyRequest')

export const changeActiveCompany = (activeCompany) => (dispatch, getState, dsClient) => {
  let authCredentials = clone(getState().auth.credentials) || {}
  if (!isEmpty(authCredentials)) {
    authCredentials.activeCompany = activeCompany
    dsClient().close()
    // timeout due to client being closed
    setTimeout(function () {
      dispatch(login(authCredentials))
    }, 0)
    return
  }
  console.error('empty authCredentials')
}
