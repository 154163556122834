import React from 'react'
import Typography from '@material-ui/core/Typography'

const Dashboard = () => {
  return (
    <div>
      <Typography variant='h4' gutterBottom component='h2'>
        Bienvenidos
      </Typography>
    </div>
  )
}

export default Dashboard
