import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import clone from 'lodash/clone'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'

import { addPendingRequest } from './mainActionsPendingRequests'

// list of rpcNames that are not required to be added to pending rpc calls
const nonPendingRpc = ['harvest/record/get/days', 'harvest/record/get/sitesDays']

const validateRecord = (recordName, path, data, cb) => {
  if (!isString(recordName)) return false
  if (!path && !isObject(data)) return false
  if (!isFunction(cb)) return false
  return true
}

/**
 * [writeRecord description]
 * @param  {[type]}   recordName  [description]
 * @param  {[type]}   [path=null] [description]
 * @param  {[type]}   data        [description]
 * @param  {Function} cb          [description]
 * @return {[type]}               [description]
 */
export const writeRecord = (recordName, path = null, data, cb) => (dispatch, getState, dsClient) => {
  if (isObject(path)) {
    cb = data
    data = clone(path)
    path = null
  }

  if (!validateRecord(recordName, path, data, cb)) {
    if (cb) return cb(new Error(400))
    return
  }
  let connectionState = getState().main.connectionState

  // add to pendingRequests if not open connection or offline
  if (connectionState !== 'OPEN' || !navigator.onLine) {
    let body = {
      'topic': 'record',
      'action': 'write',
      recordName,
      data
    }
    if (path) body.path = path
    dispatch(addPendingRequest(body))
    return cb(null, 202)
  }
  // OPEN Connection
  dsClient().record.setData(recordName, path, data, (err) => {
    if (err) {
      // NOTE: on failed permission, this error will not be triggered, but the dsClient error wll be called with the record name
      return cb(err)
    }
    return cb(null, 200)
  })
}

const validateRpc = (rpcName, data, cb) => {
  if (!isString(rpcName)) return false
  if (!isObject(data)) return false
  if (!isFunction(cb)) return false
  return true
}

/**
 * [makeRpc description]
 * @param  {[type]}   rpcName [description]
 * @param  {[type]}   data    [description]
 * @param  {Function} cb      [description]
 * @return {[type]}           [description]
 */
export const makeRpc = (rpcName, data, cb) => (dispatch, getState, dsClient) => {
  if (!validateRpc(rpcName, data, cb)) {
    if (cb) return cb(new Error(400))
    return
  }
  let connectionState = getState().main.connectionState

  // add to pendingRequests if not open connection or offline
  if (connectionState !== 'OPEN' || !navigator.onLine) {
    // do we need to add it to pendings ?
    if (includes(nonPendingRpc, rpcName)) {
      return cb(null, 202)
    }

    dispatch(addPendingRequest(
      {
        'topic': 'rpc',
        'action': 'make',
        rpcName,
        data
      }))
    return cb(null, 202)
  }
  // OPEN Connection
  dsClient().rpc.make(rpcName, data, (err, res) => {
    if (err) {
      // NOTE: on failed permission or if there is no RPC provider this error will be called
      console.error('rpcError', rpcName, err)
      return cb(err)
    }
    console.log('rpcSuccess', rpcName)
    return cb(null, res)
  })
}

/**
 * Events
 *
 */
const eventSubscriptions = {}

/**
 * [eventSubscribe]
 * @param  {string} eventName     event to subscribe to
 * @param  {function} eventCallback object with name and function properties. Minifier removes named functions so that doesn't work
 * @return {void}
 */
export const eventSubscribe = (eventName, eventCallback) => (dispatch, getState, dsClient) => {
  if (!eventCallback.name || !eventCallback.function) {
    console.error('eventCallback', 'must be object with name and function keys')
    return
  }
  if (eventSubscriptions[`${eventName}-${eventCallback.name}`]) {
    console.log('already subscribed to ', eventName, ' with function ', eventCallback.name)
    return
  }
  eventSubscriptions[`${eventName}-${eventCallback.name}`] = true
  dsClient().event.subscribe(eventName, eventCallback.function)
}

/**
 * [eventUnsubscribe description]
 * @param  {string} eventName            [description]
 * @param  {function|null} [eventCallback=null] [description]
 * @return {void}                      [description]
 */
export const eventUnsubscribe = (eventName, eventCallback = null) => (dispatch, getState, dsClient) => {
  if (!eventCallback) {
    // unsubscribe from all
    dsClient().event.unsubscribe(eventName)
    // remove functions
    for (var key in eventSubscriptions) {
      if (startsWith(key, eventName)) {
        delete eventSubscriptions[key]
      }
    }
    return
  }

  if (!eventSubscriptions[`${eventName}-${eventCallback.name}`]) {
    console.log('not subscribed to ', eventName, ' with function ', eventCallback.name)
    return
  }
  // unsubscribe from event
  delete eventSubscriptions[`${eventName}-${eventCallback.name}`]
  dsClient().event.unsubscribe(eventName, eventCallback.function)
}
