import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import clone from 'lodash/clone'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'

import HarvestViewComponent from './HarvestView.js'

import { deletePendingHarvestRecord, getPendingRecords, deleteHarvestRecord } from './redux/harvestActions'

const mapDispatchToProps = dispatch => bindActionCreators({
  deletePendingHarvestRecord,
  getPendingRecords,
  deleteHarvestRecord
}, dispatch)

const mapStateToProps = (state, ownProps) => {
  const { id, sites, formats, farms, qualities } = ownProps.location.state

  const harvestRecord = clone(state.harvest.harvestRecord[id]) || {}

  const { access } = state.main.userData

  let employeeRecord = null

  if (!isEmpty(harvestRecord)) {
    const site = filter(sites, site => site.id === harvestRecord.site)[0]
    const farm = filter(farms, (farm) => includes(farm.sites, `site/${harvestRecord.site}`))[0]
    harvestRecord.siteName = site ? site.name : 'n/d'
    harvestRecord.farmName = farm ? farm.name : 'n/d'
    harvestRecord.formatName = startCase(filter(formats, (format) => format.id === harvestRecord.format.id)[0].name)
    harvestRecord.qualityName = startCase(filter(qualities, (q) => q.id === harvestRecord.quality)[0].name)
    harvestRecord.quantity = harvestRecord.format.quantity
    const crop = filter(site.crops, (crop) => crop.id === harvestRecord.crop)
    harvestRecord.cropName = isEmpty(crop) ? 'n/d' : crop[0].name
    employeeRecord = state.employees.employeeRecord[`employee/${harvestRecord.employee}`] || {}
  }

  return { harvestRecordName: id, harvestRecord, employeeRecord, sites, formats, qualities, userAccess: access }
}

export default connect(mapStateToProps, mapDispatchToProps)(HarvestViewComponent)
