import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import CardHeader from '@material-ui/core/CardHeader'
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  name: '',
  description: '',
  reference: '',
  validFrom: '',
  amountValue: 0,
  id: ''
}

class LabourRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    if (!isEmpty(this.props.editData)) {
      this.setState({
        name: this.props.editData.name,
        description: this.props.editData.description,
        id: this.props.editData.id
      })
      // TODO: amount value and valid from
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.id]: event.target.value })
  }

  save = () => {
    this.props.handleCreate(this.state)
    // is this a modal ?
    if (this.props.handleClose) this.props.handleClose()
  }

  render () {
    return (
      <Card>
        <CardHeader
          title={`${this.state.id ? 'Editar Labor' : 'Registro Labor'}`}
        />
        <CardContent>
          <TextField
            id='name'
            label='Nombre Labor'
            required
            fullWidth
            type='string'
            onChange={this.onChange}
            value={this.state.name}
          />
          <br />
          <br />
          {/* NOTE: hid description for simplicity, name should suffice */}
          {/* <TextField
            id='description'
            label='Descripcion'
            multiline
            rowsMax='4'
            fullWidth
            margin='normal'
            onChange={this.onChange}
            value={this.state.description}
          />
          <br />
          <br /> */}
          {/* TODO: implement this logic */}
          {this.props.userAccess < 3
            ? <>
              <TextField
                id='amountValue'
                label='Valor'
                fullWidth
                type='string'
                onChange={this.onChange}
                value={this.state.amountValue}
              />
              <br />
              <br />
              <TextField
                id='validFrom'
                label='Valor Desde'
                InputLabelProps={{
                  shrink: true
                }}
                type='date'
                onChange={this.onChange}
                value={this.state.validFrom}
              />
              <br />
              <br />
              {/* TODO: esto es un array de otros recordNames */}
              <TextField
                id='reference'
                label='Referencia'
                InputLabelProps={{
                  shrink: true
                }}
                type='text'
                onChange={this.onChange}
                value={this.state.reference}
              />
            </>
            : <></>}
        </CardContent>
        <CardActions>
          <Button size='small' color='primary' onClick={this.save} disabled={!this.state.name}>
              Guardar
          </Button>
        </CardActions>
      </Card>
    )
  }
}

LabourRegister.propTypes = {
  userAccess: PropTypes.number,
  handleCreate: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  editData: PropTypes.object
}

export default LabourRegister
