import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import remove from 'lodash/remove'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'
import words from 'lodash/words'
import find from 'lodash/find'
import concat from 'lodash/concat'

import memoize from 'memoize-one'

import ActivityTable from './ActivityTable.js'
import TableIcons from '../Components/TableIcons'
import { selectActivityLogDay, getPendingActivityLog, setLoading, subscribePendingActivityLogRecord, unSubscribePendingActivityLogRecord } from './Attendance/redux/attendanceActions'
import { subscribeToCompanyMachinesList } from '../Farms/redux/farmsActions.js'
import { recordNameToId, getTipoContrato } from '../../utils'

const columns = [
  { name: 'employeeId', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'membershipId', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'employeeName', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'asistencia', label: 'asistencia', options: { filter: true, customBodyRender: TableIcons } },
  { name: 'actividades', label: 'actividades', options: { filter: true, customBodyRender: TableIcons } }
]

const defaultColumns = ['rut', 'nombre', 'predio', 'tipoContrato', 'asistencia', 'actividades']

const _employeesList = memoize((employeesMemberships) => map(employeesMemberships, (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')))
const _membershipIds = memoize((employeesMemberships) => map(employeesMemberships, (m) => recordNameToId(m)))

const _data = memoize((selectedActivityLogDay, employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, pendingActivityLog) => reduce(employeesList, (result, employeeRecordName) => {
  let employee = employeeRecord[employeeRecordName]
  let farmId = null
  let farmName = null
  let employeeContract = null

  if (!isEmpty(employeeContracts)) {
    farmId = employeeContracts[employeeRecordName] ? employeeContracts[employeeRecordName].farm : null
    employeeContract = employeeContracts[employeeRecordName]
    if (!employeeContract) return result
    if (employeeContract && employeeContract.beginDate > selectedActivityLogDay) return result
  }

  if (farmId) farmName = farmRecords[`farm/${farmId}`] ? farmRecords[`farm/${farmId}`].displayName : 'n/d'

  const employeeContractType = employeeContract ? `contract/${employeeContract.type}` : 'contract/type/'
  const contractTypeRecord = contractTypeRecords[employeeContractType]
  const employeeId = employeeRecordName.split('/')[1]
  const membershipId = recordNameToId(find(employeesMemberships, (m) => m.indexOf(employeeId) > 0))
  const pendingAttendance = includes(pendingActivityLog.pendingAttendance, membershipId)
  const pendingSiteLog = includes(pendingActivityLog.pendingSiteLog, membershipId)

  let relevant = {
    rut: employeeId,
    nombre: employee ? `${(words(employee.name)[0])} ${words(employee.lastName)[0]}` : 'n/d',
    predio: farmName || 'n/d',
    asistencia: pendingAttendance ? 'pendiente' : 'ok',
    actividades: pendingSiteLog ? 'pendiente' : 'ok',
    tipoContrato: getTipoContrato(contractTypeRecord),
    employeeId,
    membershipId,
    employeeName: { name: employee.name, lastName: employee.lastName }
  }

  for (var key in relevant) {
    if (!includes(map(columns, (c) => c.name), key)) {
      columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
    }
  }

  result.push(relevant)
  return result
}, []))

const _remoteData = memoize((employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, pendingActivityLog) => reduce(pendingActivityLog.remoteMembershipData, (result, remoteData) => {
  if (isEmpty(remoteData)) return result

  let employeeData = remoteData.employeeData
  let farmId = remoteData.farmId
  let farmName = null

  if (farmId) farmName = farmRecords[`farm/${farmId}`] ? farmRecords[`farm/${farmId}`].displayName : 'n/d'

  const employeeContractType = `contract/${remoteData.contractTypeId}`
  const contractTypeRecord = contractTypeRecords[employeeContractType]
  const employeeId = remoteData.employeeId
  const membershipId = remoteData.membershipId
  const pendingAttendance = includes(pendingActivityLog.pendingAttendance, membershipId)
  const pendingSiteLog = includes(pendingActivityLog.pendingSiteLog, membershipId)

  let relevant = {
    rut: employeeId,
    nombre: `${(words(employeeData.name)[0])} ${words(employeeData.lastName)[0]}`,
    predio: farmName || 'n/d',
    asistencia: pendingAttendance ? 'pendiente' : 'ok',
    actividades: pendingSiteLog ? 'pendiente' : 'ok',
    tipoContrato: getTipoContrato(contractTypeRecord),
    employeeId,
    membershipId,
    employeeName: { name: employeeData.name, lastName: employeeData.lastName }
  }

  for (var key in relevant) {
    if (!includes(map(columns, (c) => c.name), key)) {
      columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
    }
  }

  result.push(relevant)
  return result
}, []))

// COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
const _columns = memoize((columns, location) => {
  const orderedColumns = orderBy(columns, (o) => defaultColumns.indexOf(o.name))

  // we filter based on query
  const query = getQuery(location)
  return reduce(orderedColumns, (result, column, idx) => {
    const value = query.get(column.name)
    column.options = { ...column.options, filterList: value ? [value] : [] }
    result.push(column)
    return result
  }, [])
})

const getQuery = (location) => {
  return new URLSearchParams(location.search)
}

const _getPredio = memoize((userFarms, farmRecords) => {
  if (farmRecords[userFarms[0]]) return farmRecords[userFarms[0]].displayName
  return null
})

const mapStateToProps = (state, ownProps) => {
  const companyId = state.auth.clientData.activeCompany
  const farmRecords = state.farms.farmRecord
  const predio = _getPredio(state.farms.userFarms, farmRecords)
  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]
  const membershipIds = _membershipIds(employeesMemberships)
  const employeeContracts = state.employees.employeeContract
  const employeesList = _employeesList(employeesMemberships)
  const contractTypeRecords = state.employees.contractTypeRecord
  const { selectedActivityLogDay, pendingActivityLog, loading } = state.attendance
  const data = _data(selectedActivityLogDay, employeesList, state.employees.employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, pendingActivityLog)
  const remoteData = _remoteData(employeesList, state.employees.employeeRecord, employeeContracts, farmRecords, contractTypeRecords, employeesMemberships, pendingActivityLog)

  return {
    data: concat(data, remoteData),
    columns: _columns(columns, ownProps.location),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'multiple',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    selectedActivityLogDay,
    membershipIds,
    loading,
    predio
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  selectActivityLogDay,
  getPendingActivityLog,
  setLoading,
  subscribeToCompanyMachinesList,
  subscribePendingActivityLogRecord,
  unSubscribePendingActivityLogRecord
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable)
