import { createReducer } from '@reduxjs/toolkit'
import clone from 'lodash/clone'
import { attendanceDayRecordList, selectActivityLogDay, pendingActivityLog, setLoading, attendanceAnnotationTopics, selectActivitySummaryMonth,
  summaryPunchCard, selectActivitySummaryDay, attendanceSummaryRecord, currentAttendanceRecord } from './attendanceActions'

const initialState = {
  loading: false,
  attendanceDayRecordList: {},
  selectedActivityLogDay: '',
  pendingActivityLog: { pendingAttendance: [], pendingSiteLog: [], remoteMembershipData: [] },
  attendanceAnnotationTopics: { topics: [] },
  selectedActivitySummaryMonth: '',
  selectedActivitySummaryDay: '',
  summaryPunchCard: { updatedAt: 0, data: [], remoteMembershipData: [] },
  attendanceSummaryRecord: {},
  currentAttendanceRecord: {}
}

const attendanceReducer = createReducer(initialState, {
  [attendanceDayRecordList]: (state, action) => {
    state.attendanceDayRecordList[action.payload.listName] = action.payload.data
  },
  [selectActivityLogDay]: (state, action) => {
    state.selectedActivityLogDay = action.payload
  },
  [attendanceAnnotationTopics]: (state, action) => {
    state.attendanceAnnotationTopics = action.payload
  },
  [pendingActivityLog]: (state, action) => {
    state.pendingActivityLog = action.payload
    if (action.payload.reset) {
      state.pendingActivityLog = clone(initialState.pendingActivityLog)
    }
  },
  [setLoading]: (state, action) => {
    state.loading = action.payload
  },
  [selectActivitySummaryMonth]: (state, action) => {
    state.selectedActivitySummaryMonth = action.payload
  },
  [selectActivitySummaryDay]: (state, action) => {
    state.selectedActivitySummaryDay = action.payload
  },
  [summaryPunchCard]: (state, action) => {
    state.summaryPunchCard = action.payload
    if (action.payload.reset) {
      state.summaryPunchCard = clone(initialState.summaryPunchCard)
    }
  },
  [attendanceSummaryRecord]: (state, action) => {
    state.attendanceSummaryRecord = action.payload
    if (action.payload.reset) state.attendanceSummaryRecord = {}
  },
  [currentAttendanceRecord]: (state, action) => {
    state.currentAttendanceRecord = action.payload
    if (action.payload.reset) state.currentAttendanceRecord = {}
  }
})

export default attendanceReducer
