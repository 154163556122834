import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import memoize from 'memoize-one'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import moment from 'moment-timezone'
import 'moment/locale/es'

import AttendanceCard from './AttendanceCard.js'
import { saveAttendanceRecord, getAttendanceRecord, subscribeToAnnotationTopics, createAnnotationTopic, deleteAttendance, resetCurrentAttendance } from './redux/attendanceActions'
import { deleteFile, getFileDownloadUrl } from '../../FileUtils/redux/fileUtilsActions'

const mapDispatchToProps = dispatch => bindActionCreators({
  saveAttendanceRecord,
  getAttendanceRecord,
  subscribeToAnnotationTopics,
  createAnnotationTopic,
  deleteFile,
  getFileDownloadUrl,
  deleteAttendance,
  resetCurrentAttendance
}, dispatch)

const annotationTopicColumns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'topic', label: 'tema', options: { display: 'true', viewColumns: true, filter: true } }
]

const _attendanceAnnotationTopics = memoize((topics) => map(topics, (t) => ({ id: t.id, topic: startCase(t.topic) })))

const mapStateToProps = (state, ownProps) => {
  const attendanceAnnotationTopics = _attendanceAnnotationTopics(state.attendance.attendanceAnnotationTopics.topics)

  // month to text
  moment.locale('es')
  const split = ownProps.day.split('-')
  const date = {
    mm: startCase(moment().month(Number(split[1]) - 1).format('MMMM')),
    dd: split[2],
    yy: split[0]
  }

  return { attendanceAnnotationTopics, annotationTopicColumns, date }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceCard)
