import { createAction } from '@reduxjs/toolkit'
import each from 'async/each'
import noop from 'lodash/noop'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import reduce from 'lodash/reduce'
import startsWith from 'lodash/startsWith'
import forEach from 'lodash/forEach'
import deburr from 'lodash/deburr'
import trim from 'lodash/trim'
import isArray from 'lodash/isArray'

import { makeRpc } from '../../Main/redux/mainActionsDeepstream'
import { listSubscribe, recordSnapshot, recordSubscribe, unsubscribeList, discardRecord } from '../../Main/redux/mainActionsRecords'
import { updateHarvestRecordPaymentStatus } from '../../Harvest/redux/harvestActions'

export const outgoingPaymentOrder = createAction('payments/outgoingPaymentOrder')
export const outgoingPaymentOrderList = createAction('payments/outgoingPaymentOrderList')
export const incomingPaymentOrder = createAction('payments/incomingPaymentOrder')
export const paymentRecords = createAction('payments/paymentRecords')
export const outgoingPaymentRequest = createAction('payments/outgoingPaymentRequest')
export const paymentLiquidacionParams = createAction('payments/paymentLiquidacionParams')
export const employeesLiquidacion = createAction('payments/employeesLiquidacion')
export const outgoingPaymentRequestList = createAction('payments/outgoingPaymentRequestList')

export const subscribeToOutgoingPaymentOrderList = () => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(listSubscribe(`company/${companyId}/list/payment/order/out`, outgoingPaymentOrderList, outgoingPaymentOrder))
}

export const unSubscribeToOutgoingPaymentOrderList = () => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(unsubscribeList(`company/${companyId}/list/payment/order/out`, outgoingPaymentOrderList, true))
}

export const createOutgoingPayment = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  const { connectionState } = state.main
  const companyId = state.auth.clientData.activeCompany
  const userId = state.auth.clientData.userId

  if (connectionState !== 'OPEN') {
    return cb(new Error('mustHaveOpenConnection'))
  }

  // format new payment
  const newPayment = map(payload.data, (d) => ({
    employeeId: d.employeeId,
    paymentMethod: d.paymentMethod,
    membershipId: d.membershipId,
    companyId,
    // if items are not defined we create a generic expense record
    items: isArray(d.items) ? d.items : [`expenses/${companyId}/record/${dsClient().getUid()}`],
    total: payload.total,
    accountingDate: payload.accountingDate,
    comment: payload.comment,
    topic: payload.topic
  }))

  dispatch(makeRpc('payment/create/outgoing', { companyId, newPayment, userId }, (err, result) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    dispatch(recordSubscribe(`payment/${result.data.outgoingPaymentRequestId}`, outgoingPaymentRequest, true, () => {
      cb(null, result.data.outgoingPaymentRequestId)
    }))
  }))
}

export const discardOutgoingPaymentRequestRecord = (recordName) => (dispatch) => {
  dispatch(discardRecord(recordName))
  dispatch(outgoingPaymentRequest({}))
}

export const createPaymentOrder = (payload = {}, cb = noop) => (dispatch, getState) => {
  if (!payload.paymentIds || payload.paymentIds.length === 0) return cb('noData')
  if (!(payload.topic && isArray(payload.topic))) return cb('invalidRequest')

  let { paymentIds, companyId, topic, userId, comment } = payload
  comment = deburr(trim(payload.comment || ''))
  if (!userId) {
    const state = getState()
    companyId = state.auth.clientData.activeCompany
    userId = state.auth.clientData.userId
  }
  dispatch(makeRpc('payment/create/order', { companyId, paymentIds, userId, topic, comment }, (err, result) => {
    if (err) console.error(err)
    cb(err, result)
  }))
}

export const updatePaymentOrder = (payload = {}, cb = noop) => (dispatch, getState, dsClient) => {
  if (!payload.orderIds || payload.orderIds.length === 0) {
    return cb('noData')
  }
  const orderIds = reduce(payload.orderIds, (result, orderId) => {
    let id = orderId
    if (startsWith(orderId, 'payment')) {
      id = orderId.substring(8)
    }
    result.push(id)
    return result
  }, [])

  const state = getState()
  const userId = state.auth.clientData.userId
  const companyId = state.auth.clientData.activeCompany

  dispatch(makeRpc('payment/update/order/out', { ...payload, orderIds, userId, companyId }, (err, response) => {
    if (err) console.error(err)
    if (response.status === 200) dispatch(updatePaymentItems(response.userRequestRecordName, payload.status))
    cb(err, response)
  })
  )
}

export const getPaymentOrderRecord = (orderRecordName, cb = noop) => (dispatch) => {
  dispatch(recordSubscribe(orderRecordName, outgoingPaymentOrder, true, (err, record) => {
    if (err) return cb(err)
    const recordData = record.get('data')

    const payments = {}
    each(recordData.paymentIds, (id, cb) => {
      dispatch(recordSnapshot(`payment/${id}`, false, (err, record) => {
        if (err) console.error(err)
        payments[`payment/${id}`] = record
        cb()
      }))
    }, () => {
      dispatch(paymentRecords({ batch: true, data: payments }))
      cb(null, record)
    })
  }))
}

export const setPaymentStatus = (payload) => (dispatch, getState, dsClient) => {
  const state = getState()
  const userId = state.auth.clientData.userId
  const companyId = state.auth.clientData.activeCompany

  dispatch(makeRpc('payment/update/out', { ...payload, userId, companyId }, (err, response) => {
    if (err) console.error(err)
    if (response.status === 200) {
      dispatch(updatePaymentItems(response.userRequestRecordName, payload.status))
    }
  }))
}

// Here we update the local status on each payment item
const updatePaymentItems = (userRequestRecordName, status) => (dispatch, getState, dsClient) => {
  dsClient().record.getRecord(userRequestRecordName).whenReady((record) => {
    record.subscribe((recordData) => {
      if (recordData.status > 100) {
        if (recordData.data && recordData.data.updatePayment) {
          const paymentRecordNames = compact(map(recordData.data.updatePayment, (val, id) => (val.error.length === 0 ? `payment/${id}` : null)))
          const payments = {}
          each(paymentRecordNames, (name, cb) => {
            dispatch(recordSnapshot(name, false, (err, record) => {
              if (err) console.error(err)
              payments[name] = record
              cb()
            }))
          }, () => {
            dispatch(paymentRecords({ batch: true, data: payments }))
            // here we update based on the item scope
            const items = flatMap(payments, (val) => (val.items))
            const itemGroups = reduce(items, (result, item) => {
              const key = item.split('/')[0]
              result[key] = concat(result[key] || [], [item])
              return result
            }, {})

            forEach(itemGroups, (values, key) => {
              if (key === 'harvest') {
                dispatch(updateHarvestRecordPaymentStatus({ values, status }))
              }
            })
          })
        }
      }
    }, true)
  })
}

export const getPaymentLiquidacionParams = (yearMonth, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  if (yearMonth) dispatch(recordSnapshot(`payment/${companyId}/params/liquidacion/${yearMonth}`, paymentLiquidacionParams, cb))
}

export const subscribeToOutgoingPaymentRequestList = () => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(listSubscribe(`company/${companyId}/list/payment/request/out`, outgoingPaymentRequestList, outgoingPaymentRequest))
}

export const unSubscribeToOutgoingPaymentRequestList = () => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(unsubscribeList(`company/${companyId}/list/payment/request/out`, outgoingPaymentRequestList, true))
}

export const removePaymentRequest = (recordName, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany

  dispatch(makeRpc('payment/update/remove/request', { recordName, companyId }, cb))
}
