import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    margin: theme.spacing.unit / 2
  }
})

class DayStatsSelect extends React.Component {
  state = {
    selectedDay: ''
  }

  componentDidMount () {
    // set current stats day
    this.setState({ selectedDay: this.props.harvestStatsDay })
  }

  componentWillUnmount () {
    this.props.harvestStatsUnsubscribe(this.state.selectedDay)
  }

  changeSelectedDay = (day) => {
    this.props.harvestStatsUnsubscribe(this.state.selectedDay, () => {
      this.props.harvestStatsSubscribe(day)
      this.setState({ selectedDay: day })
    })
  }

  render () {
    const { classes } = this.props

    return (
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl}>
          <TextField
            id='day'
            name='day'
            label='Resumen del dia'
            InputLabelProps={{
              shrink: true
            }}
            type='date'
            value={this.state.selectedDay}
            onChange={(event) => this.changeSelectedDay(event.target.value)}
            className={[classes.textField, classes.selectEmpty].join(' ')}
          />
        </FormControl>
      </form>
    )
  }
}

DayStatsSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  harvestStatsDay: PropTypes.string.isRequired,
  harvestStatsSubscribe: PropTypes.func.isRequired,
  harvestStatsUnsubscribe: PropTypes.func.isRequired
}

export default withStyles(styles)(DayStatsSelect)
