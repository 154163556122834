import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import take from 'lodash/take'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'

import DayFarmSelect from './DayFarmSelect'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
})

const StatusIndicator = (props) => {
  // show indicator based on status
  if (!props.show) return null
  return (
    <>
      <Typography variant='body2' gutterBottom>
        {`Completado ${props.tableValidationComplete} %`}
      </Typography>
      <LinearProgress variant='determinate' value={props.tableValidationComplete} />
    </>
  )
}

const CustomToolbarSelect = (props) => {
  if (props.userAccess > 3) {
    return <></>
  }
  return (
    <Button className={props.classes.button} color='primary' onClick={
      () => props.action(map(props.displayData, (value) => ({ idx: value.dataIndex, data: value.data })), map(props.selectedRows.data, (row) => row.dataIndex))} >
      Validar registros
    </Button>
  )
}

class HarvestSummaryTable extends React.Component {
  componentDidMount () {
    // Subscribe to validated Days record
    this.props.subscribeValidatedDays()
  }

  componentWillUnmount () {
    // unsubscribe to validated Days record
    this.props.unsubscribeValidatedDays()
  }

  componentDidUpdate (prevProps) {
    if (this.props.selectedDay.to && this.props.selectedDay.from && (!isEqual(prevProps.selectedDay, this.props.selectedDay) || !isEqual(prevProps.selectedFarm, this.props.selectedFarm))) {
      this.props.getHarvestData(() => {
        console.log('done getting hartvest data')
      })
    }
    if (this.props.tableValidationComplete === 100) {
      if (includes(this.props.daysToValidate, this.props.selectedDay.from)) {
        this.props.validateDay({ farmId: this.props.selectedFarm.id, day: this.props.selectedDay.from })
      }
    }
  }

  onCellClick = (data, meta) => {
    // const value = this.props.data[meta.dataIndex]
    // TODO: go to harvest tablke and show filtered table for employeeId and day
  }

  validateEmployeeSummary = (data, selectedIndex) => {
    // data is an array of table values. At index 0 we have rut
    let employeeIds = map(filter(data, (d) => includes(selectedIndex, d.idx)), (d) => d.data[0])
    const registeredEmployeeIds = keys(this.props.employees)
    const employeeIdsToValidate = filter(registeredEmployeeIds, (id) => includes(employeeIds, id))

    const recordsToValidate = flatMap(flatMap(filter(this.props.data, (rec) => includes(employeeIdsToValidate, rec.rut)), (raw) => raw.harvestRecords.nonValidated), (d) => d.id)

    if (!employeeIdsToValidate.length) {
      window.alert('No se generan pagos a cosecheros no registrados')
    } else {
      let ok = window.confirm(`Seguro que desea validar registros para ${employeeIdsToValidate.length} cosecheros?`)
      // alert if there are records that won't be validated
      let ok2 = true
      if (employeeIds.length !== employeeIdsToValidate.length) {
        ok2 = window.confirm(`Hay cosecheros no registrados a quienes no se les validaran los registros. Continuar?`)
      }

      if (ok && ok2) {
        this.props.recordsToValidate({ recordsToValidate, farmId: this.props.selectedFarm.id }, (err, result) => {
          console.log(err, result)
        })
      }
    }
  }

  isRowSelectable = (dataIdx) => {
    // we can only select rows that have nonValidated records
    return !isEmpty(this.props.data[dataIdx] && this.props.data[dataIdx].harvestRecords && this.props.data[dataIdx].harvestRecords.nonValidated)
  }

  render () {
    return (
      <>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={24}>

            <Grid item xs={6}>
              <DayFarmSelect
                days={this.props.days}
                farms={this.props.farms}
                selectedDay={this.props.selectedDay}
                oneDayOnly
                selectedFarm={this.props.selectedFarm || { name: '', id: '' }}
                setSelectedDayAndFarmForTable={this.props.setSelectedDayAndFarmForTable}
                nextLoading={this.props.nextLoading}
              />
            </Grid>

            <Grid item xs={6}>
              <StatusIndicator tableValidationComplete={this.props.tableValidationComplete} show={!!this.props.selectedDay.from} />
            </Grid>

          </Grid>
        </div>

        {this.props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <>
            {
              this.props.selectedDay.from && this.props.selectedDay.to && this.props.selectedFarm.id
                ? <>
                  <MUIDataTable
                    title={'Resumen'}
                    data={this.props.data}
                    columns={this.props.columns}
                    options={{ ...this.props.options,
                      onCellClick: this.onCellClick,
                      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                        <CustomToolbarSelect
                          classes={this.props.classes}
                          selectedRows={selectedRows}
                          displayData={displayData}
                          action={this.validateEmployeeSummary}
                          userAccess={this.props.userAccess}
                        />),
                      onDownload: (buildHead, buildBody, columns, values) => {
                        const json = reduce(values, (result, val) => {
                          const temp = {}
                          forEach(val.data, (v, idx) => {
                            temp[columns[idx].name] = v
                          })
                          result.push(temp)
                          return result
                        }, [])

                        const fileName = `resumenCosecha-${this.props.selectedFarm.name}-${this.props.selectedDay.from}`
                        this.props.makeXlsxFile(fileName, [{ data: json, name: 'data' }], ['rut', 'nombre', 'apellido'])
                        // cancel default  CSV download from table
                        return false
                      },
                      isRowSelectable: this.isRowSelectable }
                    } />
                  <br />
                  {this.props.daysToValidate.length
                    ? <Card>
                      <CardHeader title='Dias pendientes de validar' />
                      <CardContent>
                        <Grid container spacing={24}>
                          {map(take(this.props.daysToValidate, 5), (day, idx) =>
                            <Grid item xs={3} key={idx}>
                              <Button color='primary' onClick={() => this.props.setSelectedDayAndFarmForTable({ selectedFarm: this.props.selectedFarm, selectedDay: { from: day, to: day } })}>{day}</Button>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>

                    : null}
                </> : <></> }
          </>
        }
      </>
    )
  }
}

HarvestSummaryTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  classes: PropTypes.object.isRequired,
  farms: PropTypes.array.isRequired,
  employees: PropTypes.object.isRequired,
  recordsToValidate: PropTypes.func.isRequired,
  validateDay: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userAccess: PropTypes.number.isRequired,
  selectedDay: PropTypes.object,
  selectedFarm: PropTypes.object,
  daysToValidate: PropTypes.array,
  tableValidationComplete: PropTypes.number,
  days: PropTypes.array,
  setSelectedDayAndFarmForTable: PropTypes.func,
  unsubscribeValidatedDays: PropTypes.func,
  subscribeValidatedDays: PropTypes.func,
  getHarvestData: PropTypes.func,
  nextLoading: PropTypes.func,
  makeXlsxFile: PropTypes.func
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  userAccess: PropTypes.number.isRequired
}

StatusIndicator.propTypes = {
  tableValidationComplete: PropTypes.number,
  show: PropTypes.bool.isRequired
}

export default withRouter(withStyles(styles)(HarvestSummaryTable))
