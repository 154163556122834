import { createAction } from '@reduxjs/toolkit'
import each from 'async/each'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import dropRight from 'lodash/dropRight'
import map from 'lodash/map'
import noop from 'lodash/noop'
import startsWith from 'lodash/startsWith'

import { listSubscribe, recordSnapshot, unsubscribeList } from '../../Main/redux/mainActionsRecords'
import { makeRpc } from '../../Main/redux/mainActionsDeepstream'

export const companyFarmsList = createAction('farms/companyFarmsList')
export const companyFarmsSiteList = createAction('farms/companyFarmsSiteList')
export const farmRecord = createAction('farms/farmRecord')
export const siteRecord = (rand) => (rand ? createAction(`farms/siteRecord__:${rand}`) : createAction(`farms/siteRecord`))
export const siteCropRecord = createAction('farms/siteCropRecord')
export const cropRecord = createAction('farms/cropRecord')
export const userFarms = createAction('farms/userFarms')
export const userSites = createAction('farms/userSites')
export const farmStart = createAction('farms/start')
export const farmReset = createAction('farms/reset')
export const companyMachinesList = createAction('farms/companyMachinesList')
export const companyMachineRecords = createAction('farms/companyMachineRecords')

export const startFarmsData = (startCallback = null) => (dispatch, getState, dsClient) => {
  const state = getState()
  const activeCompany = state.auth.clientData.activeCompany

  const { start } = state.farms
  if (!start || start !== activeCompany) {
    // subscribe to all company farms
    dispatch(listSubscribe(`company/${activeCompany}/list/farms`, companyFarmsListAction, undefined, undefined, () => {
      dispatch(farmStart(activeCompany))
      if (startCallback) return startCallback()
    }))
  } else {
    if (startCallback) return startCallback()
  }
}

// called every time the farm list entries change
export const companyFarmsListAction = (payload, callb = noop) => (dispatch, getState) => {
  dispatch(companyFarmsList(payload))
  const state = getState()
  const companyFarms = payload.data
  // get farm records from state or snapshot
  each(companyFarms, (farm, cb) => {
    dispatch(farmRecordSnapshot(farm, farmRecord, state, () => {
      cb()
    }))
  }, () => {
    each(companyFarms, (farm, cb) => {
      dispatch(getFarmSites(farm, () => {
        cb()
      }))
    }, () => {
      dispatch(_userFarmsAndSites(() => {
        callb()
      }))
    })
  })
}

const _companyFarmsSiteList = (payload, callback = noop) => (dispatch, getState) => {
  dispatch(companyFarmsSiteList(payload))
  const recordAction = siteRecord(payload.listName)
  const state = getState()
  // get site records from state or snapshot
  each(payload.data, (site, cb) => {
    dispatch(farmRecordSnapshot(site, recordAction, state, () => {
      cb()
    }))
  }, () => {
    callback()
  })
}

export const getFarmSites = (farmRecordName, callback = noop) => (dispatch, getState, dsClient) => {
  const listName = `${farmRecordName}/list/sites`

  dispatch(listSubscribe(listName, _companyFarmsSiteList, undefined, undefined, () => {
    // this is called after _companyFarmsSiteList ends getting all sites
    const state = getState()
    const sites = state.farms.siteRecord
    // get site crops
    each(sites, (site, cb1) => {
      if (!isEmpty(site.siteCrop)) {
        each(site.siteCrop, (siteCrop, cb2) => {
          const recordName = `site/${siteCrop}`

          dispatch(farmRecordSnapshot(recordName, siteCropRecord, state, () => {
            const cropRecordName = `crop/${getState().farms.siteCropRecord[recordName].crop}`

            dispatch(farmRecordSnapshot(cropRecordName, cropRecord, state, () => {
              cb2()
            }))
          }))
        }, () => {
          cb1()
        })
      } else {
        cb1()
      }
    }, () => {
      dispatch(_userFarmsAndSites())
      callback()
    })
  }))
}

// format _userFarmsAndSites info on state
const _userFarmsAndSites = (cb = noop) => (dispatch, getState) => {
  const state = getState()
  const _userFarms = parseUserFarms(state)

  dispatch(userFarms(_userFarms))

  const _userSites = flatten(filter(state.farms.companyFarmsSiteList, (value, key) => {
    if (includes(_userFarms, dropRight(key.split('/'), 2).join('/'))) return true
    return false
  }))

  dispatch(userSites(_userSites))
  cb()
}

// get all farms from user membership to company
const parseUserFarms = (state, start = false) => {
  const { activeCompany } = state.auth.clientData
  const membership = filter(state.main.userMemberships, (membership) => membership.company === activeCompany)[0]
  let _userFarms = map(membership.farm, (f) => `farm/${f}`)
  if (_userFarms[0] === 'farm/*') {
    _userFarms = state.farms.companyFarmsList[`company/${activeCompany}/list/farms`]
    if (start) _userFarms = '*'
  }
  return _userFarms
}

const farmRecordSnapshot = (recordName, action, state = null, cb = noop) => (dispatch, getState) => {
  if (!state) state = getState()
  let data = null

  if (startsWith(recordName, 'farm/')) {
    data = state.farms.farmRecord[recordName]
  }
  if (startsWith(recordName, 'site/') && recordName.split('/').length === 2) {
    data = state.farms.siteRecord[recordName]
  }
  if (startsWith(recordName, 'site/') && recordName.split('/')[2] === 'crop') {
    data = state.farms.siteCropRecord[recordName]
  }
  if (startsWith(recordName, 'crop/')) {
    data = state.farms.cropRecord[recordName]
  }

  if (!isEmpty(data)) {
    cb(null, data)
  } else {
    dispatch(recordSnapshot(recordName, action, cb))
  }
}

export const createMachine = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const { farmId } = payload
  delete payload.farmId
  dispatch(makeRpc('machine/create', { newMachine: payload, farmId, companyId }, (err, res) => {
    if (!err) {
      if (state.farms.companyMachinesList.length === 0) {
        dispatch(subscribeToCompanyMachinesList())
      }
    }
    cb(err, res)
  }))
}

export const editMachine = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const { farmId } = payload
  delete payload.farmId
  dispatch(makeRpc('machine/edit', { machine: payload, farmId, companyId }, cb))
}

export const createSite = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(makeRpc('site/create', { newSite: payload, companyId }, cb))
}

export const editSite = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(makeRpc('site/edit', { site: payload, companyId }, (err) => {
    if (!err) {
      dispatch(recordSnapshot(`site/${payload.id}`, siteRecord(), cb))
    } else {
      cb(err)
    }
  }))
}

export const subscribeToCompanyMachinesList = () => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const listName = `company/${companyId}/list/machines`
  dispatch(listSubscribe(listName, companyMachinesList, companyMachineRecords))
}

export const unSubscribeToCompanyMachinesList = () => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const listName = `company/${companyId}/list/machines`
  dispatch(unsubscribeList(listName, null, true))
}
