import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Refresh from '@material-ui/icons/Refresh'

import compact from 'lodash/compact'
import map from 'lodash/map'
import join from 'lodash/join'
import find from 'lodash/find'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import noop from 'lodash/noop'
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import words from 'lodash/words'
import toNumber from 'lodash/toNumber'
import startCase from 'lodash/startCase'
import round from 'lodash/round'

import moment from 'moment-timezone'

import UserAccessComponent from '../Components/UserAccessComponent'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    margin: theme.spacing.unit / 2
  },
  button: {
    margin: theme.spacing.unit
  }
})

const CustomToolbarSelect = (props) => {
  return (
    <>
      <UserAccessComponent access={3} {...props}>
        <Button className={props.classes.button} color='primary' onClick={() => props.computeEmployeeLiquidacionForAll(map(props.displayData, (value) => value.data[1]))} >
          Generar Liquidaciones
        </Button>
      </UserAccessComponent>
      <UserAccessComponent access={2} {...props}>
        <Button className={props.classes.button} color='primary' onClick={
          () => props.openOutgoingPaymentDialog(map(props.displayData, (value) => ({ idx: value.dataIndex, data: { employeeId: value.data[0], membershipId: value.data[1], employeeContract: value.data[2] } })), map(props.selectedRows.data, (row) => row.dataIndex))} >
          Crear Orden de Pago
        </Button>
      </UserAccessComponent>
    </>
  )
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  openOutgoingPaymentDialog: PropTypes.func.isRequired,
  computeEmployeeLiquidacionForAll: PropTypes.func.isRequired,
  userAccess: PropTypes.number.isRequired
}

const initialState = {
  paymentDialogOpen: false,
  paymentData: [],
  paymentAmount: {},
  paymentAccountingDate: '',
  paymentComment: '',
  paymentLoading: false,
  paymentTopic: '',
  selectedRows: [],
  outgoingPaymentRequestId: '',
  openLiquidacionParamDialog: false,
  employeeLiquidacionParams: {},
  liquidacionLoading: false
}

class ActivitySummaryTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    // subscribe to annotation topics
    this.props.subscribeToAnnotationTopics()

    if (this.props.selectedActivitySummaryMonth) {
      const { annotationTopicsToGroupBy } = this.props
      // don't compute again punch card if coming back from view
      if (this.props.history.action !== 'POP') {
        this.props.setLoading(true)
        this.props.getSummaryPunchCard({
          yearMonth: this.props.selectedActivitySummaryMonth,
          membershipIds: this.props.membershipIds,
          annotationTopicsToGroupBy: map(annotationTopicsToGroupBy, (t) => t.id)
        }, (err) => {
          if (!err) this.props.subscribePunchCardRecord(this.props.selectedActivitySummaryMonth)
        })
      } else {
        this.props.subscribePunchCardRecord(this.props.selectedActivitySummaryMonth)
      }
    }
    // Set default user farm as filter on mount
    if (this.props.predio && !this.props.location.search) {
      this.props.history.replace({ pathname: this.props.location.pathname, search: '?predio=' + this.props.predio })
    }
  }

  componentWillUnmount () {
    if (this.props.selectedActivitySummaryMonth) {
      this.props.unSubscribePunchCardRecord(this.props.selectedActivitySummaryMonth)
    }
    if (this.state.outgoingPaymentRequestId) {
      this.props.discardOutgoingPaymentRequestRecord(`payment/${this.state.outgoingPaymentRequestId}`)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.selectedActivitySummaryMonth !== this.props.selectedActivitySummaryMonth) {
      this.props.setLoading(true)
      this.props.unSubscribePunchCardRecord(prevProps.selectedActivitySummaryMonth)
      this.props.getSummaryPunchCard({
        yearMonth: this.props.selectedActivitySummaryMonth,
        membershipIds: this.props.membershipIds,
        annotationTopicsToGroupBy: map(this.props.annotationTopicsToGroupBy, (t) => t.id)
      }, (err) => {
        if (!err) {
          this.props.subscribePunchCardRecord(this.props.selectedActivitySummaryMonth)
        }
      })
    }

    if (this.state.liquidacionLoading) {
      this.props.setLoading(true)
    }

    if (this.props.summaryPunchCard.status > 100 && !this.state.liquidacionLoading) {
      this.props.setLoading(false)
    }
    if (this.props.summaryPunchCard.status > 300) {
      window.alert(this.props.summaryPunchCard.errMessage)
    }
  }

  computeEmployeeLiquidacionForAll = (membershipIds = this.props.membershipIds) => {
    this.setState({ liquidacionLoading: true })
    this.props.computeEmployeeLiquidacionForAll({ membershipIds, yearMonth: this.props.selectedActivitySummaryMonth }, (err) => {
      this.setState({ liquidacionLoading: false })
      if (err) {
        window.alert('err')
      } else {
        this.props.history.push(`/app/activity/summary/liquidacion`, { membershipIds, yearMonth: this.props.selectedActivitySummaryMonth, summaryPunchCard: this.props.summaryPunchCard, search: this.props.location.search })
      }
    })
  }

  onCellClick = (data, meta) => {
    const { membershipId, employeeName } = this.props.data[meta.dataIndex]
    const summary = find(this.props.summaryPunchCard.data, (d) => d.membershipId === membershipId)
    this.props.history.push(`/app/activity/summary/view`, { employeeName, membershipId, yearMonth: this.props.selectedActivitySummaryMonth, summary })
  }

  downloadSummaryPunchCard = (cb = noop) => {
    // get memberships based on filters
    const activeFilters = compact(map(this.props.columns, (col) => {
      if (!isEmpty(col.options.filterList)) {
        return { name: col.name, filter: col.options.filterList }
      }
    }))

    const filteredMembershipIds = reduce(this.props.data, (result, d) => {
      const exclude = reduce(activeFilters, (res, filter) => {
        if (!d[filter.name] || !includes(filter.filter, d[filter.name])) {
          res = true
        }
        return res
      }, false)
      if (!exclude) result.push(d.membershipId)

      return result
    }, [])

    // sanity check
    if (filteredMembershipIds.length === 0) {
      return
    }

    // replace membershipId by employee name and id
    // translate some keys: horaExtra, absent, annotations, vacation
    const jsonData = compact(map(this.props.summaryPunchCard.data, (summary) => {
      const { membershipId } = summary
      if (!includes(filteredMembershipIds, membershipId)) return null
      const employee = find(this.props.data, (d) => d.membershipId === membershipId)

      let result = clone(summary)
      result.nombre = `${employee.employeeName.name} ${employee.employeeName.lastName}`
      result.rut = employee.employeeId
      result.predio = employee.predio
      result.tipoContrato = employee.tipoContrato
      result.jornadasAusencia = summary.absent
      result.fechasVacaciones = summary.vacation.length ? summary.vacation.join(',') : null
      result.fechasLicencia = summary.license.length ? summary.license.join(',') : null
      result.fechaInicioContrato = new Date(employee.fechaInicio)
      result.finiquitado = employee.finiquitado ? new Date(employee.finiquitado) : null
      result.estadoContrato = result.contractUndeclared ? 'No declarado' : 'Declarado'

      delete result.absent
      delete result.annotations
      delete result.membershipId
      delete result.vacation
      delete result.license
      delete result.overtimePerDay
      delete result.overtimePerDayAdicional
      delete result.overtimePerDayNocturna
      delete result.overtimePerDayControlHelada
      delete result.overtimeOnlyDays
      delete result.annotationTopicsToGroupBy
      delete result.attendedDays
      delete result.contractBeginDate
      delete result.contractEndDate
      delete result.contractUndeclared
      delete result.contractTypeId

      return result
    }))

    const overtimeData = compact(map(this.props.summaryPunchCard.data, (summary) => {
      const { membershipId } = summary
      if (!includes(filteredMembershipIds, membershipId)) return null
      const employee = find(this.props.data, (d) => d.membershipId === membershipId)
      let result = {}
      result.nombre = `${employee.employeeName.name} ${employee.employeeName.lastName}`
      result.rut = employee.employeeId
      forEach(summary.overtimePerDay, (value, key) => {
        result[key] = value
      })
      forEach(summary.overtimePerDayAdicional, (value, key) => {
        result[key] ? result[key] += value : result[key] = value
      })

      result.horaExtra = summary.horaExtra
      result.horaExtraAdicional = summary.horaExtraAdicional

      return result
    }))

    const overtimeDataNocturna = compact(map(this.props.summaryPunchCard.data, (summary) => {
      const { membershipId } = summary
      if (!includes(filteredMembershipIds, membershipId)) return null
      const employee = find(this.props.data, (d) => d.membershipId === membershipId)
      let result = {}
      result.nombre = `${employee.employeeName.name} ${employee.employeeName.lastName}`
      result.rut = employee.employeeId
      forEach(summary.overtimePerDayNocturna, (value, key) => {
        result[key] = value
      })

      result.horaExtraNocturna = summary.horaExtraNocturna

      return result
    }))

    const overtimeDataControlHelada = compact(map(this.props.summaryPunchCard.data, (summary) => {
      const { membershipId } = summary
      if (!includes(filteredMembershipIds, membershipId)) return null
      const employee = find(this.props.data, (d) => d.membershipId === membershipId)
      let result = {}
      result.nombre = `${employee.employeeName.name} ${employee.employeeName.lastName}`
      result.rut = employee.employeeId
      forEach(summary.overtimePerDayControlHelada, (value, key) => {
        result[key] = value
      })

      result.horaExtraControlHelada = summary.horaExtraControlHelada

      return result
    }))

    // special grouped data
    const annotatedTopicsToGroupBy = []
    forEach(this.props.annotationTopicsToGroupBy, (value) => {
      const data = compact(map(this.props.summaryPunchCard.data, (summary) => {
        const { membershipId } = summary
        if (!includes(filteredMembershipIds, membershipId)) return null
        const employee = find(this.props.data, (d) => d.membershipId === membershipId)
        let result = {}
        result.nombre = `${employee.employeeName.name} ${employee.employeeName.lastName}`
        result.rut = employee.employeeId
        forEach(summary.annotationTopicsToGroupBy, (a) => {
          if (value.id === a.annotation.topicId) result[a.day] = a.annotation.text
        })
        return result
      }))
      annotatedTopicsToGroupBy.push({ name: value.topic, data })
    })

    // download file

    let predio = find(activeFilters, (f) => f.name === 'predio')
    if (predio) predio = predio.filter[0]
    else predio = 'todoPredio'
    const fileName = `tarja-${predio.split(' ').join('')}-${this.props.selectedActivitySummaryMonth}`
    this.props.makeXlsxFile(fileName, [
      { name: 'asistencia', data: jsonData },
      { name: 'horasExtra', data: overtimeData },
      { name: 'horasExtraNocturna', data: overtimeDataNocturna },
      { name: 'horasExtraControlHelada', data: overtimeDataControlHelada },
      ...annotatedTopicsToGroupBy],
    ['predio', 'tipoContrato', 'fechaInicioContrato', 'finiquitado', 'estadoContrato', 'nombre', 'rut', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10'])
  }

  openOutgoingPaymentDialog = (paymentData, selectedRows) => {
    this.props.outgoingPaymentRequest({})
    this.setState({ paymentDialogOpen: true, paymentData, selectedRows })
  }

  createOutgoingPayment = () => {
    this.setState({ paymentLoading: true })
    const payload = {}
    const data = map(filter(this.state.paymentData, (d) => includes(this.state.selectedRows, d.idx)), (e) => e.data)
    payload.data = compact(map(data, (value) => {
      const employeeRecordName = `employee/${value.employeeId}`
      const paymentMethod = this.props.employeeContracts[employeeRecordName] ? this.props.employeeContracts[employeeRecordName].paymentMethod : null
      if (!paymentMethod) return null
      return {
        employeeId: value.employeeId,
        paymentMethod,
        membershipId: value.membershipId
      }
    }))
    payload.total = this.state.paymentAmount
    payload.accountingDate = this.state.paymentAccountingDate
    payload.comment = this.state.paymentComment
    payload.topic = words(this.state.paymentTopic)

    this.props.createOutgoingPayment(payload, (err, outgoingPaymentRequestId) => {
      if (!err) {
        this.setState({ paymentLoading: false, outgoingPaymentRequestId })
      }
    })
  }

  createOutgoingPaymentOrder = () => {
    const paymentIds = map(this.props.outgoingPaymentRequests[`payment/${this.state.outgoingPaymentRequestId}`].data, (value) => value.paymentId)
    const topic = words(this.state.paymentTopic)
    const comment = this.state.paymentComment
    this.props.createPaymentOrder({ paymentIds, topic, comment, companyId: this.props.companyId, userId: this.props.userId }, (err, res) => {
      if (err) {
        window.alert(err)
      } else {
        // done
        this.closeDialog()
      }
    })
  }

  closeDialog = () => {
    this.setState({ paymentDialogOpen: false, paymentLoading: false, selectedRows: [] })
  }

  viewLiquidacionParams = () => {
    if (!this.props.selectedActivitySummaryMonth) {
      window.alert('selecciona el mes primero')
    } else {
      this.props.getEmployeeLiquidacionParams(this.props.selectedActivitySummaryMonth, (err, data) => {
        if (err) {
          this.setState({ openLiquidacionParamDialog: true, employeeLiquidacionParams: err })
        } else {
          const employeeLiquidacionParams = reduce(data, (result, value, key) => {
            if (key === 'updatedAt') {
              result['actualizado'] = moment(value).tz('America/Santiago').format('YYYY-MM-DD, hh:mm:ss')
              return result
            }
            if (key === 'descuentoAFP') {
              result['Descuento AFP'] = reduce(value, (res, val, k) => {
                res[`${startCase(k)}`] = `${round(val * 100, 2)} %`
                return res
              }, {})
              return result
            }
            if (key === 'factorSeguroCesantiaTrabajador') {
              result[`${startCase(key)}`] = `${round(value * 100, 2)} %`
              return result
            }
            if (key === 'descuentoSalud') {
              result[`${startCase(key)}`] = { fonasa: `${round(value.fonasa * 100, 2)} %` }
              return result
            }

            result[`${startCase(key)}`] = value
            return result
          }, {})
          this.setState({ openLiquidacionParamDialog: true, employeeLiquidacionParams })
        }
      })
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            id='date'
            name='date'
            label='Fecha'
            InputLabelProps={{
              shrink: true
            }}
            type='month'
            value={this.props.selectedActivitySummaryMonth}
            onChange={(event) => this.props.selectActivitySummaryMonth(event.target.value)}
            className={[classes.textField, classes.selectEmpty].join(' ')}
          />
        </FormControl>
        <Button className={classes.button} variant='contained' onClick={() => this.downloadSummaryPunchCard()}>
          Descargar planilla tarja
        </Button>
        <Button className={classes.button} variant='contained' onClick={() => this.viewLiquidacionParams()}>
          Ver indicadores previsionales
        </Button>
        <Button className={classes.button} variant='contained' onClick={() => {
          this.props.getSummaryPunchCard({
            yearMonth: this.props.selectedActivitySummaryMonth,
            membershipIds: this.props.membershipIds,
            annotationTopicsToGroupBy: map(this.props.annotationTopicsToGroupBy, (t) => t.id)
          })
        }}>
          Actualizar <Refresh />
        </Button>
        <Typography variant='body1' gutterBottom>
           Actualizado el {moment(this.props.summaryPunchCard.updatedAt).tz('America/Santiago').format('YYYY-MM-DD, hh:mm:ss')}
        </Typography>

        {/* create payment dialog */}
        <Dialog onClose={this.closeDialog} open={this.state.paymentDialogOpen} scroll={'paper'} fullWidth>
          <DialogTitle>Crear Pago</DialogTitle>
          <DialogContent>
            {this.state.paymentLoading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
              : <>
                {
                  isEmpty(this.props.outgoingPaymentRequests[`payment/${this.state.outgoingPaymentRequestId}`]) ? <>
                    <pre>{JSON.stringify(this.state.paymentData, null, 2)}</pre>
                    <TextField
                      label='monto'
                      autoFocus
                      id='amount'
                      fullWidth
                      type='number'
                      onChange={(e) => this.setState({ paymentAmount: { 'CLP': toNumber(e.target.value) } })}
                    />
                    <br />
                    <TextField
                      label='comentario'
                      id='comment'
                      fullWidth
                      type='string'
                      onChange={(e) => this.setState({ paymentComment: e.target.value })}
                    />
                    <br />
                    <TextField
                      label='topic'
                      id='topic'
                      fullWidth
                      type='string'
                      onChange={(e) => this.setState({ paymentTopic: e.target.value })}
                    />
                    <br />
                    <TextField
                      id='day'
                      name='day'
                      label='Fecha contable'
                      InputLabelProps={{
                        shrink: true
                      }}
                      type={'month'}
                      onChange={(event) => this.setState({ paymentAccountingDate: event.target.value })}
                    /> </>
                    : <pre>{JSON.stringify(this.props.outgoingPaymentRequests[`payment/${this.state.outgoingPaymentRequestId}`], null, 2)}</pre>
                }
              </>
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ paymentDialogOpen: false, paymentLoading: false })}>Cerrar</Button>
            {isEmpty(this.props.outgoingPaymentRequests[`payment/${this.state.outgoingPaymentRequestId}`])
              ? <Button color='primary' onClick={this.createOutgoingPayment}>Crear</Button>
              : <Button color='primary' onClick={this.createOutgoingPaymentOrder}>Generar orden de pago</Button>
            }
          </DialogActions>
        </Dialog>

        {
          this.props.selectedActivitySummaryMonth
            ? this.props.loading
              ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
              : <MUIDataTable
                title={'Resumen asistencia y actividades'}
                data={this.props.data}
                columns={this.props.columns}
                options={{ ...this.props.options,
                  download: false,
                  onCellClick: this.onCellClick,
                  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                    <CustomToolbarSelect classes={classes} selectedRows={selectedRows} displayData={displayData}
                      openOutgoingPaymentDialog={this.openOutgoingPaymentDialog}
                      userAccess={this.props.userAccess}
                      computeEmployeeLiquidacionForAll={this.computeEmployeeLiquidacionForAll}
                    />),
                  // COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
                  onFilterChange: (changedColumn, filterList) => {
                    let search = compact(map(filterList, (value, idx) => (value[0] ? `${this.props.columns[idx].name}=${value[0]}` : null)))
                    search = '?' + join(search, '&')
                    this.props.history.replace({ pathname: this.props.location.pathname, search })
                  }
                }}
              /> : <></>
        }
        <Dialog onClose={() => { this.setState({ openLiquidacionParamDialog: false }) }} open={this.state.openLiquidacionParamDialog} scroll={'paper'} fullWidth>
          <DialogTitle>{`Indicadores previsionales ${this.props.selectedActivitySummaryMonth}`}</DialogTitle>
          <DialogContent>
            <pre>
              {JSON.stringify(this.state.employeeLiquidacionParams, null, 2)}
            </pre>
          </DialogContent>
        </Dialog>

      </div>
    )
  }
}

ActivitySummaryTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  selectActivitySummaryMonth: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  membershipIds: PropTypes.array.isRequired,
  selectedActivitySummaryMonth: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  predio: PropTypes.string,
  userAccess: PropTypes.number.isRequired,
  getSummaryPunchCard: PropTypes.func.isRequired,
  summaryPunchCard: PropTypes.object.isRequired,
  makeXlsxFile: PropTypes.func.isRequired,
  unSubscribePunchCardRecord: PropTypes.func.isRequired,
  subscribePunchCardRecord: PropTypes.func.isRequired,
  subscribeToAnnotationTopics: PropTypes.func.isRequired,
  createOutgoingPayment: PropTypes.func.isRequired,
  createPaymentOrder: PropTypes.func.isRequired,
  annotationTopicsToGroupBy: PropTypes.array,
  outgoingPaymentRequests: PropTypes.object,
  outgoingPaymentRequest: PropTypes.func.isRequired,
  discardOutgoingPaymentRequestRecord: PropTypes.func.isRequired,
  employeeContracts: PropTypes.object,
  getEmployeeLiquidacionParams: PropTypes.func.isRequired,
  computeEmployeeLiquidacionForAll: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(ActivitySummaryTable))
