import React from 'react'
import { Prompt, withRouter } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { CardActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'

import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import trim from 'lodash/trim'
import deburr from 'lodash/deburr'
import clone from 'lodash/clone'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import camelCase from 'lodash/camelCase'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import forEach from 'lodash/forEach'

import InputTextDialog from '../../Components/InputTextDialog'
import AnnotationComponent from '../../Components/AnnotationComponent'
import TableSearchCreateDialog from '../../Components/TableSearchCreateDialog'

const styles = theme => ({
  root: {
    flexGrow: 1
  }
})

const initialState = {
  absent: 0,
  vacation: false,
  license: false,
  absentDialog: false,
  overtimeOnly: false,
  modified: false,
  annotations: [],
  openAnnotationTopicDialog: false,
  openAnnotationIndex: null,
  showCreateComponent: false
}

class AssistanceCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentWillUnmount () {
    this.setState(clone(initialState))
    this.props.resetCurrentAttendance()
  }

  setLocalData = () => {
    const recordName = `attendance/${this.props.membershipId}/${this.props.day}`

    this.props.getAttendanceRecord(recordName, (err, attendanceRecord) => {
      if (err) {
        // there is no record saved, we should save
        this.setState({ modified: true })
        return
      }
      this.setState({ ...attendanceRecord })
    })
  }

  componentDidMount () {
    if (this.props.day) {
      this.setLocalData()
    }
    // subscribe to annotation topics
    this.props.subscribeToAnnotationTopics()
  }

  componentDidUpdate (prevProps) {
    if (this.props.day !== prevProps.day) {
      // eslint-disable-next-line
      this.setState(clone(initialState), () => this.setLocalData())
    }
  }

  save = () => {
    const state = clone(this.state)
    // we hanlde absent separately because it can be 0 thus pick would erase it
    const absent = state.absent
    // remove local keys
    // we keep updatedAt as indicator of edited record
    state.absentDialog = null
    state.modified = null
    state.userId = null
    state.openAnnotationTopicDialog = null
    state.openAnnotationIndex = null
    state.showCreateComponent = null

    // sanitize anotation
    state.annotations = reduce(state.annotations, (res, note) => {
      if (!note.topicId) note.topicId = 'none'
      res.push({ ...note, text: trim(deburr(note.text)) })
      return res
    }, [])

    if (state.annotations.length === 0) {
      state.annotations = null
    }

    const payload = pickBy(state, identity)
    payload.day = this.props.day
    payload.membershipId = this.props.membershipId
    payload.absent = absent
    payload.contractType = this.props.contractType

    this.props.saveAttendanceRecord(payload, (err, data) => {
      if (err) window.alert(err.message || err)
      else this.setState({ modified: false, ...data })
    })
  }

  deleteAssistance = () => {
    const ok = window.confirm('Seguro deseas eliminar el registro de asistencia?')
    if (ok) {
      const { day, membershipId } = this.props
      this.props.deleteAttendance({ membershipId, day }, (err) => {
        if (err) window.alert('Hubo un error')
        this.props.history.goBack()
      })
    }
  }

  setAbsentTime = (value = 0.5) => {
    if (value) {
      this.setState({ absent: value, modified: true, license: false, vacation: false, overtimeOnly: false })
    } else {
      this.setState({ absent: 0, modified: true, overtimeOnly: false })
    }
  }

  setVacation = () => {
    if (!this.state.vacation) {
      this.setState({ vacation: true, absent: 0, license: false, modified: true, overtimeOnly: false })
    } else {
      this.setState({ vacation: false, modified: true })
    }
  }

  setLicense = () => {
    if (!this.state.license) {
      this.setState({ license: true, absent: 0, vacation: false, modified: true, overtimeOnly: false })
    } else {
      this.setState({ license: false, modified: true })
    }
  }

  setOvertimeOnly = () => {
    if (!this.state.overtimeOnly) {
      this.setState({ overtimeOnly: true, license: false, absent: this.props.contractType === 'f' ? 1 : 0, vacation: false, modified: true })
    } else {
      this.setState({ overtimeOnly: false, modified: true, absent: 0 })
    }
  }

  handleClose = () => {
    this.setState({ openAnnotationTopicDialog: false, openAnnotationIndex: null }, () => {
      // Just to avoid a blink
      setTimeout(() => {
        this.setState({ showCreateComponent: false })
      }, 10)
    })
  }

  onCreatedAnnotationTopic = (topic) => {
    const payload = {
      topic: camelCase(trim(deburr(topic)))
    }
    this.props.createAnnotationTopic(payload, (err, topicId) => {
      if (!err) this.onSelectedAnnotationTopic(topicId)
    })
  }

  onAnnotationChange = (payload) => {
    // handle anotation text
    if (payload.text || payload.text === '') {
      const annotations = clone(this.state.annotations)
      const annotation = annotations[payload.idx]
      annotations[payload.idx] = { ...annotation, text: payload.text }
      this.setState({ modified: true, annotations })
    }

    // Annotation topic
    if (payload.openTopicDialog) {
      this.setState({ openAnnotationTopicDialog: true, openAnnotationIndex: payload.idx })
    }

    // Annotation files

    // file uploaded to bucket, keep reference in annotation
    if (payload.uploadedFile) {
      const annotations = clone(this.state.annotations)
      const annotation = annotations[payload.idx]
      const annotationFiles = isEqual(annotation.files, [{}]) ? [] : annotation.files
      const { data } = payload.uploadedFile
      forEach(data, (d) => annotationFiles.push({ name: d.fileName, recordName: d.fileRecordName }))
      annotations[payload.idx] = { ...annotation, files: annotationFiles }
      this.setState({ modified: true, annotations })
    }

    // open file in new tab
    if (payload.viewFile) {
      const { recordName } = payload.viewFile
      if (!recordName) return

      this.props.getFileDownloadUrl({ fileRecordName: recordName }, (err, res) => {
        if (err) {
          window.alert('Error al descargar', err.message)
        }
        if (res.data && res.data.getUrl) {
          window.open(res.data.getUrl)
        }
      })
    }
    // delete file
    // this will only set it's property to deleted and remove it from company files
    if (payload.deleteFile) {
      const ok = window.confirm(`Seguro desea borrar archivo ${payload.deleteFile.name} ?`)
      if (ok) {
        const annotations = clone(this.state.annotations)
        const annotation = annotations[payload.idx]
        const { recordName } = payload.deleteFile
        const annotationFiles = filter(annotation.files, (f) => f.recordName !== recordName)
        annotations[payload.idx] = { ...annotation, files: annotationFiles }
        this.setState({ modified: true, annotations })
        this.props.deleteFile(recordName)
      }
    }

    // delete annotation
    if (payload.delete || payload.delete === 0) {
      const ok = window.confirm(`Seguro desea borrar anotacion ?`)
      if (ok) {
        const annotation = find(this.state.annotations, (a, idx) => idx === payload.delete)
        // delete files
        forEach(annotation.files, (file) => this.props.deleteFile(file.recordName))
        // remove annotation
        const annotations = filter(this.state.annotations, (a, idx) => idx !== payload.delete)
        this.setState({ modified: true, annotations })
      }
    }
  }

  onSelectedAnnotationTopic = (topicId) => {
    const annotations = clone(this.state.annotations)
    const annotation = annotations[this.state.openAnnotationIndex]
    annotations[this.state.openAnnotationIndex] = { ...annotation, topicId }
    this.setState({ modified: true, annotations })
    this.handleClose()
  }

  addAnnotation = () => {
    const base = [{ topicId: '', text: '', files: [{}] }]
    if (isEmpty(this.state.annotations)) this.setState({ annotations: base })
    else this.setState({ annotations: concat(this.state.annotations, base) })
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <Prompt
          when={this.state.modified && !this.props.readOnly}
          message={'Tienes cambios sin guardar. Seguro que deseas continuar?'}
        />

        <Card>
          <CardHeader
            title={`Asistencia ${this.props.date.dd} de ${this.props.date.mm} ${this.props.date.yy} : ${startCase(this.props.employeeName)}`}
          />
          <CardContent>
            <div className={classes.root}>
              <Grid container direction='row' spacing={16}>
                <Grid item xs>
                  <FormControl>
                    <FormLabel component='legend'>Asistencia completa</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        disabled={this.state.vacation || this.state.license}
                        control={
                          <Switch
                            checked={this.state.absent === 0}
                            onChange={() => this.setAbsentTime(this.state.absent ? 0 : 0.5)}
                          />
                        }
                        label={this.state.absent ? 'no' : 'si'}
                      />
                    </FormGroup>
                  </FormControl>
                  {this.state.absent && !this.state.overtimeOnly
                    ? <FormControl>
                      <FormLabel component='legend'>Ausencia</FormLabel>
                      <FormGroup column>
                        {map([{ label: 'medio dia', value: 0.5 }, { label: 'dia completo', value: 1 }], (option, key) =>
                          <FormControlLabel
                            key={key}
                            control={
                              <Checkbox
                                checked={this.state.absent === option.value}
                                onChange={() => this.setAbsentTime(option.value)}
                              />
                            }
                            label={option.label}
                          />
                        )}
                      </FormGroup>
                    </FormControl> : <></>
                  }
                </Grid>

                <Grid item xs>
                  <FormControl>
                    <FormLabel component='legend'>Vacaciones </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.vacation}
                            onChange={this.setVacation}
                          />
                        }
                        label={this.state.vacation ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <FormControl>
                    <FormLabel component='legend'>Licencia</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.license}
                            onChange={this.setLicense}
                          />
                        }
                        label={this.state.license ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <FormControl>
                    <FormLabel component='legend'>¿Solo Horas Extra?</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.overtimeOnly}
                            onChange={this.setOvertimeOnly}
                          />
                        }
                        label={this.state.overtimeOnly ? 'si' : 'no'}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <Tooltip title='Cualquier información legal/laboral: si prensenta licencia, comprobantes entrega Epp, comprobantes vacaciones, permisos solicitados, etc'>
                    <span>
                      <Button size='small' color='primary' variant='outlined' onClick={() => this.addAnnotation()} disabled={this.props.readOnly}>
                        Agregar Anotacion
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>

                {map(this.state.annotations, (note, idx) =>
                  (<AnnotationComponent key={idx} idx={idx} topicData={this.props.attendanceAnnotationTopics} topicId={note.topicId} text={note.text} files={note.files} onChange={this.onAnnotationChange} readOnly={this.props.readOnly} />)
                )}

              </Grid>
            </div>
          </CardContent>
          <CardActions>
            <Button size='small' color={this.state.modified ? 'secondary' : 'primary'} onClick={this.save} disabled={this.props.readOnly}>
              Guardar
            </Button>
            {
              this.state.updatedAt
                ? <>
                  <Typography variant='body1' gutterBottom>
                    Actualizado el {moment(this.state.updatedAt).tz('America/Santiago').format('YYYY-MM-DD, hh:mm:ss')}
                  </Typography>
                  <span className={classes.root} />
                  <Button size='small' color={'primary'} onClick={this.deleteAssistance} disabled={this.props.readOnly}>
                    Eliminar asistencia
                  </Button>
                </> : <></>
            }
          </CardActions>
        </Card>
        {/* DIALOGS */}
        {/* Select/create annotation */}
        <TableSearchCreateDialog
          handleSelect={this.onSelectedAnnotationTopic}
          handleClose={this.handleClose}
          open={this.state.openAnnotationTopicDialog}
          data={this.props.attendanceAnnotationTopics}
          columns={this.props.annotationTopicColumns}
          title={'Temas'}
          text={'Tema'}
          showCreateComponent={this.state.showCreateComponent}
          showCreateEdit={() => this.setState({ showCreateComponent: true })}
          disableEdit
          createEditComponent={() => (<InputTextDialog
            closeInputTextDialog={() => this.setState({ showCreateComponent: false })}
            callback={this.onCreatedAnnotationTopic}
            open={this.state.showCreateComponent}
            title={'Crear tema anotacion'}
          />)}
        />
      </>
    )
  }
}

AssistanceCard.propTypes = {
  day: PropTypes.string.isRequired,
  membershipId: PropTypes.string.isRequired,
  employeeName: PropTypes.string,
  saveAttendanceRecord: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  deleteAttendance: PropTypes.func.isRequired,
  getAttendanceRecord: PropTypes.func.isRequired,
  getFileDownloadUrl: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  subscribeToAnnotationTopics: PropTypes.func.isRequired,
  createAnnotationTopic: PropTypes.func.isRequired,
  resetCurrentAttendance: PropTypes.func.isRequired,
  classes: PropTypes.object,
  date: PropTypes.object,
  attendanceAnnotationTopics: PropTypes.array.isRequired,
  annotationTopicColumns: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  contractType: PropTypes.string
}

export default withRouter(withStyles(styles)(AssistanceCard))
