import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import EmployeesPaymentMethodRegister from './EmployeesPaymentMethodRegister'

const EmployeeRegisterPaymentMethod = (props) => {
  const edit = props.location.pathname.indexOf('edit') > 1
  const { employeeId, goBack } = props.location.state
  const employee = props.employees[`employee/${employeeId}`]

  return (
    <div>
      {
        props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <EmployeesPaymentMethodRegister {...props}
            employeeId={employeeId}
            employeeName={employee.name}
            employeeLastName={employee.lastName}
            edit={edit}
            loading={props.loading}
            goBack={!!goBack}
          />
      }
    </div>
  )
}

EmployeeRegisterPaymentMethod.propTypes = {
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  employees: PropTypes.object.isRequired
}

export default EmployeeRegisterPaymentMethod
