import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ReactToPrint from 'react-to-print'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
import compact from 'lodash/compact'
import join from 'lodash/join'

import InputDateDialog from '../Components/InputDateDialog'
import { forEach } from 'async'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
})

const CustomToolbarSelect = (props) => {
  return (
    <>
      <Button className={props.classes.button} color='primary' onClick={
        () => props.signDateDialog(map(props.displayData, (value) => ({ idx: value.dataIndex, data: { contractId: value.data[0], employeeId: value.data[1] } })), map(props.selectedRows.data, (row) => row.dataIndex))} >
      Establecer Fecha Firma
      </Button>
      {props.userAccess <= 2
        ? <Button className={props.classes.button} color='primary' onClick={
          () => props.signContract(map(props.displayData, (value) => ({ idx: value.dataIndex, data: { contractId: value.data[0], employeeId: value.data[1], signDate: value.data[7] } })), map(props.selectedRows.data, (row) => row.dataIndex))} >
          Firmar
        </Button>
        : <></>}
      <Button className={props.classes.button} color='primary' onClick={
        () => props.printSignedContracts(map(props.displayData, (value) => ({ idx: value.dataIndex, data: { contractId: value.data[0], employeeId: value.data[1], signature: value.data[8] } })), map(props.selectedRows.data, (row) => row.dataIndex))} >
      Vista Impresion
      </Button>
    </>
  )
}

class ContractSignTable extends React.Component {
  state = {
    openDialog: false,
    contractIds: [],
    contractsToPrint: [],
    openPrintContractDialog: false
  }

  signDateDialog = (data, selectedIndex) => {
    const employeeContractAndId = map(filter(data, (d) => includes(selectedIndex, d.idx)), (e) => e.data)
    const contractIds = map(employeeContractAndId, (employee) => employee.contractId)
    this.setState({ openDialog: true, contractIds })
  }

  setSignDate = (signDate) => {
    this.props.setSignDateForContracts({ signDate, contractIds: this.state.contractIds }, (err, res) => {
      if (err) window.alert(err)
    })
  }

  signContract = (data, selectedIndex) => {
    const employeeContractAndId = map(filter(data, (d) => includes(selectedIndex, d.idx)), (e) => e.data)
    // check all contracts have signDate
    const allValid = reduce(employeeContractAndId, (r, data) => {
      if (!data.signDate) r = false
      return r
    }, true)

    if (!allValid) {
      window.alert('Todos los contratos deben tener la fecha de firma establecida')
    } else {
      // sign
      const contractIds = map(employeeContractAndId, (employee) => employee.contractId)
      const ok = window.confirm(`Seguro de firmar ${contractIds.length} contratos?`)
      if (ok) {
        this.props.signContracts({ contractIds }, (err, res) => {
          if (err) window.alert(err)
        })
      }
    }
  }

  printSignedContracts = (data, selectedIndex) => {
    const employeeContractAndId = map(filter(data, (d) => includes(selectedIndex, d.idx)), (e) => e.data)
    // check all contracts have signature
    const allValid = reduce(employeeContractAndId, (r, data) => {
      if (!data.signature) r = false
      return r
    }, true)

    if (!allValid) {
      window.alert('Todos los contratos deben tener la fecha de firma establecida')
    } else {
      const htmls = []
      // build contracts html
      forEach(employeeContractAndId, (data, cb) => {
        const employeeRecordName = `employee/${data.employeeId}`
        const employeeContract = this.props.employeeContracts[employeeRecordName]
        const employeeRecord = this.props.employeeRecords[employeeRecordName]
        const employeeContractType = employeeContract ? `contract/${employeeContract.type}` : 'contract/type/'
        const contractTypeRecord = this.props.contractTypeRecords[employeeContractType]

        this.props.buildContractHtml(employeeContract, employeeRecord, contractTypeRecord, this.props.farmRecords, this.props.userAccess, (err, html) => {
          if (!err) htmls.push(html)
          cb()
        })
      }, () => {
        this.setState({ contractsToPrint: htmls }, () => this.togglePrintContractDialog())
      })
    }
  }

  togglePrintContractDialog = () => this.setState({ openPrintContractDialog: !this.state.openPrintContractDialog })

  render () {
    const { classes } = this.props
    return (
      <div>
        <MUIDataTable
          title={'Listado de contratos para firmar'}
          data={this.props.data}
          columns={this.props.columns}
          options={{ ...this.props.options,
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
              <CustomToolbarSelect classes={classes} selectedRows={selectedRows} displayData={displayData}
                signDateDialog={this.signDateDialog}
                signContract={this.signContract}
                printSignedContracts={this.printSignedContracts}
                userAccess={this.props.userAccess}
              />
            ),
            // COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
            onFilterChange: (changedColumn, filterList) => {
              let search = compact(map(filterList, (value, idx) => (value[0] ? `${this.props.columns[idx].name}=${value[0]}` : null)))
              search = '?' + join(search, '&')
              this.props.history.replace({ pathname: this.props.location.pathname, search })
            }
          }}
        />
        <InputDateDialog
          closeInputTextDialog={() => this.setState({ openDialog: false })}
          callback={this.setSignDate}
          open={this.state.openDialog}
          title={'Indicar fecha firma contratos'}
          showNullDate
        />
        {/* print contract dialog */}
        <Dialog onClose={this.togglePrintContractDialog} open={this.state.openPrintContractDialog} scroll={'paper'} fullWidth>
          <DialogTitle>Contrato</DialogTitle>
          <DialogContent ref={el => (this.componentRef = el)}>
            {map(this.state.contractsToPrint, (html, key) => (
              <div key={key} style={{ 'pageBreakAfter': 'always', margin: '20px' }}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                <hr />
              </div>))}
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => <Button color='primary' >Imprimir</Button>}
              content={() => this.componentRef}
            />
            <Button onClick={() => this.togglePrintContractDialog()}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ContractSignTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setSignDateForContracts: PropTypes.func,
  signContracts: PropTypes.func,
  buildContractHtml: PropTypes.func,
  employeeContracts: PropTypes.object,
  employeeRecords: PropTypes.object,
  contractTypeRecords: PropTypes.object,
  farmRecords: PropTypes.object,
  userAccess: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  signDateDialog: PropTypes.func.isRequired,
  signContract: PropTypes.func.isRequired,
  printSignedContracts: PropTypes.func.isRequired,
  userAccess: PropTypes.number.isRequired
}

export default withRouter(withStyles(styles)(ContractSignTable))
