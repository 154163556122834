import { createReducer } from '@reduxjs/toolkit'
import pickBy from 'lodash/pickBy'
import clone from 'lodash/clone'
import forEach from 'lodash/forEach'

import { harvestInputQrOpen, companyHarvestFormatsList,
  harvestFormatRecord, companyHarvestDestinationsList,
  harvestDestinationRecord, companyHarvestQualitiesList,
  harvestQualityRecord, harvestDefaults, loading, harvestStart,
  harvestRecordBulk, harvestSitesDays, setSelectedDayAndFarmForTable,
  harvestStats, harvestSeasonStats, updateHarvestRecordKey,
  harvestValidatedDays, todayRecordsList, harvestPayments, updateHarvestRecordPaymentStatus, statsCompute,
  harvestSeasons, harvestSeasonStatsId } from './harvestActions'

const initialState = {
  harvestInputQrOpen: false,
  companyHarvestFormatsList: {},
  harvestFormatRecord: {},
  companyHarvestDestinationsList: {},
  harvestDestinationRecord: {},
  companyHarvestQualitiesList: {},
  harvestQualityRecord: {},
  harvestRecord: {},
  harvestDefaults: {},
  loading: true,
  start: { company: null, date: null },
  selectedDay: { from: '', to: '' },
  selectedFarm: {},
  harvestSitesDays: [],
  harvestStats: {},
  harvestSeasonStats: {},
  harvestValidatedDays: {},
  todayRecordsList: {},
  harvestPayments: {},
  statsCompute: false,
  harvestSeasons: {},
  harvestSeasonStatsId: ''
}

const harvestReducer = createReducer(initialState, {
  [harvestInputQrOpen]: (state, action) => { state.harvestInputQrOpen = action.payload },
  [companyHarvestFormatsList]: (state, action) => { state.companyHarvestFormatsList[action.payload.listName] = action.payload.data },
  [companyHarvestDestinationsList]: (state, action) => { state.companyHarvestDestinationsList[action.payload.listName] = action.payload.data },
  [companyHarvestQualitiesList]: (state, action) => { state.companyHarvestQualitiesList[action.payload.listName] = action.payload.data },
  [todayRecordsList]: (state, action) => { state.todayRecordsList[action.payload.listName] = action.payload.data },
  [harvestStats]: (state, action) => {
    if (action.payload.reset) {
      state.harvestStats = {}
    } if (action.payload.current) {
      state.harvestStats = pickBy(state.harvestStats, (val, key) => key.indexOf(action.payload.current) > 0)
    } else {
      state.harvestStats[action.payload.recordName] = action.payload.data
    }
  },
  [harvestSeasonStats]: (state, action) => { state.harvestSeasonStats = action.payload },
  [harvestSeasonStatsId]: (state, action) => { state.harvestSeasonStatsId = action.payload },
  [harvestSeasons]: (state, action) => { state.harvestSeasons = action.payload },
  [statsCompute]: (state, action) => { state.statsCompute = action.payload },
  [harvestQualityRecord]: (state, action) => {
    state.harvestQualityRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.harvestQualityRecord[action.payload.recordName]
    }
  },
  [harvestFormatRecord]: (state, action) => {
    state.harvestFormatRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.harvestFormatRecord[action.payload.recordName]
    }
  },
  [harvestDestinationRecord]: (state, action) => {
    state.harvestDestinationRecord[action.payload.recordName] = action.payload.data
    if (action.payload.remove) {
      delete state.harvestDestinationRecord[action.payload.recordName]
    }
  },
  [harvestDefaults]: (state, action) => { state.harvestDefaults = action.payload },
  [harvestSitesDays]: (state, action) => { state.harvestSitesDays = action.payload.data },
  [harvestValidatedDays]: (state, action) => { state.harvestValidatedDays = action.payload },
  [loading]: (state, action) => { state.loading = action.payload },
  [harvestStart]: (state, action) => {
    state.start = action.payload
    state.harvestRecord = {}
  },
  [setSelectedDayAndFarmForTable]: (state, action) => {
    state.selectedFarm = action.payload.selectedFarm
    state.selectedDay = action.payload.selectedDay
  },
  [harvestRecordBulk]: (state, action) => {
    state.harvestRecord = action.payload
  },
  [updateHarvestRecordKey]: (state, action) => {
    forEach(action.payload, (data) => {
      let record = clone(state.harvestRecord[data.recordName])
      if (record) {
        record[data.key] = data.value
        state.harvestRecord[data.recordName] = record
      }
    })
  },
  [updateHarvestRecordPaymentStatus]: (state, action) => {
    forEach(action.payload.values, (recordName) => {
      let record = clone(state.harvestRecord[recordName])
      if (record) {
        record.payment.status = action.payload.status
        state.harvestRecord[recordName] = record
      }
    })
  },
  [harvestPayments]: (state, action) => { state.harvestPayments[action.payload.recordName] = action.payload.data }
})

// TODO: evaluate if this is still necessary since we are not using record lists
function reducer (state = initialState, action) {
  // compute state by harvest reducer
  const state1 = harvestReducer(state, action)

  // harvest record actions come from different lists, therefore the list subscribe method was
  // comparing entries on state to entries on each list thus causing a state reset,
  // there were more entries on state that on the harvest records days list for a given day,
  // therefore it though all the other records were deleted.
  // In order to have multiple lists that update the state path, we have a function that creates multiple actions that share the same path, but differ at the end,
  // therefore the listSubscribe method believes it is handling a different slice of state.
  // That is why we have this auxiliary reducer to handle such action types and keep state synced.
  //
  // This also breaks the remove logic from state, thats why we use the deleted prop on the harvest record to remove from state

  if (action.type.split('__:')[0] === 'harvest/harvestRecord') {
    let state2 = {
      ...state1,
      harvestRecord: {
        ...state.harvestRecord,
        [action.payload.recordName]: action.payload.data
      }
    }
    if (action.payload.remove || (action.payload.data && action.payload.data.deleted)) {
      delete state2.harvestRecord[action.payload.recordName]
    }
    return state2
  } else {
    return state1
  }
}

export default reducer
