import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import moment from 'moment-timezone'

import map from 'lodash/map'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import clone from 'lodash/clone'
import filter from 'lodash/filter'
import now from 'lodash/now'

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit / 2
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    minWidth: 120
  }
})

const initialState = {
  contractType: {
    id: '',
    name: '',
    type: ''
  },
  beginDate: '',
  expirationDate: '',
  endDate: '',
  labours: [],
  reportsTo: '',
  farm: { id: '', name: '' },
  paymentMethod: '',
  selectedPaymentMethod: {},
  undeclared: false
}

class EmployeeRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    const { location } = this.props

    if (location.pathname.indexOf('edit') > 1 && location.state.employeeId) {
      const employeeContract = this.props.contracts[`employee/${location.state.employeeId}`]

      if (!isEmpty(employeeContract)) {
        let farm = filter(this.props.farms, (farm) => farm.id === employeeContract.farm)[0]
        if (isEmpty(farm)) farm = { id: '', name: '' }

        let contractType = filter(this.props.contractTypes, (cT) => cT.id === employeeContract.type)[0]
        if (isEmpty(contractType)) contractType = { id: '', name: '', type: '' }

        this.setState({
          ...employeeContract,
          farm: { id: farm.id, name: farm.name },
          contractType,
          paymentMethod: employeeContract.paymentMethod,
          selectedPaymentMethod: filter(this.props.employeeAccounts, (acc) => acc.id === employeeContract.paymentMethod.id)[0]
        })
      }
    }
  }

  onChange = (event) => {
    if (event.target.name === 'paymentMethod') {
      this.setState({ paymentMethod: { type: 'bankAccount', id: event.target.value.id }, selectedPaymentMethod: event.target.value })
    } else {
      this.setState({ [event.target.name || event.target.id]: event.target.value })
    }
  }

  contractPrepare = (type = null) => {
    // format dates
    let beginDate = this.state.beginDate
    if (type === 'type/omit') {
      beginDate = moment(now()).format('YYYY-MM-DD')
    }
    const contract = {
      employeeId: this.props.employeeId,
      type: this.state.contractType.id || type,
      farm: this.state.farm.id || this.props.farms[0].id,
      beginDate,
      expirationDate: this.state.expirationDate,
      labours: this.state.labours,
      reportsTo: this.state.reportsTo,
      paymentMethod: this.state.paymentMethod,
      undeclared: this.state.undeclared
    }

    return contract
  }

  contractRegister = (type = null) => {
    if (!this.state.contractType.id && !this.state.beginDate && !this.state.farm.id && this.state.paymentMethod) {
      type = 'type/omit'
    }

    const contract = this.contractPrepare(type)
    this.props.createContract({ contract }, () => {
      this.props.history.push(`/app/employees/table`)
    })
  }

  contractEdit = () => {
    const ok = window.confirm('Seguro deseas modificar contrato?')
    if (ok) {
      const contract = this.contractPrepare()
      const currentContract = this.props.contracts[`employee/${this.props.location.state.employeeId}`]
      // we can change paymentMethod without altering contract
      // NOTE: for undeclared we do a double negative because it is either true or undefined
      if (!(isEqual(contract.type, currentContract.type) && isEqual(contract.undeclared, !!currentContract.undeclared) && isEqual(contract.beginDate, currentContract.beginDate) && isEqual(contract.expirationDate, currentContract.expirationDate) && isEqual(contract.farm, currentContract.farm))) {
        this.props.editContract({ contract }, () => {
          this.props.history.goBack()
        })
      } else {
        if (!isEqual(contract.paymentMethod, currentContract.paymentMethod)) {
          this.props.editContractPaymentMethod({ contract }, () => {
            this.props.history.goBack()
          })
        }
      }
    }
  }

  contractDisabled = () => {
    if (!this.state.contractType.id && this.state.beginDate && !this.state.farm.id && this.state.paymentMethod) return false
    if (this.state.contractType.id && this.state.beginDate && this.state.farm.id && this.state.paymentMethod) return false
    return true
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {this.props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
          : <div>
            <Card>
              <CardHeader
                title={this.props.edit ? 'Editar datos contrato' : 'Registro contrato'}
              />
              <CardContent>
                <Grid container direction='row' spacing={24}>
                  <Grid item xs>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id='beginDate'
                        name='beginDate'
                        label='Fecha Inicio'
                        InputLabelProps={{
                          shrink: true
                        }}
                        type='date'
                        value={this.state.beginDate}
                        onChange={this.onChange}
                        className={[classes.textField, classes.selectEmpty].join(' ')}
                      />
                    </FormControl>

                    <form className={classes.root} autoComplete='off'>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor='age-label-placeholder'>
                            Tipo Contrato
                        </InputLabel>

                        <Select
                          value={filter(this.props.contractTypes, (c) => c.id === this.state.contractType.id)[0] || this.state.contractType}
                          onChange={this.onChange}
                          name='contractType'
                          autoWidth
                        >
                          {map(this.props.contractTypes, (contract, key) => {
                            return <MenuItem key={key} value={contract}>{startCase(contract.name)}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      {/* Handle plazo fijo contracts */}
                      {this.state.contractType.type === 'p'
                        ? <FormControl className={classes.formControl}>
                          <TextField
                            id='expirationDate'
                            name='expirationDate'
                            label='Fecha termino'
                            InputLabelProps={{
                              shrink: true
                            }}
                            type='date'
                            value={this.state.expirationDate}
                            onChange={this.onChange}
                            className={[classes.textField, classes.selectEmpty].join(' ')}
                          />
                        </FormControl>
                        : <></>}
                    </form>

                    <form className={classes.root} autoComplete='off'>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor='age-label-placeholder'>
                            Predio
                        </InputLabel>

                        <Select
                          value={filter(this.props.farms, (farm) => farm.id === this.state.farm.id)[0] || this.state.farm}
                          onChange={this.onChange}
                          name='farm'
                          autoWidth
                        >
                          {map(this.props.farms, (farm, key) => {
                            return <MenuItem key={farm.id} value={farm}>{startCase(farm.name)}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </form>

                    <br />
                    <br />
                    <Typography variant='body1' gutterBottom>
                      Medio de pago
                    </Typography>

                    <form className={classes.root} autoComplete='off'>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink>
                        Seleccionar
                        </InputLabel>

                        <Select
                          value={this.state.selectedPaymentMethod || ''}
                          onChange={this.onChange}
                          name='paymentMethod'
                          autoWidth
                          error={!this.state.paymentMethod}
                        >
                          {map(this.props.employeeAccounts, (acc, idx) => <MenuItem key={idx} value={acc}>{`${startCase(acc.bankName)} : ${acc.bankAccountNumber}`}</MenuItem>)}
                        </Select>

                      </FormControl>
                    </form>
                  </Grid>

                  <Grid item xs>
                    <FormControl>
                      <FormLabel component='legend'>Contrato declarado</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!this.state.undeclared}
                              onChange={() => this.setState({ undeclared: !this.state.undeclared })}
                            />
                          }
                          label={this.state.undeclared ? 'no' : 'si'}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                {this.props.edit
                  ? <Button size='small' color='primary' onClick={() => this.contractEdit()} disabled={this.contractDisabled()}>
                      Guardar
                  </Button>
                  : <Button size='small' color='primary' onClick={() => this.contractRegister()} disabled={this.contractDisabled()}>
                        Registrar contrato
                  </Button>
                }
              </CardActions>
            </Card>

          </div>
        }
      </div>
    )
  }
}

EmployeeRegister.propTypes = {
  classes: PropTypes.object.isRequired,
  contractTypes: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  createContract: PropTypes.func.isRequired,
  editContract: PropTypes.func.isRequired,
  editContractPaymentMethod: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  employeeId: PropTypes.string,
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  contracts: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  employeeAccounts: PropTypes.array.isRequired
}

export default withStyles(styles)(EmployeeRegister)
