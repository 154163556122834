import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import clone from 'lodash/clone'
import round from 'lodash/round'

import TextField from '@material-ui/core/TextField'

const initialState = {
  hour: '',
  minutes: ''
}

class HourMinuteInputDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  handleChange = (event) => {
    const { id, value } = event.target
    if (id === 'hour') {
      if (value % 1 !== 0 || value < 0) {
        window.alert('Ingresa un valor entero')
        this.setState({ hour: '' })
        return
      }

      if (value === '') {
        this.setState({ hour: '' })
        return
      }

      if (this.props.maxValue && value > this.props.maxValue) {
        window.alert(`Ingresa una hora entre 0 y ${this.props.maxValue}`)
        this.setState({ hour: '' })
        return
      }
      this.setState({ hour: Number(value) })
    }

    if (id === 'minutes') {
      if (value % 1 !== 0 || value < 0) {
        window.alert('Ingresa un valor entero')
        this.setState({ minutes: '' })
        return
      }

      if (value === '') {
        this.setState({ minutes: '' })
        return
      }

      if (value > 59) {
        window.alert('Ingresa minutos entre 0 y 59')
        this.setState({ minutes: '' })
        return
      }
      this.setState({ minutes: Number(value) })
    }
  }

  handleClose = () => {
    let hour = this.state.hour
    if (hour === '') hour = 0
    let minutes = this.state.minutes
    if (minutes === '') minutes = 0
    this.props.callback(Number(hour) + round(Number(minutes) / 60, 2))
    this.props.handleClose()
    this.setState(clone(initialState))
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{this.props.title || 'Ingresa horas y minutos'}</DialogTitle>
          <DialogContent>
            <TextField
              label='horas'
              autoFocus
              id='hour'
              fullWidth
              type='number'
              onChange={this.handleChange}
              value={this.state.hour}
            />
            <TextField
              label='minutos'
              id='minutes'
              fullWidth
              type='number'
              onChange={this.handleChange}
              value={this.state.minutes}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
                Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

HourMinuteInputDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  maxValue: PropTypes.number
}

export default HourMinuteInputDialog
