import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import map from 'lodash/map'
import clone from 'lodash/clone'
import startCase from 'lodash/startCase'

const initialState = {
  value: { type: '', name: '' }
}

export default class InputTextDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  handleClose = () => {
    this.props.closeInputTextDialog()
    this.setState(initialState)
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  handleSave = () => {
    this.props.callback(this.state.value)
    this.handleClose()
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{this.props.title}</DialogTitle>
          <DialogContent>
            <Select
              value={this.state.value}
              onChange={this.handleChange}
              fullWidth
              disabled={this.state.disable}
            >
              {map(this.props.options, (value, idx) => {
                return <MenuItem key={idx} value={value}>{startCase(value.type) + ' : ' + startCase(value.name)}</MenuItem>
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancelar
            </Button>
            <Button onClick={this.handleSave} color='primary'>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

InputTextDialog.propTypes = {
  closeInputTextDialog: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  options: PropTypes.array.isRequired
}
