import { createAction } from '@reduxjs/toolkit'

import remove from 'lodash/remove'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import drop from 'lodash/drop'
import join from 'lodash/join'
import split from 'lodash/split'
import chunk from 'lodash/chunk'
import noop from 'lodash/noop'
import now from 'lodash/now'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'
import moment from 'moment-timezone'
import 'moment/locale/es'
import eachSeries from 'async/eachSeries'
import each from 'async/each'

import { makeRpc } from '../../Main/redux/mainActionsDeepstream'

import { listSubscribe, recordSnapshot } from '../../Main/redux/mainActionsRecords'

let companyId = null

export const companyEmployeesList = createAction('employees/companyEmployeesList')
export const companyEmployeesListAltered = createAction('employees/companyEmployeesListAltered')
export const employeesRecords = createAction('employees/employeesRecords')
export const employeesContracts = createAction('employees/employeesContracts')
export const employeesBankAccount = createAction('employees/employeesBankAccount')
export const employeesRecordsBulk = createAction('employees/employeesRecordsBulk')
export const employeesContractsBulk = createAction('employees/employeesContractsBulk')
export const loading = createAction('employees/loading')
export const companyContractTypesList = createAction('employees/companyContractTypesList')
export const contractTypeRecord = createAction('employees/contractTypeRecord')
export const createEmployeeResult = createAction('employees/createEmployeeResult')
export const employeesPerDayAndFarm = createAction('employees/employeesPerDayAndFarm')
export const employeesStart = createAction('employees/start')

/**
 *
 * helper functions
 */

const getEmployeeRecordName = (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')

const getEmployeeCompanyMembership = (state, employeeId) => {
  const companyId = state.auth.clientData.activeCompany
  return filter(state.employees.companyEmployeesList[`company/${companyId}/list/employees`], (m) => m.indexOf(employeeId) > 1)[0]
}

// START
export const startEmployeesData = (cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  companyId = state.auth.clientData.activeCompany
  // first load
  const { start } = state.employees
  if (!start || start !== companyId) {
    dispatch(listSubscribe(`company/${companyId}/list/employees`, companyEmployeesMemberships, false, () => {
      dispatch(listSubscribe(`company/${companyId}/list/contract/types`, companyContractTypesList, contractTypeRecord, () => {
        dispatch(employeesStart(companyId))
        return cb()
      }))
    }))
  } else {
    // this reducer holds inactive memberships that were searched for and thus are erased when nbo longer needed
    dispatch(companyEmployeesListAltered({ listName: `company/${companyId}/list/employees`, clear: true }))
    return cb()
  }
}

const companyEmployeesMemberships = (value) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  dispatch(companyEmployeesList({ ...value }))
  dispatch(_employeeRecordAndContract(value.data, () => {
    dispatch(loading(false))
  }))
}

/**
 * _employeeRecordAndContract: makes rpc to get data in chunks from db.
 * TODO: pagination for harvest record tables, and for employee summary calculate for each one on server side upon request when handling too much data
 * @param  {[type]} payload [listName, entries]
 * @return {[type]}         [description]
 */
export const _employeeRecordAndContract = (membershipList, callback = noop) => (dispatch, getState) => {
  let result = {}

  const rpc = (chunks, cb) => {
    if (chunks.length === 0) return cb()
    dispatch(makeRpc('employee/get/recordAndContract', { companyId, records: chunks[0] }, (err, response) => {
      if (err) {
        console.error(err)
      }
      result = merge(result, response)
      return rpc(drop(chunks), cb)
    }))
  }

  rpc(chunk(membershipList, 100), () => {
    if (result.employees) {
      dispatch(employeesRecordsBulk(result.employees))
    }
    if (result.companyContracts) {
      dispatch(employeesContractsBulk(result.companyContracts))
    }
    // done
    callback()
  })
}

const getEmployeeRecord = (membership, state, force = false, cb = noop) => (dispatch, getState, dsClient) => {
  const employeeRecordName = getEmployeeRecordName(membership)
  const { employeeRecord } = state.employees

  if (force || isEmpty(employeeRecord[employeeRecordName])) {
    dispatch(recordSnapshot(employeeRecordName, employeesRecords, (err) => {
      if (err) console.error(err)
      cb()
    }))
  } else {
    return cb()
  }
}

export const getEmployeeContract = (membership, state, force = false, cb = noop) => (dispatch, getState, dsClient) => {
  const recordName = getEmployeeRecordName(membership)
  const { employeeContract } = state.employees

  if (force || isEmpty(employeeContract[recordName])) {
    // get contract Id
    dispatch(recordSnapshot(membership, null, (err, membershipData) => {
      if (err) {
        console.error(err)
        return cb()
      }
      // get record
      dispatch(recordSnapshot(`contract/${membershipData.contract}`, null, (err, data) => {
        if (err) {
          console.error(err)
          return cb()
        }
        data.contractId = membershipData.contract
        dispatch(employeesContracts({ recordName, data }))
        return cb()
      }))
    }))
  } else {
    return cb()
  }
}

export const getEmployeeData = (employeeId, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const membershipRecordName = getEmployeeCompanyMembership(state, employeeId)
  dispatch(getEmployeeRecord(membershipRecordName, state, true, () => {
    dispatch(getEmployeeContract(membershipRecordName, state, true, () => {
      dispatch(getEmployeeBankAccount(employeeId.split('/')[1], cb))
    }))
  }))
}

export const getNonRegisteredEmployeeData = (employeeId, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(recordSnapshot(`employee/${employeeId}`, employeesRecords, (err, data) => {
    if (err) console.error(err)
    cb(err, data)
  }))
}

export const createEmployee = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  dispatch(makeRpc('employee/create', { companyId, ...payload }, (err, res) => {
    console.log(err || res)

    const { countryId } = payload.employee
    dispatch(recordSnapshot(`employee/${countryId}`, employeesRecords, (err, data) => {
      if (err) console.error(err)
      dispatch(loading(false))
      cb(err, res)
    }))
  }))
}

export const editEmployee = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))

  dispatch(makeRpc('employee/edit', { companyId, ...payload }, (err, res) => {
    console.log(err || res)
    if (res === 200) {
      const { countryId } = payload.employee
      dispatch(recordSnapshot(`employee/${countryId}`, employeesRecords, (err, data) => {
        if (err) console.error(err)
        dispatch(loading(false))
        cb(err, res)
      }))
    } else {
      dispatch(loading(false))
      cb(err, res)
    }
  }))
}

export const createContract = (payload, cb = noop) => (dispatch) => {
  dispatch(loading(true))
  dispatch(makeRpc('employee/contract/create', { companyId, ...payload }, (err, res) => {
    console.log(err || res)
    dispatch(loading(false))
    cb(err, res)
  }))
}

export const editContract = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  const state = getState()
  const membershipRecordName = getEmployeeCompanyMembership(state, payload.contract.employeeId)

  dispatch(makeRpc('employee/contract/edit', { companyId, membershipRecordName, ...payload }, (err, res) => {
    dispatch(loading(false))
    cb(err, res)
  }))
}

export const editContractPaymentMethod = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  const state = getState()
  const membershipRecordName = getEmployeeCompanyMembership(state, payload.contract.employeeId)

  dispatch(makeRpc('employee/contract/edit/paymentMethod', { companyId, membershipRecordName, ...payload }, (err, res) => {
    dispatch(loading(false))
    cb(err, res)
  }))
}

export const uploadContractFiles = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  const state = getState()
  const membershipRecordName = getEmployeeCompanyMembership(state, payload.employeeId)

  dispatch(makeRpc('employee/contract/edit/files', { companyId, membershipRecordName, ...payload }, (err, res) => {
    console.log(err || res)
    if (res === 200) {
      dispatch(getEmployeeContract(membershipRecordName, state, true, () => {
        dispatch(loading(false))
        cb()
      }))
    } else {
      dispatch(loading(false))
      cb(err, res)
    }
  }))
}

export const terminateContract = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const membershipId = join(drop(split(getEmployeeCompanyMembership(state, payload.employeeId), '/')), '/')

  dispatch(makeRpc('employee/contract/terminate', { membershipId, companyId, endDate: payload.endDate }, (err, res) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    dispatch(employeesContracts({ recordName: `employee/${payload.employeeId}`, remove: true }))
    cb(err, res)
  }))
}

export const editPath = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const { path, data, employeeId } = payload
  dispatch(makeRpc('employee/edit/path', { companyId, path, employeeId, data }, (err, res) => {
    if (err) console.error(err)
    dispatch(recordSnapshot(`employee/${employeeId}`, employeesRecords, (err) => {
      if (err) console.error(err)
      cb(err, res)
    }))
  }))
}

export const createPaymentMethod = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const { employeeId } = payload

  dispatch(makeRpc('employee/bank-account/create', { companyId, ...payload }, (err, res) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    // update state
    dispatch(getEmployeeBankAccount(employeeId, cb))
  }))
}

export const deletePaymentMethod = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  if (payload.employeeContract.paymentMethod.id === payload.bankAccount.id) {
    window.alert('No se puede eliminar la cuenta asociada al contrato de trabajo. Asocie una nueva cuenta al trabajador antes de eliminar la actual.')
    return cb()
  }

  dispatch(makeRpc('employee/bank-account/delete', { companyId, bankAccountId: payload.bankAccount.id, employeeId: payload.employeeId }, (err, res) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    // update state
    dispatch(getEmployeeBankAccount(payload.employeeId, cb))
  }))
}

export const getEmployeeBankAccount = (employeeId, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(recordSnapshot(`employee/${employeeId}/bank-accounts`, null, (err, data) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    dispatch(employeesBankAccount({ recordName: `employee/${employeeId}`, data: data[companyId] || [] }))
    cb()
  }))
}

export const getContracts = (employeeId, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(makeRpc(`employee/get/contracts`, { employeeId, companyId }, (err, res) => {
    if (err) {
      console.error(err)
    }
    cb(err, res)
  }))
}

export const getMembership = (employeeId, cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(makeRpc(`membership/get`, { id: employeeId, companyId }, (err, res) => {
    if (err) {
      return cb(err)
    }
    if (isEmpty(res.data)) {
      return cb()
    }
    // this reducer holds inactive memberships that were searched for and thus will be erased when nbo longer needed
    dispatch(companyEmployeesListAltered({ listName: `company/${companyId}/list/employees`, push: true, data: `membership/${res.data[0]}` }))
    dispatch(getEmployeeData(employeeId, cb))
  }))
}

export const getAbsentEmployees = (cb = noop) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  // build array of last 15 days
  const today = moment(now()).tz('America/Santiago').format('YYYY-MM-DD')
  const dates = [today]
  for (let index = 1; index < 15; index++) {
    dates.push(moment(today).subtract(index, 'days').format('YYYY-MM-DD'))
  }
  const farmRecords = getState().farms.farmRecord
  const farmIds = map(farmRecords, (f, id) => id.split('/')[1])
  // get all stats for that time frame
  let employeesPerDate = reduce(dates, (r, d) => {
    r[d] = []
    return r
  }, {})

  eachSeries(dates, (date, cbDate) => {
    each(farmIds, (farmId, cbFarm) => {
      let employeesPerFarm = []
      dispatch(recordSnapshot(`harvest/${companyId}/stats/${farmId}/${date}`, null, (err, data) => {
        if (err || isEmpty(data)) {
          // no data
          return cbFarm()
        }
        // get employees per farm and date
        employeesPerFarm = reduce(data, (result, value, site) => {
          result = uniq(concat(value.employees))
          return result
        }, [])
        if (employeesPerFarm.length > 0) employeesPerDate[date].push({ farmId, employees: employeesPerFarm })
        cbFarm()
      }))
    }, cbDate)
  }, () => {
    dispatch(loading(false))
    dispatch(employeesPerDayAndFarm(employeesPerDate))
  })
}
