import React, { Component } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Main from './Main/MainContainer.js'
import Auth from './Auth/AuthContainer.js'

const PrivateRoute = ({ component, isAuthenticated, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      isAuthenticated
        ? React.createElement(component, props)
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />}
  />

class App extends Component {
  render () {
    return (
      <Switch>
        <Route path='/' exact render={() => <Redirect to='/app' />} />
        <PrivateRoute
          isAuthenticated={this.props.isAuthenticated}
          path='/app'
          component={Main}
        />
        <Route exact path='/login' component={Auth} />
      </Switch>
    )
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object
}

const mapStateToProps = state => ({ isAuthenticated: state.auth.isAuthenticated })

export default withRouter(connect(mapStateToProps)(App))
