import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import UserAccessComponent from '../Components/UserAccessComponent'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing.unit / 2
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
})

class Employees extends React.Component {
  componentDidMount () {
    this.props.startEmployeesData()
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>

          {/* REGISTER AND REVIEW WORKERS */}
          <Grid item xs={6}>
            <UserAccessComponent access={4} {...this.props}>
              <Button className={classes.button} color='primary' variant='outlined' component={Link} to='/app/employees/register'>
                Registrar nuevo trabajador
              </Button>
            </UserAccessComponent>
          </Grid>
          <Grid item xs={6}>
            <Button className={classes.button} variant='outlined' component={Link} to='/app/employees/table'>
              Ver tabla trabajadores
            </Button>
          </Grid>

          {/* ATTENDANCE AND ATTENDANCE SUMMARY */}
          <UserAccessComponent access={4} {...this.props}>
            <Grid item xs={6}>
              <Button className={classes.button} color='primary' variant='outlined' component={Link} to='/app/activity/table'>
                    Registrar Actividad Diaria
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className={classes.button} variant='outlined' component={Link} to='/app/activity/summary'>
                    Resumen Actividad Mensual
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className={classes.button} variant='outlined' component={Link} to='/app/employees/absent'>
                    Inasistencias Cosecha
              </Button>
            </Grid>

          </UserAccessComponent>

          {/* CONTRACT */}
          <UserAccessComponent access={4} {...this.props}>
            <UserAccessComponent access={3} {...this.props}>
              <Grid item xs={6}>
                <Button className={classes.button} variant='outlined' component={Link} to='/app/contract/type'>
                    Registrar Formato Contrato
                </Button>
              </Grid>
            </UserAccessComponent>
            <Grid item xs={6}>
              <Button className={classes.button} variant='outlined' component={Link} to='/app/contract/type/table'>
                    Ver Tabla formato contratos
              </Button>
            </Grid>
            <UserAccessComponent access={4} {...this.props}>
              <Grid item xs={6}>
                <Button className={classes.button} variant='outlined' component={Link} to='/app/contract/sign/table'>
                    firmar contratos trabajadores
                </Button>
              </Grid>
            </UserAccessComponent>
          </UserAccessComponent>
        </Grid>
      </div>
    )
  }
}

Employees.propTypes = {
  startEmployeesData: PropTypes.func.isRequired,
  userAccess: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Employees)
