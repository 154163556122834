import { createAction } from '@reduxjs/toolkit'

import memoize from 'memoize-one'

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'

import each from 'async/each'

// local imports
import { login } from '../../Auth/redux/authActions.js'
import { startFarmsData } from '../../Farms/redux/farmsActions.js'
import { startHarvestData } from '../../Harvest/redux/harvestActions.js'
import { startEmployeesData } from '../../Employees/redux/employeesActions.js'
import { startContractData } from '../../Contract/redux/contractActions'
import { sendPendingRequests } from './mainActionsPendingRequests'
import { listSubscribe, recordSubscribe, dispatchBatchInterval } from './mainActionsRecords'
import { appConfig } from '../../../config'

// in order to know when we first load

export const connectionState = createAction('main/connectionState')
export const connectionError = createAction('main/connectionError')
export const mainStart = createAction('main/start')
export const loading = createAction('main/loading')
export const userRequests = createAction('main/userRequests')

export const connectionHandler = () => (dispatch, getState, dsClient) => {
  // start the dispatch batch interval
  dispatch(dispatchBatchInterval())

  dispatch(loading(true))
  // listen to online event in order to reconnect when needed
  window.addEventListener('online', () => {
    dispatch(login())
  })

  // upon first login, the connectionStateChanged event has already been triggered
  const status = dsClient().getConnectionState()
  const { start } = getState().main

  if (status === 'OPEN' && start) {
    dispatch(connectionState(status))
    setTimeout(function () {
      dispatch(sendPendingRequests())
    }, 1000)
    dispatch(mainStart(false))
    dispatch(getUserData())
  }

  if (status !== 'OPEN' && !navigator.onLine) {
    // we are off internet
    // do not show loading indicator
    dispatch(loading(false))
  }

  // re-mounting with an already open connection
  if (status === 'OPEN' && !start) {
    dispatch(loading(false))
  }

  // set up handlers
  dsClient().on('connectionStateChanged', status => {
    dispatch(connectionState(status))
    const { start } = getState().main

    if (status === 'AWAITING_AUTHENTICATION') checkStuckConnection(dispatch, dsClient())

    if (status === 'OPEN') {
      dispatch(connectionError({}))
      setTimeout(function () {
        dispatch(sendPendingRequests())
      }, 1000)

      if (start) {
        dispatch(mainStart(false))
        dispatch(getUserData())
      }
    }
  })

  dsClient().on('error', (error, event, topic) => {
    console.error(error)
    // TODO: dsClient().setData permission errors gives back only record name
    // USE A TOAST or NOTIFICATION TO INFORM THE ERROR
    dispatch(connectionError({ event, topic }))
  })
}

const checkStuckConnection = (dispatch, ds) => {
  // if AWAITING_AUTHENTICATION after 2 secs, re-login
  setTimeout(() => {
    if (ds.getConnectionState() === 'AWAITING_AUTHENTICATION') dispatch(login())
  }, 2 * 1000)
}

/*
  Get user data
  Once the user is logged subscribe to basic records and lists in order to operate
 */
export const userMembershipList = createAction('main/userMembershipList')
export const userMemberships = createAction('main/userMemberships')
export const userData = createAction('main/userData')
export const userAccess = createAction('main/userAccess')
export const companySelectorData = createAction('main/companySelectorData')

const _userAccess = memoize((userMemberships, companyId) => reduce(userMemberships, (result, value) => {
  if (value.company === companyId) result = value.access
  return result
}, 99))

// Should be called only once per client load. If we call again we will be adding redundant subscriptions
const getUserData = (cb) => (dispatch, getState) => {
  let state = getState()
  const { userId } = state.auth.clientData

  // too soon?
  if (!userId) {
    setTimeout(function () {
      return dispatch(getUserData(cb))
    }, 10)
  } else {
    dispatch(recordSubscribe(`user/${userId}`, userData, null, null, () => {
      console.log('done:userId')
      dispatch(listSubscribe(`user/${userId}/list/memberships`, userMembershipList, userMemberships, () => {
        console.log('done:memberships')
        // NOTE: we use a timeout since we are batching dispatches and membership info might not be ready on state yet on first login
        setTimeout(() => {
          dispatch(formatCompanySelectorData(() => {
            dispatch(startFarmsData(() => {
              console.log('done:farms')
              dispatch(userAccessFunction(() => {
                console.log('done:userAccess')
                dispatch(startContractData(() => {
                  console.log('done:contract')
                  dispatch(startEmployeesData(() => {
                    console.log('done:employes')
                    dispatch(startHarvestData(() => {
                      console.log('done:harvest')
                      console.log('done:main')
                      dispatch(loading(false))
                    }))
                  }))
                }))
              }))
            }))
          }))
        }, appConfig.dispatchBatchInterval)
      }))
    }))
  }
}

const userAccessFunction = (cb) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const { userMemberships } = state.main
  if (isEmpty(userMemberships)) {
    setTimeout(function () {
      dispatch(userAccessFunction(cb))
    }, 10)
  } else {
    const access = _userAccess(userMemberships, companyId)
    dispatch(userAccess(access))
    return cb()
  }
}

/**
 * [formatCompanySelectorData description]
 * @param  {[type]} [cb=null] [description]
 * @return {[type]}           [description]
 */
export const formatCompanySelectorData = (cb = null) => (dispatch, getState, dsClient) => {
  let userCompanies = map(getState().main.userMemberships, (m) => `company/${m.company}`)

  each(userCompanies, (recordName, callback) => {
    dispatch(recordSubscribe(recordName, companySelectorData, true, () => {
      callback()
    }))
  }, (err) => {
    if (err) console.error(err)
    // we check if entries changed, if they did, check if we need to remove listener for record and remove it from state
    // let currentRecords = keys(getState().main[companySelectorData.toString().split('/')[1]])
    // TODO: if entries changed they will be removed from state and discarded from client
    if (cb) return cb()
  })
}
