import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import PhotoIcon from '@material-ui/icons/PhotoCameraOutlined'
import IconButton from '@material-ui/core/IconButton'
import CardHeader from '@material-ui/core/CardHeader'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress'

import memoize from 'memoize-one'
import moment from 'moment-timezone'

import map from 'lodash/map'
import clone from 'lodash/clone'
import startCase from 'lodash/startCase'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import random from 'lodash/random'

import { isChileRut, formatChileRut } from '../../utils'

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit / 2
  },
  progress: {
    flexGrow: 1
  }
})

const initialState = {
  site: '',
  crop: '',
  employee: '',
  row: null,
  destination: '',
  quality: null,
  format: null,
  quantity: null,
  weight: null,
  date: null,
  warning: false,
  setDate: false,
  loading: false
}

class HarvestInputForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  onChange = (event) => {
    if (event.target.id === 'employee') {
      let value = formatChileRut(event.target.value)

      this.setState({ [event.target.id]: value }, () => {
        if (isChileRut(this.state.employee)) {
          if (!includes(this.props.employeeIds, this.state.employee)) {
            this.setState({ warning: true })
          } else {
            this.setState({ warning: false })
          }
        }
      })
    } else if (event.target.id === 'quantity' && event.target.value === '') {
      this.setState({ [event.target.id]: 0 })
    } else if (event.target.id === 'date') {
      // we set a random time for the given date in order to avoiud potential conflicts with equal timestamps
      let hour = random(15, 23)
      let min = random(0, 59)
      min = min.toString().length === 2 ? min : `0${min}`
      let sec = random(0, 59)
      sec = sec.toString().length === 2 ? sec : `0${sec}`

      const timestamp = moment(`${event.target.value} ${hour}:${min}:${sec}`).tz('America/Santiago').unix() * 1000 + random(0, 999)

      this.setState({ date: timestamp })
    } else {
      this.setState({ [event.target.name || event.target.id]: event.target.value })
    }
  }

  onClick = (defaults) => {
    const newRecord = {
      state: this.state,
      defaults
    }

    this.setState({ loading: true })

    this.props.createHarvestRecord(newRecord, () => {
      this.setState(clone(initialState))
    })
  }

  defaultFormat = memoize((formats, defaultFormat) => filter(formats, (format) => format.id === defaultFormat)[0] || '')
  defaultQuality = memoize((qualities, defaultQuality) => filter(qualities, (quality) => quality.id === defaultQuality)[0] || '')
  defaultSite = memoize((sites, defaultSite) => filter(sites, (site) => site.id === defaultSite)[0] || '')
  defaultSiteCrop = memoize((site, defaultSiteCrop) => filter(site.crops, (crop) => crop.id === defaultSiteCrop)[0] || '')
  disabled = memoize((state, defaultSiteCrop, defaultFormat, defaultQuality, defaultSite, defaultQuantity, defaultEmployee) => {
    if (state.loading) return true
    if ((state.site || defaultSite) && (state.format || defaultFormat) && (state.crop || defaultSiteCrop) && (state.quantity || defaultQuantity) && (state.quality || defaultQuality)) {
      if (state.employee && isChileRut(state.employee)) {
        return false
      }
      if (defaultEmployee && isChileRut(defaultEmployee)) {
        return false
      }
    }
    return true
  })

  render () {
    const { classes } = this.props
    const defaultFormat = this.defaultFormat(this.props.formats, this.props.defaultFormat)
    const defaultQuality = this.defaultQuality(this.props.qualities, this.props.defaultQuality)
    const defaultSite = this.defaultSite(this.props.sites, this.props.defaultSite)
    const defaultSiteCrop = this.defaultSiteCrop(this.state.site || defaultSite, this.props.defaultSiteCrop)
    const disabled = this.disabled(this.state, defaultSiteCrop, defaultFormat, defaultQuality, defaultSite, this.props.defaultQuantity, this.props.employeeId)

    return (
      <div>
        <Card>
          <CardHeader
            title='Registro cosecha'
          />
          <CardContent>
            <TextField
              id='employee'
              label='RUT'
              placeholder='RUT'
              autoFocus
              fullWidth
              type='tel'
              onChange={this.onChange}
              className={classes.textField}
              value={this.state['employee'] || this.props.employeeId}
              error={!isChileRut(this.state['employee']) && isEmpty(this.props.employeeId)}
              disabled={this.props.editMode}
              helperText={this.state.warning ? 'Trabajador no registrado' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => this.setState({ employee: '', warning: false }, () => this.props.openCamera())}>
                      <PhotoIcon />
                    </IconButton>
                  </InputAdornment>
                ) }}
            />
            <br />
            <br />
            <div>
              <InputLabel shrink htmlFor='age-label-placeholder'>
                  Formato
              </InputLabel>
              <Select
                value={this.state.format || defaultFormat}
                onChange={this.onChange}
                name='format'
                fullWidth
              >
                {map(this.props.formats, (value, idx) => {
                  return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
                })}
              </Select>
            </div>
            <br />
            <br />

            <div>
              <InputLabel shrink htmlFor='age-label-placeholder'>
                  Calidad
              </InputLabel>
              <Select
                value={this.state.quality || defaultQuality}
                onChange={this.onChange}
                name='quality'
                fullWidth
              >
                {map(this.props.qualities, (value, idx) => {
                  return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
                })}
              </Select>
            </div>
            <br />
            <br />

            <TextField
              id='quantity'
              label='Cantidad'
              placeholder='Cantidad'
              fullWidth
              type='number'
              onChange={this.onChange}
              className={classes.textField}
              value={this.state['quantity'] || (this.state['quantity'] !== 0 && this.props.defaultQuantity)}
            />
            <br />
            <br />
            <div>
              <InputLabel shrink htmlFor='age-label-placeholder'>
                Cuartel
              </InputLabel>
              <Select
                value={this.state.site || defaultSite}
                onChange={this.onChange}
                name='site'
                fullWidth
              >
                {map(this.props.sites, (value, idx) => {
                  return <MenuItem key={idx} value={value}>{`${startCase(value.type)} ${startCase(value.name)}`}</MenuItem>
                })}
              </Select>
            </div>
            <br />
            <br />

            <div>
              <InputLabel shrink htmlFor='age-label-placeholder'>
                Variedad
              </InputLabel>
              <Select
                value={this.state.crop || defaultSiteCrop}
                onChange={this.onChange}
                name='crop'
                fullWidth
              >
                {map(this.state.site.crops || defaultSite.crops, (value, idx) => {
                  return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
                })}
              </Select>
            </div>
            <br />
            <br />
            {
              !this.props.editMode && this.props.userAccess < 4
                ? <>
                  <div>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.setDate}
                            onChange={() => this.setState({ setDate: !this.state.setDate })}
                          />
                        }
                        label='Definir fecha'
                      />
                    </FormGroup>
                    {this.state.setDate
                      ? <TextField
                        id='date'
                        label='Fecha'
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        type='date'
                        onChange={this.onChange}
                        className={classes.textField}
                      />
                      : <></>}
                  </div>
                  <br />
                  <br />
                </> : <></>
            }

          </CardContent>
          <CardActions>
            <Button variant='contained' color='primary' onClick={() => this.onClick({ defaultSite, defaultFormat, defaultQuality, defaultQuantity: this.props.defaultQuantity, defaultSiteCrop, defaultEmployee: this.props.employeeId })} disabled={disabled}>
              {this.props.editMode ? 'Guardar' : 'Crear'}
            </Button>
            <div className={classes.progress}>
              {this.state.loading ? <LinearProgress /> : <></>}
            </div>
          </CardActions>
        </Card>
      </div>
    )
  }
}

HarvestInputForm.propTypes = {
  classes: PropTypes.object.isRequired,
  employeeId: PropTypes.string,
  defaultQuantity: PropTypes.number,
  defaultFormat: PropTypes.string,
  defaultSite: PropTypes.string,
  defaultQuality: PropTypes.string,
  defaultSiteCrop: PropTypes.string,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  createHarvestRecord: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  openCamera: PropTypes.func.isRequired,
  employeeIds: PropTypes.array.isRequired,
  userAccess: PropTypes.number.isRequired
}

export default withStyles(styles)(HarvestInputForm)
