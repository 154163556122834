import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect } from 'react-router-dom'
import * as _ from 'lodash'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './Login.styles'
import { apkUrl } from '../../config'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      submited: false
    }
  }

  submitLogin = () => {
    this.setState({ submitted: true })
    const { email, password } = this.state
    const credentials = { username: _.toLower(email), password }
    this.props.login(credentials, () => {
      this.setState({ submitted: false })
    })
  }

  render () {
    const { classes } = this.props

    const { from } = this.props.location.state || {
      from: { pathname: '/app' }
    }

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Iniciar sesión
          </Typography>
          <form className={classes.form}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='email'>Email</InputLabel>
              <Input id='email' name='email' autoComplete='email' value={this.state.email} autoFocus onChange={(e) => this.setState({ email: e.target.value })} />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='password'>Password</InputLabel>
              <Input name='password' type='password' id='password' value={this.state.password} autoComplete='current-password' onChange={(e) => this.setState({ password: e.target.value })} />
            </FormControl>
            {/* TODO: use this?
              <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Remember me'
            /> */}
            {
              this.state.submitted ? <CircularProgress className={classes.progress} />
                : <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={this.submitLogin}
                >
                Ingresar
                </Button>
            }
          </form>
        </Paper>

        <br />
        <br />
        <hr />
        <br />
        <br />

        <Button
          fullWidth
          variant='outlined'
          color='primary'
          onClick={() => { window.location.assign(apkUrl) }}
        >
        Descargar App
        </Button>

      </main>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object
}

export default withRouter(withStyles(styles)(Login))
