import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'

import memoize from 'memoize-one'
import lowerCase from 'lodash/lowerCase'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import clone from 'lodash/clone'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import camelCase from 'lodash/camelCase'

import { isChileRut, isEmail, formatChileRut } from '../../utils'
import { pensionSystems, healthInsurances } from '../../config'

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit / 2
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: 120
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }

})

const initialState = {
  name: '',
  lastName: '',
  birthDate: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  healthInsurance: '',
  healthStatus: '',
  pension: '',
  countryId: '',
  civilState: '',
  nationality: '',
  expanded: false,
  continue: false,
  alreadyInDatabase: false
}
class EmployeeRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  componentDidMount () {
    const { location } = this.props
    if (location.pathname.indexOf('edit') > 1 && location.state.employeeId) {
      const { employeeId } = location.state
      const employeeData = this.props.employees[`employee/${employeeId}`]
      if (!isEmpty(employeeData)) {
        this.setState({
          ...employeeData,
          countryId: employeeId
        })
      }
    }
  }

  onChange = (event) => {
    if (event.target.id === 'countryId') {
      let value = formatChileRut(event.target.value)
      this.setState({ ...clone(initialState), [event.target.id]: value }, () => {
        if (isChileRut(this.state.countryId)) {
          if (includes(this.props.companyEmployeesList, this.state.countryId)) {
            let ok = window.confirm('Persona con contrato, revisar tabla trabajadores')
            if (ok) {
              this.props.history.push(`/app/employees/table`)
            }
          } else {
            this.props.getNonRegisteredEmployeeData(this.state.countryId, (err, data) => {
              if (!err && !isEmpty(data)) {
                const employee = clone(data)
                this.setState({ ...employee, continue: true, alreadyInDatabase: true })
              }
            })
          }
        }
      })
    } else {
      this.setState({ [event.target.name || event.target.id]: event.target.value })
    }
  }

  employeePrepare = () => {
    const employee = {
      name: lowerCase(trim(this.state.name)),
      lastName: lowerCase(trim(this.state.lastName)),
      birthDate: this.state.birthDate,
      nationality: lowerCase(trim(this.state.nationality)),
      civilState: trim(this.state.civilState),
      phone: trim(this.state.phone),
      email: trim(this.state.email),
      address: toLower(trim(this.state.address)),
      city: toLower(trim(this.state.city)),
      healthInsurance: camelCase(trim(this.state.healthInsurance)),
      pension: camelCase(trim(this.state.pension)),
      countryId: toLower(trim(this.state.countryId))
    }

    return employee
  }

  employeeRegister = () => {
    const employee = this.employeePrepare()

    if (this.state.alreadyInDatabase) {
      this.props.editEmployee({ employee }, () => {
        this.props.history.push(`/app/employees/register/payment-method`, { employeeId: employee.countryId })
      })
    } else {
      this.props.createEmployee({ employee }, (err) => {
        if (err) {
          window.alert('Error creando el trabajador.')
        } else {
          this.props.history.push(`/app/employees/register/payment-method`, { employeeId: employee.countryId })
        }
      })
    }
  }

  employeeEdit = () => {
    const employee = this.employeePrepare()
    this.props.editEmployee({ employee }, () => {
      // NOTE: here we could add extras
      // this.props.history.push(`/app/employees/edit/extras`, { employeeId: employee.countryId })
      this.props.history.push(`/app/employees/view`, { employeeId: employee.countryId })
    })
  }

  employeeRegisterDisabled = () => {
    if (this.state.name && this.state.lastName && this.state.birthDate && this.state.address &&
       this.state.city && this.state.healthInsurance && this.state.pension && this.state.countryId &&
       this.state.civilState && this.state.nationality
    ) return false
    return true
  }

  edit = memoize((location) => location.pathname.indexOf('edit') > 1)

  render () {
    const { classes } = this.props
    const edit = this.edit(this.props.location)

    return (
      <div>
        {
          this.props.loading ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
            : <Card>
              <CardHeader
                title={edit ? 'Editar datos trabajador' : 'Registro trabajador'}
              />
              <CardContent>
                <TextField
                  id='countryId'
                  placeholder='RUT'
                  fullWidth
                  type='string'
                  onChange={this.onChange}
                  className={classes.textField}
                  value={this.state['countryId']}
                  error={!!this.state.countryId && !isChileRut(this.state.countryId)}
                  disabled={edit}
                />
                <br />
                <br />

                <TextField
                  id='name'
                  placeholder='Nombres'
                  fullWidth
                  label='Nombres'
                  type='string'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['name']}
                />
                <br />
                <br />
                <TextField
                  id='lastName'
                  placeholder='Apellidos'
                  label='Apellidos'
                  fullWidth
                  type='string'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['lastName']}
                />
                <br />
                <br />
                <FormControl className={classes.formControl}>
                  <TextField
                    id='birthDate'
                    name='birthDate'
                    label='Fecha Nacimiento'
                    InputLabelProps={{
                      shrink: true
                    }}
                    type='date'
                    value={this.state.birthDate}
                    onChange={this.onChange}
                    className={[classes.textField, classes.selectEmpty].join(' ')}
                  />
                </FormControl>

                <br />
                <br />
                <TextField
                  id='nationality'
                  placeholder='Nacionalidad'
                  label='Nacionalidad'
                  fullWidth
                  type='text'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['nationality']}
                />
                <br />
                <br />

                <TextField
                  id='civilState'
                  placeholder='Estado Civil'
                  label='Estado Civil'
                  fullWidth
                  type='text'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['civilState']}
                />
                <br />
                <br />

                <TextField
                  id='phone'
                  placeholder='Telefono'
                  label='Telefono'
                  fullWidth
                  type='number'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['phone']}
                />
                <br />
                <br />

                <TextField
                  id='email'
                  label='Email'
                  fullWidth
                  type='email'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['email']}
                  error={!!this.state.email && !isEmail(this.state['email'])}
                />
                <br />
                <br />

                <TextField
                  id='address'
                  placeholder='Direccion'
                  label='Direccion'
                  fullWidth
                  type='string'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['address']}
                />
                <br />
                <br />

                <TextField
                  id='city'
                  placeholder='Comuna'
                  label='Comuna'
                  fullWidth
                  type='string'
                  onChange={this.onChange}
                  className={this.props.classes.textField}
                  value={this.state['city']}
                />
                <br />
                <br />

                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                        Salud
                  </InputLabel>
                  <Select
                    id='healthInsurance'
                    value={this.state['healthInsurance']}
                    onChange={this.onChange}
                    name='healthInsurance'
                    fullWidth
                  >
                    {map(healthInsurances, (salud, idx) => <MenuItem key={idx} value={salud}>{startCase(salud)}</MenuItem>)}
                  </Select>
                </FormControl>
                <br />
                <br />

                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                        AFP
                  </InputLabel>
                  <Select
                    id='pension'
                    value={this.state['pension']}
                    onChange={this.onChange}
                    name='pension'
                    fullWidth
                  >
                    {map(pensionSystems, (afp, idx) => <MenuItem key={idx} value={afp}>{startCase(afp)}</MenuItem>)}
                  </Select>
                </FormControl>

              </CardContent>
              <CardActions>
                {edit ? <Button size='small' color='primary' onClick={() => this.employeeEdit()} disabled={this.employeeRegisterDisabled()}>
                              Guardar
                </Button>
                  : <Button size='small' color='primary' onClick={() => this.employeeRegister()} disabled={this.employeeRegisterDisabled()}>
                    {this.state.continue ? 'Continuar' : 'Crear'}
                  </Button>
                }
              </CardActions>
            </Card>
        }
      </div>
    )
  }
}

EmployeeRegister.propTypes = {
  classes: PropTypes.object.isRequired,
  createEmployee: PropTypes.func.isRequired,
  editEmployee: PropTypes.func.isRequired,
  getNonRegisteredEmployeeData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  employees: PropTypes.object.isRequired,
  companyEmployeesList: PropTypes.array.isRequired
}

export default withStyles(styles)(EmployeeRegister)
