import { createAction } from '@reduxjs/toolkit'
import each from 'async/each'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import keys from 'lodash/keys'
import difference from 'lodash/difference'

import { listSubscribe, recordSubscribe } from '../../Main/redux/mainActionsRecords'
import { makeRpc } from '../../Main/redux/mainActionsDeepstream'

// in order to know when we first load
let start = null

export const companyExpenses = createAction('expenses/companyExpenses')
export const companyMonthlyExpenses = createAction('expenses/companyMonthlyExpenses')
export const expenseMetadata = createAction('expenses/expenseMetadata')
export const selectMonth = createAction('expenses/selectMonth')
export const loading = createAction('expenses/loading')
export const addDetailDialog = createAction('expenses/addDetailDialog')
export const companyExpensesAccounts = createAction('expenses/companyExpensesAccounts')

export const getMonthlyExpenses = (value) => (dispatch, getState, dsClient) => {
  each(value.data, (entry, cb) => {
    dispatch(listSubscribe(entry, companyMonthlyExpenses, false, () => {
      cb()
    }))
  }, () => {
    // get most recent records
    let list = orderBy(value.data, (s) => Number(s.split('/')[3].split('-').join('')), ['desc'])
    // save current month
    dispatch(selectMonth(list[0]))
    dispatch(subscribeExpenseRecord(getState().expenses.companyMonthlyExpenses[list[0]], () => {
      dispatch(loading(false))
    }))
  })
}

export const getExpenseRecordForMonth = (month, callback = null) => (dispatch, getState, dsClient) => {
  dispatch(loading(true))
  dispatch(selectMonth(month))
  setTimeout(function () {
    if (month === 'todos') {
      // just get snapshot
      each(getState().expenses.companyMonthlyExpenses, (list, cb) => {
        dispatch(getExpenseRecord(list, () => {
          cb()
        }))
      }, () => {
        if (callback) callback()
        dispatch(loading(false))
      })
    } else {
      // subscribe to record
      dispatch(subscribeExpenseRecord(getState().expenses.companyMonthlyExpenses[month], () => {
        if (callback) callback()
        dispatch(loading(false))
      }))
    }
  }, 5)
}

/**
 * getExpenseRecord receives array of record names and if record data not in state, get the snapshot along with metaData snapshot
 * @param  {array} recordNameList  List of record names
 * @param  {func} [callback=null] Optional callback
 * @return {void}
 */
export const getExpenseRecord = (recordNameList, callback = null) => (dispatch, getState, dsClient) => {
  // check all documents in store, if not present download along with metadata
  const cE = getState().expenses.companyExpenses
  each(recordNameList, (recordName, cb) => {
    let record = cE[recordName]
    if (isEmpty(record)) {
      // get record data
      dsClient().record.snapshot(recordName, (err, data) => {
        if (err) {
          console.error(err)
          return cb()
        }
        dispatch(companyExpenses({ recordName, data }))
        if (data.metadata) {
          dsClient().record.snapshot(data.metadata, (err, metaData) => {
            if (err) {
              console.error(err)
              return cb()
            }
            dispatch(expenseMetadata({ recordName: data.metadata, data: metaData }))
            cb()
          })
        } else {
          cb()
        }
      })
    } else {
      cb()
    }
  }, () => {
    if (callback) return callback()
  })
}

/**
 * subscribeExpenseRecord receives array of record name list and subscribe to records after checking there are no current subscriptions
 * @param  {array} recordNameList  List of record names
 * @param  {func} [callback=null] Optional callback
 * @return {void}
 */
export const subscribeExpenseRecord = (recordNameList, callback = null) => (dispatch, getState, dsClient) => {
  each(recordNameList, (recordName, cb) => {
    if (!dsClient().record.recordCores.get(recordName)) {
      dispatch(recordSubscribe(recordName, companyExpenses, true, false, () => {
        dispatch(metadataSubscription(recordName, () => {
          cb()
        }))
      }))
    } else {
      cb()
    }
  }, () => {
    if (callback) return callback()
  })
}

export const startExpensesData = () => (dispatch, getState, dsClient) => {
  let activeCompany = getState().auth.clientData.activeCompany
  if (!start || start !== activeCompany) {
    dispatch(listSubscribe(`company/${activeCompany}/list/monthly-expenses`, getMonthlyExpenses))
    dispatch(listSubscribe(`company/${activeCompany}/list/expenses/accounts`, getExpensesAccounts))
    start = activeCompany
  }
}

export const getExpensesAccounts = (value) => (dispatch, getState, dsClient) => {
  each(value.data, (recordName, cb) => {
    dsClient().record.snapshot(recordName, (err, data) => {
      if (err) {
        console.error(err)
        return cb()
      }
      dispatch(companyExpensesAccounts({ recordName, data }))
      dispatch(diffList(companyExpensesAccounts, value.data))
      cb()
    })
  })
}

const metadataSubscription = (recordName, cb) => (dispatch, getState, dsClient) => {
  const record = getState().expenses.companyExpenses[recordName]
  if (record.metadata) {
    // subscribe to metadata changes
    dispatch(recordSubscribe(record.metadata, expenseMetadata, true, false, () => {
      cb()
    }))
  } else {
    // subscribe to record metadata path
    dispatch(recordSubscribe(recordName, onExpenseMetadataDefined, false, 'metadata', () => {
      cb()
    }))
  }
}

const onExpenseMetadataDefined = (metadataId) => (dispatch, getState, dsClient) => {
  // subscribe to metadata changes
  if (metadataId) dispatch(recordSubscribe(metadataId, expenseMetadata, true))
}

export const addDetailItem = (payload, cb = null) => (dispatch, getState, dsClient) => {
  dispatch(makeRpc('expenses/addDetailItem', payload, (err, res) => {
    console.log(err || res)
    if (cb) return cb()
  }))
}

export const deleteDetailItem = (payload, cb = null) => (dispatch, getState, dsClient) => {
  dispatch(makeRpc('expenses/deleteDetailItem', payload, (err, res) => {
    console.log(err || res)
    if (cb) return cb()
  }))
}

export const addExpenseAccount = (account, cb = null) => (dispatch, getState, dsClient) => {
  const companyId = getState().auth.clientData.activeCompany
  dispatch(makeRpc('expenses/addExpenseAccount', { companyId, account }, (err, res) => {
    console.log(err || res)
    if (cb) return cb()
  }))
}

export const deleteExpenseAccount = (payload, cb = null) => (dispatch, getState, dsClient) => {
  const companyId = getState().auth.clientData.activeCompany
  dispatch(makeRpc('expenses/deleteExpenseAccount', { companyId, ...payload }, (err, res) => {
    console.log(err || res)
    if (cb) return cb()
  }))
}

export const addExpenseMetadata = (payload, cb = null) => (dispatch, getState, dsClient) => {
  const companyId = getState().auth.clientData.activeCompany
  dispatch(makeRpc('expenses/addExpenseMetadata', { companyId, ...payload }, (err, res) => {
    console.log(err || res)
    if (cb) return cb()
  }))
}

export const deleteExpenseMetadata = (payload, cb = null) => (dispatch, getState, dsClient) => {
  const companyId = getState().auth.clientData.activeCompany
  dispatch(makeRpc('expenses/deleteExpenseMetadata', { companyId, ...payload }, (err, res) => {
    console.log(err || res)
    if (cb) return cb()
  }))
}

/**
 * Checks if there is a difference between list entries and state and updates state to match entries.
 * Done automatically when subscribed to list with recordAction
 * @param  {[type]} action  [description]
 * @param  {[type]} entries [description]
 * @return {[type]}         [description]
 */
const diffList = (action, entries) => (dispatch, getState) => {
  const entriesInState = keys(getState().expenses[action.toString().split('/')[1]])
  const diff = difference(entriesInState, entries)
  each(diff, (recordName) => {
    dispatch(action({ recordName, remove: true }))
  })
}
