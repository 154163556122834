import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import classnames from 'classnames'

import memoize from 'memoize-one'

import map from 'lodash/map'
import clone from 'lodash/clone'
import startCase from 'lodash/startCase'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'
import now from 'lodash/now'

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit / 2
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }

})

const initialState = {
  defaultQuantity: 4,
  defaultFormat: '',
  defaultSite: '',
  defaultQuality: '',
  defaultSiteCrop: '',
  disable: false,
  expanded: true
}

class HarvestDefaults extends React.Component {
  constructor (props) {
    super(props)
    this.state = clone(initialState)
  }

  onChange = (event) => {
    if (event.target.id === 'defaultQuantity' && event.target.value === '') {
      this.setState({ [event.target.id]: 0 })
    } else {
      this.setState({ [event.target.name || event.target.id]: event.target.value })
    }
  }

  save = () => {
    const defaultFormat = this.state.defaultFormat.id
    const defaultQuality = this.state.defaultQuality.id
    const defaultSite = this.state.defaultSite.id
    const defaultSiteCrop = this.state.defaultSiteCrop.id
    const defaultQuantity = toNumber(this.state.defaultQuantity)
    this.props.setHarvestDefaults({ defaultFormat, defaultSite, defaultQuantity, defaultQuality, defaultSiteCrop, date: now() }, () => {
      this.setState({ disable: true })
      this.handleExpandClick(false)
    })
  }

  modify = () => {
    this.props.setHarvestDefaults({}, () => {
      this.setState({ disable: false })
    })
  }

  handleExpandClick = (force) => {
    this.setState({ expanded: force || !this.state.expanded })
  }

  componentDidMount () {
    if (!isEmpty(this.props.harvestDefaults)) {
      // if date longer than 14 hrs reset params
      if (now() - this.props.harvestDefaults.date > 14 * 3600 * 1000) {
        this.modify()
        this.handleExpandClick(true)
      } else {
        this.setState({ disable: true })
        this.handleExpandClick(false)
      }
    }
  }

  defaultFormat = memoize((formats, defaultFormat) => filter(formats, (format) => format.id === defaultFormat)[0] || '')
  defaultQuality = memoize((qualities, defaultQuality) => filter(qualities, (quality) => quality.id === defaultQuality)[0] || '')
  defaultSite = memoize((sites, defaultSite) => filter(sites, (site) => site.id === defaultSite)[0] || '')
  defaultSiteCrop = memoize((site, defaultSiteCrop) => filter(site.crops, (crop) => crop.id === defaultSiteCrop)[0] || '')
  disabled = memoize((state, defaultSiteCrop, defaultFormat, defaultSite, defaultQuantity) => {
    if ((state.defaultFormat.id || defaultFormat) && (state.defaultSite.id || defaultSite) && (state.defaultSiteCrop.id || defaultSiteCrop) && (state.defaultQuantity || defaultQuantity)) return false
    return true
  })

  render () {
    const { classes } = this.props
    const defaultFormat = this.defaultFormat(this.props.formats, this.props.harvestDefaults.defaultFormat)
    const defaultQuality = this.defaultQuality(this.props.qualities, this.props.harvestDefaults.defaultQuality)
    const defaultSite = this.defaultSite(this.props.sites, this.props.harvestDefaults.defaultSite)
    const defaultSiteCrop = this.defaultSiteCrop(this.state.defaultSite || defaultSite, this.props.harvestDefaults.defaultSiteCrop)
    const disabled = this.disabled(this.state, defaultSiteCrop, defaultFormat, defaultSite, this.props.harvestDefaults.defaultQuantity)

    return (
      <div>
        <Card>
          <CardHeader
            title={`Parametros cosecha actual`}
            action={
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded
                })}
                onClick={() => this.handleExpandClick()}
                aria-expanded={this.state.expanded}
                aria-label='Ver mas'
              > <ExpandMoreIcon />
              </IconButton>
            }
          />
          <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <div>
                <InputLabel shrink htmlFor='age-label-placeholder'>
                  Formato
                </InputLabel>
                <Select
                  value={this.state.defaultFormat || defaultFormat}
                  onChange={this.onChange}
                  name='defaultFormat'
                  fullWidth
                  disabled={this.state.disable}
                >
                  {map(this.props.formats, (value, idx) => {
                    return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
                  })}
                </Select>
              </div>
              <br />
              <br />

              <div>
                <InputLabel shrink htmlFor='age-label-placeholder'>
                  Calidad
                </InputLabel>
                <Select
                  value={this.state.defaultQuality || defaultQuality}
                  onChange={this.onChange}
                  name='defaultQuality'
                  fullWidth
                  disabled={this.state.disable}
                >
                  {map(this.props.qualities, (value, idx) => {
                    return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
                  })}
                </Select>
              </div>
              <br />
              <br />

              <TextField
                id='defaultQuantity'
                label='Cantidad'
                placeholder='Cantidad'
                fullWidth
                type='number'
                onChange={this.onChange}
                className={classes.textField}
                value={this.state['defaultQuantity'] || (this.state['defaultQuantity'] !== 0 && this.props.harvestDefaults.defaultQuantity)}
                disabled={this.state.disable}
              />
              <br />
              <br />
              <div>
                <InputLabel shrink htmlFor='age-label-placeholder'>
                Cuartel
                </InputLabel>
                <Select
                  value={this.state.defaultSite || defaultSite}
                  onChange={this.onChange}
                  name='defaultSite'
                  fullWidth
                  disabled={this.state.disable}
                >
                  {map(this.props.sites, (value, idx) => {
                    return <MenuItem key={idx} value={value}>{`${value.farmName} : ${startCase(value.type)} ${startCase(value.name)}`}</MenuItem>
                  })}
                </Select>
              </div>
              <br />
              <br />

              <div>
                <InputLabel shrink htmlFor='age-label-placeholder'>
                Variedad
                </InputLabel>
                <Select
                  value={this.state.defaultSiteCrop || defaultSiteCrop}
                  onChange={this.onChange}
                  name='defaultSiteCrop'
                  fullWidth
                  disabled={this.state.disable}
                >
                  {map(this.state.defaultSite.crops || defaultSite.crops, (value, idx) => {
                    return <MenuItem key={idx} value={value}>{startCase(value.name)}</MenuItem>
                  })}
                </Select>
              </div>
              <br />
              <br />

            </CardContent>
            <CardActions>
              {isEmpty(this.props.harvestDefaults)
                ? <Button size='small' color='primary' onClick={() => this.save()} disabled={disabled}>
              Registrar
                </Button>
                : <Button size='small' color='primary' onClick={() => this.modify()} disabled={disabled}>
              Modificar
                </Button>
              }
            </CardActions>
          </Collapse>
        </Card>
      </div>
    )
  }
}

HarvestDefaults.propTypes = {
  classes: PropTypes.object.isRequired,
  harvestDefaults: PropTypes.object.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  setHarvestDefaults: PropTypes.func.isRequired
}

export default withStyles(styles)(HarvestDefaults)
