import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout, changeActiveCompany } from '../Auth/redux/authActions.js'
import { connectionHandler } from './redux/mainActions'
import { sendPendingRequests } from './redux/mainActionsPendingRequests'
import MainComponent from './Main.js'

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  connectionHandler,
  changeActiveCompany,
  sendPendingRequests
}, dispatch)

const mapStateToProps = state => ({ mainState: state.main, activeCompany: state.auth.clientData && state.auth.clientData.activeCompany })

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent)
