import { createAction } from '@reduxjs/toolkit'
import each from 'async/each'
import eachSeries from 'async/eachSeries'
import moment from 'moment-timezone'

import reduce from 'lodash/reduce'
import map from 'lodash/map'
import now from 'lodash/now'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import noop from 'lodash/noop'
import forEach from 'lodash/forEach'
import find from 'lodash/find'

import { makeRpc, eventSubscribe } from '../../Main/redux/mainActionsDeepstream'

import { modifyHarvestRecordPendingRequest, deleteHarvestRecordPendingRequest } from '../../Main/redux/mainActionsPendingRequests'

import { listSubscribe, discardRecord, recordSubscribe, discardList, recordSnapshot, unsubscribeRecord } from '../../Main/redux/mainActionsRecords'
import { createPaymentOrder, outgoingPaymentOrder } from '../../Payments/redux/paymentsActions'
import { recordNameToId } from '../../../utils'

// current date
const ahora = now()
// TODO: use company tz
const ymd = moment(ahora).tz('America/Santiago').format('YYYY-MM-DD')
// keep active company in memory
let activeCompany = null
let userId = null

export const harvestInputQrOpen = createAction('harvest/harvestInputQrOpen')
export const companyHarvestFormatsList = createAction('harvest/companyHarvestFormatsList')
export const harvestFormatRecord = createAction('harvest/harvestFormatRecord')
export const companyHarvestDestinationsList = createAction('harvest/companyDestinationsFormatsList')
export const harvestDestinationRecord = createAction('harvest/harvestDestinationRecord')
export const companyHarvestQualitiesList = createAction('harvest/companyHarvestQualitiesList')
export const harvestQualityRecord = createAction('harvest/harvestQualityRecord')
export const harvestRecord = (rand) => (rand ? createAction(`harvest/harvestRecord__:${rand}`) : createAction(`harvest/harvestRecord`))
export const harvestRecordBulk = createAction('harvest/harvestRecordBulk')
export const harvestDefaults = createAction('harvest/harvestDefaults')
export const harvestSitesDays = createAction('harvest/harvestSitesDays')
export const loading = createAction('harvest/loading')
export const harvestStart = createAction('harvest/start')
export const harvestStats = createAction('harvest/stats')
export const harvestSeasonStats = createAction('harvest/harvestSeasonStats')
export const harvestSeasons = createAction('harvest/harvestSeasons')
export const harvestSeasonStatsId = createAction('harvest/harvestSeasonStatsId')
export const setSelectedDayAndFarmForTable = createAction('harvest/setSelectedDayAndFarmForTable')
export const todayRecordsList = createAction('harvest/todayRecordsList')
export const updateHarvestRecordKey = createAction('harvest/updateHarvestRecordKey')
export const harvestValidatedDays = createAction('harvest/harvestValidatedDays')
export const harvestPayments = createAction('harvest/harvestPayments')
export const updateHarvestRecordPaymentStatus = createAction('harvest/updateHarvestRecordPaymentStatus')
export const statsCompute = createAction('harvest/statsCompute')

export const startHarvestData = (cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  activeCompany = state.auth.clientData.activeCompany
  userId = state.auth.clientData.userId

  dispatch(getPendingRecords(() => {
    console.log('got pending records')
  }))

  const { start } = state.harvest

  if (!start.company || start.company !== activeCompany || (start.date && (ymd !== moment(start.date).tz('America/Santiago').format('YYYY-MM-DD')))) {
    console.log('newHarvestStart')

    dispatch(listSubscribe(`company/${activeCompany}/list/harvest/formats`, companyHarvestFormatsList, harvestFormatRecord))
    // dispatch(listSubscribe(`company/${activeCompany}/list/harvest/destinations`, companyHarvestDestinationsList, harvestDestinationRecord))
    dispatch(listSubscribe(`company/${activeCompany}/list/harvest/qualities`, companyHarvestQualitiesList, harvestQualityRecord))
    const newStart = { company: activeCompany, date: now() }

    dispatch(listSubscribe(`harvest/${activeCompany}/list/sites/days`, harvestSitesDays, null, () => {
      dispatch(harvestStatsSubscribe(null, () => {
        // this resets state.harvestRecord
        dispatch(harvestStart(newStart))
        // subscribe to harvest list events
        dispatch(harvestEvent())

        dispatch(discardOldRecords(() => {
          console.log('harvestStatsSubscribe:done')
          dispatch(loading(false))
          return cb()
        }))
      }))
    }))
  } else {
    dispatch(harvestStatsSubscribe(null, () => {
      // subscribe to harvest list events
      dispatch(harvestEvent())

      console.log('harvestStatsSubscribe:done')
      dispatch(loading(false))
      return cb()
    }))
  }
}

export const createHarvestRecord = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany

  // include userId who created the record
  payload.createdBy = userId

  const recordUid = `${moment(payload.date).tz('America/Santiago').format('YYYY-MM-DD')}/${dsClient().getUid()}`

  dispatch(makeRpc('harvest/record/create', { newRecord: pickBy(payload, identity), recordUid, companyId }, (err, res) => {
    if (err) {
      console.error(err)
      return cb(err, null)
    }
    // update record data on state
    const action = harvestRecord()
    if (res === 202) {
      dispatch(action({ recordName: `harvest/${companyId}/record/${recordUid}`, data: { ...payload, pending: true } }))
      return cb(null, `harvest/${companyId}/record/${recordUid}`)
    } else {
      dispatch(action({ recordName: `harvest/${companyId}/record/${recordUid}`, data: { ...payload } }))
      return cb(null, `harvest/${companyId}/record/${recordUid}`)
    }
  }))
}

export const nextLoading = (value) => (dispatch) => {
  dispatch(loading(value))
  return Promise.resolve()
}
/**
 * getHarvestData for farm between days
 * @return {[type]} [description]
 */
export const getHarvestData = (cb) => (dispatch, getState, dsClient) => {
  dispatch(nextLoading(true)).then(() => {
    const state = getState()
    const companyId = activeCompany
    const { selectedDay, selectedFarm } = state.harvest
    const farm = selectedFarm.id === 'todos' ? '*' : selectedFarm.id

    let allSites = null

    if (selectedFarm.id === 'todos') {
      allSites = state.farms.userSites
    }

    // get all days between dates
    let days = [selectedDay.from]
    let tomorrow = moment(selectedDay.from).add(1, 'd')
    while (tomorrow.isBetween(selectedDay.from, selectedDay.to)) {
      days.push(tomorrow.format('YYYY-MM-DD'))
      tomorrow.add(1, 'd')
    }
    days.push(selectedDay.to)

    if (allSites) {
      dispatch(makeRpc('harvest/record/get/days', { companyId, farm, days }, (err, result) => {
        if (err) {
          console.error(err)
        }
        dispatch(harvestRecordBulk(result))
        dispatch(loading(false))
        return cb()
      }))
    } else {
      const sites = map(selectedFarm.sites, (site) => site.split('/')[1])

      dispatch(makeRpc('harvest/record/get/sitesDays', { companyId, farm, sites, days }, (err, result) => {
        if (err) {
          console.error(err)
        }
        dispatch(harvestRecordBulk(result))
        dispatch(loading(false))
        return cb()
      }))
    }
  })
}

/**
 * [subscribeToTodayRecordList description]
 * @param  {[type]} arguments [description]
 * @return {[type]}           [description]
 */

export const subscribeToDayRecordList = (day = null) => (dispatch, getState) => {
  const state = getState()
  const { userFarms } = state.farms

  // get lists for all user farms
  const listNames = map(userFarms, (farm) => `harvest/${activeCompany}/list/${farm.split('/')[1]}/records/${day || ymd}`)
  const action = harvestRecord()

  eachSeries(listNames, (list, cb) => {
    dispatch(listSubscribe(list, todayRecordsList, action, () => {
      cb()
    }))
  })
}

/**
 * clear old harvest records from dsClient
 * @param  {Function} cb
 */
export const discardOldRecords = (cb = noop) => (dispatch, getState, dsClient) => {
  // checking that they are not lists
  each(dsClient().record.recordCores.keys(), (value, callb) => {
    const name = value[0]

    const nameArray = name.split('/')
    // make sure they are harvest records for active company
    if (nameArray[0] === 'harvest' && nameArray[1] === activeCompany) {
      // discard old record
      if (nameArray[2] === 'record' && nameArray[3] !== ymd) {
        dispatch(discardRecord(name))
      }
      // discard old lists
      if (nameArray[2] === 'list' && nameArray[4] === 'records' && nameArray[5] !== ymd) {
        dispatch(discardList(name))
      }
    }
    // done
    callb()
  }, () => {
    return cb()
  })
}

/**
 * Pending harvest records are added to state with a pending key.
 * Once connection is established the pending key is removed by the record data update
 * @return {[type]} [description]
 */
export const getPendingRecords = (callback = null) => (dispatch, getState) => {
  const state = getState()
  // TODO: check failed requests?
  const { pendingRequests } = state.main
  const companyId = state.auth.clientData.activeCompany

  const pendingHarvestRecords = map(reduce(pendingRequests, (result, value) => {
    if (value && value.rpcName === 'harvest/record/create') {
      result[`harvest/${companyId}/record/${value.data.recordUid}`] = { ...value.data.newRecord, pending: true }
      return result
    } else {
      return result
    }
  }, {}), (data, key) => ({ recordName: `${key}`, data }))

  // remove pending harvest records from current state
  if (isEmpty(pendingHarvestRecords)) {
    const pendingHarvestRecordsInState = reduce(state.harvest.harvestRecord, (result, value, recordName) => {
      if (value.pending) {
        result.push({ recordName, data: { ...value, pending: false } })
      }
      return result
    }, [])

    // now we iterate in each pending record in state and remove it
    each(pendingHarvestRecordsInState, (payload, cb) => {
      const action = harvestRecord()
      dispatch(action(payload))
      cb()
    }, () => {
      console.log('doneRemovePendingHarvestRecordsFromState')
    })
  }

  // if there are pending records they will be added to state
  each(pendingHarvestRecords, (record, cb) => {
    const action = harvestRecord()
    dispatch(action(record))
    cb()
  }, () => {
    if (callback) return callback()
  })
}

export const setHarvestDefaults = (payload, cb = noop) => (dispatch) => {
  dispatch(harvestDefaults(payload))
  cb()
}

export const modifyPendingHarvestRecord = (recordUid, payload, cb = noop) => (dispatch, getState) => {
  const companyId = activeCompany
  const data = { newRecord: payload, recordUid, companyId }

  dispatch(modifyHarvestRecordPendingRequest(data, (err, res) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    // update store
    const action = harvestRecord()
    dispatch(action({ recordName: `harvest/${companyId}/record/${recordUid}`, data: { ...payload, pending: true } }))
    return cb(null, res)
  }))
}

export const deletePendingHarvestRecord = (recordUid, cb = noop) => (dispatch, getState) => {
  const companyId = activeCompany

  dispatch(deleteHarvestRecordPendingRequest(recordUid, (err, res) => {
    if (err) {
      console.error(err)
      cb(err)
      return
    }
    // remove from harvestRecord
    const recordName = `harvest/${companyId}/record/${recordUid}`

    const action = harvestRecord()
    dispatch(action({ recordName, remove: true }))
    return cb(err, res)
  }))
}

export const deleteHarvestRecord = (recordName, site, cb = noop) => (dispatch, getState, dsClient) => {
  const companyId = activeCompany
  // do not delete non pending records without connection
  if (dsClient().getConnectionState() !== 'OPEN' || !navigator.onLine) {
    return cb('debesEstarConectadoAInternet')
  }

  dispatch(makeRpc('harvest/record/delete', { recordName, companyId, site }, (err, res) => {
    if (err) {
      console.error(err)
      return cb(err)
    }
    // NOTE: this is in case we are not subscribed to record
    const action = harvestRecord()
    dispatch(action({ recordName, remove: true }))
    // discard record
    dispatch(discardRecord(recordName))
    cb(null, res)
  }))
}

export const modifyHarvestRecord = (recordName, payload, cb = noop) => (dispatch, getState, dsClient) => {
  const companyId = activeCompany
  // do not edit non pending records without connection
  if (dsClient().getConnectionState() !== 'OPEN' || !navigator.onLine) {
    return cb('debesEstarConectadoAInternet')
  }

  dispatch(makeRpc('harvest/record/edit', { newRecord: payload, recordName, companyId }, (err, res) => {
    if (err) console.error(err)
    const action = harvestRecord()
    dispatch(recordSnapshot(recordName, action))
    cb(err, res)
  }))
}

export const subscribeHarvestRecord = (name, cb) => (dispatch) => {
  const action = harvestRecord()
  dispatch(recordSubscribe(name, action, true, (err) => {
    cb(err)
  }))
}

export const harvestStatsUnsubscribe = (day, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const farms = state.farms.userFarms
  const farmIds = map(farms, (f) => f.split('/')[1])
  each(farmIds, (farm, cb1) => {
    dispatch(unsubscribeRecord(`harvest/${companyId}/stats/${farm}/${day}`, undefined, harvestStats))
    cb1()
  }, () => cb())
}

export const harvestStatsSubscribe = (day, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const farms = state.farms.userFarms
  const currentHarvestSeasonStatsId = state.harvest.harvestSeasonStatsId

  // subscribe to company harvest seasons records
  dispatch(recordSubscribe(`harvest/${companyId}/seasons`, harvestSeasons))

  if (!day) {
    // has season started?
    if (isEmpty(state.harvest.harvestSitesDays)) return cb()

    day = state.harvest.harvestSitesDays[0].split('/')[1]
  }

  const farmIds = map(farms, (f) => f.split('/')[1])
  // first we remove stats from other days
  dispatch(harvestStats({ current: day }))

  each(farmIds, (farm, cb1) => {
    dispatch(recordSubscribe(`harvest/${companyId}/stats/${farm}/${day}`, harvestStats, true, () => {
      cb1()
    }))
  }, () => {
    const harvestSeasons = getState().harvest.harvestSeasons
    // get the seasonId baed on day
    const seasonStatsId = getHarvestSeasonStatsId(day, harvestSeasons)
    // save to state
    dispatch(harvestSeasonStatsId(seasonStatsId))

    // if seasonId changed compute again company stats
    if (currentHarvestSeasonStatsId !== seasonStatsId) {
      // we compute stats in order to have information updated up until yesterday
      // computation is done before siubscribing because on first computation the record might not be defined still
      const payload = { companyId }
      // if it is a previous season inform seasonId
      if (seasonStatsId) payload.seasonId = seasonStatsId
      dispatch(makeRpc('harvest/stats/company/compute', payload, (err) => {
        if (err) {
          console.error(err)
        }
        // subscribe to season stats
        dispatch(recordSubscribe(`harvest/${activeCompany}/stats${seasonStatsId ? '/' + seasonStatsId : ''}`, harvestSeasonStats, false, cb))
      }))
    } else {
      // no need to recalculate
      // subscribe to season stats
      dispatch(recordSubscribe(`harvest/${activeCompany}/stats${seasonStatsId ? '/' + seasonStatsId : ''}`, harvestSeasonStats, false, cb))
    }
  })
}

/**
 * Get harvest season Id based on harvest seasons record and current day
 * for current season return empty string
 */
const getHarvestSeasonStatsId = (day, harvestSeasons) => {
  let seasonId = ''
  if (!harvestSeasons.current || moment(day).isAfter(harvestSeasons.current.from)) {
    return seasonId
  }

  forEach(harvestSeasons, (value, key) => {
    if (key !== 'defaults' && key !== 'current') {
      if (moment(day).isAfter(value.from) && moment(day).isBefore(value.to)) {
        seasonId = key
        return false
      }
    }
  })
  return seasonId
}

// NOTE: with too many records this gets time due to each harvest record gets updated on client side.
export const createHarvestPayment = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const { connectionState } = state.main
  const companyId = activeCompany
  const employeeContracts = state.employees.employeeContract
  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]

  if (connectionState !== 'OPEN') {
    return cb(new Error('mustHaveOpenConnection'))
  }

  dispatch(loading(true))

  // make sure we are sending only selected employees data
  const data = filter(payload.data, (d) => includes(payload.employeeIds, d.rut))
  // first format of newPayment
  const newPayment = map(data, (d) => ({
    employeeId: d.rut,
    contractId: employeeContracts[`employee/${d.rut}`].contractId,
    membershipId: recordNameToId(find(employeesMemberships, (membership) => membership.indexOf(d.rut) > -1)),
    harvestRecords: map(d.harvestRecords.nonPayment, (harvestRecord) => (harvestRecord.id)),
    accountingDate: payload.accountingDate,
    topic: ['harvest']
  }))

  dispatch(makeRpc('harvest/payment/create', { companyId, newPayment, userId }, (err, result) => {
    if (err) {
      console.error(err)
      dispatch(loading(false))
      return cb(err)
    }

    dispatch(recordSubscribe(result.harvestPayment, harvestPayments, true, () => {
      dispatch(loading(false))
      cb(null, result)
    }))
  }))
}

export const updateHarvestRecordPaymentData = (data) => (dispatch) => {
  // update records data locally
  each(data, (value) => {
    const { items } = value.payment
    const dataToUpdate = map(items, (item) => ({ recordName: item, key: 'payment', value: { id: value.paymentId, status: 100 } }))
    dispatch(updateHarvestRecordKey(dataToUpdate))
  })
}

export const createHarvestPaymentOrder = (payload = {}, cb = noop) => (dispatch) => {
  const companyId = activeCompany
  dispatch(createPaymentOrder({ companyId, userId, topic: ['harvest'], ...payload }, (err, result) => {
    if (err) return cb(err)

    dispatch(recordSubscribe(result.orderRecordName, outgoingPaymentOrder, true, () => {
      cb(null, result)
    }))
  }))
}

export const recordsToValidate = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const companyId = activeCompany

  dispatch(loading(true))

  dispatch(makeRpc('harvest/record/validate', { companyId, ...payload }, (err, result) => {
    if (err) console.error(err)
    if (result && result.updatedRecords) {
      // update records
      const data = map(result.updatedRecords, (recordName) => ({ recordName, key: 'validated', value: result.updatedAt }))
      dispatch(updateHarvestRecordKey(data))
    }
    dispatch(loading(false))
    cb(err, result.status)
  }))
}

export const validateDay = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const companyId = activeCompany

  if (payload.farmId === 'todos') {
    const state = getState()
    payload.farmId = map(state.farms.userFarms, (farm) => farm.split('/')[1])
  }

  dispatch(makeRpc('harvest/stats/validate/days', { companyId, ...payload }, (err, result) => {
    if (err) console.error(err)
    cb(err, result.status)
  }))
}

/**
 * harvestEvent: this will hanlde when a harvest related event is emitted
 * @return {void}
 */
const harvestEvent = () => (dispatch) => {
  const companyId = activeCompany

  // define event callback
  const onListCreated = () => {
    dispatch(subscribeToDayRecordList())
    dispatch(harvestStatsSubscribe())
  }

  // subscribe
  // harvestListCreated: this will alert when a list of recrods fro a farm ina day is created and the we subscribe to it
  dispatch(eventSubscribe(`harvest/${companyId}/list/created`, { name: 'onListCreated', function: onListCreated }))

  // define event callback
  const action = harvestRecord()
  const onHarvestRecordChange = (recordName) => {
    dispatch(recordSnapshot(recordName, action))
  }

  // subscribe
  // Event for record changes
  dispatch(eventSubscribe(`harvest/${companyId}/record/change`, { name: 'onHarvestRecordChange', function: onHarvestRecordChange }))
}

export const subscribeValidatedDays = () => (dispatch) => {
  const companyId = activeCompany
  dispatch(recordSubscribe(`harvest/${companyId}/stats/validated/days`, harvestValidatedDays))
}

export const unsubscribeValidatedDays = () => (dispatch) => {
  const companyId = activeCompany
  dispatch(unsubscribeRecord(`harvest/${companyId}/stats/validated/days`, undefined, harvestValidatedDays))
}

export const computeFarmDayStats = (day, cb = noop) => (dispatch) => {
  const companyId = activeCompany
  dispatch(statsCompute(true))
  dispatch(makeRpc('harvest/stats/farms/compute/day', { companyId, day }, (e) => {
    dispatch(makeRpc('harvest/stats/employees/compute/day', { companyId, day }, (err) => {
      dispatch(statsCompute(false))
      cb(e || err)
    }))
  }))
}

export const computeCompanyStats = (cb = noop) => (dispatch) => {
  const companyId = activeCompany
  dispatch(statsCompute(true))
  dispatch(makeRpc('harvest/stats/company/compute', { companyId }, (e) => {
    dispatch(statsCompute(false))
    cb(e)
  }))
}
