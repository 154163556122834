import { createAction } from '@reduxjs/toolkit'
import noop from 'lodash/noop'
import filter from 'lodash/filter'
import forEach from 'async/forEach'

import { makeRpc } from '../../../Main/redux/mainActionsDeepstream'
import { recordSnapshot, listSubscribe, unsubscribeList, recordSubscribe, unsubscribeRecord } from '../../../Main/redux/mainActionsRecords'
import { siteLogRecordDayList } from '../../SiteLog/redux/siteLogActions'
import { employeesLiquidacion } from '../../../Payments/redux/paymentsActions'

export const attendanceDayRecordList = createAction('attendance/attendanceDayRecordList')
export const selectActivityLogDay = createAction('attendance/selectActivityLogDay')
export const pendingActivityLog = createAction('attendance/pendingActivityLog')
export const setLoading = createAction('attendance/loading')
export const attendanceAnnotationTopics = createAction('attendance/attendanceAnnotationTopics')
export const selectActivitySummaryMonth = createAction('attendance/selectActivitySummaryMonth')
export const selectActivitySummaryDay = createAction('attendance/selectActivitySummaryDay')
export const summaryPunchCard = createAction('attendance/summaryPunchCard')
export const attendanceSummaryRecord = createAction('attendance/attendanceSummaryRecord')
export const currentAttendanceRecord = createAction('attendance/currentAttendanceRecord')

let attendanceAnnotationTopicsRecord = null

export const getAttendanceRecord = (recordName, cb) => (dispatch) => {
  dispatch(recordSnapshot(recordName, currentAttendanceRecord, cb))
}

export const resetCurrentAttendance = () => (dispatch) => {
  dispatch(currentAttendanceRecord({ reset: true }))
}

export const subscribeToAnnotationTopics = () => (dispatch, getState, dsClient) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const recordName = `attendance/${companyId}/annotationTopics`

  dispatch(recordSubscribe(recordName, attendanceAnnotationTopics, async (err, record) => {
    if (err === 404) {
      // create record
      attendanceAnnotationTopicsRecord = await dsClient().record.getRecord(recordName).whenReady()
      attendanceAnnotationTopicsRecord.set('topics', [])
      // call again to subscribe
      dispatch(subscribeToAnnotationTopics())
    }
    if (!err) attendanceAnnotationTopicsRecord = record
  }))
}

export const createAnnotationTopic = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  if (!attendanceAnnotationTopicsRecord) {
    return cb('retry')
  }
  let topics = attendanceAnnotationTopicsRecord.get('topics')
  const id = dsClient().getUid()
  topics.push({ id, topic: payload.topic })
  attendanceAnnotationTopicsRecord.set('topics', topics, (err) => cb(err, id))
}

export const subscribeAttendanceDayRecordList = (membershipId, day) => (dispatch, getState) => {
  dispatch(listSubscribe(`attendance/${membershipId}/${day}/list`, handleAttendanceDayRecordList))
}

export const unSubscribeAttendanceDayRecordList = (membershipId, day) => (dispatch, getState) => {
  dispatch(unsubscribeList(`attendance/${membershipId}/${day}/list`))
  // clear state
  dispatch(siteLogRecordDayList([]))
}

const handleAttendanceDayRecordList = (payload) => (dispatch) => {
  // set list on state
  dispatch(attendanceDayRecordList(payload))
  // here we set the site log records list on state
  const siteLogList = filter(payload.data, (recordName) => recordName.split('/')[0] === 'site' && recordName.split('/')[2] === 'log')
  dispatch(siteLogRecordDayList(siteLogList))
}

export const saveAttendanceRecord = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const userId = state.auth.clientData.userId
  const { attendance } = state

  dispatch(makeRpc('attendance/record/save', { ...payload, companyId, userId }, (err, ok) => {
    if (err) cb(err, null)
    else {
      const recordName = `attendance/${payload.membershipId}/${payload.day}`
      dispatch(recordSnapshot(recordName, currentAttendanceRecord, cb))

      // check we have the attendance records list, if it is first save it won't be present
      if (!attendance.attendanceDayRecordList[`attendance/${payload.membershipId}/${payload.day}/list`]) {
        setTimeout(() => dispatch(subscribeAttendanceDayRecordList(payload.membershipId, payload.day)), 300)
      }
    }
  }))
}

export const deleteAttendance = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(makeRpc('attendance/record/delete', { companyId, ...payload }, cb))
}

export const getPendingActivityLog = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany

  dispatch(makeRpc('attendance/record/get/pendingLog', { ...payload, companyId }, (err, response) => {
    if (err) console.error(err)
    cb(err, response)
  }))
}

export const subscribePendingActivityLogRecord = (day, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(recordSubscribe(`attendance/${companyId}/pendingActivity/${day}`, pendingActivityLog, cb))
}

export const unSubscribePendingActivityLogRecord = (day) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(unsubscribeRecord(`attendance/${companyId}/pendingActivity/${day}`))
  dispatch(pendingActivityLog({ reset: true }))
}

export const getSummaryPunchCard = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany

  dispatch(makeRpc('attendance/summary/get/punchCard', { ...payload, companyId }, (err, response) => {
    if (err) console.error(err)
    cb(err, response)
  }))
}

export const subscribePunchCardRecord = (yearMonth, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(recordSubscribe(`attendance/${companyId}/punchCard/${yearMonth}`, summaryPunchCard, cb))
}

export const unSubscribePunchCardRecord = (yearMonth) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(unsubscribeRecord(`attendance/${companyId}/punchCard/${yearMonth}`))
  dispatch(summaryPunchCard({ reset: true }))
}

export const subscribeSummaryRecord = (recordName, cb = noop) => (dispatch, getState) => dispatch(recordSubscribe(recordName, attendanceSummaryRecord, cb))

export const unSubscribeSummaryRecord = (recordName, cb = noop) => (dispatch, getState) => {
  dispatch(unsubscribeRecord(recordName))
  dispatch(attendanceSummaryRecord({ reset: true }))
  cb()
}

export const computeEmployeeLiquidacion = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(makeRpc('payment/compute/employee/liquidacion', { ...payload, companyId }, (err, res) => {
    if (err) {
      cb(err)
    } else {
      dispatch(recordSubscribe(`payment/${companyId}/${payload.membershipId}/liquidacion/${payload.yearMonth}`, employeesLiquidacion, true, cb))
    }
  }))
}

export const unSubscribeEmployeeLiquidacion = (recordName) => (dispatch) => {
  dispatch(unsubscribeRecord(recordName))
}

export const computeEmployeeLiquidacionForAll = (payload, cb = noop) => (dispatch, getState) => {
  const { yearMonth } = payload
  forEach(payload.membershipIds, (membershipId, cb1) => {
    dispatch(computeEmployeeLiquidacion({ yearMonth, membershipId }, cb1))
  }, cb)
}

export const updateEmployeeLiquidacion = (payload, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(makeRpc('payment/update/employee/liquidacion', { ...payload, companyId }, cb))
}

export const getEmployeeLiquidacionParams = (yearMonth, cb = noop) => (dispatch, getState) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  dispatch(recordSnapshot(`payment/${companyId}/params/liquidacion/${yearMonth}`, null, cb))
}
