import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PaymentViewComponent from './PaymentView.js'
import { setPaymentStatus, getPaymentOrderRecord } from './redux/paymentsActions'

const mapDispatchToProps = dispatch => bindActionCreators({
  setPaymentStatus,
  getPaymentOrderRecord
}, dispatch)

const mapStateToProps = (state, ownProps) => {
  const { outgoingPaymentOrderRecordName, incomingPaymentOrderRecordName } = ownProps.location.state
  const { paymentRecords } = state.payments
  let outgoingPaymentOrderRecord = null
  let incomingPaymentOrderRecord = null
  if (outgoingPaymentOrderRecordName) outgoingPaymentOrderRecord = state.payments.outgoingPaymentOrder[outgoingPaymentOrderRecordName]
  if (incomingPaymentOrderRecordName) incomingPaymentOrderRecord = state.payments.incomingPaymentOrder[incomingPaymentOrderRecordName]

  return { outgoingPaymentOrderRecordName, incomingPaymentOrderRecordName, outgoingPaymentOrderRecord, incomingPaymentOrderRecord, paymentRecords }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentViewComponent)
