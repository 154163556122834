import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import moment from 'moment-timezone'
import 'moment/locale/es'

import ActivityDayView from './ActivityDayView'
import { subscribeAttendanceDayRecordList, unSubscribeAttendanceDayRecordList, selectActivitySummaryDay, unSubscribeSummaryRecord, subscribeSummaryRecord, computeEmployeeLiquidacion, updateEmployeeLiquidacion, unSubscribeEmployeeLiquidacion } from './Attendance/redux/attendanceActions'
import { addSiteLogRecord, siteLogRecords, removeSiteLogRecord } from './SiteLog/redux/siteLogActions'

const mapDispatchToProps = dispatch => bindActionCreators({
  subscribeAttendanceDayRecordList,
  unSubscribeAttendanceDayRecordList,
  addSiteLogRecord,
  siteLogRecords,
  removeSiteLogRecord,
  selectActivitySummaryDay,
  unSubscribeSummaryRecord,
  subscribeSummaryRecord,
  computeEmployeeLiquidacion,
  updateEmployeeLiquidacion,
  unSubscribeEmployeeLiquidacion
}, dispatch)

const mapStateToProps = (state, ownProps) => {
  let { membershipId, day, employeeName, yearMonth, summary, contractType } = ownProps.location.state
  let { siteLogRecordDayList, siteLogRecord } = state.siteLog
  if (siteLogRecordDayList.length === 0) {
    siteLogRecordDayList = ['']
  }
  const showSummary = !isEmpty(summary)
  let attendedDays = null
  let monthText = null
  if (showSummary) {
    attendedDays = summary.attendedDays
    // month to text
    moment.locale('es')
    monthText = startCase(moment(yearMonth, 'YYYY-MM').format('MMMM'))
    // selected day
    day = state.attendance.selectedActivitySummaryDay
  }

  const { attendanceSummaryRecord, currentAttendanceRecord } = state.attendance
  const { access } = state.main.userData
  const employeeContracts = state.employees.employeeContract
  const employeesLiquidacion = state.payments.employeesLiquidacion
  const companyId = state.auth.clientData.activeCompany
  const employeeLiquidacionRecordName = `payment/${companyId}/${membershipId}/liquidacion/${yearMonth}`
  const employeeLiquidacion = employeesLiquidacion[employeeLiquidacionRecordName] || {}
  const annotationTopicsToGroupBy = state.siteLog.siteLogAnnotationTopics.topics

  return { employeeLiquidacionRecordName, contractType, annotationTopicsToGroupBy, companyId, employeeLiquidacion, employeeContracts, userAccess: access, membershipId, day, siteLogRecordDayList, siteLogRecord, employeeName, yearMonth, summary, showSummary, attendedDays, monthText, attendanceSummaryRecord, currentAttendanceRecord }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDayView)
