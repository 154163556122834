import React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Autorenew from '@material-ui/icons/Autorenew'
import CircularProgress from '@material-ui/core/CircularProgress'

import memoize from 'memoize-one'

import isNumber from 'lodash/isNumber'
import flatMap from 'lodash/flatMap'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import mergeWith from 'lodash/mergeWith'
import compact from 'lodash/compact'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'

/**
 * handleEmpty
 * @param  {Array} arr array to search
 * @param  {string} id  element id
 * @return {string}  element name or empty
 */
const handleEmpty = (arr, id) => {
  const data = find(arr, ['id', id])
  if (!data) {
    console.error('notFound', arr, id)
    return ''
  }
  return data.name
}

const _companyStats = memoize((farms, qualities, harvestDayStats) =>
  // harvest/${companyId}/stats/${farm}/${day}
  compact(flatMap(harvestDayStats, (value, statName) => {
    const splitted = statName.split('/')
    const farmId = splitted[3]
    const farmName = handleEmpty(farms, farmId)
    // per farm object
    const farmSummary = {
      farmId,
      farmName,
      data: {}
    }

    // keep track of employees
    let employees = []

    // value has {$siteId: {$cropId: {$quality: $data, employees: [$employeeIds]}}}
    forEach(value, (siteData) => {
      // merge values and handle relevant fields
      mergeWith(farmSummary.data, siteData, (objValue, srcValue, key) => {
        // do not include time
        if (key === 'startTime' || key === 'endTime') {
          return null
        }
        // add quantity and weight values
        if (isNumber(srcValue)) {
          return (objValue || 0) + srcValue
        }
        // add employees
        if (key === 'employees') {
          employees = uniq(concat(employees, srcValue))
          return employees.length
        }
      })
    })
    // map data to relevant information
    farmSummary.dataSum = {}
    forEach(farmSummary.data, (cropData) => {
      forEach(cropData, (qualData, qualId) => {
        if (isEmpty(farmSummary.dataSum[qualId])) {
          farmSummary.dataSum[qualId] = qualData.weight
          farmSummary.dataSum[qualId].name = handleEmpty(qualities, qualId)
        } else {
          mergeWith(farmSummary.dataSum[qualId], qualData.weight, (objVal, srcVal) => objVal + srcVal)
        }
      })
    })
    // TODO: prepare dataSum to hanlde other units
    return farmSummary
  })))

const _primaryText = memoize((input) => {
  const value = filter(input.dataSum, (d) => d.name !== 'iqf')
  if (isEmpty(value)) return `${input.farmName}:`
  return `${input.farmName}: ${new Intl.NumberFormat().format(value[0].kg)} kgs Export :: ${input.data.employees} Cosecheros`
})

const _secondaryText = memoize((input) => {
  const value = filter(input, (d) => d.name === 'iqf')
  if (isEmpty(value)) return ''
  return `${value[0].kg} kgs Iqf`
})

const _statsDay = memoize((harvestDayStats) => {
  // harvest/${companyId}/stats/${farm}/${day}
  const day = keys(harvestDayStats)
  if (day.length) {
    return day[0].split('/')[4]
  }
  return ''
})

const HarvestCompanyStatsCard = (props) => {
  const companyStats = _companyStats(props.farms, props.qualities, props.harvestDayStats)
  const statsDay = _statsDay(props.harvestDayStats)

  return (
    <Card>
      <CardHeader
        title={<Tooltip title='Ultimo dia de cosecha'><Button>{`Total día: ${statsDay}`}</Button></Tooltip>}
        action={
          props.statsCompute ? <CircularProgress />
            : <Tooltip title='Actualizar'>
              <IconButton onClick={() => props.computeFarmDayStats(statsDay, (err) => err && window.alert('Error al actualizar'))}>
                <Autorenew />
              </IconButton>
            </Tooltip>
        }
      />
      <CardContent>
        <List>
          {
            map(companyStats, (value, key) =>
              <React.Fragment key={key}>
                <ListItem>
                  <ListItemText
                    primary={`${_primaryText(value)}`}
                    secondary={`${_secondaryText(value.dataSum)}`}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          }
        </List>
      </CardContent>
      {/* TODO: action button in order to go to other seasons */}
    </Card>
  )
}

HarvestCompanyStatsCard.propTypes = {
  qualities: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  harvestDayStats: PropTypes.object,
  statsCompute: PropTypes.bool,
  computeFarmDayStats: PropTypes.func.isRequired
}

export default HarvestCompanyStatsCard
