import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { appConfig } from '../../config'
import { getFileDownloadUrl } from './redux/fileUtilsActions'

const FileDownLoadLink = (props) => {
  const downLoadFile = (event, href, fileRecordName) => {
    event.preventDefault()
    props.getFileDownloadUrl({ href, fileRecordName }, (err, res) => {
      if (err) {
        window.alert('Error al descargar', err.message)
        return
      }

      if (res.data && res.data.getUrl) {
        window.open(res.data.getUrl)
      }
    })
  }

  return (
    <Link to={''} onClick={(event) => downLoadFile(event, props.href, props.fileRecordName)}>{props.name || props.href.split(appConfig.downloadUrlPrefix)[1]}</Link>
  )
}

FileDownLoadLink.propTypes = {
  href: PropTypes.string,
  fileRecordName: PropTypes.string,
  name: PropTypes.string,
  getFileDownloadUrl: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getFileDownloadUrl
}, dispatch)

export default connect(null, mapDispatchToProps)(FileDownLoadLink)
