import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import compact from 'lodash/compact'
import map from 'lodash/map'
import join from 'lodash/join'
import lowerCase from 'lodash/lowerCase'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    margin: theme.spacing.unit / 2
  },
  button: {
    margin: theme.spacing.unit
  }
})

const CustomToolbarSelect = (props) => {
  return (<></>)
  // <Button className={props.classes.button} color='primary' onClick={
  //   () => props.generateQr(map(props.displayData, (value) => ({ idx: value.dataIndex, data: value.data[0].split(':')[1] })), map(props.selectedRows.data, (row) => row.dataIndex))} >
  //   Generar QR
  // </Button>
}

class ActivityTable extends React.Component {
  componentDidMount () {
    // send list of memberships and get all those who have not completed their attendance nor site log
    if (this.props.selectedActivityLogDay) {
      // set loader on first visit only
      if (this.props.history.action !== 'POP') this.props.setLoading(true)

      this.props.getPendingActivityLog({ membershipIds: this.props.membershipIds, day: this.props.selectedActivityLogDay }, (err) => {
        if (!err) {
          this.props.subscribePendingActivityLogRecord(this.props.selectedActivityLogDay, () => this.props.setLoading(false))
        }
      })
    }
    // general subscriptions
    // TODO: keep track on main maybe to do this based on the route path: when path enters a space we subscribe to general info, when it leaves, we unsubscribe.
    //  Otherwise I must subs and unsub on every change in activityDayView
    // TODO: subscribe to annotation topics
    this.props.subscribeToCompanyMachinesList()

    // Set default user farm as filter on mount
    if (this.props.predio && !this.props.location.search) {
      this.props.history.replace({ pathname: this.props.location.pathname, search: '?predio=' + this.props.predio })
    }
  }

  componentWillUnmount () {
    if (this.props.selectedActivityLogDay) {
      this.props.unSubscribePendingActivityLogRecord(this.props.selectedActivityLogDay)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.selectedActivityLogDay !== this.props.selectedActivityLogDay) {
      this.props.setLoading(true)
      this.props.unSubscribePendingActivityLogRecord(prevProps.selectedActivityLogDay)
      this.props.getPendingActivityLog({ membershipIds: this.props.membershipIds, day: this.props.selectedActivityLogDay }, (err) => {
        if (!err) {
          this.props.subscribePendingActivityLogRecord(this.props.selectedActivityLogDay, () => this.props.setLoading(false))
        }
      })
    }
  }

  onCellClick = (data, meta) => {
    const { membershipId, employeeName, tipoContrato } = this.props.data[meta.dataIndex]
    const contractType = lowerCase(tipoContrato.charAt(0))
    this.props.history.push(`/app/activity/day`, { employeeName, membershipId, day: this.props.selectedActivityLogDay, contractType })
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            id='date'
            name='date'
            label='Fecha'
            InputLabelProps={{
              shrink: true
            }}
            type='date'
            value={this.props.selectedActivityLogDay}
            onChange={(event) => this.props.selectActivityLogDay(event.target.value)}
            className={[classes.textField, classes.selectEmpty].join(' ')}
          />
        </FormControl>

        {
          this.props.selectedActivityLogDay
            ? this.props.loading
              ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
              : <MUIDataTable
                title={'Listado de trabajadores'}
                data={this.props.data}
                columns={this.props.columns}
                options={{ ...this.props.options,
                  download: false,
                  onCellClick: this.onCellClick,
                  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (<CustomToolbarSelect classes={classes} selectedRows={selectedRows} displayData={displayData} generateQr={this.generateQr} />),
                  // // COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
                  onFilterChange: (changedColumn, filterList) => {
                    let search = compact(map(filterList, (value, idx) => (value[0] ? `${this.props.columns[idx].name}=${value[0]}` : null)))
                    search = '?' + join(search, '&')
                    this.props.history.replace({ pathname: this.props.location.pathname, search })
                  }
                }}
              /> : <></>
        }
      </div>
    )
  }
}

ActivityTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  selectActivityLogDay: PropTypes.func.isRequired,
  getPendingActivityLog: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  membershipIds: PropTypes.array.isRequired,
  selectedActivityLogDay: PropTypes.string.isRequired,
  predio: PropTypes.string,
  subscribeToCompanyMachinesList: PropTypes.func.isRequired,
  subscribePendingActivityLogRecord: PropTypes.func.isRequired,
  unSubscribePendingActivityLogRecord: PropTypes.func.isRequired
}

CustomToolbarSelect.propTypes = {
  // classes: PropTypes.object,
  // displayData: PropTypes.array,
  // selectedRows: PropTypes.object
}

export default withRouter(withStyles(styles)(ActivityTable))
