import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import memoize from 'memoize-one'

import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import lowerCase from 'lodash/lowerCase'

import _ContractTypeRegister from './ContractTypeRegister.js'
import _ContractTypeTable from './ContractTypeTable.js'

import { createContractType, updateContractType, deactivateContractType } from './redux/contractActions'
import { recordNameToId } from '../../utils'

const columns = [{ name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } }, { name: 'createdAt', options: { viewColumns: false, display: 'excluded', filter: false } }]
const defaultColumns = ['nombre', 'activo', 'validoDesde', 'validoHasta']

const _data = memoize((contractTypeRecords, access) => reduce(contractTypeRecords, (result, contractTypeRecord, contractTypeRecordName) => {
  const contractTypeId = recordNameToId(contractTypeRecordName)

  if (contractTypeId === 'type/omit') return result
  if (!contractTypeRecord.validFrom) return result
  if (contractTypeRecord.personal && access >= 3) return result

  let relevant = {
    nombre: contractTypeRecord.name,
    activo: contractTypeRecord.active ? 'si' : 'no',
    validoDesde: contractTypeRecord.validFrom,
    validoHasta: contractTypeRecord.validUntil || '',
    id: contractTypeId,
    createdAt: contractTypeRecord.createdAt
  }
  for (var key in relevant) {
    if (key !== 'id') {
      if (!includes(map(columns, (c) => c.name), key)) {
        columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
      }
    }
  }
  result.push(relevant)
  return orderBy(result, ['createdAt'], ['desc'])
}, []))

const _columns = memoize((columns) => orderBy(columns, (o) => defaultColumns.indexOf(o.name)))

const mapStateToProps = (state, ownProps) => {
  let contractId = null
  let edit = false
  if (ownProps.location.state) {
    contractId = ownProps.location.state.contractId
    edit = ownProps.location.state.edit
  }

  const contractTypeRecords = state.employees.contractTypeRecord
  const { access } = state.main.userData

  return {
    data: _data(contractTypeRecords, access),
    columns: _columns(columns),
    options: { responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'none',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    contractId,
    edit,
    contractTypeRecords,
    userAccess: access
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createContractType,
  updateContractType,
  deactivateContractType
}, dispatch)

const ContractTypeTable = connect(mapStateToProps, mapDispatchToProps)(_ContractTypeTable)
const ContractTypeRegister = connect(mapStateToProps, mapDispatchToProps)(_ContractTypeRegister)

export default { ContractTypeTable, ContractTypeRegister }
