import { createAction } from '@reduxjs/toolkit'
import noop from 'lodash/noop'
import map from 'lodash/map'
import { makeRpc } from '../../../Main/redux/mainActionsDeepstream'
import { recordSnapshot, recordSubscribe } from '../../../Main/redux/mainActionsRecords'

export const siteLogRecordDayList = createAction('siteLog/siteLogRecordDayList')
export const addSiteLogRecordNameToList = createAction('siteLog/addSiteLogRecordNameToList')
export const removeSiteLogRecordNameToList = createAction('siteLog/removeSiteLogRecordNameToList')
export const siteLogRecords = createAction('siteLog/siteLogRecords')
export const siteLogAnnotationTopics = createAction('siteLog/siteLogAnnotationTopics')

let siteLogAnnotationTopicsRecord = null

export const getSiteLogRecord = (recordName, cb) => (dispatch) => {
  dispatch(recordSnapshot(recordName, siteLogRecords, cb))
}

export const subscribeToAnnotationTopics = () => (dispatch, getState, dsClient) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const recordName = `site/${companyId}/annotationTopics`

  dispatch(recordSubscribe(recordName, siteLogAnnotationTopics, async (err, record) => {
    if (err === 404) {
      // create record
      siteLogAnnotationTopicsRecord = await dsClient().record.getRecord(recordName).whenReady()
      siteLogAnnotationTopicsRecord.set('topics', [])
      // call again to subscribe
      dispatch(subscribeToAnnotationTopics())
    }
    if (!err) siteLogAnnotationTopicsRecord = record
  }))
}

export const createAnnotationTopic = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  if (!siteLogAnnotationTopicsRecord) {
    return cb('retry')
  }

  let topics = siteLogAnnotationTopicsRecord.get('topics')
  if (payload.editId) {
    topics = map(topics, (topic) => {
      if (topic.id === payload.editId) topic.topic = payload.topic
      return topic
    })
    siteLogAnnotationTopicsRecord.set('topics', topics, (err) => cb(err, payload.editId))
    return
  }

  const id = dsClient().getUid()
  topics.push({ id, topic: payload.topic })
  siteLogAnnotationTopicsRecord.set('topics', topics, (err) => cb(err, id))
}

export const saveSiteLogRecord = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const userId = state.auth.clientData.userId

  dispatch(makeRpc('site/log/save', { ...payload, companyId, userId }, (err, res) => {
    if (err) console.error(err)
    else dispatch(recordSnapshot(res.data, siteLogRecords, cb))
  }))
}

export const deleteSiteLogRecord = (payload, cb = noop) => (dispatch, getState, dsClient) => {
  const state = getState()
  const companyId = state.auth.clientData.activeCompany
  const userId = state.auth.clientData.userId

  dispatch(makeRpc('site/log/delete', { ...payload, companyId, userId }, (err) => {
    if (!err) dispatch(siteLogRecords({ recordName: payload.recordName, remove: true }))
    cb(err)
  }))
}

export const addSiteLogRecord = () => (dispatch) => dispatch(addSiteLogRecordNameToList())
export const removeSiteLogRecord = (recordName = '') => (dispatch) => dispatch(removeSiteLogRecordNameToList(recordName))
