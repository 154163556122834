import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { startEmployeesData } from './redux/employeesActions'

import EmployeesDashboard from './EmployeesDashboard'

const mapDispatchToProps = dispatch => bindActionCreators({
  startEmployeesData
}, dispatch)

const mapStateToProps = state => {
  const { access } = state.main.userData
  return { employeesState: state.employees, userAccess: access }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesDashboard)
