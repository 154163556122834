import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import memoize from 'memoize-one'
import reduce from 'lodash/reduce'
import startCase from 'lodash/startCase'
import map from 'lodash/map'
import moment from 'moment-timezone'
import 'moment/locale/es'

import SiteLogCard from './SiteLogCard.js'
import { getSiteLogRecord, addSiteLogRecord, saveSiteLogRecord, deleteSiteLogRecord, subscribeToAnnotationTopics, createAnnotationTopic } from './redux/siteLogActions'
import { createLabour, editLabour } from '../../Contract/redux/contractActions.js'
import { createMachine, editMachine, createSite, editSite } from '../../Farms/redux/farmsActions.js'
import { deleteFile, getFileDownloadUrl } from '../../FileUtils/redux/fileUtilsActions'
import { recordNameToId } from '../../../utils'

const mapDispatchToProps = dispatch => bindActionCreators({
  getSiteLogRecord,
  saveSiteLogRecord,
  addSiteLogRecord,
  deleteSiteLogRecord,
  createLabour,
  editLabour,
  createMachine,
  editMachine,
  createSite,
  editSite,
  subscribeToAnnotationTopics,
  createAnnotationTopic,
  deleteFile,
  getFileDownloadUrl
}, dispatch)

const laboursColumns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'name', label: 'labor', options: { display: 'true', viewColumns: true, filter: true } }
  /* NOTE: hide description for simplicity, name should suffice
   { name: 'description', label: 'descripcion', options: { display: 'true', viewColumns: true, filter: true } }
  */
]

const machinesColumns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'name', label: 'implemento', options: { display: 'true', viewColumns: true, filter: true } },
  { name: 'farm', label: 'predio', options: { display: 'true', viewColumns: true, filter: true } },
  { name: 'description', label: 'descripcion', options: { display: 'true', viewColumns: true, filter: true } }
]

const sitesColumns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'name', label: 'lugar', options: { display: 'true', viewColumns: true, filter: true } },
  { name: 'type', label: 'tipo', options: { display: 'true', viewColumns: true, filter: true } },
  { name: 'farm', label: 'predio', options: { display: 'true', viewColumns: true, filter: true } }
]

const annotationTopicColumns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'topic', label: 'tema', options: { display: 'true', viewColumns: true, filter: true } }
]

const _laboursData = memoize((labourRecord) => reduce(labourRecord, (result, record, recordName) => {
  const data = {
    id: recordNameToId(recordName),
    name: startCase(record.name),
    record
    /* NOTE: hide description for simplicity, name should suffice
     description: record.description || '',
    */
  }
  result.push(data)
  return result
}, []))

const _machinesData = memoize((machineRecord, farmRecords) => reduce(machineRecord, (result, record, recordName) => {
  const data = {
    id: recordNameToId(recordName),
    name: `${record.manufacturer} ${record.model}`,
    description: record.description || '',
    farm: farmRecords[`farm/${record.farmId}`].displayName || 'n/d',
    record
  }
  result.push(data)
  return result
}, []))

const _sitesData = memoize((siteRecord, farmRecords) => reduce(siteRecord, (result, record, recordName) => {
  const data = {
    id: recordNameToId(recordName),
    name: record.displayName,
    farm: farmRecords[`farm/${record.farm}`].displayName || '',
    farmId: record.farm,
    type: record.type || ''
  }
  result.push(data)
  return result
}, []))

const _farmsData = memoize((farmRecords) => reduce(farmRecords, (result, record, recordName) => {
  const data = {
    id: recordNameToId(recordName),
    name: startCase(record.displayName || '')
  }
  result.push(data)
  return result
}, []))

const _siteLogAnnotationTopics = memoize((topics) => map(topics, (t) => ({ id: t.id, topic: startCase(t.topic) })))

const mapStateToProps = (state, ownProps) => {
  const { labourRecord } = state.contract
  const { siteRecord, farmRecord, companyMachineRecord } = state.farms
  const machinesData = _machinesData(companyMachineRecord, farmRecord)
  const laboursData = _laboursData(labourRecord)
  const sitesData = _sitesData(siteRecord, farmRecord)
  const farmsData = _farmsData(farmRecord)
  const siteLogAnnotationTopics = _siteLogAnnotationTopics(state.siteLog.siteLogAnnotationTopics.topics)
  const { access } = state.main.userData

  // month to text
  moment.locale('es')
  const split = ownProps.day.split('-')
  const date = {
    mm: startCase(moment().month(Number(split[1]) - 1).format('MMMM')),
    dd: split[2],
    yy: split[0]
  }

  return { userAccess: access, machinesData, machinesColumns, laboursData, laboursColumns, sitesData, sitesColumns, farmsData, siteLogAnnotationTopics, annotationTopicColumns, date }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteLogCard)
