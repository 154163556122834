import { createReducer } from '@reduxjs/toolkit'

import { loginRequest, loginResponse, logoutRequest } from './authActions'

const initialState = {
  isAuthenticated: false,
  clientData: {},
  loginRequest: false,
  credentials: {}
}

const authReducer = createReducer(initialState, {
  [loginRequest]: (state, action) => {
    state.credentials = action.payload
    state.loginRequest = true
  },

  [loginResponse]: (state, action) => {
    state.isAuthenticated = action.payload.isAuthenticated
    state.clientData = action.payload.clientData
    state.loginRequest = false
  },

  [logoutRequest]: (state, action) => (initialState)
})

export default authReducer
