import { connect } from 'react-redux'

import HarvestSummaryViewComponent from './HarvestSummaryView.js'

const mapStateToProps = (state, ownProps) => {
  const { value, formats } = ownProps.location.state
  return { employeeHarvestSummary: value, formats }
}

export default connect(mapStateToProps, null)(HarvestSummaryViewComponent)
