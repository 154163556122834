import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  textColor: {
    color: 'white'
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
    color: 'white'
  }
})

class CompanySelect extends React.Component {
  state = {
    label: 'Empresa'
  };

  render () {
    const { classes } = this.props

    return (
      <form className={classes.root} autoComplete='off'>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor='age-label-placeholder'>
            <Typography
              component='h6'
              variant='button'
              className={classes.textColor}
              noWrap
            >
              {this.state.label}
            </Typography>
          </InputLabel>
          <Select
            value={this.props.companySelectorData[`company/${this.props.activeCompany}`] || ''}
            onChange={(event) => this.props.changeActiveCompany(event.target.value.company)}
            name='company'
            className={classes.selectEmpty}
          >
            {map(this.props.companySelectorData, (company) => <MenuItem key={company.createdAt} value={company}>{company.displayName}</MenuItem>)}
          </Select>
        </FormControl>
      </form>
    )
  }
}

CompanySelect.propTypes = {
  classes: PropTypes.object.isRequired,
  activeCompany: PropTypes.string.isRequired,
  companySelectorData: PropTypes.object.isRequired,
  changeActiveCompany: PropTypes.func.isRequired
}

export default withStyles(styles)(CompanySelect)
