import React from 'react'
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables'
import { withRouter } from 'react-router'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import compact from 'lodash/compact'
import join from 'lodash/join'
import { isChileRut } from '../../utils'

let found = false
let lastSearch = ''

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
})

const CustomToolbarSelect = (props) => {
  return (
    <Button className={props.classes.button} color='primary' onClick={
      () => props.generateQr(map(props.displayData, (value) => ({ idx: value.dataIndex, data: value.data[0].split(':')[1] })), map(props.selectedRows.data, (row) => row.dataIndex))} >
      Generar QR
    </Button>
  )
}

class EmployeesTable extends React.Component {
  onCellClick = (data, meta) => {
    this.props.dispatchLoading(true)
    let id = this.props.data[meta.dataIndex].id.split(':')[1]
    // force data fetch
    this.props.getEmployeeData(id, () => {
      this.props.dispatchLoading(false)
      this.props.history.push(`/app/employees/view`, { employeeId: id.split('/')[1] })
    })
  }

  generateQr = (data, selectedIndex) => {
    const employeeIds = map(filter(data, (d) => includes(selectedIndex, d.idx)), (employee) => employee.data.split('/')[1])
    this.props.history.push(`/app/employees/qrcodes`, { employeeIds })
  }

  customSearch = (searchQuery, currentRow) => {
    const hayStack = currentRow.join(' ')
    const needle = hayStack.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    const last = this.props.data[this.props.data.length - 1].id === currentRow[0]

    if (lastSearch === '' && searchQuery) lastSearch = searchQuery

    if (lastSearch !== searchQuery) {
      found = false
      lastSearch = searchQuery
    }

    if (needle) {
      found = true
      return true
    }

    if (!found && last) {
      if (isChileRut(searchQuery)) {
        // go get the memebership from database in case it exists
        this.props.dispatchLoading(true)
        this.props.getMembership(searchQuery, () => {
          this.props.dispatchLoading(false)
          this.props.history.replace({ pathname: this.props.location.pathname, search: '?rut=' + searchQuery })
        })
      }
    } else {
      return false
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div>
        {
          this.props.loading
            ? <CircularProgress style={{ marginLeft: '50%', position: 'relative', top: 4 }} />
            : <MUIDataTable
              title={'Listado de trabajadores'}
              data={this.props.data}
              columns={this.props.columns}
              options={{ ...this.props.options,
                onCellClick: this.onCellClick,
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (<CustomToolbarSelect classes={classes} selectedRows={selectedRows} displayData={displayData} generateQr={this.generateQr} />),
                customSearch: this.customSearch,
                // // COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
                onFilterChange: (changedColumn, filterList) => {
                  let search = compact(map(filterList, (value, idx) => (value[0] ? `${this.props.columns[idx].name}=${value[0]}` : null)))
                  search = '?' + join(search, '&')
                  this.props.history.replace({ pathname: this.props.location.pathname, search })
                }

              }}
            />
        }
      </div>
    )
  }
}

EmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getEmployeeData: PropTypes.func.isRequired,
  getMembership: PropTypes.func.isRequired,
  dispatchLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  generateQr: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(EmployeesTable))
